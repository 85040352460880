import {
  ERROR_MESSAGE_REGEX,
  ERROR_MESSAGE_SEPARATOR,
  ErrorType,
} from '@yeekatee/shared-util-errors';

type ErrorTranslations = Record<ErrorType, string>;

export const getErrorI18n = (key: ErrorType) => {
  const translations: ErrorTranslations = {
    [ErrorType.UserNotConfirmedException]: $localize`User has not been confirmed yet`,
    [ErrorType.UsernameExistsException]: $localize`User does not exist`,
    [ErrorType.PasswordResetRequiredException]: $localize`Password reset is required`,
    [ErrorType.EmailExistsException]: $localize`Email already exists`,
    [ErrorType.UserEmailNotConfirmedException]: $localize`User email has not been confirmed yet`,
    [ErrorType.UserLambdaValidationException]: $localize`Error performing this operation`,
    [ErrorType.NotAuthorizedException]: $localize`Wrong credentials used`,
    [ErrorType.InvalidParameterException]: $localize`Invalid parameter value used`,
    [ErrorType.AliasExistsException]: $localize`Username already exists`,
    [ErrorType.GenericException]: $localize`An error was encountered. Please try again later`,
  };

  return translations[key];
};

interface GraphQLError {
  errorType: string;
  message: string;
}

export function handleError(error: any): string {
  if (!error) return getErrorI18n(ErrorType.GenericException);

  // Error coming from Lambda triggers
  let { name, message } = error;
  let data = '';
  const formattedError = ERROR_MESSAGE_REGEX.exec(message);
  if (formattedError?.length) {
    const errorMessage = formattedError[1];
    [name, data] = errorMessage.split(ERROR_MESSAGE_SEPARATOR);
  }

  // Error coming from GraphQL
  const errors = error.graphQLErrors as GraphQLError[];
  if (errors && errors.length > 0) {
    const gqlError = errors.slice(-1).pop();
    if (gqlError) {
      name = gqlError.errorType;
    }
  }

  if (!(name in ErrorType)) {
    name = ErrorType.GenericException;
  }

  message = getErrorI18n(name as ErrorType);

  if (data) {
    message = message.replace('%s', data);
  }

  return message;
}
