import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ENVIRONMENT, Environment } from '@yeekatee/shared-util-environment';
import { ImgFallbackDirective } from '../directives/img-fallback.directive';

@Component({
  selector: 'yeekatee-instrument-logo',
  standalone: true,
  templateUrl: './instrument-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ImgFallbackDirective],
})
export class InstrumentLogoComponent {
  @Input() cssClass = '';
  @Input() src?: string | null = '';
  @Input() alt = 'Avatar';

  protected readonly defaultSrc = this.environment.fallbackAssetLogo;

  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment) {}
}
