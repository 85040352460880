export enum ErrorType {
  UserNotConfirmedException = 'UserNotConfirmedException',
  UsernameExistsException = 'UsernameExistsException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  EmailExistsException = 'EmailExistsException',
  UserEmailNotConfirmedException = 'UserEmailNotConfirmedException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  NotAuthorizedException = 'NotAuthorizedException',
  InvalidParameterException = 'InvalidParameterException',
  AliasExistsException = 'AliasExistsException',
  GenericException = 'GenericException',
}

export const ERROR_MESSAGE_BOUNDARY = '##';
export const ERROR_MESSAGE_SEPARATOR = ':';
export const ERROR_MESSAGE_REGEX = new RegExp(
  `${ERROR_MESSAGE_BOUNDARY}(\\w+${ERROR_MESSAGE_SEPARATOR}?.*)${ERROR_MESSAGE_BOUNDARY}`,
  'g',
);

export function generateError(type: ErrorType, data?: string): Error {
  let errorMessage = `${type}`;
  if (data) {
    errorMessage += `${ERROR_MESSAGE_SEPARATOR}${data}`;
  }
  return new Error(
    `${ERROR_MESSAGE_BOUNDARY}${errorMessage}${ERROR_MESSAGE_BOUNDARY}`,
  );
}
