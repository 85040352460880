import { NgOptimizedImage } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IonicSlides } from '@ionic/angular/standalone';

export type ImageViewer = {
  url: string;
  width?: number;
  height?: number;
  alt?: string;
};

@Component({
  selector: 'yeekatee-images-viewer',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './images-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // For Swiper.js - https://ionicframework.com/docs/angular/slides
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImagesViewerComponent {
  swiperModules = [IonicSlides];

  @Input() images?: ImageViewer[];
  @Input() startFrom?: number = 0;
  @Output() closeModal = new EventEmitter<void>();

  @ViewChild('container') container?: ElementRef;

  defaultAlt = 'Image';
  defaultHeight = 256;
  defaultWidth = 256;
}
