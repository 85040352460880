import { TimeSeriesPeriod } from '@yeekatee/client-api-angular';
import { ChartNavigation } from './chart-navigation.interface';

export enum TimeSeriesNavPeriod {
  day = '1D',
  week = '1W',
  month = '1M',
  threemonths = '3M',
  sixmonths = '6M',
  year = '1Y',
  fiveYears = 'MAX',
}

export const timeSeriesNavConfig: ChartNavigation = [
  {
    content: TimeSeriesNavPeriod.day,
    value: TimeSeriesPeriod.day,
  },
  {
    content: TimeSeriesNavPeriod.week,
    value: TimeSeriesPeriod.week,
  },
  {
    content: TimeSeriesNavPeriod.month,
    value: TimeSeriesPeriod.month,
  },
  {
    content: TimeSeriesNavPeriod.threemonths,
    value: TimeSeriesPeriod.threeMonths,
  },
  {
    content: TimeSeriesNavPeriod.sixmonths,
    value: TimeSeriesPeriod.sixMonths,
  },
  {
    content: TimeSeriesNavPeriod.year,
    value: TimeSeriesPeriod.year,
  },
  {
    content: TimeSeriesNavPeriod.fiveYears,
    value: TimeSeriesPeriod.fiveYears,
  },
];
