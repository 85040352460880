<ion-toolbar>
  <ion-buttons slot="start">
    <ion-button
      class="h-7 ion-hide-xl-up"
      (click)="routeToAuth(!user)"
      [routerLink]="
        user ? [baseRoute, NavigationRouteNames.USERS, user.id] : []
      "
    >
      <div slot="icon-only" class="avatar">
        <yeekatee-avatar [src]="user?.picture" [size]="AvatarSize.Tiny" />
      </div>
    </ion-button>
  </ion-buttons>
  @if (loading) {
    <ion-title>
      <ion-spinner name="dots" />
    </ion-title>
  } @else if (title) {
    <yeekatee-title
      (titleClicked)="titleClicked.emit()"
      [title]="title"
      [button]="clickableTitle"
    />
  } @else {
    <yeekatee-logo
      slot="start"
      cssClass="w-40 h-8 align-middle ion-margin-horizontal ion-hide-xl-up"
    />
  }
  <ion-buttons slot="end">
    <ion-button
      class="ion-hide-xl-up"
      [routerLink]="[baseRoute, NavigationRouteNames.CHATS]"
    >
      <ion-icon slot="icon-only" name="chatbubble-ellipses-outline" />
    </ion-button>
  </ion-buttons>
  @if (loading) {
    <ion-progress-bar type="indeterminate" />
  }
</ion-toolbar>
