import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AccountLinkingApiActions } from '../../account-linking';
import { GamesApiActions } from '../../games/games.actions';
import { UsersProfileActions } from '../../users';
import {
  PortfolioActions,
  PortfoliosApiActions,
  PortfoliosListActions,
  PortfoliosNewSimulationActions,
  PortfoliosReportsActions,
  UserPropertiesEffectsActions,
} from '../portfolios.actions';
import {
  DEFAULT_REPORT_PORTFOLIO_KEY,
  getDefaultPortfolioKeyAfterDelete,
  UserPortfolioEntity,
} from '../portfolios.models';

export const name = 'userPortfolios';

export interface State extends EntityState<UserPortfolioEntity> {
  loading: boolean;
  error: boolean;

  defaultPortfolioKey: string | undefined;
}

export interface PartialState {
  [name]: State;
}

export const adapter = createEntityAdapter<UserPortfolioEntity>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: false,
  defaultPortfolioKey: undefined,
});

export const reducer = createReducer(
  initialState,
  on(
    PortfoliosApiActions.loadReportPreferencesSuccess,
    PortfoliosApiActions.loadReportPreferencesFailure,
    UserPropertiesEffectsActions.handleNotification,
    PortfoliosListActions.loadList,
    PortfoliosListActions.reloadList,
    AccountLinkingApiActions.exchangedFlanksCodeSuccess,
    AccountLinkingApiActions.syncYeekateeBankSuccess,
    AccountLinkingApiActions.submitScaSuccess,
    GamesApiActions.joinGameSuccess,
    PortfoliosNewSimulationActions.addSimulationPortfolio,
    PortfolioActions.refreshPortfolioReport,
    UsersProfileActions.onEnter,
    PortfoliosReportsActions.setPortfoliosReport,
    PortfoliosReportsActions.copyPortfolio,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PortfoliosApiActions.loadUserPropertiesSuccess,
    (state, { portfolios }): State =>
      adapter.upsertMany(portfolios, {
        ...state,
        defaultPortfolioKey:
          portfolios.map((p) => p.key).at(0) ?? DEFAULT_REPORT_PORTFOLIO_KEY,
        loading: false,
        error: false,
      }),
  ),
  on(
    PortfoliosApiActions.loadUserPortfoliosSuccess,
    (state, { portfolios }): State =>
      adapter.upsertMany(portfolios, {
        ...state,
        loading: false,
        error: false,
      }),
  ),
  on(
    PortfoliosApiActions.loadUserPropertiesFailure,
    PortfoliosApiActions.loadUserPortfoliosFailure,
    PortfoliosApiActions.copyPortfolioFailure,
    (state): State => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
  on(
    PortfoliosListActions.renamePortfolio,
    (state, { portfolio, name }): State =>
      portfolio.id
        ? adapter.updateOne({ id: portfolio.id, changes: { name } }, state)
        : state,
  ),
  on(
    PortfoliosListActions.deletePortfolio,
    (state, { portfolio }): State =>
      portfolio.id
        ? adapter.removeOne(portfolio.id, {
            ...state,
            defaultPortfolioKey: getDefaultPortfolioKeyAfterDelete(
              state,
              portfolio,
            ),
          })
        : state,
  ),
  on(
    UserPropertiesEffectsActions.updatePortfolioBookingDates,
    (state, { portfolioUpdate }): State =>
      adapter.updateOne(portfolioUpdate, state),
  ),
);
