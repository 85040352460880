import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IonBackButton, isPlatform } from '@ionic/angular/standalone';
import { extractBaseRoute } from '@yeekatee/shared-util-routes';

@Component({
  selector: 'yeekatee-back-button',
  standalone: true,
  imports: [IonBackButton, RouterLink],
  templateUrl: './back-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  @Input() defaultHref: string | undefined;
  @Input() noRouting = false;

  protected readonly isIos = isPlatform('ios');
  protected readonly baseRoute = extractBaseRoute(this.router.url);

  constructor(private readonly router: Router) {}
}
