import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocalesSelectors } from '../locales';
import { AUTH_FEATURE_KEY, State } from './auth.reducer';

export const selectAuthState = createFeatureSelector<State>(AUTH_FEATURE_KEY);

export const selectAuthenticatorViewModel = createSelector(
  selectAuthState,
  LocalesSelectors.getLandingPageLanguage,
  (state, language) => ({
    view: state.view,
    loading: state.loading,
    destination: state.challengeDestination,
    username: state.username,
    language,
  }),
);

export const selectPasswordSettingsViewModel = createSelector(
  selectAuthState,
  (state) => ({
    loading: state.loading,
    username: state.username,
  }),
);

export const selectUserId = createSelector(
  selectAuthState,
  (state) => state.userId,
);

export const selectUser = createSelector(
  selectAuthState,
  (state) => state.user,
);

export const selectLastRoute = createSelector(
  selectAuthState,
  (state) => state.lastRoute,
);

export const selectUpdatedCredentials = createSelector(
  selectAuthState,
  (state) => state.updatedCredentials,
);

export const selectAuthenticatedUserId = createSelector(
  selectUser,
  (user) => user?.id,
);

export const isAuthenticated = createSelector(
  selectUserId,
  (userId) => !!userId,
);

export const selectEmailVerified = createSelector(
  selectUser,
  (user) => user?.email_verified,
);

export const selectPreferredUsername = createSelector(
  selectUser,
  (user) => user?.preferred_username,
);

export const selectGroups = createSelector(selectUser, (user) => user?.groups);

export const selectIsFederatedSignIn = createSelector(
  selectGroups,
  (groups) =>
    groups?.some(
      (group) => group.endsWith('Apple') || group.endsWith('Google'),
    ) ?? false,
);

export const selectSignUpComplete = createSelector(
  selectAuthenticatedUserId,
  selectEmailVerified,
  selectIsFederatedSignIn,
  (userId, emailVerified, federatedSignIn) =>
    !!userId && (!!emailVerified || federatedSignIn),
);

export const selectRedirectCustomState = createSelector(
  selectLastRoute,
  (lastRoute) => JSON.stringify({ lastRoute }),
);

export const selectAccountVm = createSelector({
  user: selectUser,
  isFederatedSignIn: selectIsFederatedSignIn,
});

export const selectShowOnboardingModal = createSelector(
  selectSignUpComplete,
  selectPreferredUsername,
  (signUpComplete, username) => signUpComplete && username === undefined,
);

export const selectIsOnboarded = createSelector(
  selectSignUpComplete,
  selectPreferredUsername,
  (signUpComplete, username) => signUpComplete && !!username,
);
