import { AppInfo, URLOpenListenerEvent } from '@capacitor/app';
import {
  AppUpdateInfo,
  FlexibleUpdateState,
} from '@capawesome/capacitor-app-update';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../utils';

export const CapacitorAppActions = createActionGroup({
  source: 'Capacitor App',
  events: {
    /**
     * Dispatched when the app was opened via a deep link URL.
     *
     * @see https://capacitorjs.com/docs/apis/app#addlistenerappurlopen-
     */
    'App URL Open': props<{ event: URLOpenListenerEvent }>(),

    /**
     * Dispatched when the app changes between foreground and background.
     *
     * @see https://capacitorjs.com/docs/apis/app#addlistenerappstatechange-
     */
    'App State Change': props<{
      isActive: boolean;
      latestStateUpdate?: string;
    }>(),

    'Get App Info Success': props<{ appInfo: AppInfo }>(),
    'Get App Info Failure': errorProps(),
  },
});

export const CapacitorAppEffectsActions = createActionGroup({
  source: 'Capacitor App Effects',
  events: {
    /**
     * Dispatched when the app is initialized.
     */
    'On Init': emptyProps(),

    /**
     * Dispatched when the app was opened via a deep link URL and needs to route.
     */
    'Route On Deeplink': props<{ routeUrl: string }>(),

    /**
     * Triggered by other effects to update the timestamp of the latest state update.
     */
    'Update Timestamp': props<{ latestStateUpdate: number }>(),

    /**
     * The platforms reported by Capacitor.
     */
    'Update Platforms': props<{ platforms: string[] }>(),
  },
});

/**
 * Actions from the App Update Capacitor plugin
 */
export const CapacitorAppUpdateActions = createActionGroup({
  source: 'Capacitor App Update',
  events: {
    'Check For Update Success': props<{ updateInfo: AppUpdateInfo }>(),
    'Check For Update Failure': errorProps(),

    /**
     * Listen on event `onFlexibleUpdateStateChange` from the App Update plugin.
     *
     * @see https://capawesome.io/plugins/app-update/#addlisteneronflexibleupdatestatechange
     */
    'Flexible Update Status Update': props<{
      flexibleUpdateState: FlexibleUpdateState;
    }>(),
  },
});
