import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ImageUploadService } from '@yeekatee/shared-util-images';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'yeekatee-blurhash-image',
  standalone: true,
  templateUrl: './blurhash-image.component.html',
})
export class BlurhashImageComponent implements OnChanges {
  @Input() hash?: string;
  @Input() width?: number;
  @Input() height?: number;

  protected imageUrl?: string;

  constructor(private readonly imageUploadService: ImageUploadService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const _hash = changes['hash']?.currentValue as string | undefined;
    const _width = changes['width']?.currentValue as number | undefined;
    const _height = changes['height']?.currentValue as number | undefined;

    if (!_hash || !_width || !_height) return;

    this.imageUrl = this.imageUploadService.decodeImageFromBlurhash(
      _hash,
      _width,
      _height,
    );
  }
}
