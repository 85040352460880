import { createSelector } from '@ngrx/store';
import {
  GameCompetitionState,
  GamePlayerRanking,
} from '@yeekatee/client-api-angular';
import { AuthSelectors } from '../../auth';
import { NavigationSelectors } from '../../navigation';
import { GamePlayerEntity } from '../games.models';
import { GamesState, selectGamesState } from '../games.reducer';
import * as CompetitionsSelectors from './games-competitions.selectors';
import * as fromReducers from './games-players.reducer';

const { selectEntities } = fromReducers.adapter.getSelectors();

export const selectGamePlayersState = createSelector(
  selectGamesState,
  (state: GamesState) => state[fromReducers.FEATURE_KEY],
);

export const selectGamePlayersEntities = createSelector(
  selectGamePlayersState,
  (state: fromReducers.State) => selectEntities(state),
);

export const isGamePlayersLoading = createSelector(
  selectGamePlayersState,
  (state: fromReducers.State) => state.loading,
);

export const selectGameOwnPlayer = createSelector(
  selectGamePlayersEntities,
  AuthSelectors.selectUser,
  (entities, user): GamePlayerEntity | undefined =>
    user?.id ? entities[user.id] : undefined,
);

export const selectOwnPlayerCompetitions = createSelector(
  selectGameOwnPlayer,
  (player) => player?.competitions ?? [],
);

export const selectOwnPlayerCompetition = createSelector(
  selectGameOwnPlayer,
  NavigationSelectors.selectGameIdFromRoute,
  (player, gameId) =>
    gameId ? player?.competitions?.find((c) => c.gameId === gameId) : undefined,
);

export const isOwnPlayerParticipating = createSelector(
  selectOwnPlayerCompetition,
  (competition) => competition?.isParticipating ?? false,
);

export const selectOwnPlayerRanking = createSelector(
  selectOwnPlayerCompetition,
  CompetitionsSelectors.selectRankingType,
  (competition, rankingType): GamePlayerRanking | undefined =>
    competition?.rankings?.find((ranking) => ranking.type === rankingType) ??
    undefined,
);

export const selectOwnPlayerPortfolioKey = createSelector(
  selectOwnPlayerCompetition,
  (competition) => competition?.portfolioKey ?? undefined,
);

export const selectGameToJoin = createSelector(
  selectOwnPlayerCompetitions,
  CompetitionsSelectors.getAllGameCompetitions,
  isGamePlayersLoading,
  (playerCompetitions, allCompetitions, playerLoading) => {
    if (playerLoading !== false) return;

    const isNotParticipating = (id: string) =>
      !playerCompetitions.some(({ gameId }) => gameId === id);

    const isRunning = (state?: GameCompetitionState | null) =>
      state === GameCompetitionState.TRIAL ||
      state === GameCompetitionState.STARTED;

    return (
      allCompetitions.find(
        ({ state, id }) => isRunning(state) && isNotParticipating(id),
      )?.id ?? undefined
    );
  },
);
