export const computeHash = (keys: string | string[] = [], userId?: string) => {
  const array = [...(userId ? [userId] : []), ...[keys].flat().sort()];
  const combinedString = [userId, ...array].join('-');
  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    const char = combinedString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash.toString();
};
