import {
  TradeStatus,
  UserTrxBuySell,
  UserTrxExpensePortfolio,
  UserTrxFi,
  UserTrxForex,
  UserTrxMacc,
  UserTrxPayment,
  UserTrxPropertyStatus,
} from '@yeekatee/booking-util-definitions';
import { SimulationTransactionError } from '@yeekatee/client-api-angular';

// Virtual portfolios

type VirtualTransactionErrors = Partial<Record<TradeStatus, string>>;

export const getVirtualTransactionErrorI18n = (key: TradeStatus) => {
  const translations: VirtualTransactionErrors = {
    BookingError: $localize`The transactions could not be booked`,
    CashToSmall: $localize`The total amount for the transaction is too small. Please increase the monetary amount of the transaction`,
    Intraday: $localize`Intraday transactions are not allowed`,
    InvalidBalances: $localize`The account balance could not be determined`,
    InvalidCashAmount: $localize`The monetary amount you provided is either missing or not formatted properly`,
    InvalidCurrency: $localize`The currency you provided is either missing or not formatted properly`,
    InvalidExchange: $localize`The exchange rate you provided is either missing or not formatted properly`,
    InvalidFi: $localize`The financial instrument you provided can not be traded at present time`,
    NotAllowedFi: $localize`The provided financial instrument is not allowed to trade`,
    InvalidFiAmount: $localize`The quantity you provided is either missing or not formatted properly`,
    InvalidPortfolio: $localize`The portfolio you provided is not known, closed or not virtual`,
    InvalidPrice: $localize`The price you provided is either missing or not formatted properly`,
    NoCashBalance: $localize`The balance of your account can not cover the entered transaction`,
    NoFiBalance: $localize`You do not own enough units to activate a sell order`,
    NotProcessed: $localize`The entered transaction could not be processed`,
    OkCashAdjusted: $localize`The monetary amount for the buy order has been adjusted`,
    OkFiAdjusted: $localize`The quantity for the buy order has been adjusted`,
    OldTimestamp: $localize`The price timestamp is too old`,
    TooManyTrxDay: $localize`You exceeded the allowed number of transactions in a day`,
    UnknownFi: $localize`The financial instrument you provided is unknown to us`,
    Timeout: $localize`Please try later again`,
    MultiCurrency: $localize`You have multiple currencies for the provided financial instrument`,
    MultiTransaction: $localize`You have multiple transactions for the provided financial instrument`,
    AllocationTooHigh: $localize`Your buy leads to a too high allocation`,
    TooManyInvestments: $localize`You have too many investments at the same time`,
    OutsideTrxsWindow: $localize`You are outside of the trading window`,
  };

  return translations[key];
};

// Simulation portfolios

type TransactionKey =
  | keyof UserTrxBuySell
  | keyof UserTrxPayment
  | keyof UserTrxForex
  | keyof UserTrxExpensePortfolio;

type TransactionItemKey = keyof UserTrxMacc | keyof UserTrxFi;

type SimulationTransactionPropertyErrorI18n = Partial<
  Record<
    TransactionKey | UserTrxPropertyStatus,
    | Partial<
        Record<
          TransactionItemKey | UserTrxPropertyStatus,
          Partial<Record<UserTrxPropertyStatus, string>>
        >
      >
    | string
  >
>;

const getSimulationTransactionErrorI18n = (
  key: UserTrxPropertyStatus | TransactionKey,
) => {
  const translations: SimulationTransactionPropertyErrorI18n = {
    [UserTrxPropertyStatus.ExchangeMissmatch]: $localize`Exchange rate mismatch found`,
    [UserTrxPropertyStatus.ForexCurrencyMissmatch]: $localize`Forex currency is missing`,
    instrument: {
      currency: {
        [UserTrxPropertyStatus.Invalid]: $localize`Instrument currency is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Instrument currency is missing`,
      },
      amount: {
        [UserTrxPropertyStatus.Invalid]: $localize`Instrument quantity is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Instrument quantity is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Instrument quantity has a wrong sign`,
      },
      price: {
        [UserTrxPropertyStatus.Invalid]: $localize`Instrument price is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Instrument price is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Instrument price has a wrong sign`,
      },
      gross: {
        [UserTrxPropertyStatus.Invalid]: $localize`Instrument gross quantity is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Instrument gross quantity is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Instrument gross quantity has a wrong sign`,
      },
      expense: {
        [UserTrxPropertyStatus.Invalid]: $localize`Instrument expense is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Instrument expense is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Instrument expense has a wrong sign`,
      },
    },
    macc: {
      currency: {
        [UserTrxPropertyStatus.Invalid]: $localize`Account currency is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Account currency is missing`,
      },
      amount: {
        [UserTrxPropertyStatus.Invalid]: $localize`Account amount is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Account amount is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Account amount has a wrong sign`,
      },
      exchange: {
        [UserTrxPropertyStatus.Invalid]: $localize`Account exchange is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Account exchange is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Account exchange has a wrong sign`,
      },
    },
    macc1: {
      currency: {
        [UserTrxPropertyStatus.Invalid]: $localize`First account currency is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`First account currency is missing`,
      },
      amount: {
        [UserTrxPropertyStatus.Invalid]: $localize`First account amount is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`First account amount is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`First account amount has a wrong sign`,
      },
      exchange: {
        [UserTrxPropertyStatus.Invalid]: $localize`First account exchange is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`First account exchange is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`First account exchange has a wrong sign`,
      },
    },
    macc2: {
      currency: {
        [UserTrxPropertyStatus.Invalid]: $localize`Second account currency is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Second account currency is missing`,
      },
      amount: {
        [UserTrxPropertyStatus.Invalid]: $localize`Second account amount is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Second account amount is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Second account amount has a wrong sign`,
      },
      exchange: {
        [UserTrxPropertyStatus.Invalid]: $localize`Second account exchange is invalid`,
        [UserTrxPropertyStatus.Missing]: $localize`Second account exchange is missing`,
        [UserTrxPropertyStatus.WrongSign]: $localize`Second account exchange has a wrong sign`,
      },
    },
  };

  return translations[key];
};

export const getSimulationTransactionTranslation = (
  error?: SimulationTransactionError,
): string | undefined => {
  const firstError = error?.fieldErrors?.[0];
  if (!firstError?.error) return undefined;

  let translation = getSimulationTransactionErrorI18n(
    firstError.error as UserTrxPropertyStatus,
  );
  if (typeof translation === 'string') return translation;

  if (!firstError.field) return undefined;

  let value: any = null;
  const fields = firstError.field.split('.');
  for (let f = 0; f < fields.length && value !== undefined; f++) {
    const field = fields[f];
    value = value
      ? value[field]
      : getSimulationTransactionErrorI18n(field as TransactionKey);
  }

  translation = value ? value[firstError.error] : undefined;
  return typeof translation === 'string' ? translation : undefined;
};
