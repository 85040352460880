import { Pipe, PipeTransform } from '@angular/core';
import { Country, countries } from '../country-code/countries';

@Pipe({
  name: 'countryName',
  standalone: true,
})
export class CountryNamePipe implements PipeTransform {
  transform(countryIso: string): string | undefined {
    return countries
      .find((country: Country) => country?.isoCode === countryIso)
      ?.name();
  }
}
