import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

enum SupportedLocales {
  EN = 'en',
  DE = 'de',
}

type CashSuffixes = Record<string, Record<SupportedLocales, string>>;

@Pipe({
  name: 'numberSuffix',
  standalone: true,
})
export class NumberSuffixPipe extends DecimalPipe implements PipeTransform {
  defaultLanguage = SupportedLocales.EN;

  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    super(locale);
  }

  override transform(value: any, args?: any): any {
    const { baseValue, suffix } = this.getSuffix(value);

    return baseValue === 0
      ? super.transform(baseValue, args)
      : super.transform(baseValue, args) + suffix;
  }

  getSuffix(value: number | string): {
    baseValue: number | string;
    suffix: string;
  } {
    // https://minershaven.fandom.com/wiki/Cash_Suffixes
    const cashSuffixes: CashSuffixes = {
      '18': { en: 'Qn', de: 'Trill.' },
      '15': { en: 'qd', de: 'Brd.' },
      '12': { en: 'T', de: 'Bio.' },
      '9': { en: 'B', de: 'Mrd.' },
      '6': { en: 'M', de: 'Mio.' },
      '3': { en: 'k', de: 'Tsd.' },
    };
    const numberValue = Number(value);
    if (Number.isNaN(numberValue)) return { baseValue: value, suffix: '' };
    if (numberValue === 0) return { baseValue: 0, suffix: '' };
    const exponent = Math.floor(Math.log10(Math.abs(numberValue)) / 3) * 3;

    if (exponent === 0) return { baseValue: numberValue, suffix: '' };

    const suffixMap = cashSuffixes[`${exponent}`];
    if (!suffixMap) return { baseValue: numberValue, suffix: '' };

    const language = this.locale.substring(0, 2);
    const suffix =
      suffixMap[language as SupportedLocales] ??
      suffixMap[this.defaultLanguage];

    const scale = Math.pow(10, exponent);
    return { baseValue: numberValue / scale, suffix: suffix };
  }
}
