import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { DynamicTranslationService } from './dynamic-translation/dynamic-translation.service';

export interface DynamicTranslatedText {
  status: 'translated' | 'identical';
  text: string;
}

@Pipe({
  standalone: true,
  name: 'dynamicTranslation',
})
export class DynamicTranslationPipe implements PipeTransform {
  private readonly defaultSourceLanguage = 'en';
  private readonly defaultTargetLanguage = new Intl.Locale(this.appLocale)
    .language;

  constructor(
    private readonly dynamicTranslationService: DynamicTranslationService,
    @Inject(LOCALE_ID) private readonly appLocale: string,
  ) {}

  /**
   * Process a dynamic source text, and translate it into a target language.
   * By default, it translates from English into the user's current app language
   *
   * @param sourceText the text to translate
   * @param sourceLanguage the source language of the text - language only, without the region
   * @param targetLanguage the language to translate into - language only, without the region
   */
  transform(
    sourceText?: string | null,
    sourceLanguage?: string,
    targetLanguage?: string,
  ): Observable<DynamicTranslatedText> | undefined {
    if (!sourceText) return undefined;
    const source = sourceLanguage ?? this.defaultSourceLanguage;
    const target = targetLanguage ?? this.defaultTargetLanguage;

    if (source === target) return of({ status: 'identical', text: sourceText });

    return this.dynamicTranslationService
      .translateText(sourceText, source, target)
      .pipe(
        map((value) => ({ status: 'translated', text: value.translatedText })),
      );
  }
}
