<ion-header>
  <ion-toolbar>
    <ion-title i18n>Select country</ion-title>
    <ion-buttons slot="start">
      <yeekatee-close-button (click)="cancel.emit()" />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar
    [formControl]="searchQuery"
    i18n-placeholder
    placeholder="Search"
    role="searchbox"
  />
  @if (countryList$ | async; as countryList) {
    <ion-list>
      @for (letter of getKeys(countryList); track letter) {
        <ion-item-group>
          @if (letter !== top) {
            <ion-item-divider>
              <ion-label>{{ letter }}</ion-label>
            </ion-item-divider>
          }
          @for (country of countryList[letter]; track country.isoCode) {
            <ion-item (click)="countryCodeSelect.emit(country)" button="true">
              <ion-label>
                {{ country.name() }}
              </ion-label>
              <ion-chip slot="end">
                <ion-avatar>
                  <img
                    [alt]="country.isoCode"
                    [src]="'/assets/flags/1x1/' + country.isoCode + '.svg'"
                  />
                </ion-avatar>
                <ion-label>{{ country.dialCode }}</ion-label>
              </ion-chip>
            </ion-item>
          }
        </ion-item-group>
      }
    </ion-list>
  }
</ion-content>
