import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice',
  standalone: true,
})
export class FormatPricePipe extends DecimalPipe implements PipeTransform {
  private readonly minDecimals = 2;
  private readonly precision = 5;
  private readonly longDecimals = '1.5';

  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    super(locale);
  }

  override transform(value: any, isLongDecimals?: any): any {
    const price = Number(value);
    if (Number.isNaN(price)) return value;

    let digitsInfo = '';
    if (Number.isFinite(price)) {
      if (isLongDecimals) digitsInfo = this.longDecimals;
      else {
        const t0 = price.toString(); // @TODO improve, might switch to scientific notation
        const d0 = t0.indexOf('.');

        if (
          d0 < 0 ||
          t0.length - d0 - 1 <= this.minDecimals ||
          d0 + this.minDecimals >= this.precision
        ) {
          digitsInfo = '1.2';
        } else {
          const decimals = this.precision - d0;
          digitsInfo = `1.${decimals}`;
        }
      }
    }

    return super.transform(price, digitsInfo, this.locale);
  }
}
