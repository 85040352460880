import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[yeekateeImgFallback]',
  standalone: true,
})
export class ImgFallbackDirective {
  @Input({ required: true }) src: string | undefined | null;
  @Input({ required: true }) defaultSrc: string | undefined;

  @HostBinding('src') get srcBinding() {
    return this.src ?? this.defaultSrc;
  }

  @HostListener('error') onError() {
    this.src = this.defaultSrc;
  }
}
