// We import all the Angular locales we support explicitly.
// This is needed to have them bundled in the app.
// Only the one selected locale will however be registered on demand at init.
import ngLocaleDe from '@angular/common/locales/de';
import ngLocaleDeCH from '@angular/common/locales/de-CH';
import ngLocaleEn from '@angular/common/locales/en';
import ngLocaleEs from '@angular/common/locales/es';
import ngLocaleFr from '@angular/common/locales/fr';
import ngLocaleFrCH from '@angular/common/locales/fr-CH';
import ngLocaleIt from '@angular/common/locales/it';
import ngLocaleItCH from '@angular/common/locales/it-CH';

/**
 * These are the locales for which we have a translation, and that we tested.
 *
 * ! Always keep sorted with the primary regional variant first!
 *   This is important for the findBestLocaleGivenDeviceAndPreference function.
 *   It ensures that the primary regional variant is preferred when
 *   an exact match is not found.
 */
export const availableLocales = [
  'en-US',
  // 'en-GB',

  'de-DE',
  'de-CH',

  'es-ES',

  'fr-FR',
  'fr-CH',

  'it-IT',
  'it-CH',
] as const;
export type AvailableLocale = (typeof availableLocales)[number];

/**
 * This provides a mapping of our supported locales,
 * and the related Angular module to register and translation file to load.
 * TODO: Activating languages according to your availability
 */
export const localeLookupMap: Record<AvailableLocale, LocaleDetails> = {
  'de-CH': {
    ngLocale: ngLocaleDeCH,
    translationFile: 'de',
    name: 'Deutsch',
    region: 'Schweiz',
  },
  'de-DE': {
    ngLocale: ngLocaleDe,
    translationFile: 'de',
    name: 'Deutsch',
    region: 'Deutschland',
  },
  // 'en-GB': {
  //   ngLocale: ngLocaleEnGB,
  //   translationFile: 'en-GB',
  //   name: 'English',
  //   region: 'Great Britain',
  // },
  'en-US': {
    ngLocale: ngLocaleEn,
    translationFile: undefined,
    name: 'English',
    region: 'United States',
  },
  'es-ES': {
    ngLocale: ngLocaleEs,
    translationFile: 'es',
    name: 'Español',
    region: 'España',
  },
  'fr-CH': {
    ngLocale: ngLocaleFrCH,
    translationFile: 'fr',
    name: 'Français',
    region: 'Suisse',
  },
  'fr-FR': {
    ngLocale: ngLocaleFr,
    translationFile: 'fr',
    name: 'Français',
    region: 'France',
  },
  'it-CH': {
    ngLocale: ngLocaleItCH,
    translationFile: 'it',
    name: 'Italiano',
    region: 'Svizzera',
  },
  'it-IT': {
    ngLocale: ngLocaleIt,
    translationFile: 'it',
    name: 'Italiano',
    region: 'Italia',
  },
};

export type LocaleDetails = {
  /**
   * The Angular locale file to load from `@angular/common/locales`.
   */
  ngLocale: unknown;

  /**
   * The name of the JSON translation file in `apps/mobile/src/assets/locale`,
   * without the path and suffix.
   *
   * If undefined, we will load no translation, but still apply the locale.
   *
   * @example 'de'
   */
  translationFile?: string;

  /**
   * The name of the language, in the language itself.
   */
  name: string;

  /**
   * If available, the region.
   */
  region?: string;
};

/**
 * Check if a string is a locale we support.
 *
 * @param locale the string to check - SHOULD be a BCP47 language code
 */
export const isSupportedLocale = (locale: string): locale is AvailableLocale =>
  availableLocales.includes(locale as AvailableLocale);
