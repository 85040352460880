import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs';

/**
 * Effects to log all actions to the console.
 *
 * @todo if we configured the remote devtools, we could avoid this.
 */
@Injectable()
export class LoggerEffects {
  logToConsole$ = createEffect(
    () =>
      this.actions$.pipe(
        tap(({ type, ...payload }) => console.log(type, payload)),
      ),
    { dispatch: false },
  );

  constructor(private readonly actions$: Actions) {}
}
