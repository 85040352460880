import { createSelector } from '@ngrx/store';
import {
  InstrumentsState,
  selectInstrumentsState,
} from '../instruments.reducer';

import { DiscoverInstrumentCategoryType } from '../instruments.models';
import {
  FEATURE_KEY,
  instrumentDiscoverCategoryAdapter,
} from './discover-category.reducer';

export const discoverCategorySliceSelector = createSelector(
  selectInstrumentsState,
  (state: InstrumentsState) => state[FEATURE_KEY],
);

const { selectEntities, selectAll } =
  instrumentDiscoverCategoryAdapter.getSelectors();

export const selectDiscoverCategoryEntities = createSelector(
  discoverCategorySliceSelector,
  selectEntities,
);

export const selectDiscoverAssets = createSelector(
  discoverCategorySliceSelector,
  selectAll,
);

function getRandomElements<T>(
  instrumentsId: string[],
  count: number,
): string[] {
  return [...instrumentsId].sort(() => Math.random() - 0.5).slice(0, count);
}

export const selectRandomTwoDiscoverAssetsPerCategory = createSelector(
  selectDiscoverAssets,
  (assets): Record<string, string[]> => {
    const randomAssets: Record<string, string[]> = {};

    assets.forEach((asset) => {
      if (
        asset.id === DiscoverInstrumentCategoryType.STOCKS_LARGE_CAP ||
        asset.id === DiscoverInstrumentCategoryType.STOCKS_TOP_TECH ||
        !asset.id.includes('stocks')
      ) {
        const randomInstrumentIds = getRandomElements(asset.instrumentIds, 2);
        randomAssets[asset.id] = randomInstrumentIds;
      }
    });

    return randomAssets;
  },
);

export const selectAssetsByCategory = (selectedCategory: string) =>
  createSelector(selectDiscoverCategoryEntities, (categories): string[] => {
    const category = categories[selectedCategory];
    return category ? category.instrumentIds : [];
  });
