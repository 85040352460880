import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular-ivy';
import { tap } from 'rxjs';
import { AnalyticsApiActions } from '../analytics';
import {
  LaunchModalApiActions,
  LaunchModalEffectsActions,
} from '../launchmodals';

/**
 * Effects to send breadcrumbs and other events to Sentry.
 */
@Injectable()
export class SentryEffects {
  /**
   * Add a Sentry breadcrumb for every action.
   *
   * @todo should we filter out some actions?
   */
  addBreadcrumb$ = createEffect(
    () =>
      this.actions$.pipe(
        tap((action) => {
          Sentry.addBreadcrumb({
            category: 'ngrx',
            level: 'info',
            message: action.type,
            data: action,
          });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Some NgRx actions describe failures. We can send these to Sentry as well.
   * We only report failures that cannot be handled by the user or the UI,
   * and that we should fix.
   */
  captureFailureActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          // Analytics
          AnalyticsApiActions.recordEventFailure,

          // Launch modal
          LaunchModalApiActions.loadLaunchModalsFailure,
          // LaunchModalEffectsActions.unknownAction, // TODO Must be turned into errorProps
          LaunchModalEffectsActions.browserActionFailure,
          LaunchModalEffectsActions.appStoreActionFailure,
        ),
        tap((action) => Sentry.captureException(action.error)),
      ),
    { dispatch: false },
  );

  constructor(private readonly actions$: Actions) {}
}
