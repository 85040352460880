// Event Source Prefix
// The prefix for the Event Source is com.yeekatee. This is used to filter the events.
export const eventSourcePrefix = 'com.yeekatee';
export const eventSourceAppsync = eventSourcePrefix + '.appsync';
export const eventSourceCognito = eventSourcePrefix + '.identity';
export const eventSourceInstrumentData = eventSourcePrefix + '.instrumentdata';
export const eventSourceAccountLinking = eventSourcePrefix + '.accountlinking';
export const eventSourceActivity = eventSourcePrefix + '.activity';
export const eventSourceUser = eventSourcePrefix + '.user';
export const eventSourceInvite = eventSourcePrefix + '.invite';
export const eventSourceMarketing = eventSourcePrefix + '.marketing';
export const eventSourceQuiz = eventSourcePrefix + '.quiz';
export const eventSourceBooking = eventSourcePrefix + '.booking';

// Event detail types
// The detail type is used to filter events.
export const eventConsumeInviteCode = 'ConsumeInviteCode';
export const eventPushForMarketing = 'PushForMarketing';
export const eventDailyQuiz = 'dailyQuiz';
export const eventPushForCompetition = 'PushForCompetition';
export const eventPushForUsers = 'PushForUsers';
export const eventAnswerQuiz = 'AnswerQuiz';
export const eventReportInstruments = 'ReportInstruments';
