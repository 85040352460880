<ion-grid
  class="border-solid border-t-4 border-slate-100 dark:border-slate-900"
>
  <ion-row class="ion-align-items-center">
    <ion-col class="ion-no-padding">
      <ion-item lines="none" class="pl-[5px] ion-no-padding">
        <ion-thumbnail
          style="--size: 43px"
          slot="start"
          class="mask mask-squircle"
        >
          <ion-skeleton-text [animated]="true" />
        </ion-thumbnail>
        <ion-label class="ion-no-margin">
          <ion-skeleton-text [animated]="true" style="width: 40%" />
          <ion-skeleton-text [animated]="true" style="width: 40%" />
          <ion-skeleton-text [animated]="true" style="width: 40%" />
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="auto">
      <div class="h-full w-6"></div>
    </ion-col>
    <ion-col>
      <ion-grid>
        <ion-row class="ion-margin-horizontal">
          <ion-col>
            <ion-skeleton-text style="width: 100%" [animated]="true" />
            <ion-skeleton-text style="width: 100%" [animated]="true" />
            <ion-skeleton-text style="width: 60%" [animated]="true" />
          </ion-col>
        </ion-row>
        @if (isDetailView) {
          <ion-row class="ion-margin-horizontal">
            <ion-col>
              <ion-skeleton-text style="width: 10%" [animated]="true" />
            </ion-col>
          </ion-row>
          <ion-row class="ion-margin-horizontal">
            <ion-col>
              <ion-item lines="none" class="ion-no-padding">
                <ion-thumbnail slot="start" class="mask mask-circle h-6 w-6">
                  <ion-skeleton-text [animated]="true" />
                </ion-thumbnail>
              </ion-item>
            </ion-col>
          </ion-row>
        }
        <ion-row class="ion-margin-horizontal">
          <ion-col size="auto">
            <ion-skeleton-text [animated]="true" class="w-14 h-8 rounded-2xl" />
          </ion-col>
          @if (!isDetailView) {
            <ion-col size="auto">
              <ion-skeleton-text
                [animated]="true"
                class="w-14 h-8 rounded-2xl"
              />
            </ion-col>
          }
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
