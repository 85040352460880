import { createSelector } from '@ngrx/store';
import { AuthSelectors } from '../auth';
import { NavigationSelectors } from '../navigation';
import { UsersSelectors } from '../users';
import {
  ActivitiesEntity,
  CollectionPrefix,
  CollectionsEntity,
  FollowActivityStatus,
  InteractiveTimelineObject,
  NoteEntity,
  TimelineFilter,
  findFollowActivityWithStatus,
  getCollectionByPrefix,
  isNoteEntity,
  mapActivityToChatListItem,
  mapActivityToTimelineNote,
  mapFollowActivityToPersons,
} from './activity-streams.models';

import { memoize } from '../utils';
import * as fromActivities from './features/activities.selectors';
import * as fromCollections from './features/collections.selectors';
import * as fromObjects from './features/objects.selectors';

export * from './features/activities.selectors';
export * from './features/collections.selectors';

export const selectTimelineForCurrentUser = memoize(
  (filterValue: TimelineFilter) =>
    createSelector(
      fromCollections.selectInboxForAuthenticatedUser(filterValue),
      fromActivities.getActivitiesEntities,
      fromObjects.getObjectsEntities,
      fromCollections.getCollectionsEntities,
      UsersSelectors.getUsersEntities,
      AuthSelectors.selectUserId,
      (inbox, activities, objects, collections, users, userId) =>
        inbox.items
          .map((activityId) =>
            mapActivityToTimelineNote(
              activityId,
              activities,
              objects,
              collections,
              users,
              userId,
            ),
          )
          .filter(
            (a): a is InteractiveTimelineObject =>
              (!!a?.object?.content || !!a?.object?.image?.length) &&
              !a.object.inReplyTo,
          ),
    ),
);

export const selectTimelineLoading = memoize((filterValue: TimelineFilter) =>
  createSelector(
    fromCollections.selectInboxForAuthenticatedUser(filterValue),
    (inbox) => inbox.loading,
  ),
);

export const selectTimelineRefreshing = memoize((filterValue: TimelineFilter) =>
  createSelector(
    fromCollections.selectInboxForAuthenticatedUser(filterValue),
    (inbox) => inbox.refreshing,
  ),
);

export const selectTimelineLoaded = memoize((filterValue: TimelineFilter) =>
  createSelector(
    fromCollections.selectInboxForAuthenticatedUser(filterValue),
    (inbox) => !inbox.nextToken,
  ),
);

export const selectActivitiesForSelectedUser = createSelector(
  fromCollections.selectOutboxForSelectedUser,
  fromActivities.getActivitiesEntities,
  fromObjects.getObjectsEntities,
  fromCollections.getCollectionsEntities,
  UsersSelectors.getUsersEntities,
  AuthSelectors.selectUserId,
  (outbox, activities, objects, collections, users, userId) =>
    outbox.items
      .map((activityId) =>
        mapActivityToTimelineNote(
          activityId,
          activities,
          objects,
          collections,
          users,
          userId,
        ),
      )
      .filter(
        (a): a is InteractiveTimelineObject =>
          !!a?.object?.content || !!a?.object?.image?.length,
      ),
);

export const selectOutboxLoaded = createSelector(
  fromCollections.selectOutboxForSelectedUser,
  (outbox) => !outbox.nextToken,
);

export const selectActivitiesForSelectedAsset = createSelector(
  fromCollections.selectSharedInboxForSelectedAsset,
  fromActivities.getActivitiesEntities,
  fromObjects.getObjectsEntities,
  fromCollections.getCollectionsEntities,
  UsersSelectors.getUsersEntities,
  AuthSelectors.selectUserId,
  (outbox, activities, objects, collections, users, userId) =>
    outbox.items
      .map((activityId) =>
        mapActivityToTimelineNote(
          activityId,
          activities,
          objects,
          collections,
          users,
          userId,
        ),
      )
      .filter(
        (a): a is InteractiveTimelineObject =>
          !!a?.object?.content || !!a?.object?.image?.length,
      ),
);

export const selectActivitiesForSelectedAssetLoaded = createSelector(
  fromCollections.selectSharedInboxForSelectedAsset,
  (inbox) => !inbox.nextToken,
);

export const selectActivityId = createSelector(
  NavigationSelectors.selectActivityIdFromRoute,
  (id) => id,
);

export const selectCurrentActivity = createSelector(
  fromActivities.getActivitiesEntities,
  fromObjects.getObjectsEntities,
  fromCollections.getCollectionsEntities,
  UsersSelectors.getUsersEntities,
  AuthSelectors.selectUserId,
  selectActivityId,
  (activities, objects, collections, users, userId, activityId) => {
    const activity = activityId ? activities?.[activityId] : undefined;
    const id = activity?.parent ?? activityId;
    return id
      ? mapActivityToTimelineNote(
          id,
          activities,
          objects,
          collections,
          users,
          userId,
        )
      : undefined;
  },
);

export const selectNoteEntities = createSelector(
  fromObjects.getObjectsEntities,
  (entities) =>
    Object.fromEntries(
      Object.entries(entities).filter(([, object]) => isNoteEntity(object)) as [
        string,
        NoteEntity,
      ][],
    ),
);

export const selectLikesCollectionForSelectedActivity = createSelector(
  fromCollections.getCollectionsEntities,
  selectCurrentActivity,
  (entities, activity): CollectionsEntity =>
    getCollectionByPrefix(
      entities,
      CollectionPrefix.LIKES,
      activity?.object?.id,
    ),
);

export const selectLikesListForSelectedActivityLoaded = createSelector(
  selectLikesCollectionForSelectedActivity,
  (collection) => !collection.nextToken,
);

export const selectRepliesCollectionForSelectedActivity = createSelector(
  fromCollections.getCollectionsEntities,
  selectCurrentActivity,
  (entities, activity): CollectionsEntity =>
    getCollectionByPrefix(
      entities,
      CollectionPrefix.REPLIES,
      activity?.object?.id,
    ),
);

export const selectRepliesListForSelectedActivityLoaded = createSelector(
  selectRepliesCollectionForSelectedActivity,
  (collection) => !collection.nextToken,
);

export const selectTotalRepliesListForSelectedActivity = createSelector(
  selectRepliesCollectionForSelectedActivity,
  (collection) => collection.totalItems,
);

export const selectRepliesIdsForSelectedActivity = createSelector(
  fromActivities.getActivitiesEntities,
  selectRepliesCollectionForSelectedActivity,
  (entities, replies): string[] =>
    replies.items.map((activityId) => entities[activityId]?.object as string),
);

export const selectFollowingStatus = createSelector(
  fromCollections.selectFollowingForAuthenticatedUser,
  fromActivities.getActivitiesEntities,
  UsersSelectors.selectUserId,
  (collection, activities, userId) =>
    findFollowActivityWithStatus(
      collection,
      activities,
      (activity: ActivitiesEntity) => activity.object === userId,
    ),
);

export const selectFollowerStatus = createSelector(
  fromCollections.selectFollowersForAuthenticatedUser,
  fromActivities.getActivitiesEntities,
  UsersSelectors.selectUserId,
  (collection, activities, userId) =>
    findFollowActivityWithStatus(
      collection,
      activities,
      (activity: ActivitiesEntity) => activity.actor === userId,
    ),
);

export const selectFollowingListForSelectedUser = createSelector(
  fromCollections.selectFollowingForSelectedUser,
  fromActivities.getActivitiesEntities,
  fromObjects.getObjectsEntities,
  fromCollections.selectFollowingForAuthenticatedUser,
  UsersSelectors.getUsersEntities,
  (following, activities, objects, ownFollowing, users) =>
    following.items
      .map((activityId) =>
        mapFollowActivityToPersons(
          activityId,
          activities,
          objects,
          'following',
          ownFollowing,
          users,
        ),
      )
      .filter((activity) => !!activity),
);

export const selectActiveFollowingListForSelectedUser = createSelector(
  selectFollowingListForSelectedUser,
  (followers) =>
    followers.filter(
      (activity) => activity?.status === FollowActivityStatus.ACTIVE,
    ),
);

export const selectFollowingListForSelectedUserLoaded = createSelector(
  fromCollections.selectFollowingForSelectedUser,
  (collection) => !collection.nextToken,
);

export const selectFollowersListForSelectedUser = createSelector(
  fromCollections.selectFollowersForSelectedUser,
  fromActivities.getActivitiesEntities,
  fromObjects.getObjectsEntities,
  fromCollections.selectFollowingForAuthenticatedUser,
  UsersSelectors.getUsersEntities,
  (followers, activities, objects, ownFollowing, users) =>
    followers.items
      .map((activityId) =>
        mapFollowActivityToPersons(
          activityId,
          activities,
          objects,
          'followers',
          ownFollowing,
          users,
        ),
      )
      .filter((activity) => !!activity),
);

export const selectActiveFollowersListForSelectedUser = createSelector(
  selectFollowersListForSelectedUser,
  (followers) =>
    followers.filter(
      (activity) => activity?.status === FollowActivityStatus.ACTIVE,
    ),
);

export const selectPendingFollowersListForSelectedUser = createSelector(
  selectFollowersListForSelectedUser,
  (followers) =>
    followers.filter(
      (activity) => activity?.status === FollowActivityStatus.PENDING,
    ),
);

export const selectFollowersListForSelectedUserLoaded = createSelector(
  fromCollections.selectFollowersForSelectedUser,
  (collection) => !collection.nextToken,
);

export const selectTotalFollowingForSelectedUser = createSelector(
  fromCollections.selectFollowingForSelectedUser,
  (collection) => collection.totalItems,
);

export const selectTotalFollowersForSelectedUser = createSelector(
  fromCollections.selectFollowersForSelectedUser,
  (collection) => collection.totalItems,
);

export const selectTotalPendingFollowersForSelectedUser = createSelector(
  selectPendingFollowersListForSelectedUser,
  (list) => list.length,
);

export const selectChatList = createSelector(
  fromCollections.selectChatCollections,
  fromObjects.getObjectsEntities,
  fromActivities.getActivitiesEntities,
  UsersSelectors.getUsersEntities,
  AuthSelectors.selectUserId,
  (chats, objects, activities, users, userId) =>
    chats
      .map((c) =>
        mapActivityToChatListItem(
          c.items?.at(0), // get only the latest activity
          activities,
          objects,
          users,
          userId,
          c.id,
        ),
      )
      .filter((c): c is InteractiveTimelineObject => !!c?.id)
      .sort((a, b) => (a.id > b.id ? -1 : 1)),
);

export const selectChatRoomActivities = createSelector(
  fromCollections.selectChatItemIds,
  fromActivities.getActivitiesEntities,
  fromObjects.getObjectsEntities,
  UsersSelectors.getUsersEntities,
  AuthSelectors.selectUserId,
  (activitiesIds, activities, objects, users, userId) =>
    activitiesIds
      ?.map((id) =>
        mapActivityToChatListItem(id, activities, objects, users, userId),
      )
      .filter((a) => !!a),
);
