import { createAction, props } from '@ngrx/store';
import {
  ActivityStreamsActivity,
  ActivityStreamsActivityInput,
} from '@yeekatee/client-api-angular';
import { errorProps } from '../../utils';
import {
  ActivitiesCollection,
  ActivitiesEntity,
} from '../activity-streams.models';

export const apiGetActivitySuccess = createAction(
  '[Activities/API] Get Activity Success',
  props<{
    activity?: ActivityStreamsActivity;
    parent?: ActivityStreamsActivity;
  }>(),
);

export const apiGetActivityFailure = createAction(
  '[Activities/API] Get Activity Failure',
  errorProps(),
);

// TODO Check the following actions.
// It is important that we can handle these directly in reducers,
// before we even get a reply from PutActivity (optimistic update).
// This means we need additional info in the reducer to process them,
// such as the current user ID (e.g. for the LIKED collection).
// We could either have intermediate effects performing this step,
// preparing actions to be then sent into PutActivity in another step,
// or we could extend the actions definitions (maybe via a reusable creator).
// Ideally these actions could then be processed by reducers,
// and effects would use the optimisticUpdate operator by NgRx
// for rollbacks and loading status.
// We also need to understand how to handle the fact that right now IDs
// are generated server-side.
// https://ngrx.io/guide/data/limitations#client-side-primary-key-generation
// This possibly prevents optimistic updates from working as they should.

export const apiCreateNote = createAction(
  '[Activities/API] Create A Note',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiCreateNoteSuccess = createAction(
  '[Activities/API] Create A Note Success',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiCreateNoteFailure = createAction(
  '[Activities/API] Create A Note Failure',
  errorProps(),
);

/**
 * This action should:
 *       1. Create the activity in the activities store
 *       2. Insert the ID in the target's LIKES collection, and in the users' LIKED collection
 */
export const apiLikeNote = createAction(
  '[Activities/API] Like Note',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiLikeNoteSuccess = createAction(
  '[Activities/API] Like Note Success',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiLikeNoteFailure = createAction(
  '[Activities/API] Like Note Failure',
  errorProps(),
);

/**
 * This action should:
 *       1. Fetch the activity via the target ID
 *       2. Create the Undo activity in the activities store
 *       3. Remove the target ID in the original object's LIKES collection,
 *          and in the users' LIKED collection
 */
export const apiUnlikeNote = createAction(
  '[Activities/API] Unlike Note',
  props<{ activity: ActivityStreamsActivity; noteId: string }>(),
);

export const apiUnlikeNoteSuccess = createAction(
  '[Activities/API] Unlike Note Success',
  props<{ activity: ActivityStreamsActivity; noteId: string }>(),
);

export const apiUnlikeNoteFailure = createAction(
  '[Activities/API] Unlike Note Failure',
  errorProps(),
);

/**
 * This action should:
 *       1. Create the activity in the activities substore
 *       2. Create an entry in the target's FOLLOWERS, and in the user's FOLLOWING
 */
export const apiFollowUser = createAction(
  '[Activities Effects] Follow a User',
  props<{ activity: ActivityStreamsActivityInput }>(),
);

export const followUserResult = createAction(
  '[Activities Effects] Follow a User Result',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiFollowUserSuccess = createAction(
  '[Activities/API] Follow a User Success',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiFollowUserFailure = createAction(
  '[Activities/API] Follow a User Failure',
  errorProps(),
);

/**
 * This action should
 *       1. Fetch the activity via the target ID:
 *       2. Create the Undo activity in the activities substore
 *       3. Remove the target ID in the original user's FOLLOWERS,
 *          and in the current user's FOLLOWING
 */
export const apiRevokeFollow = createAction(
  '[Activities Effects] Revoke Follow a User',
  props<{
    activity: ActivityStreamsActivity;
    userId: string;
  }>(),
);

export const apiRevokeFollowSuccess = createAction(
  '[Activities/API] Revoke Follow a User Success',
  props<{
    activity: ActivityStreamsActivity;
    userId: string;
  }>(),
);

export const apiRevokeFollowFailure = createAction(
  '[Activities/API] Revoke Follow a User Failure',
  errorProps(),
);

/**
 * This action should
 *       1. Fetch the activity via the target ID:
 *       2. Create the Undo activity in the activities substore
 *       3. Remove the target ID in the original user's FOLLOWERS,
 *          and in the current user's FOLLOWING
 */
export const apiUnfollowUser = createAction(
  '[Activities Effects] Unfollow a User',
  props<{
    activity: ActivityStreamsActivity;
    userId: string;
  }>(),
);

export const apiUnfollowUserSuccess = createAction(
  '[Activities/API] Unfollow a User Success',
  props<{
    activity: ActivityStreamsActivity;
    userId: string;
  }>(),
);

export const apiUnfollowUserFailure = createAction(
  '[Activities/API] Unfollow a User Failure',
  errorProps(),
);

/**
 * This action should:
 *       1. Create the activity in the activities substore
 *       2. Modify the existing target activity result to Accept
 */
export const apiAcceptFollow = createAction(
  '[Activities Effects] Accept a Follow Request',
  props<{ activity: ActivityStreamsActivity; inReplyTo?: ActivitiesEntity }>(),
);

export const apiAcceptFollowSuccess = createAction(
  '[Activities/API] Accept a Follow Request Success',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiAcceptFollowFailure = createAction(
  '[Activities/API] Accept a Follow Request Failure',
  errorProps(),
);

/**
 * This action should:
 *       1. Create the activity in the activities substore
 *       2. Modify the existing target activity result to Reject
 */
export const apiRejectFollow = createAction(
  '[Activities Effects] Reject a Follow Request',
  props<{
    activity: ActivityStreamsActivity;
    rejectedActivity: ActivitiesEntity;
  }>(),
);

export const apiRejectFollowSuccess = createAction(
  '[Activities/API] Reject a Follow Request Success',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiRejectFollowFailure = createAction(
  '[Activities/API] Reject a Follow Request Failure',
  errorProps(),
);

/**
 * Used when loading person collections totals
 */
export const apiLoadPersonCollectionsTotalsSuccess = createAction(
  '[Activities/API] Load Person Collections Totals Success',
  props<{ collections: ActivitiesCollection[] }>(),
);

export const apiLoadPersonCollectionsTotalsFailure = createAction(
  '[Activities/API] Load Person Collections Totals Failure',
  errorProps(),
);

/**
 * Call Put Activity API deleting a note in the backend
 */
export const apiDeleteNote = createAction(
  '[Activities Effects] Delete Note Request',
  props<{ activity: ActivityStreamsActivity; noteActivityId: string }>(),
);

export const apiDeleteNoteSuccess = createAction(
  '[Activities/API] Delete Note Request Success',
  props<{ activity: ActivityStreamsActivity }>(),
);

export const apiDeleteNoteFailure = createAction(
  '[Activities/API] Delete Note Request Failure',
  errorProps(),
);
