import { createSelector } from '@ngrx/store';
import { InstrumentsSelectors } from '../../instruments';
import { ThemeSelectors } from '../../theme';
import {
  MarketNewsSentimentStatistics,
  mapSentimentChartToChartData,
} from '../market-news.models';
import { MarketNewsState, selectMarketNewsState } from '../market-news.reducer';
import { FEATURE_KEY, State, mInstrumentsAdapter } from './instruments.reducer';

export const mInstrumentSliceSelector = createSelector(
  selectMarketNewsState,
  (state: MarketNewsState) => state[FEATURE_KEY],
);

const { selectEntities } = mInstrumentsAdapter.getSelectors();

export const selectMarketNewsInstrumentsEntities = createSelector(
  mInstrumentSliceSelector,
  selectEntities,
);

export const selectLoadingMarketNews = createSelector(
  mInstrumentSliceSelector,
  (state: State) => state.loading,
);

export const selectDefaultOrderingType = createSelector(
  mInstrumentSliceSelector,
  (state: State) => state.defaultOrderingType,
);

export const selectMarketNewsInstrument = createSelector(
  selectMarketNewsInstrumentsEntities,
  InstrumentsSelectors.selectInstrumentId,
  (entities, id) => (id ? entities[id] : undefined),
);

export const selectStoriesIds = createSelector(
  selectMarketNewsInstrument,
  (instrument) => instrument?.stories,
);

export const selectPageToken = createSelector(
  selectMarketNewsInstrument,
  (instrument) => instrument?.nextToken,
);

export const selectOrderingType = createSelector(
  selectMarketNewsInstrument,
  selectDefaultOrderingType,
  (instrument, defaultOrderingType) =>
    instrument?.orderingType ?? defaultOrderingType,
);

export const selectSentimentChart = createSelector(
  selectMarketNewsInstrument,
  (instrument) => instrument?.sentimentChart,
);

export const selectLoadingSentimentChart = createSelector(
  mInstrumentSliceSelector,
  (state: State) => state.loadingChart,
);

export const selectSentimentChartStatistics = createSelector(
  selectSentimentChart,
  (chart): MarketNewsSentimentStatistics => ({
    averageSentiment: chart?.averageSentiment ?? undefined,
    averageConfidence: chart?.averageConfidence ?? undefined,
  }),
);

export const selectSentimentChartData = createSelector(
  selectSentimentChart,
  ThemeSelectors.selectColors,
  (chart, colors) => {
    return mapSentimentChartToChartData(chart, colors);
  },
);

export const selectIsEndOfNews = createSelector(
  selectPageToken,
  (nextToken) => !nextToken,
);
