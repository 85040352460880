import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IonAvatar, IonItem, IonLabel } from '@ionic/angular/standalone';
import {
  NavigationRouteNames,
  extractBaseRoute,
} from '@yeekatee/shared-util-routes';
import { AvatarComponent } from '../avatar/avatar.component';
import { ItemSkeletonComponent } from '../item-skeleton/item-skeleton.component';

@Component({
  standalone: true,
  imports: [
    AvatarComponent,
    IonAvatar,
    IonItem,
    IonLabel,
    ItemSkeletonComponent,
    RouterLink,
  ],
  selector: 'yeekatee-user-item',
  templateUrl: './user-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserItemComponent {
  @Input() userId?: string | null;
  @Input() picture?: string | null;
  @Input() username?: string | null;
  @Input() name?: string | null;
  @Input() location?: string | null;

  @Input() baseRoute?: string;
  @Input() noRouting: BooleanInput = false;

  protected readonly defaultBaseRoute = extractBaseRoute(this.router.url);
  protected readonly NavigationRouteNames = NavigationRouteNames;

  constructor(private readonly router: Router) {}
}
