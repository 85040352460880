import { Token } from '@capacitor/push-notifications';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NotificationAppData } from '@yeekatee/shared-util-notifications';
import { errorProps } from '../utils';

export const CapacitorPushNotificationsActions = createActionGroup({
  source: 'Capacitor Push Notifications',
  events: {
    /**
     * Called when the push notification registration finishes without problems.
     *
     * Provides the push notification token.
     */
    'Registration Success': props<{ token: Token }>(),

    /**
     * Called when the push notification registration finished with problems.
     *
     * Provides an error with the registration problem.
     */
    'Registration Failure': errorProps(),

    /**
     * Called when the device receives a push notification.
     */
    'Push Notification Received': props<{
      data: NotificationAppData;
    }>(),

    /**
     * Called when an action is performed on a push notification.
     */
    'Push Notification Action Performed': props<{
      data: NotificationAppData;
    }>(),
  },
});

export const PushNotificationsAPIActions = createActionGroup({
  source: 'Push Notifications/API',
  events: {
    /**
     * When the backend accepted the device token for push notifications.
     */
    'Token Registration Success': props<{
      encryptedPlatformEndpointArn: string;
    }>(),

    /**
     * An error while setting up the push notifications token in the backend.
     */
    'Token Registration Failure': errorProps(),

    /**
     * When the device was successfully removed by the push notification backend.
     */
    'Device Unregistration Success': emptyProps(),

    /**
     * Something failed while unregistering the device.
     */
    'Device Unregistration Failure': errorProps(),

    /**
     * The user granted the app permissions to send notifications.
     */
    'Notifications Permissions Granted': emptyProps(),

    /**
     * The user denied the app permissions to send notifications.
     */
    'Notifications Permissions Denied': errorProps(),
  },
});

/**
 * Pinpoint is also used for marketing push notifications.
 * This is separate from Analytics but it uses the same API to manage endpoints.
 */
export const PushNotificationsPinpointActions = createActionGroup({
  source: 'Push Notifications/Pinpoint',
  events: {
    /**
     * When the device push token was successfully registered with Pinpoint.
     */
    'Endpoint Address Update Success': props<{ response: unknown }>(),

    /**
     * Something failed while registering the device with Pinpoint.
     */
    'Endpoint Address Update Failure': errorProps(),

    /**
     * When the device was successfully marked as INACTIVE in Pinpoint.
     */
    'Endpoint Inactivate Success': props<{ response: unknown }>(),

    /**
     * Something failed while marking the device as INACTIVE in Pinpoint.
     */
    'Endpoint Inactivate Failure': errorProps(),
  },
});

export const RthubAPIActions = createActionGroup({
  source: 'Rthub/API',
  events: {
    /**
     * Received a message via the Rthub Phoenix Channel.
     *
     * @todo Possible objects definition
     */
    'Message Received': props<{
      channel: string;
      data: NotificationAppData;
    }>(),

    /**
     * An error occurred while getting messages via Rthub
     */
    'Delivery Failure': errorProps(),
  },
});
