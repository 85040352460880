/**
 * @deprecated extracted from f0a5ca1a954559ddf57f22452008700938970bdc^ for backwards compatibility
 */
export const BankKey = {
  Abaco: 'AC', // Abaco Capital
  Abanco: 'A1', // Abanca
  Abante: 'A2', // Abante
  AbnAmro: 'ABN', // ABN AMRO Belgium
  AcaciaInversion: 'AI', // Acacia inversion
  ActivoBank: 'AB', // Activobank
  Allianz: 'ALZ', // Allianz
  AltairFinance: 'AF', // Altair Finance
  Altamar: 'AR', // Altamar
  Andbank: 'AN', // Andbank
  ASB: 'ASB', // ASB - atlantic Security bank
  Avanza: 'AV', // Avanza
  AXA: 'AXA', // Axa
  AYG: 'AYG', // AYG Banco
  AZValor: 'AZ', // AzValor
  BancaCivicia: 'B1', // Banca civica
  BancaMarch: 'BM', // Banca March
  BancoAlcala: 'BA', // Banco Alcala
  BancoCaminos: 'B2', // Banco Caminos
  BancoCastilla: 'B3', // Banco castilla
  Bankia: 'B4', // Bankia
  Bankinter: 'B5', // Bankinter
  BBVA: 'BBVA', // BBVA
  BDP: 'BDP', // Millennium BCP
  BDL: 'BDL', // Banque del Luxemburg
  Beka: 'BK', // Beka Asset Management
  Belfius: 'B6', // Belfius
  BelgraviaCapital: 'BC', // Belgravia Capital
  Beobank: 'BE', // Beobank
  Bestinver: 'BV', // Bestinver
  Big: 'Big', // Big Portugal
  BINANCE: 'BI', // Binance
  Bitfinex: 'BF', // Bitfinex
  Bitpanda: 'BP', // Bitpanda
  Bitstamp: 'BT', // Bitstamp
  Bittrex: 'BX', // Bittrex
  BNP: 'BNP', // BNP Paribas Wealth Management
  BTG: 'BTG', // NetxInvestor
  BuyAndHold: 'BH', // Buy And Hold
  Caceis: 'C1', // Caceis
  Caixabank: 'C2', // Caixabank
  Cajamar: 'C3', // Caixa Callosa
  GrupoCooperativoCajamar: 'C4', // Caixa rura vilareal
  CreditAgricole: 'CA', // Credit Agricole
  Cexio: 'C5', // Cexio
  CitiBank: 'CC', // Citi private bank
  CreditMutuel: 'CM', // Crédit Mutuel Nord Europe
  CNP: 'CNP', // CNP France
  Cobas: 'C6', // Cobas
  coinbase: 'CB', // Coinbase
  Cointracker: 'CT', // Cointracker
  Cointracking: 'CG', // Cointracking
  CreditAndorra: 'C7', // Credit Andorra
  CreditoCooperativo: 'C8', // Credito Cooperativo
  CreditSuisse: 'CS', // Credit Suisse
  DEGIRO: 'DE', // Degiro
  DeutscheBank: 'DB', // Deutsche Bank
  DunasCapital: 'DC', // Dunas Capital
  DuxInversores: 'DI', // Dux Inversores
  EBN: 'EBN', // EBN
  EDM: 'EDM', // Edm
  EDR: 'EDR', // Edmond de Rothschild
  EFG: 'EFG', // EFG
  ETORO: 'ET', // Etoro
  Etrade: 'ED', // Etrade
  EVOBanco: 'EV', // EVO Banco
  Fidelity: 'FD', // Fidelity
  Finanbest: 'FB', // Finanbest
  FinecoBank: 'FO', // Fineco Bank
  Finizens: 'FZ', // Finizens
  GVCGaesco: 'GVC', // GVC Gaesco
  GBM: 'GBM', // GBM
  Gesconsult: 'GC', // Gesconsult
  Gesiuris: 'GE', // Gesiuris
  Horos: 'HO', // Horos AM
  GoldmanSachs: 'GS', // Goldman Sachs
  Ibercaja: 'I1', // Ibercaja
  Ibroker: 'I2', // Ibroker
  Imaginbank: 'I3', // Imaginbank
  IndexaCapital: 'IC', // Indexa Capital
  ING: 'ING', // ING
  InteractiveBrokers: 'IB', // Interactive Brokers
  BancoInversis: 'I4', // Banco Inversis
  Itau: 'IT', // Itau
  JPM: 'JPM', // JP Morgan
  SafraSarasin: 'JS', // J. Safra Sarasin
  JuliusBaer: 'JB', // Julius Baer
  Kraken: 'KR', // Kraken
  Kucoin: 'KU', // Kucoin
  Kutxabank: 'KT', // Kutxabank
  Larrainvial: 'LA', // Larrainvial
  LGT: 'LGT', // LGT
  Liberbank: 'LB', // Liberbank
  LomardOdier: 'LO', // Lombard International
  LoretoInversiones: 'LI', // Loreto Inversiones
  LUKB: 'LUKB', // Luzerner Kantonalbank
  MagallanesValue: 'MV', // Magallanes Value
  Mapfre: 'MP', // Mapfre AM
  MetaGestion: 'MG', // Meta Gestión
  Milleis: 'MI', // Milléis
  Morabanc: 'MO', // Morabanc
  MorganStanley: 'MS', // Morgan Stanley
  Movestic: 'MC', // Movestic
  MutuaActivos: 'MA', // Mutua Activos
  MuzaGestion: 'MZ', // Muza Gestión
  MyInvestor: 'MY', // My Investor
  Natixis: 'NA', // Natixis
  NetCash: 'NC', // NetCash
  Nortia: 'NO', // Nortia-AEP
  Novobanco: 'NB', // Novobanco empresas
  Nutmeg: 'NM', // Nutmeg
  OficinaDirecta: 'OD', // Oficina directa
  Openbank: 'OB', // Openbank
  BancoPastor: 'B9', // Banco pastor
  Patrivalor: 'PV', // Patrivalor
  Pershing: 'PH', // Pershing
  Pictet: 'PT', // Pictet
  BancoPopular: 'B8', // Banco popular
  Renta4: 'R4', // Renta 4
  RentaMarkets: 'RM', // Renta Markets
  Revolut: 'RV', // Revolut
  Robinhood: 'RH', // Robinhood
  RothschildMartinMaurel: 'EMM', // Rothschild Martin Maurel
  BancoSabadell: 'BS', // Banco Sabadell
  SantaLucia: 'SL', // Santa Lucía AM
  BancoSantander: 'BST', // Banco Santander
  SAVR: 'SAVR', // SAVR
  Saxobank: 'SB', // Saxobank
  ScalableCapital: 'S1', // Scalable Capital
  Scotiabank: 'S2', // Scotiabank MX Scotiatrade
  ScottishWidows: 'SW', // Scottish Widows
  SEB: 'SEB', // SEB
  SelfBank: 'S9', // Self Bank
  Skandia: 'SK', // Skandia
  Sogenal: 'SO', // Societe Generale Luxembourg
  Swedbank: 'SD', // Swedbank
  SwissLife: 'SF', // Swiss Life France
  Swissquote: 'SQ', // Swissquote
  Targobank: 'TB', // Targobank
  TradeRepublic: 'TR', // Trade Republic
  Trading212: 'T212', // Trading 212
  UBP: 'UPB', // UBP Wealth
  UBS: 'UBS', // Ubs
  Unicaja: 'UJ', // Unicaja
  Unnim: 'UN', // Unnim
  Valentum: 'VA', // Valentum
  Vanlanschot: 'VN', // Vanlanschot
  Varianza: 'VR', // Varianza
  Vontobel: 'VT', // Vontobel
  Degroof: 'DG', // Degroof Private Bank
  WellsFargo: 'WF', // Wells Fargo
  WiZink: 'WZ', // Wi Zink
  XP: 'SP', // XP Investimento Advisor
  XTB: 'XTB', // XTB

  // testing
  BANK01: 'BANK01',
  BANK02: 'BANK02',
} as const;
