import { AfterViewInit, Directive } from '@angular/core';
import { IonDatetime } from '@ionic/angular/standalone';
import { DateTime } from 'luxon';

/**
 * Ionic ion-datetime component is missing this feature since 2022.
 *
 * @see https://ionicframework.com/docs/api/datetime#first-day-of-the-week
 * @see https://github.com/tc39/ecma402/issues/6
 */
@Directive({
  selector: 'ion-datetime[yeekateeFirstDayWeek]',
  standalone: true,
})
export class FirstDayWeekDirective implements AfterViewInit {
  constructor(private component: IonDatetime) {}

  ngAfterViewInit() {
    const { year, month, day } = DateTime.now();
    const { weekday } = DateTime.local(year, month, day, {
      locale: this.component.locale,
    }).startOf('week', {
      useLocaleWeeks: true,
    });
    this.component.firstDayOfWeek = weekday;
  }
}
