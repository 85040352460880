<ion-label>
  <p class="hyphens-auto ion-text-wrap" i18n
    >By
    @if (legalType === LegalType.SIGN_IN) {
      logging in
    } @else if (legalType === LegalType.ACCOUNT_LINKING) {
      syncing your portfolio
    } @else {
      signing up
    }
    you agree to our
    <a class="font-bold text-ion_primary" (click)="openTermsOfUse()"
      >Terms of use</a
    >
    and
    <a class="font-bold text-ion_primary" (click)="openPolicies()"
      >Privacy policy</a
    >.</p
  >
</ion-label>
