import { createActionGroup, props } from '@ngrx/store';
import { RawThemeColors } from './theme.model';
import { ColorMode } from './theme.reducer';

export const ThemeActions = createActionGroup({
  source: 'Theme',
  events: {
    'Dark Mode Selected': props<{ mode: ColorMode; colors: RawThemeColors }>(),
    'Light Mode Selected': props<{ mode: ColorMode; colors: RawThemeColors }>(),
    'Change Web Theme': props<{ isActive: boolean }>(),
  },
});
