import { createSelector } from '@ngrx/store';
import { UserPortfolio } from '@yeekatee/client-api-angular';
import { userPortfoliosFeature } from '../portfolios';

export const selectPortfoliosListVM = createSelector(
  userPortfoliosFeature.selectUserSyncedPortfolios,
  userPortfoliosFeature.selectUserVirtualPortfolios,
  userPortfoliosFeature.selectUserSimulationPortfolios,
  userPortfoliosFeature.selectUserOtherPortfolios,
  userPortfoliosFeature.selectLoading,
  (synched, virtual, simulation, other, loading) =>
    ({
      items: (
        [
          ...(synched.length > 0 ? [synchedHeader, ...synched] : []),
          ...(virtual.length > 0 ? [virtualHeader, ...virtual] : []),
          ...(simulation.length > 0 ? [simulationHeader, ...simulation] : []),
          ...(other.length ? [otherHeader, ...other] : []),
        ] as (PortfolioListHeader | UserPortfolio)[]
      ).map((p) =>
        'kind' in p
          ? p
          : ({
              kind: 'portfolio',
              portfolio: p,
              synching: !!p.synching,
              canResync: !!p.canResync,
              canRename: !!p.canRename,
              canAddTransactions: !!p.canAddTransactions,
              canDeleteAllTransactions: !!p.canDeleteAllTransactions,
            } satisfies PortfolioListElem),
      ),
      loading,
    }) satisfies PortfoliosListVM,
);

export type PortfolioListHeader = {
  kind: 'header';
  type: 'synced' | 'virtual' | 'simulation' | 'other';
};

const synchedHeader: PortfolioListHeader = {
  kind: 'header',
  type: 'synced',
};

const virtualHeader: PortfolioListHeader = {
  kind: 'header',
  type: 'virtual',
};

const simulationHeader: PortfolioListHeader = {
  kind: 'header',
  type: 'simulation',
};

const otherHeader: PortfolioListHeader = {
  kind: 'header',
  type: 'other',
};

export type PortfolioListElem = {
  kind: 'portfolio';
  portfolio: UserPortfolio;
  synching: boolean;
  canResync: boolean;
  canRename: boolean;
  canAddTransactions: boolean;
  canDeleteAllTransactions: boolean;
};

export type PortfoliosListItemVM = PortfolioListHeader | PortfolioListElem;

export type PortfoliosListVM = {
  items: PortfoliosListItemVM[];
  loading: boolean;
};
