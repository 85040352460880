import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromDiscoverCategory from './features/discover-category.reducer';
import * as fromDividends from './features/dividends.reducer';
import * as fromInstruments from './features/instruments.reducer';
import * as fromQuotes from './features/quotes.reducer';
import * as fromTimeSeries from './features/time-series.reducer';

export const INSTRUMENTS_FEATURE_KEY = 'instruments';

export interface InstrumentsState {
  readonly [fromInstruments.FEATURE_KEY]: fromInstruments.State;
  readonly [fromQuotes.FEATURE_KEY]: fromQuotes.State;
  readonly [fromTimeSeries.FEATURE_KEY]: fromTimeSeries.State;
  readonly [fromDividends.FEATURE_KEY]: fromDividends.State;
  readonly [fromDiscoverCategory.FEATURE_KEY]: fromDiscoverCategory.State;
}

export function reducers(state: InstrumentsState | undefined, action: Action) {
  return combineReducers(
    {
      [fromInstruments.FEATURE_KEY]: fromInstruments.reducer,
      [fromQuotes.FEATURE_KEY]: fromQuotes.reducer,
      [fromTimeSeries.FEATURE_KEY]: fromTimeSeries.reducer,
      [fromDividends.FEATURE_KEY]: fromDividends.reducer,
      [fromDiscoverCategory.FEATURE_KEY]: fromDiscoverCategory.reducer,
    },
    {
      [fromInstruments.FEATURE_KEY]: fromInstruments.initialState,
      [fromQuotes.FEATURE_KEY]: fromQuotes.initialState,
      [fromTimeSeries.FEATURE_KEY]: fromTimeSeries.initialState,
      [fromDividends.FEATURE_KEY]: fromDividends.initialState,
      [fromDiscoverCategory.FEATURE_KEY]: fromDiscoverCategory.initialState,
    },
  )(state, action);
}
export const selectInstrumentsState = createFeatureSelector<InstrumentsState>(
  INSTRUMENTS_FEATURE_KEY,
);
