import { createFeature, createSelector } from '@ngrx/store';
import { EXAMPLE_USER_ID } from '@yeekatee/booking-util-definitions';
import { UserPortfolioType } from '@yeekatee/client-api-angular';
import { NavigationSelectors } from '../../navigation';
import { UsersSelectors } from '../../users';
import { adapter, name, reducer } from './user-portfolios.reducer';

export const userPortfoliosFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectUserPortfoliosState }) => {
    const entitySelectors = adapter.getSelectors(selectUserPortfoliosState);

    const selectUserFromRouteOrExampleId = createSelector(
      UsersSelectors.selectUserId,
      (userId) => userId ?? EXAMPLE_USER_ID,
    );

    const selectUserPortfolios = createSelector(
      selectUserFromRouteOrExampleId,
      entitySelectors.selectAll,
      (userId, portfolios) => portfolios.filter((p) => p.userId === userId),
    );

    const selectUserPortfoliosKeys = createSelector(
      selectUserPortfolios,
      (portfolios) =>
        portfolios.map((p) => p.key).filter((k): k is string => !!k),
    );

    const hasUserPortfolios = createSelector(
      selectUserPortfolios,
      (portfolios) => portfolios.length > 0,
    );

    const selectUserSyncedPortfolios = createSelector(
      selectUserPortfolios,
      (portfolios) =>
        portfolios.filter((p) => p.type === UserPortfolioType.ACCOUNT),
    );

    const selectUserVirtualPortfolios = createSelector(
      selectUserPortfolios,
      (portfolios) =>
        portfolios.filter((p) => p.type === UserPortfolioType.VIRTUAL),
    );

    const selectUserSimulationPortfolios = createSelector(
      selectUserPortfolios,
      (portfolios) =>
        portfolios.filter((p) => p.type === UserPortfolioType.SIMULATION),
    );

    const selectUserOtherPortfolios = createSelector(
      selectUserPortfolios,
      (portfolios) =>
        portfolios.filter(
          (p) =>
            ![
              UserPortfolioType.ACCOUNT,
              UserPortfolioType.VIRTUAL,
              UserPortfolioType.SIMULATION,
            ].includes(p.type ?? UserPortfolioType.EXAMPLE),
        ),
    );

    const selectUserPortfoliosCanAddTransactions = createSelector(
      selectUserPortfolios,
      (portfolios) => portfolios.filter((p) => p.canAddTransactions),
    );

    const hasTradeableUserPortfolios = createSelector(
      selectUserPortfoliosCanAddTransactions,
      (portfolios) => portfolios.length > 0,
    );

    const selectPortfolioKeysInRoute = createSelector(
      NavigationSelectors.selectPortfolioKeysFromRoute,
      (keys): string[] => (keys ? [keys].flat() : []),
    );

    const selectTradeablePortfolioInRoute = createSelector(
      selectPortfolioKeysInRoute,
      selectUserPortfolios,
      (keys, portfolios) => {
        const tradeablePortfolios = portfolios?.filter(
          (p) => !!keys?.includes(p.key ?? '') && !!p.canAddTransactions,
        );
        return tradeablePortfolios.length === 1
          ? tradeablePortfolios.at(0)
          : undefined;
      },
    );

    return {
      ...entitySelectors,
      selectUserFromRouteOrExampleId,
      selectUserPortfolios,
      selectUserPortfoliosKeys,
      hasUserPortfolios,
      selectUserSyncedPortfolios,
      selectUserVirtualPortfolios,
      selectUserSimulationPortfolios,
      selectUserOtherPortfolios,
      selectUserPortfoliosCanAddTransactions,
      hasTradeableUserPortfolios,
      selectPortfolioKeysInRoute,
      selectTradeablePortfolioInRoute,
    };
  },
});
