import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InstrumentsEntity } from '../instruments';
import * as InstrumentsSelectors from '../instruments/features/instruments.selectors';
import { UsersEntity, UsersSelectors } from '../users';
import {
  FAVOURITES_FEATURE_KEY,
  FavouritesState,
  favouritesAdapter,
} from './favourites.reducer';

// Lookup the 'Favourites' feature state managed by NgRx
export const selectFavouritesState = createFeatureSelector<FavouritesState>(
  FAVOURITES_FEATURE_KEY,
);

const { selectAll, selectEntities } = favouritesAdapter.getSelectors();

export const selectFavouritesLoaded = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => state.loaded,
);

export const selectFavouritesError = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => state.error,
);

export const selectAllFavourites = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => selectAll(state),
);

export const selectFavouritesEntities = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => selectEntities(state),
);

export const selectSelectedId = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => state.selectedId,
);

export const selectSelected = createSelector(
  selectFavouritesEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
);

export const selectFavouriteUsersIds = createSelector(
  selectAllFavourites,
  (favourites) =>
    favourites.filter((f) => f.type === 'USER').map((f) => f.objectId),
);

export const selectFavouriteUsers = createSelector(
  selectFavouriteUsersIds,
  UsersSelectors.getUsersEntities,
  (favourites, users) =>
    favourites.map((id) => users[id]).filter((u): u is UsersEntity => !!u),
);

export const selectedUserIsFavourite = createSelector(
  selectFavouriteUsers,
  UsersSelectors.selectUser,
  (favourites, user) =>
    !!user?.id && !!favourites.find((f) => f?.id === user.id),
);

export const selectFavouriteInstrumentsEntities = createSelector(
  selectAllFavourites,
  (fs) => fs.filter((f) => f.type === 'FINANCIAL_INSTRUMENT'),
);

export const selectFavouriteInstrumentsIds = createSelector(
  selectFavouriteInstrumentsEntities,
  (fs) => fs.map((f) => f.objectId),
);

export const selectFavouriteInstruments = createSelector(
  selectFavouriteInstrumentsEntities,
  InstrumentsSelectors.selectInstrumentsEntities,
  (favourites, instruments): InstrumentsEntity[] =>
    favourites.map(
      ({ objectId, symbol, mic }): InstrumentsEntity => ({
        ...instruments[objectId],
        id: objectId,
        symbol,
        mic,
      }),
    ),
);

export const selectedInstrumentIsFavourite = createSelector(
  selectFavouriteInstruments,
  InstrumentsSelectors.selectInstrumentId,
  InstrumentsSelectors.selectListing,
  (favourites, instrumentId, listing) =>
    !!favourites.find(
      (f) =>
        f.id === instrumentId &&
        f.symbol == listing?.symbol &&
        f.mic == listing?.mic,
    ),
);
