import { Browser } from '@capacitor/browser';
import { domainNames, YEEKATEE_DOMAIN } from '@yeekatee/shared-util-networking';
import { Environment, FeaturesConfig, FlanksLinkToken } from './environment';

export type CdkOutput = {
  AwsAccount: string;
  CognitoIdentityPoolId: string;
  CognitoUserPoolId: string;
  CognitoUserPoolWebClientId: string;
  PinpointAppId: string;
  Region: string;
  S3Bucket: string;
};

/**
 * We only need a subset of the Sentry configuration options. Using SentryConfig
 * broke with a cryptic error related to the type without any annotation.
 */
export type SentryEnvConfig = {
  enabled: boolean;
  enableNative: boolean;
  dsn: string;
  captureNgrx: boolean;
};

export type EnvironmentParams = {
  production: boolean;
  oauthRedirectUrl: string;
  flanksLinkToken: FlanksLinkToken;
  sentry: SentryEnvConfig;
  featuresConfig: FeaturesConfig;
};

export const defaultUrls = (account: string) => {
  const { cdn, rthub, gql, auth } = domainNames(YEEKATEE_DOMAIN, account);
  return {
    cdn: `https://${cdn.domainName}`,
    rthub: `wss://${rthub.domainName}`,
    gql: `https://${gql.domainName}/graphql`,
    auth: auth.domainName,
  };
};

export const defineEnvironment = (
  {
    AwsAccount,
    CognitoIdentityPoolId,
    CognitoUserPoolId,
    CognitoUserPoolWebClientId,
    PinpointAppId,
    Region,
    S3Bucket,
  }: CdkOutput,
  {
    production,
    oauthRedirectUrl,
    flanksLinkToken,
    sentry,
    featuresConfig,
  }: EnvironmentParams,
): Environment => ({
  production,

  awsAccount: AwsAccount,
  cloudFrontDistributionDomainName: defaultUrls(AwsAccount).cdn,
  rthubURL: defaultUrls(AwsAccount).rthub,

  flanksLinkToken,
  featuresConfig,

  sentry: {
    ...sentry,

    // Defined in apps/mobile/plugins/assignEnvVariablePlugin.js. Values are provided by the CI process
    dist: `${process.env['BUILD_NUMBER']}`,
    release: `${process.env['VERSION_NAME']} (${process.env['BUILD_NUMBER']})`,
  },

  aws: {
    Auth: {
      region: Region,

      userPoolId: CognitoUserPoolId,
      userPoolWebClientId: CognitoUserPoolWebClientId,
      identityPoolId: CognitoIdentityPoolId,
      identityPoolRegion: Region,

      mandatorySignIn: false,

      authenticationFlowType: 'USER_SRP_AUTH',

      oauth: {
        scope: [
          'email',
          'openid',
          'phone',
          'profile',
          'aws.cognito.signin.user.admin',
        ],
        domain: defaultUrls(AwsAccount).auth,
        redirectSignIn: oauthRedirectUrl,
        redirectSignOut: oauthRedirectUrl,
        responseType: 'code',
        urlOpener: (url) => Browser.open({ url, windowName: '_self' }),
        // signUpVerificationMethod: 'code',
        // allowGuestAccess: false,
      },
    },

    // AppSync GraphQL Configuration - https://aws-amplify.github.io/amplify-js/api/#4-graphql-api-operations
    aws_appsync_graphqlEndpoint: defaultUrls(AwsAccount).gql,
    aws_appsync_region: Region,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',

    Storage: {
      bucket: S3Bucket,
      region: Region,
    },

    Analytics: {
      appId: PinpointAppId,
      region: Region,
    },
  },

  appScheme: 'https://app.yeekatee.com',
  minUsernameLength: 3,
  maxUsernameLength: 25,
  minPasswordLength: 8,
  maxPasswordLength: 255,
  passwordRegExp: new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+?_,.-]).{4,}',
  ),
  phoneNumberRegExp: new RegExp('^[- +()0-9]+$'),

  toastTimeout: 5000,

  fallbackAssetLogo: 'assets/icon/default-asset.svg',
  fallbackUserAvatar: 'assets/icon/generic-avatar.svg',

  defaultCountryIso: 'CH',

  // TODO Shouldn't these be configurable?
  giphyDataKey: 'fplmvx8SXBNfVr2lvEKkibWPwyky5KXG',
  cannyBoardToken: '40c32b09-69c4-e3b2-151d-4e40442ad88e',
});
