/**
 * The name of the Cognito User Pool Group used for invited users.
 *
 * With the groups in the ID token, we can distinguish whether a user
 * was already invited or not. This must be stored with the user:
 * for example, if they change device, but already signed up before,
 * they should not have to enter a invite code again.
 *
 * @todo Furthermore, although currently not implemented:
 *       AppSync shall use Cognito Groups to authorize access to the API.
 *       This is to ensure that the backend allows invited users only.
 */
export const COGNITO_GROUP_INVITED = 'invited';
export const COGNITO_GROUP_MODERATORS = 'moderators';
export const COGNITO_GROUP_GAME_ADMINS = 'gameAdmins';
