import { createSelector } from '@ngrx/store';
import { User } from '@yeekatee/client-api-angular';
import {
  createInvitationList,
  InvitationListVm,
  invitationsFeature,
} from '../invitations';
import { UsersSelectors } from '../users';

export const selectInvitedUsers = createSelector(
  invitationsFeature.selectAll,
  UsersSelectors.getUsersEntities,
  (invitations, users) =>
    invitations
      .map((invitation) =>
        invitation?.usedBy ? users[invitation?.usedBy] : undefined,
      )
      .filter((user): user is User => !!user),
);

export const selectInviteListVM = createSelector(
  invitationsFeature.selectAll,
  invitationsFeature.selectLoadingList,
  invitationsFeature.selectLoadingCode,
  invitationsFeature.selectOwnInviteCode,
  selectInvitedUsers,
  (invitations, loadingList, loadingCode, code, users): InvitationListVm => ({
    invitations: createInvitationList(invitations, users).reverse(),
    loadingList,
    loadingCode,
    hasOwnCode: !!code?.id,
  }),
);
