export interface Country {
  name: () => string;
  dialCode: string;
  isoCode: string;
}

export type CountryList = Record<string, Country[]>;

export const countries: Country[] = [
  {
    name: () => $localize`Afghanistan`,
    dialCode: '+93',
    isoCode: 'AF',
  },
  {
    name: () => $localize`Aland Islands`,
    dialCode: '+358',
    isoCode: 'AX',
  },
  {
    name: () => $localize`Albania`,
    dialCode: '+355',
    isoCode: 'AL',
  },
  {
    name: () => $localize`Algeria`,
    dialCode: '+213',
    isoCode: 'DZ',
  },
  {
    name: () => $localize`American Samoa`,
    dialCode: '+1684',
    isoCode: 'AS',
  },
  {
    name: () => $localize`Andorra`,
    dialCode: '+376',
    isoCode: 'AD',
  },
  {
    name: () => $localize`Angola`,
    dialCode: '+244',
    isoCode: 'AO',
  },
  {
    name: () => $localize`Anguilla`,
    dialCode: '+1264',
    isoCode: 'AI',
  },
  {
    name: () => $localize`Antarctica`,
    dialCode: '+672',
    isoCode: 'AQ',
  },
  {
    name: () => $localize`Antigua and Barbuda`,
    dialCode: '+1268',
    isoCode: 'AG',
  },
  {
    name: () => $localize`Argentina`,
    dialCode: '+54',
    isoCode: 'AR',
  },
  {
    name: () => $localize`Armenia`,
    dialCode: '+374',
    isoCode: 'AM',
  },
  {
    name: () => $localize`Aruba`,
    dialCode: '+297',
    isoCode: 'AW',
  },
  {
    name: () => $localize`Ascension Island`,
    dialCode: '+247',
    isoCode: 'AC',
  },
  {
    name: () => $localize`Australia`,
    dialCode: '+61',
    isoCode: 'AU',
  },
  {
    name: () => $localize`Austria`,
    dialCode: '+43',
    isoCode: 'AT',
  },
  {
    name: () => $localize`Azerbaijan`,
    dialCode: '+994',
    isoCode: 'AZ',
  },
  {
    name: () => $localize`Bahamas`,
    dialCode: '+1242',
    isoCode: 'BS',
  },
  {
    name: () => $localize`Bahrain`,
    dialCode: '+973',
    isoCode: 'BH',
  },
  {
    name: () => $localize`Bangladesh`,
    dialCode: '+880',
    isoCode: 'BD',
  },
  {
    name: () => $localize`Barbados`,
    dialCode: '+1246',
    isoCode: 'BB',
  },
  {
    name: () => $localize`Belarus`,
    dialCode: '+375',
    isoCode: 'BY',
  },
  {
    name: () => $localize`Belgium`,
    dialCode: '+32',
    isoCode: 'BE',
  },
  {
    name: () => $localize`Belize`,
    dialCode: '+501',
    isoCode: 'BZ',
  },
  {
    name: () => $localize`Benin`,
    dialCode: '+229',
    isoCode: 'BJ',
  },
  {
    name: () => $localize`Bermuda`,
    dialCode: '+1441',
    isoCode: 'BM',
  },
  {
    name: () => $localize`Bhutan`,
    dialCode: '+975',
    isoCode: 'BT',
  },
  {
    name: () => $localize`Bolivia`,
    dialCode: '+591',
    isoCode: 'BO',
  },
  {
    name: () => $localize`Bosnia and Herzegovina`,
    dialCode: '+387',
    isoCode: 'BA',
  },
  {
    name: () => $localize`Botswana`,
    dialCode: '+267',
    isoCode: 'BW',
  },
  {
    name: () => $localize`Brazil`,
    dialCode: '+55',
    isoCode: 'BR',
  },
  {
    name: () => $localize`British Indian Ocean Territory`,
    dialCode: '+246',
    isoCode: 'IO',
  },
  {
    name: () => $localize`Brunei Darussalam`,
    dialCode: '+673',
    isoCode: 'BN',
  },
  {
    name: () => $localize`Bulgaria`,
    dialCode: '+359',
    isoCode: 'BG',
  },
  {
    name: () => $localize`Burkina Faso`,
    dialCode: '+226',
    isoCode: 'BF',
  },
  {
    name: () => $localize`Burundi`,
    dialCode: '+257',
    isoCode: 'BI',
  },
  {
    name: () => $localize`Cambodia`,
    dialCode: '+855',
    isoCode: 'KH',
  },
  {
    name: () => $localize`Cameroon`,
    dialCode: '+237',
    isoCode: 'CM',
  },
  {
    name: () => $localize`Canada`,
    dialCode: '+1',
    isoCode: 'CA',
  },
  {
    name: () => $localize`Cape Verde`,
    dialCode: '+238',
    isoCode: 'CV',
  },
  {
    name: () => $localize`Cayman Islands`,
    dialCode: '+1345',
    isoCode: 'KY',
  },
  {
    name: () => $localize`Central African Republic`,
    dialCode: '+236',
    isoCode: 'CF',
  },
  {
    name: () => $localize`Chad`,
    dialCode: '+235',
    isoCode: 'TD',
  },
  {
    name: () => $localize`Chile`,
    dialCode: '+56',
    isoCode: 'CL',
  },
  {
    name: () => $localize`China`,
    dialCode: '+86',
    isoCode: 'CN',
  },
  {
    name: () => $localize`Christmas Island`,
    dialCode: '+61',
    isoCode: 'CX',
  },
  {
    name: () => $localize`Cocos (Keeling) Islands`,
    dialCode: '+61',
    isoCode: 'CC',
  },
  {
    name: () => $localize`Colombia`,
    dialCode: '+57',
    isoCode: 'CO',
  },
  {
    name: () => $localize`Comoros`,
    dialCode: '+269',
    isoCode: 'KM',
  },
  {
    name: () => $localize`Congo`,
    dialCode: '+242',
    isoCode: 'CG',
  },
  {
    name: () => $localize`Cook Islands`,
    dialCode: '+682',
    isoCode: 'CK',
  },
  {
    name: () => $localize`Costa Rica`,
    dialCode: '+506',
    isoCode: 'CR',
  },
  {
    name: () => $localize`Croatia`,
    dialCode: '+385',
    isoCode: 'HR',
  },
  {
    name: () => $localize`Cuba`,
    dialCode: '+53',
    isoCode: 'CU',
  },
  {
    name: () => $localize`Cyprus`,
    dialCode: '+357',
    isoCode: 'CY',
  },
  {
    name: () => $localize`Czech Republic`,
    dialCode: '+420',
    isoCode: 'CZ',
  },
  {
    name: () => $localize`Democratic Republic of the Congo`,
    dialCode: '+243',
    isoCode: 'CD',
  },
  {
    name: () => $localize`Denmark`,
    dialCode: '+45',
    isoCode: 'DK',
  },
  {
    name: () => $localize`Djibouti`,
    dialCode: '+253',
    isoCode: 'DJ',
  },
  {
    name: () => $localize`Dominica`,
    dialCode: '+1767',
    isoCode: 'DM',
  },
  {
    name: () => $localize`Dominican Republic`,
    dialCode: '+1849',
    isoCode: 'DO',
  },
  {
    name: () => $localize`Ecuador`,
    dialCode: '+593',
    isoCode: 'EC',
  },
  {
    name: () => $localize`Egypt`,
    dialCode: '+20',
    isoCode: 'EG',
  },
  {
    name: () => $localize`El Salvador`,
    dialCode: '+503',
    isoCode: 'SV',
  },
  {
    name: () => $localize`Equatorial Guinea`,
    dialCode: '+240',
    isoCode: 'GQ',
  },
  {
    name: () => $localize`Eritrea`,
    dialCode: '+291',
    isoCode: 'ER',
  },
  {
    name: () => $localize`Estonia`,
    dialCode: '+372',
    isoCode: 'EE',
  },
  {
    name: () => $localize`Eswatini`,
    dialCode: '+268',
    isoCode: 'SZ',
  },
  {
    name: () => $localize`Ethiopia`,
    dialCode: '+251',
    isoCode: 'ET',
  },
  {
    name: () => $localize`Falkland Islands (Malvinas)`,
    dialCode: '+500',
    isoCode: 'FK',
  },
  {
    name: () => $localize`Faroe Islands`,
    dialCode: '+298',
    isoCode: 'FO',
  },
  {
    name: () => $localize`Fiji`,
    dialCode: '+679',
    isoCode: 'FJ',
  },
  {
    name: () => $localize`Finland`,
    dialCode: '+358',
    isoCode: 'FI',
  },
  {
    name: () => $localize`France`,
    dialCode: '+33',
    isoCode: 'FR',
  },
  {
    name: () => $localize`French Guiana`,
    dialCode: '+594',
    isoCode: 'GF',
  },
  {
    name: () => $localize`French Polynesia`,
    dialCode: '+689',
    isoCode: 'PF',
  },
  {
    name: () => $localize`Gabon`,
    dialCode: '+241',
    isoCode: 'GA',
  },
  {
    name: () => $localize`Gambia`,
    dialCode: '+220',
    isoCode: 'GM',
  },
  {
    name: () => $localize`Georgia`,
    dialCode: '+995',
    isoCode: 'GE',
  },
  {
    name: () => $localize`Germany`,
    dialCode: '+49',
    isoCode: 'DE',
  },
  {
    name: () => $localize`Ghana`,
    dialCode: '+233',
    isoCode: 'GH',
  },
  {
    name: () => $localize`Gibraltar`,
    dialCode: '+350',
    isoCode: 'GI',
  },
  {
    name: () => $localize`Greece`,
    dialCode: '+30',
    isoCode: 'GR',
  },
  {
    name: () => $localize`Greenland`,
    dialCode: '+299',
    isoCode: 'GL',
  },
  {
    name: () => $localize`Grenada`,
    dialCode: '+1473',
    isoCode: 'GD',
  },
  {
    name: () => $localize`Guadeloupe`,
    dialCode: '+590',
    isoCode: 'GP',
  },
  {
    name: () => $localize`Guam`,
    dialCode: '+1671',
    isoCode: 'GU',
  },
  {
    name: () => $localize`Guatemala`,
    dialCode: '+502',
    isoCode: 'GT',
  },
  {
    name: () => $localize`Guernsey`,
    dialCode: '+44-1481',
    isoCode: 'GG',
  },
  {
    name: () => $localize`Guinea`,
    dialCode: '+224',
    isoCode: 'GN',
  },
  {
    name: () => $localize`Guinea-Bissau`,
    dialCode: '+245',
    isoCode: 'GW',
  },
  {
    name: () => $localize`Guyana`,
    dialCode: '+592',
    isoCode: 'GY',
  },
  {
    name: () => $localize`Haiti`,
    dialCode: '+509',
    isoCode: 'HT',
  },
  {
    name: () => $localize`Holy See (Vatican City State)`,
    dialCode: '+379',
    isoCode: 'VA',
  },
  {
    name: () => $localize`Honduras`,
    dialCode: '+504',
    isoCode: 'HN',
  },
  {
    name: () => $localize`Hong Kong`,
    dialCode: '+852',
    isoCode: 'HK',
  },
  {
    name: () => $localize`Hungary`,
    dialCode: '+36',
    isoCode: 'HU',
  },
  {
    name: () => $localize`Iceland`,
    dialCode: '+354',
    isoCode: 'IS',
  },
  {
    name: () => $localize`India`,
    dialCode: '+91',
    isoCode: 'IN',
  },
  {
    name: () => $localize`Indonesia`,
    dialCode: '+62',
    isoCode: 'ID',
  },
  {
    name: () => $localize`Iran`,
    dialCode: '+98',
    isoCode: 'IR',
  },
  {
    name: () => $localize`Iraq`,
    dialCode: '+964',
    isoCode: 'IQ',
  },
  {
    name: () => $localize`Ireland`,
    dialCode: '+353',
    isoCode: 'IE',
  },
  {
    name: () => $localize`Isle of Man`,
    dialCode: '+44-1624',
    isoCode: 'IM',
  },
  {
    name: () => $localize`Israel`,
    dialCode: '+972',
    isoCode: 'IL',
  },
  {
    name: () => $localize`Italy`,
    dialCode: '+39',
    isoCode: 'IT',
  },
  {
    name: () => $localize`Ivory Coast`,
    dialCode: '+225',
    isoCode: 'CI',
  },
  {
    name: () => $localize`Jamaica`,
    dialCode: '+1876',
    isoCode: 'JM',
  },
  {
    name: () => $localize`Japan`,
    dialCode: '+81',
    isoCode: 'JP',
  },
  {
    name: () => $localize`Jersey`,
    dialCode: '+44-1534',
    isoCode: 'JE',
  },
  {
    name: () => $localize`Jordan`,
    dialCode: '+962',
    isoCode: 'JO',
  },
  {
    name: () => $localize`Kazakhstan`,
    dialCode: '+77',
    isoCode: 'KZ',
  },
  {
    name: () => $localize`Kenya`,
    dialCode: '+254',
    isoCode: 'KE',
  },
  {
    name: () => $localize`Kiribati`,
    dialCode: '+686',
    isoCode: 'KI',
  },
  {
    name: () => $localize`Korea, Democratic People's Republic of Korea`,
    dialCode: '+850',
    isoCode: 'KP',
  },
  {
    name: () => $localize`Korea, Republic of South Korea`,
    dialCode: '+82',
    isoCode: 'KR',
  },
  {
    name: () => $localize`Kosovo`,
    dialCode: '+383',
    isoCode: 'XK',
  },
  {
    name: () => $localize`Kuwait`,
    dialCode: '+965',
    isoCode: 'KW',
  },
  {
    name: () => $localize`Kyrgyzstan`,
    dialCode: '+996',
    isoCode: 'KG',
  },
  {
    name: () => $localize`Laos`,
    dialCode: '+856',
    isoCode: 'LA',
  },
  {
    name: () => $localize`Latvia`,
    dialCode: '+371',
    isoCode: 'LV',
  },
  {
    name: () => $localize`Lebanon`,
    dialCode: '+961',
    isoCode: 'LB',
  },
  {
    name: () => $localize`Lesotho`,
    dialCode: '+266',
    isoCode: 'LS',
  },
  {
    name: () => $localize`Liberia`,
    dialCode: '+231',
    isoCode: 'LR',
  },
  {
    name: () => $localize`Libya`,
    dialCode: '+218',
    isoCode: 'LY',
  },
  {
    name: () => $localize`Liechtenstein`,
    dialCode: '+423',
    isoCode: 'LI',
  },
  {
    name: () => $localize`Lithuania`,
    dialCode: '+370',
    isoCode: 'LT',
  },
  {
    name: () => $localize`Luxembourg`,
    dialCode: '+352',
    isoCode: 'LU',
  },
  {
    name: () => $localize`Macau`,
    dialCode: '+853',
    isoCode: 'MO',
  },
  {
    name: () => $localize`Madagascar`,
    dialCode: '+261',
    isoCode: 'MG',
  },
  {
    name: () => $localize`Malawi`,
    dialCode: '+265',
    isoCode: 'MW',
  },
  {
    name: () => $localize`Malaysia`,
    dialCode: '+60',
    isoCode: 'MY',
  },
  {
    name: () => $localize`Maldives`,
    dialCode: '+960',
    isoCode: 'MV',
  },
  {
    name: () => $localize`Mali`,
    dialCode: '+223',
    isoCode: 'ML',
  },
  {
    name: () => $localize`Malta`,
    dialCode: '+356',
    isoCode: 'MT',
  },
  {
    name: () => $localize`Marshall Islands`,
    dialCode: '+692',
    isoCode: 'MH',
  },
  {
    name: () => $localize`Martinique`,
    dialCode: '+596',
    isoCode: 'MQ',
  },
  {
    name: () => $localize`Mauritania`,
    dialCode: '+222',
    isoCode: 'MR',
  },
  {
    name: () => $localize`Mauritius`,
    dialCode: '+230',
    isoCode: 'MU',
  },
  {
    name: () => $localize`Mayotte`,
    dialCode: '+262',
    isoCode: 'YT',
  },
  {
    name: () => $localize`Mexico`,
    dialCode: '+52',
    isoCode: 'MX',
  },
  {
    name: () => $localize`Micronesia, Federated States of Micronesia`,
    dialCode: '+691',
    isoCode: 'FM',
  },
  {
    name: () => $localize`Moldova`,
    dialCode: '+373',
    isoCode: 'MD',
  },
  {
    name: () => $localize`Monaco`,
    dialCode: '+377',
    isoCode: 'MC',
  },
  {
    name: () => $localize`Mongolia`,
    dialCode: '+976',
    isoCode: 'MN',
  },
  {
    name: () => $localize`Montenegro`,
    dialCode: '+382',
    isoCode: 'ME',
  },
  {
    name: () => $localize`Montserrat`,
    dialCode: '+1664',
    isoCode: 'MS',
  },
  {
    name: () => $localize`Morocco`,
    dialCode: '+212',
    isoCode: 'MA',
  },
  {
    name: () => $localize`Mozambique`,
    dialCode: '+258',
    isoCode: 'MZ',
  },
  {
    name: () => $localize`Myanmar`,
    dialCode: '+95',
    isoCode: 'MM',
  },
  {
    name: () => $localize`Namibia`,
    dialCode: '+264',
    isoCode: 'NA',
  },
  {
    name: () => $localize`Nauru`,
    dialCode: '+674',
    isoCode: 'NR',
  },
  {
    name: () => $localize`Nepal`,
    dialCode: '+977',
    isoCode: 'NP',
  },
  {
    name: () => $localize`Netherlands`,
    dialCode: '+31',
    isoCode: 'NL',
  },
  // Removed as long as no picture available with country-flag-icons
  // {
  //   name: () => $localize`Netherlands Antilles`,
  //   dialCode: '+599',
  //   isoCode: 'AN',
  // },
  {
    name: () => $localize`New Caledonia`,
    dialCode: '+687',
    isoCode: 'NC',
  },
  {
    name: () => $localize`New Zealand`,
    dialCode: '+64',
    isoCode: 'NZ',
  },
  {
    name: () => $localize`Nicaragua`,
    dialCode: '+505',
    isoCode: 'NI',
  },
  {
    name: () => $localize`Niger`,
    dialCode: '+227',
    isoCode: 'NE',
  },
  {
    name: () => $localize`Nigeria`,
    dialCode: '+234',
    isoCode: 'NG',
  },
  {
    name: () => $localize`Niue`,
    dialCode: '+683',
    isoCode: 'NU',
  },
  {
    name: () => $localize`Norfolk Island`,
    dialCode: '+672',
    isoCode: 'NF',
  },
  {
    name: () => $localize`North Macedonia`,
    dialCode: '+389',
    isoCode: 'MK',
  },
  {
    name: () => $localize`Northern Mariana Islands`,
    dialCode: '+1670',
    isoCode: 'MP',
  },
  {
    name: () => $localize`Norway`,
    dialCode: '+47',
    isoCode: 'NO',
  },
  {
    name: () => $localize`Oman`,
    dialCode: '+968',
    isoCode: 'OM',
  },
  {
    name: () => $localize`Pakistan`,
    dialCode: '+92',
    isoCode: 'PK',
  },
  {
    name: () => $localize`Palau`,
    dialCode: '+680',
    isoCode: 'PW',
  },
  {
    name: () => $localize`Palestine`,
    dialCode: '+970',
    isoCode: 'PS',
  },
  {
    name: () => $localize`Panama`,
    dialCode: '+507',
    isoCode: 'PA',
  },
  {
    name: () => $localize`Papua New Guinea`,
    dialCode: '+675',
    isoCode: 'PG',
  },
  {
    name: () => $localize`Paraguay`,
    dialCode: '+595',
    isoCode: 'PY',
  },
  {
    name: () => $localize`Peru`,
    dialCode: '+51',
    isoCode: 'PE',
  },
  {
    name: () => $localize`Philippines`,
    dialCode: '+63',
    isoCode: 'PH',
  },
  {
    name: () => $localize`Pitcairn`,
    dialCode: '+872',
    isoCode: 'PN',
  },
  {
    name: () => $localize`Poland`,
    dialCode: '+48',
    isoCode: 'PL',
  },
  {
    name: () => $localize`Portugal`,
    dialCode: '+351',
    isoCode: 'PT',
  },
  {
    name: () => $localize`Puerto Rico`,
    dialCode: '+1939',
    isoCode: 'PR',
  },
  {
    name: () => $localize`Qatar`,
    dialCode: '+974',
    isoCode: 'QA',
  },
  {
    name: () => $localize`Reunion`,
    dialCode: '+262',
    isoCode: 'RE',
  },
  {
    name: () => $localize`Romania`,
    dialCode: '+40',
    isoCode: 'RO',
  },
  {
    name: () => $localize`Russia`,
    dialCode: '+7',
    isoCode: 'RU',
  },
  {
    name: () => $localize`Rwanda`,
    dialCode: '+250',
    isoCode: 'RW',
  },
  {
    name: () => $localize`Saint Barthelemy`,
    dialCode: '+590',
    isoCode: 'BL',
  },
  {
    name: () => $localize`Saint Helena, Ascension and Tristan Da Cunha`,
    dialCode: '+290',
    isoCode: 'SH',
  },
  {
    name: () => $localize`Saint Kitts and Nevis`,
    dialCode: '+1869',
    isoCode: 'KN',
  },
  {
    name: () => $localize`Saint Lucia`,
    dialCode: '+1758',
    isoCode: 'LC',
  },
  {
    name: () => $localize`Saint Martin`,
    dialCode: '+590',
    isoCode: 'MF',
  },
  {
    name: () => $localize`Saint Pierre and Miquelon`,
    dialCode: '+508',
    isoCode: 'PM',
  },
  {
    name: () => $localize`Saint Vincent and the Grenadines`,
    dialCode: '+1784',
    isoCode: 'VC',
  },
  {
    name: () => $localize`Samoa`,
    dialCode: '+685',
    isoCode: 'WS',
  },
  {
    name: () => $localize`San Marino`,
    dialCode: '+378',
    isoCode: 'SM',
  },
  {
    name: () => $localize`Sao Tome and Principe`,
    dialCode: '+239',
    isoCode: 'ST',
  },
  {
    name: () => $localize`Saudi Arabia`,
    dialCode: '+966',
    isoCode: 'SA',
  },
  {
    name: () => $localize`Senegal`,
    dialCode: '+221',
    isoCode: 'SN',
  },
  {
    name: () => $localize`Serbia`,
    dialCode: '+381',
    isoCode: 'RS',
  },
  {
    name: () => $localize`Seychelles`,
    dialCode: '+248',
    isoCode: 'SC',
  },
  {
    name: () => $localize`Sierra Leone`,
    dialCode: '+232',
    isoCode: 'SL',
  },
  {
    name: () => $localize`Singapore`,
    dialCode: '+65',
    isoCode: 'SG',
  },
  {
    name: () => $localize`Sint Maarten`,
    dialCode: '+1721',
    isoCode: 'SX',
  },
  {
    name: () => $localize`Slovakia`,
    dialCode: '+421',
    isoCode: 'SK',
  },
  {
    name: () => $localize`Slovenia`,
    dialCode: '+386',
    isoCode: 'SI',
  },
  {
    name: () => $localize`Solomon Islands`,
    dialCode: '+677',
    isoCode: 'SB',
  },
  {
    name: () => $localize`Somalia`,
    dialCode: '+252',
    isoCode: 'SO',
  },
  {
    name: () => $localize`South Africa`,
    dialCode: '+27',
    isoCode: 'ZA',
  },
  {
    name: () => $localize`South Georgia and the South Sandwich Islands`,
    dialCode: '+500',
    isoCode: 'GS',
  },
  {
    name: () => $localize`South Sudan`,
    dialCode: '+211',
    isoCode: 'SS',
  },
  {
    name: () => $localize`Spain`,
    dialCode: '+34',
    isoCode: 'ES',
  },
  {
    name: () => $localize`Sri Lanka`,
    dialCode: '+94',
    isoCode: 'LK',
  },
  {
    name: () => $localize`Sudan`,
    dialCode: '+249',
    isoCode: 'SD',
  },
  {
    name: () => $localize`Suriname`,
    dialCode: '+597',
    isoCode: 'SR',
  },
  {
    name: () => $localize`Svalbard and Jan Mayen`,
    dialCode: '+47',
    isoCode: 'SJ',
  },
  {
    name: () => $localize`Sweden`,
    dialCode: '+46',
    isoCode: 'SE',
  },
  {
    name: () => $localize`Switzerland`,
    dialCode: '+41',
    isoCode: 'CH',
  },
  {
    name: () => $localize`Syrian Arab Republic`,
    dialCode: '+963',
    isoCode: 'SY',
  },
  {
    name: () => $localize`Taiwan`,
    dialCode: '+886',
    isoCode: 'TW',
  },
  {
    name: () => $localize`Tajikistan`,
    dialCode: '+992',
    isoCode: 'TJ',
  },
  {
    name: () => $localize`Tanzania, United Republic of Tanzania`,
    dialCode: '+255',
    isoCode: 'TZ',
  },
  {
    name: () => $localize`Thailand`,
    dialCode: '+66',
    isoCode: 'TH',
  },
  {
    name: () => $localize`Timor-Leste`,
    dialCode: '+670',
    isoCode: 'TL',
  },
  {
    name: () => $localize`Togo`,
    dialCode: '+228',
    isoCode: 'TG',
  },
  {
    name: () => $localize`Tokelau`,
    dialCode: '+690',
    isoCode: 'TK',
  },
  {
    name: () => $localize`Tonga`,
    dialCode: '+676',
    isoCode: 'TO',
  },
  {
    name: () => $localize`Trinidad and Tobago`,
    dialCode: '+1868',
    isoCode: 'TT',
  },
  {
    name: () => $localize`Tunisia`,
    dialCode: '+216',
    isoCode: 'TN',
  },
  {
    name: () => $localize`Turkey`,
    dialCode: '+90',
    isoCode: 'TR',
  },
  {
    name: () => $localize`Turkmenistan`,
    dialCode: '+993',
    isoCode: 'TM',
  },
  {
    name: () => $localize`Turks and Caicos Islands`,
    dialCode: '+1649',
    isoCode: 'TC',
  },
  {
    name: () => $localize`Tuvalu`,
    dialCode: '+688',
    isoCode: 'TV',
  },
  {
    name: () => $localize`Uganda`,
    dialCode: '+256',
    isoCode: 'UG',
  },
  {
    name: () => $localize`Ukraine`,
    dialCode: '+380',
    isoCode: 'UA',
  },
  {
    name: () => $localize`United Arab Emirates`,
    dialCode: '+971',
    isoCode: 'AE',
  },
  {
    name: () => $localize`United Kingdom`,
    dialCode: '+44',
    isoCode: 'GB',
  },
  {
    name: () => $localize`United States`,
    dialCode: '+1',
    isoCode: 'US',
  },
  {
    name: () => $localize`United States Minor Outlying Islands`,
    dialCode: '+246',
    isoCode: 'UM',
  },
  {
    name: () => $localize`Uruguay`,
    dialCode: '+598',
    isoCode: 'UY',
  },
  {
    name: () => $localize`Uzbekistan`,
    dialCode: '+998',
    isoCode: 'UZ',
  },
  {
    name: () => $localize`Vanuatu`,
    dialCode: '+678',
    isoCode: 'VU',
  },
  {
    name: () => $localize`Venezuela, Bolivarian Republic of Venezuela`,
    dialCode: '+58',
    isoCode: 'VE',
  },
  {
    name: () => $localize`Vietnam`,
    dialCode: '+84',
    isoCode: 'VN',
  },
  {
    name: () => $localize`Virgin Islands, British`,
    dialCode: '+1284',
    isoCode: 'VG',
  },
  {
    name: () => $localize`Virgin Islands, U.S.`,
    dialCode: '+1340',
    isoCode: 'VI',
  },
  {
    name: () => $localize`Wallis and Futuna`,
    dialCode: '+681',
    isoCode: 'WF',
  },
  {
    name: () => $localize`Yemen`,
    dialCode: '+967',
    isoCode: 'YE',
  },
  {
    name: () => $localize`Zambia`,
    dialCode: '+260',
    isoCode: 'ZM',
  },
  {
    name: () => $localize`Zimbabwe`,
    dialCode: '+263',
    isoCode: 'ZW',
  },
];
