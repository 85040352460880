import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { IonLabel } from '@ionic/angular/standalone';

export enum LegalType {
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  ACCOUNT_LINKING = 'ACCOUNT_LINKING',
}

@Component({
  selector: 'yeekatee-legal',
  standalone: true,
  templateUrl: './legal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonLabel],
})
export class LegalComponent {
  @Input() language?: string = 'en';
  @Input() legalType: LegalType = LegalType.SIGN_UP;

  protected readonly LegalType = LegalType;

  protected openPolicies() {
    Browser.open({
      url: `https://www.yeekatee.com/${this.language}/privacy-policy`,
    });
  }

  protected openTermsOfUse() {
    Browser.open({
      url: `https://www.yeekatee.com/${this.language}/terms-of-use`,
    });
  }
}
