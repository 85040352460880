import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AvailableLocale, localeLookupMap } from './locales.models';
import { LOCALES_FEATURE_KEY, LocalesState } from './locales.reducer';

// Lookup the 'Locales' feature state managed by NgRx
export const getLocalesState =
  createFeatureSelector<LocalesState>(LOCALES_FEATURE_KEY);

export const getLocalesLoaded = createSelector(
  getLocalesState,
  (state: LocalesState) => state.loaded,
);

export const getLocalesError = createSelector(
  getLocalesState,
  (state: LocalesState) => state.error,
);

export const getUseDeviceLocale = createSelector(
  getLocalesState,
  (state) => state.useDeviceLocale,
);

export const getLocalesEntities = createSelector(() => localeLookupMap);

export const getLocalesWithRegion = createSelector(
  getLocalesEntities,
  (locales) =>
    Object.entries(locales)
      .filter(([, locale]) => locale.region)
      .map(([id, locale]) => ({
        ...locale,
        id: id as AvailableLocale,
      })),
);

export const getSelectedId = createSelector(
  getLocalesState,
  (state: LocalesState) => state.loadedLocale,
);

export const getBaseLocale = createSelector(
  getSelectedId,
  (locale) => new Intl.Locale(locale ?? 'en-US').language,
);

//TODO remove selector as soon as landing page has default i18n routing
export const getLandingPageLanguage = createSelector(getBaseLocale, (lang) =>
  ['en', 'es', 'de'].includes(lang) ? lang : 'en',
);

export const getSelected = createSelector(
  getLocalesEntities,
  getSelectedId,
  (entities, selected) => (selected ? entities[selected] : undefined),
);

export const getAppIsNotInEnglish = createSelector(
  getSelectedId,
  (locale) => !locale || !locale.startsWith('en'),
);
