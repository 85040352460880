import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromActivities from './features/activities.reducer';
import * as fromCollections from './features/collections.reducer';
import * as fromObjects from './features/objects.reducer';

export interface ActivitiesState {
  readonly [fromActivities.FEATURE_KEY]: fromActivities.State;
  readonly [fromCollections.FEATURE_KEY]: fromCollections.State;
  readonly [fromObjects.FEATURE_KEY]: fromObjects.State;
}

export const FEATURE_KEY = 'activityStreams';

export interface ActivitiesPartialState {
  readonly [FEATURE_KEY]: ActivitiesState;
}

export function reducers(state: ActivitiesState | undefined, action: Action) {
  return combineReducers(
    {
      [fromActivities.FEATURE_KEY]: fromActivities.reducer,
      [fromCollections.FEATURE_KEY]: fromCollections.reducer,
      [fromObjects.FEATURE_KEY]: fromObjects.reducer,
    },
    {
      [fromActivities.FEATURE_KEY]: fromActivities.initialState,
      [fromCollections.FEATURE_KEY]: fromCollections.initialState,
      [fromObjects.FEATURE_KEY]: fromObjects.initialState,
    },
  )(state, action);
}

export const selectActivitiesState =
  createFeatureSelector<ActivitiesState>(FEATURE_KEY);
