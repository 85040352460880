import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../utils';
import { SignInCredentials, SignUpCredentials, User } from './models';

/**
 * The Launcher page, where the user can decide whether to log in or sign up.
 * Optionally, directly with the social providers.
 */
export const AuthLaunchActions = createActionGroup({
  source: 'Auth Launch',
  events: {
    'Launch Init': emptyProps(),
    'To Sign Up': emptyProps(),
    'To Sign In': emptyProps(),
    'Sign Up With Google': emptyProps(),
    'Sign Up With Apple': emptyProps(),
  },
});

/**
 * The user decided to sign up with username and password.
 * They must enter these credentials, and hit sign up.
 */
export const AuthSignUpActions = createActionGroup({
  source: 'Auth Sign Up',
  events: {
    'Sign Up': props<{ credentials: SignUpCredentials }>(),
    'Back To Launch': emptyProps(),
  },
});

/**
 * The user just signed up, and has to confirm their email with a code.
 */
export const AuthEmailConfirmActions = createActionGroup({
  source: 'Auth Email Confirm',
  events: {
    'Resend Code': emptyProps(),
    'Verify Code': props<{ code: string }>(),
    'Back To Sign Up': emptyProps(),
  },
});

/**
 * The user decided to sign in, usually with email and password, but may still
 * decide to use one of the social sign in providers, or reset their password.
 */
export const AuthSignInActions = createActionGroup({
  source: 'Auth Sign In',
  events: {
    'Sign In': props<{ credentials: SignInCredentials }>(),
    'To Forgot Password': emptyProps(),
    'Sign In With Google': emptyProps(),
    'Sign In With Apple': emptyProps(),
    'Back To Launch': emptyProps(),
  },
});

/**
 * The user just signed in, and may have to confirm their phone with a code.
 */
export const AuthPhoneConfirmActions = createActionGroup({
  source: 'Auth Phone Confirm',
  events: {
    'Resend Code': emptyProps(),
    'Verify Code': props<{ code: string }>(),
    'Back To Sign In': emptyProps(),
  },
});

/**
 * The user requested to reset their password, they must enter their email,
 * and new password, and they will then later get a code to commit.
 */
export const AuthForgotPasswordActions = createActionGroup({
  source: 'Auth Forgot Password',
  events: {
    'Reset Password': props<{ credentials: SignInCredentials }>(),
    'Back To Sign In': emptyProps(),
  },
});

/**
 * The user requested a password reset, and has to confirm it with a
 * one-time code, that they receive on their email address.
 */
export const AuthForgotPasswordEmailConfirmActions = createActionGroup({
  source: 'Auth Forgot Password Email Confirm',
  events: {
    'Resend Code': emptyProps(),
    'Verify Code': props<{ code: string }>(),
    'Back To Forgot Password': emptyProps(),
  },
});

/**
 * Actions dispatched from the authentication guard.
 */
export const AuthGuardActions = createActionGroup({
  source: 'Auth Guard',
  events: {
    'Redirecting To Auth': props<{ lastRoute?: string }>(),
  },
});

/**
 * Actions dispatched from the effects, when the user is not authenticated.
 */
export const AuthEffectOperatorActions = createActionGroup({
  source: 'Auth Effect Operator',
  events: {
    'Auth Required To Run Effect': emptyProps(),
  },
});

/**
 * The responses coming from the Amplify/Cognito API.
 */
export const AuthAPIActions = createActionGroup({
  source: 'Auth API',
  events: {
    'Sign Up Success': props<{ user: User }>(),
    'Sign Up Failure': errorProps(),

    'Resend Sign Up Success': emptyProps(),
    'Resend Sign Up Failure': errorProps(),

    'Confirm Sign Up Success': emptyProps(),
    'Confirm Sign Up Failure': errorProps(),

    'Sign In Success': props<{ user: User }>(),
    'Sign In Failure': errorProps(),
    'Sign In User Not Confirmed': props<{ username: string }>(),
    'Sign In Presented Challenge': props<{
      name: string;
      parameters: object;
    }>(),

    'Confirm Sign In Success': emptyProps(),
    'Confirm Sign In Failure': errorProps(),

    'Sign Out Success': emptyProps(),
    'Sign Out Failure': errorProps(),

    'Reset Password Success': props<{ destination: string }>(),
    'Reset Password Failure': errorProps(),

    'Confirm Password Success': emptyProps(),
    'Confirm Password Failure': errorProps(),

    'Confirm Password Settings Success': emptyProps(),
    'Confirm Password Settings Failure': errorProps(),

    'Set Email Success': emptyProps(),
    'Set Email Failure': errorProps(),

    'Confirm Email Success': emptyProps(),
    'Confirm Email Failure': errorProps(),

    'Configure Analytics Success': emptyProps(),
    'Configure Analytics Failure': errorProps(),
  },
});

/**
 * The events coming from within the Amplify Hub, filtered by auth events.
 *
 * @todo Identify all events and see if some effects can be simplified
 * @see https://docs.amplify.aws/lib/utilities/hub/q/platform/js/
 */
export const AuthAmplifyHubActions = createActionGroup({
  source: 'Auth Amplify Hub',
  events: {
    'Sign In Success': props<{ userId: string }>(),
    'Sign In Failure': errorProps(),
    'Auto Sign In Success': props<{ userId: string }>(),
    'Auto Sign In Failure': errorProps(),
    'Custom OAuth State': props<{ customState: string }>(),
  },
});

/**
 * Events coming from Capacitor/App itself
 */
export const AuthCapacitorActions = createActionGroup({
  source: 'Auth Capacitor',
  events: {
    'Federated Sign In Callback': props<{ callbackUrl: string }>(),
    'Load Username Success': props<{ username: string }>(),
    'Load Username Failure': errorProps(),
  },
});

/**
 * Events happening within the Auth effects
 */
export const AuthEffectsActions = createActionGroup({
  source: 'Auth Effects',
  events: {
    'On Init Effects': emptyProps(),

    'Parsed Custom OAuth State Success': props<{ lastRoute?: string }>(),
    'Parsed Custom OAuth State Failure': errorProps(),

    'No Current User Detected': emptyProps(),
    'Federated Sign In Success': props<{ user: User }>(),
    'Federated Sign In Failure': errorProps(),

    'Get Cached User Success': props<{ user: User }>(),
    'Get Cached User Failure': errorProps(),

    'Get Current User Success': props<{ user: User }>(),
    'Get Current User Failure': errorProps(),

    'Refresh Current User Success': props<{ user: User }>(),
    'Refresh Current User Failure': errorProps(),

    'Refresh Current User Route Back Success': props<{ user: User }>(),
    'Refresh Current User Route Back Failure': errorProps(),

    'App Init': props<{ userId?: string }>(),

    'Notify Error': errorProps(),

    'Sign Out Cleanup Success': emptyProps(),
    'Sign Out Cleanup Failure': errorProps(),

    'Redirect To Auth': props<{ lastRoute?: string }>(),
  },
});

/**
 * The main settings page, the user can sign out from here
 *
 * @todo Should we rather have some Settings Actions for all these?
 */
export const AuthSettingsActions = createActionGroup({
  source: 'Auth Settings',
  events: {
    'Sign Out': emptyProps(),
  },
});

export const AuthEmailSettingsActions = createActionGroup({
  source: 'Auth Settings Email',
  events: {
    'Set Email': props<{ user: User }>(),
  },
});

export const AuthEmailConfirmSettingsActions = createActionGroup({
  source: 'Auth Settings Confirm Email',
  events: {
    'Request New Email Code': emptyProps(),
    'Verify Code': props<{ code: string }>(),
  },
});

export const AuthPasswordSettingsActions = createActionGroup({
  source: 'Auth Settings Password',
  events: {
    'Reset Password': props<{ credentials: SignInCredentials }>(),
  },
});

export const AuthPasswordConfirmSettingsActions = createActionGroup({
  source: 'Auth Forgot Confirm Password',
  events: {
    'Resend Code': emptyProps(),
    'Verify Code': props<{ code: string }>(),
  },
});

export const AuthPhoneSettingsActions = createActionGroup({
  source: 'Auth Settings Phone',
  events: {
    'Set Phone Number': props<{ phone: string }>(),
    'Verify Code': props<{ code: string }>(),
  },
});
