import { createFeatureSelector, createSelector } from '@ngrx/store';
import chroma from 'chroma-js';
import { FEATURE_KEY, ThemeState } from './theme.reducer';

export const selectThemeState = createFeatureSelector<ThemeState>(FEATURE_KEY);

export const selectColors = createSelector(
  selectThemeState,
  ({ colors }) =>
    colors && {
      primary: chroma(colors.primary),
      secondary: chroma(colors.secondary),
      tertiary: chroma(colors.tertiary),
      contrast: chroma(colors.contrast),
      uptrend: chroma(colors.uptrend),
      downtrend: chroma(colors.downtrend),
      neutral: chroma(colors.neutral),
      light: chroma(colors.light),
    },
);

export const selectColorScale = createSelector(
  selectColors,
  (colors) =>
    colors &&
    chroma
      .scale([colors.primary, colors.secondary, colors.tertiary])
      .mode('lch'),
);

export const selectWebThemeActive = createSelector(
  selectThemeState,
  ({ isWebThemeActive }) => isWebThemeActive,
);

export const selectThemeMode = createSelector(
  selectThemeState,
  ({ mode }) => mode,
);
