import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[yeekateeFlashing]',
})
export class FlashingDirective implements OnChanges {
  @Input() set yeekateeFlashing(activated: boolean | string) {
    if (typeof activated === 'string') {
      this.activated = activated !== 'false';
      return;
    }
    this.activated = activated;
  }
  @Input() priceTendency = 0;
  @Input() duration = 500;

  activated = true;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(): void {
    if (!this.activated) return;
    this.handleTextFlashing();
  }

  handleTextFlashing() {
    const flashTextPlus = 'text-uptrend';
    const flashTextMinus = 'text-downtrend';
    const bold = 'font-semibold';

    if (this.priceTendency === 0) return;
    const flashText = this.priceTendency > 0 ? flashTextPlus : flashTextMinus;
    this.renderer.addClass(this.elRef.nativeElement, flashText);
    this.renderer.addClass(this.elRef.nativeElement, bold);
    setTimeout(() => {
      this.renderer.removeClass(this.elRef.nativeElement, flashText);
      this.renderer.removeClass(this.elRef.nativeElement, bold);
    }, this.duration);
  }
}
