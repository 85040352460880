<swiper-container
  [modules]="swiperModules"
  [navigation]="true"
  [pagination]="true"
  [zoom]="true"
  [initialSlide]="startFrom"
  #container
  class="h-full w-full"
>
  @for (image of images; track image.url) {
    <swiper-slide>
      <div class="swiper-zoom-container">
        <img
          tappable
          [ngSrc]="image.url"
          [width]="image.width ?? defaultWidth"
          [height]="image.height ?? defaultHeight"
          [alt]="image.alt ?? defaultAlt"
        />
      </div>
    </swiper-slide>
  }
</swiper-container>
