<ion-card>
  <ion-item-divider color="light">
    <ion-skeleton-text animated="true" />
  </ion-item-divider>
  <ion-card-content>
    @for (n of [].constructor(numLines); track n) {
      <ion-skeleton-text animated="true" />
    }
  </ion-card-content>
</ion-card>
