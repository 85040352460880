import { createSelector } from '@ngrx/store';
import {
  ActivitiesState,
  selectActivitiesState,
} from '../activity-streams.reducer';
import * as fromObjects from './objects.reducer';

const { selectAll, selectEntities } = fromObjects.adapter.getSelectors();

export const getObjectsState = createSelector(
  selectActivitiesState,
  (state: ActivitiesState) => state.objects,
);

export const getAllObjects = createSelector(
  getObjectsState,
  (state: fromObjects.State) => selectAll(state),
);

export const getObjectsEntities = createSelector(
  getObjectsState,
  (state: fromObjects.State) => selectEntities(state),
);
