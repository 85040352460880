import { createSelector } from '@ngrx/store';
import { ActivitiesSelectors, TimelineFilter } from '../activity-streams';
import * as AuthSelectors from '../auth/auth.selectors';
import { MarketNewsSelectors } from '../market-news';

export const selectHomeVM = createSelector(
  AuthSelectors.isAuthenticated,
  MarketNewsSelectors.selectMarketNewsTimelineLoading,
  ActivitiesSelectors.selectTimelineRefreshing(TimelineFilter.FOR_YOU),
  ActivitiesSelectors.selectTimelineRefreshing(TimelineFilter.FOLLOWING),
  (
    isAuthenticated,
    newsLoading,
    refreshingForYouTimeline,
    refreshingFollowingTimeline,
  ) => ({
    isAuthenticated,
    newsLoading,
    refreshingTimeline: refreshingForYouTimeline || refreshingFollowingTimeline,
  }),
);
