import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CreateCredentialsResponse,
  LinkAccountResult,
  SubmitScaResponse,
} from '@yeekatee/client-api-angular';
import { SupportedBank } from '@yeekatee/shared-util-banks';
import { errorProps } from '../utils';
import { RequestBankInput } from './account-linking.model';

export const AccountLinkingBanksPageActions = createActionGroup({
  source: 'Account Linking Banks Page',
  events: {
    'Selected Bank To Sync': props<{ bank: SupportedBank }>(),
    'Received Callback': emptyProps(), // code comes from router store
    'Leaving Callback Page': emptyProps(),
    'Flag Bank': props<RequestBankInput>(),
    'Open Bank Request Modal': emptyProps(),
    'Close Bank Request Modal': emptyProps(),
  },
});

export const AccountLinkingCredentialsPageActions = createActionGroup({
  source: 'Account Linking Credentials Page',
  events: {
    /**
     * ! Here we depart from NgRx best practices by doing some pre-processing
     * ! inside the smart component, where we encrypt the user input,
     * ! to prevent accidentally leaking it (e.g. in Sentry breadcrumbs)
     */
    'Submit Encrypted Credentials': props<{ jwe: string }>(),
    'Submit Sca Key': props<{ sca: string }>(),
    'Route To Portfolio': emptyProps,
    'Sca Abort': emptyProps,
  },
});

export const AccountLinkingEffectsActions = createActionGroup({
  source: 'Account Linking Effects',
  events: {
    'Received Flanks Callback Success': props<{
      code: string;
      delayed: boolean;
    }>(),
    'Received Flanks Callback Failure': emptyProps(),
    'Notify Error': errorProps(),
  },
});

export const AccountLinkingApiActions = createActionGroup({
  source: 'Account Linking API',
  events: {
    'Exchanged Flanks Code Success': props<{
      result: LinkAccountResult;
    }>(),
    'Exchanged Flanks Code Failure': errorProps(),
    'Sync Yeekatee Bank Success': props<{ result: LinkAccountResult }>(),
    'Sync Yeekatee Bank Failure': errorProps(),

    'Create Credentials Success': props<{
      response: CreateCredentialsResponse;
    }>(),
    'Create Credentials Failure': errorProps(),
    'Submit Sca Success': props<{
      response: SubmitScaResponse;
    }>(),
    'Submit Sca Failure': errorProps(),
  },
});
