import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { filter, fromEvent, map, startWith } from 'rxjs';
import { ThemeActions } from './theme.actions';
import { ColorMode } from './theme.reducer';

@Injectable()
export class ThemeEffects {
  private media$ = (query: string) => {
    const mediaQuery = window.matchMedia(query);
    return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      filter((list: MediaQueryList) => list.matches),
      map(() => {
        const style = getComputedStyle(document.body);

        /**
         * getPropertyValue can and will return any whitespace in the property.
         * This will break chroma.
         *
         * ```
         * Error: unknown hex color:  #ffffff
         * ```
         */
        const getColor = (name: string) =>
          style.getPropertyValue(`--ion-color-${name}`).trim();

        return {
          primary: getColor('primary'),
          secondary: getColor('secondary'),
          tertiary: getColor('tertiary'),
          contrast: getColor('light-contrast'),
          uptrend: getColor('uptrend'),
          downtrend: getColor('danger'),
          neutral: getColor('medium'),
          light: getColor('light'),
        };
      }),
    );
  };

  prefersDark$ = createEffect(() =>
    this.media$('(prefers-color-scheme: dark)').pipe(
      map((colors) =>
        ThemeActions.darkModeSelected({ mode: ColorMode.DARK, colors }),
      ),
    ),
  );

  prefersLight$ = createEffect(() =>
    this.media$('(prefers-color-scheme: light)').pipe(
      map((colors) =>
        ThemeActions.lightModeSelected({ mode: ColorMode.LIGHT, colors }),
      ),
    ),
  );
}
