import { createSelector } from '@ngrx/store';
import { computeHash } from '@yeekatee/booking-util-definitions';
import { BookingPeriod } from '@yeekatee/client-api-angular';
import { getQuoteId } from '@yeekatee/shared-util-typesafe-dynamodb';
import {
  InstrumentsSelectors,
  QuotesSelectors,
  findListing,
  isTradeable,
} from '../instruments';
import {
  PortfolioItemVM,
  getReportValuesByCurrencyAndCalculationBase,
  getStoreReportId,
  portfolioReportsFeature,
} from '../portfolios';
import { memoize } from '../utils';

export const selectPortfolioItemVM = memoize(
  (
    itemId?: string,
    userId?: string,
    currency?: string,
    period?: BookingPeriod,
    startDate?: string,
    endDate?: string,
    keys?: string[],
  ) => {
    const reportId = userId
      ? getStoreReportId(
          computeHash(keys, userId),
          period,
          currency,
          startDate,
          endDate,
        )
      : undefined;

    return createSelector(
      InstrumentsSelectors.selectInstrumentsEntities,
      QuotesSelectors.selectQuotesEntities,
      portfolioReportsFeature.selectReportItemById(itemId, reportId),
      portfolioReportsFeature.selectReportPeriod,
      portfolioReportsFeature.selectReportCurrency,
      portfolioReportsFeature.selectCalculationBaseType,
      InstrumentsSelectors.selectInstrumentLoading,
      InstrumentsSelectors.selectQuoteLoading,
      portfolioReportsFeature.canAddTransactions,
      portfolioReportsFeature.selectGeekView,
      (
        instruments,
        quotes,
        item,
        reportPeriod,
        reportCurrency,
        calculationBaseType,
        instrumentLoading,
        quoteLoading,
        canAddTransactions,
        geekView,
      ): PortfolioItemVM => {
        const id = item?.instrument?.id ?? undefined;
        const instrument = id ? instruments[id] : undefined;
        const itemInstrument = item?.instrument ?? undefined;
        const symbol = itemInstrument?.symbol ?? undefined;
        const mic = itemInstrument?.mic ?? undefined;
        const quote =
          id && symbol ? quotes[getQuoteId(id, symbol, mic)] : undefined;

        const { netGross, cashFlows } =
          getReportValuesByCurrencyAndCalculationBase(
            item,
            currency,
            calculationBaseType,
          ) ?? {};

        return {
          userId,
          instrument: instrument?.id ? { ...instrument, quote } : undefined,
          itemInstrument,
          listing: symbol ? findListing(instrument, symbol, mic) : undefined,
          item,
          historicCost: netGross?.historicCost ?? undefined,
          periodicCost: netGross?.periodicCost ?? undefined,
          profitLoss: netGross?.ledger?.profitLoss?.return ?? undefined,
          performanceReport: netGross?.performance ?? undefined,
          capital: netGross?.ledger?.capital ?? undefined,
          cashFlows,
          period: period ?? reportPeriod,
          instrumentLoading,
          quoteLoading,
          title: itemInstrument?.name ?? undefined,
          canAddTransactions: canAddTransactions && isTradeable(instrument),
          currency: currency ?? reportCurrency,
          calculationBaseType,
          geekView,
        };
      },
    );
  },
);
