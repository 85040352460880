import { createSelector } from '@ngrx/store';
import { CalculationBaseType } from '@yeekatee/client-api-angular';
import {
  CalculationBaseTypeI18n,
  InvestmentViewType,
  InvestmentViewTypeI18n,
  PortfolioOptions,
  portfolioReportsFeature,
  PortfoliosSelectors,
  PortfolioViewType,
  SplitOptionTypeI18n,
  userPropertiesFeature,
} from '../portfolios';

export type PortfolioOptionsVM = {
  options: PortfolioOptions;
  valuationCurrencies: string[];
  investmentViewsI18n: InvestmentViewTypeI18n;
  calculationBasesI18n: CalculationBaseTypeI18n;
  splitOptionsI18n: SplitOptionTypeI18n;
  portfolioViewType: PortfolioViewType;
  portfolioViewTypeI18n: Record<PortfolioViewType, string>;
  portfolioViewTypesDisabled: boolean;
  periodsDisabled: boolean;
  currenciesDisabled: boolean;
  investmentTypesDisabled: boolean;
  splitOptionsDisabled: boolean;
  calculationBaseTypesDisabled: boolean;
  supportedCalculationBaseTypes: CalculationBaseType[];
};

const defaultCalculationBaseTypes = [
  CalculationBaseType.Gross,
  CalculationBaseType.Net,
  CalculationBaseType.Capitalized,
];

export const selectPortfolioOptionsVM = createSelector(
  portfolioReportsFeature.selectReportOptions,
  userPropertiesFeature.selectUserValCurrencies,
  PortfoliosSelectors.selectPortfolioInvestmentViewsI18n,
  PortfoliosSelectors.selectPortfolioCalculationBasesI18n,
  PortfoliosSelectors.selectPortfolioSplitOptionsI18n,
  portfolioReportsFeature.selectReportPortfolioView,
  PortfoliosSelectors.selectPortfolioViewTypeI18n,
  portfolioReportsFeature.selectSinglePortfolio,
  (
    options,
    valuationCurrencies,
    investmentViewsI18n,
    calculationBasesI18n,
    splitOptionsI18n,
    portfolioViewType,
    portfolioViewTypeI18n,
    portfolio,
  ): PortfolioOptionsVM => {
    return {
      options,
      valuationCurrencies: valuationCurrencies,
      investmentViewsI18n,
      calculationBasesI18n,
      supportedCalculationBaseTypes:
        portfolio?.supportedCalculationBaseTypes ?? defaultCalculationBaseTypes,
      splitOptionsI18n,
      portfolioViewType,
      portfolioViewTypeI18n,
      portfolioViewTypesDisabled: false,
      periodsDisabled: portfolioViewType === PortfolioViewType.INTRADAY,
      currenciesDisabled: false,
      investmentTypesDisabled: portfolioViewType === PortfolioViewType.INTRADAY,
      splitOptionsDisabled:
        options.investmentViewType !== InvestmentViewType.All ||
        portfolioViewType === PortfolioViewType.INTRADAY,
      calculationBaseTypesDisabled: false,
    };
  },
);
