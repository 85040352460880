import { Action, createReducer, on } from '@ngrx/store';
import { SubmitScaErrors } from '@yeekatee/client-api-angular';
import { SupportedBank } from '@yeekatee/shared-util-banks';
import {
  AccountLinkingApiActions,
  AccountLinkingBanksPageActions,
  AccountLinkingCredentialsPageActions,
  AccountLinkingEffectsActions,
} from './account-linking.actions';
import {
  ScaState,
  StoreCreateCredentialsSCADetails,
} from './account-linking.model';

export interface AccountLinkingState {
  loading: boolean;
  error: boolean;
  openBankRequestModal: boolean;
  selectedBank?: SupportedBank;
  accountId?: string;
  pendingSca?: StoreCreateCredentialsSCADetails;
  scaState?: ScaState;
}

export const FEATURE_KEY = 'accountLinking';

export interface AccountLinkingPartialState {
  readonly [FEATURE_KEY]: AccountLinkingState;
}

export const initialState: AccountLinkingState = {
  loading: false,
  error: false,
  openBankRequestModal: false,
};

const reducer = createReducer(
  initialState,
  on(AccountLinkingBanksPageActions.leavingCallbackPage, (state) => ({
    ...state,
    loading: false,
    error: false,
    selectedBank: undefined,
  })),
  on(
    AccountLinkingEffectsActions.receivedFlanksCallbackSuccess,
    AccountLinkingCredentialsPageActions.submitEncryptedCredentials,
    AccountLinkingCredentialsPageActions.submitScaKey,
    AccountLinkingBanksPageActions.flagBank,
    (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
  ),
  on(AccountLinkingBanksPageActions.selectedBankToSync, (state, { bank }) => ({
    ...state,
    loading: true,
    error: false,
    selectedBank: bank,
  })),
  on(
    AccountLinkingApiActions.createCredentialsSuccess,
    (state, { response }) => ({
      ...state,
      accountId: response.accountId != null ? response.accountId : undefined,
      pendingSca: response.sca_details,
      loading: false,
      error: false,
    }),
  ),
  on(
    AccountLinkingApiActions.exchangedFlanksCodeSuccess,
    AccountLinkingApiActions.syncYeekateeBankSuccess,
    (state) => ({
      ...state,
      loading: false,
      error: false,
    }),
  ),
  on(
    AccountLinkingBanksPageActions.flagBank,
    AccountLinkingBanksPageActions.closeBankRequestModal,
    (state) => ({
      ...state,
      openBankRequestModal: false,
    }),
  ),
  on(AccountLinkingBanksPageActions.openBankRequestModal, (state) => ({
    ...state,
    openBankRequestModal: true,
  })),
  on(AccountLinkingApiActions.submitScaSuccess, (state) => ({
    ...state,
    loading: false,
    error: false,
    pendingSca: undefined,
    scaState: ScaState.success,
  })),
  on(
    AccountLinkingApiActions.exchangedFlanksCodeFailure,
    AccountLinkingApiActions.syncYeekateeBankFailure,
    AccountLinkingApiActions.createCredentialsFailure,
    AccountLinkingApiActions.submitScaFailure,
    (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
  on(AccountLinkingApiActions.submitScaFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: true,
    pendingSca:
      error.message == SubmitScaErrors.invalidScaCode
        ? state.pendingSca
        : undefined,
    scaState:
      error.message == SubmitScaErrors.invalidScaCode
        ? state.scaState
        : ScaState.abort,
  })),
  on(
    AccountLinkingCredentialsPageActions.routeToPortfolio,
    AccountLinkingCredentialsPageActions.scaAbort,
    (state) => ({
      ...state,
      scaState: undefined,
    }),
  ),
);

export function accountLinkingReducer(
  state: AccountLinkingState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
