import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthEffectsActions } from '../../auth';
import { PortfoliosListActions } from '../../portfolios/portfolios.actions';
import { GamesApiActions } from '../games.actions';
import { GamePlayerEntity } from '../games.models';

export const FEATURE_KEY = 'players';

export interface State extends EntityState<GamePlayerEntity> {
  loading: boolean;
}

export const adapter = createEntityAdapter<GamePlayerEntity>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});

const playersReducer = createReducer(
  initialState,
  on(
    AuthEffectsActions.appInit,
    GamesApiActions.joinGameSuccess,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    GamesApiActions.loadGamePlayerSuccess,
    (state, { player }): State =>
      adapter.upsertOne(player, {
        ...state,
        loading: false,
      }),
  ),
  on(
    GamesApiActions.loadGamePlayerFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(PortfoliosListActions.deletePortfolio, (state, { portfolio }) => {
    const { gameId, userId } = portfolio;
    if (!gameId) return state;

    const player = userId ? state.entities[userId] : undefined;
    if (!player) return state;

    const competitions = player.competitions?.filter(
      (c) => c.gameId !== gameId,
    );
    if (!competitions) return state;

    return userId
      ? adapter.updateOne(
          {
            id: userId,
            changes: {
              competitions,
            },
          },
          state,
        )
      : state;
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return playersReducer(state, action);
}
