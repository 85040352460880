import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IonButton,
  IonIcon,
  IonText,
  isPlatform,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';

@Component({
  selector: 'yeekatee-close-button',
  standalone: true,
  imports: [IonButton, IonIcon, IonText],
  templateUrl: './close-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseButtonComponent {
  @Input() isDisabled?: boolean;

  protected readonly isIos = isPlatform('ios');

  constructor() {
    addIcons({ close });
  }
}
