import { createSelector } from '@ngrx/store';
import { MarketNewsStoryEntity } from '../market-news.models';
import { MarketNewsState, selectMarketNewsState } from '../market-news.reducer';
import * as InstrumentsSelectors from './instruments.selectors';
import { FEATURE_KEY, mStoriesAdapter } from './stories.reducer';

export const mStoriesSliceSelector = createSelector(
  selectMarketNewsState,
  (state: MarketNewsState) => state[FEATURE_KEY],
);

const { selectEntities } = mStoriesAdapter.getSelectors();

export const selectMarketNewsStoriesEntities = createSelector(
  mStoriesSliceSelector,
  selectEntities,
);

export const selectMarketNewsStories = createSelector(
  selectMarketNewsStoriesEntities,
  InstrumentsSelectors.selectStoriesIds,
  (entities, ids) =>
    ids
      ?.map((id) => entities[id])
      .filter((s): s is MarketNewsStoryEntity => !!s) ?? [],
);

export const selectMarketNewsTimelineLoading = createSelector(
  mStoriesSliceSelector,
  ({ timeline }) => timeline.loading,
);

export const selectMarketNewsTimeline = createSelector(
  mStoriesSliceSelector,
  ({ timeline }) => timeline.stories,
);

export const selectMarketNewsTimelineIdentifiers = createSelector(
  mStoriesSliceSelector,
  ({ timeline }) => timeline.identifiers,
);

export const selectIsEndOfTimeline = createSelector(
  mStoriesSliceSelector,
  ({ timeline }) => !timeline.identifiers.some((i) => i.nextToken !== 'EOD'),
);
