import { z } from 'zod';

const simpleOps = z.union([
  z.literal('eq'),
  z.literal('neq'),
  z.literal('gt'),
  z.literal('gte'),
  z.literal('lt'),
  z.literal('lte'),
  z.literal('contains'),
]);

const notOps = z.literal('not');

const complexOps = z.union([z.literal('and'), z.literal('or')]);

/**
 * The list of platforms supported by Capacitor that can be used in rules.
 *
 * @see https://ionicframework.com/docs/angular/platform#platforms
 */
const capacitorPlatforms = z.union([
  //
  z.literal('android'),
  z.literal('capacitor'),
  z.literal('cordova'),
  z.literal('desktop'),
  z.literal('electron'),
  z.literal('hybrid'),
  z.literal('ios'),
  z.literal('ipad'),
  z.literal('iphone'),
  z.literal('mobile'),
  z.literal('mobileweb'),
  z.literal('phablet'),
  z.literal('pwa'),
  z.literal('tablet'),
]);

const simpleExpression = z.discriminatedUnion('field', [
  z.object({
    op: simpleOps,
    field: z.literal('version'),
    val: z.string(),
  }),

  z.object({
    op: z.literal('contains'),
    field: z.literal('platforms'),
    val: capacitorPlatforms,
  }),

  z.object({
    op: simpleOps,
    field: z.literal('datetime'),
    val: z.string().datetime(),
  }),

  z.object({
    op: z.literal('eq').or(z.literal('neq')),
    field: z.literal('authenticated'),
    val: z.boolean(),
  }),

  z.object({
    op: z.literal('eq').or(z.literal('neq')),
    field: z.literal('updateAvailable'),
    val: z.boolean(),
  }),
]);

export type SimpleExpression = z.infer<typeof simpleExpression>;

export type NotExpression = {
  op: 'not';
  val: ComplexExpression;
};
export type ComplexExpression =
  | SimpleExpression
  | NotExpression
  | {
      op: 'and' | 'or';
      vals: ComplexExpression[];
    };

const notExpression: z.ZodType<NotExpression> = z.lazy(() =>
  z
    .object({
      op: notOps,
      val: complexExpression,
    })
    .strict(),
);

// TODO: should probably be a discriminatedUnion, but that didn't work...
//       and anyway it looks like it's going to be deprecated
//       https://github.com/colinhacks/zod/issues/2106
const complexExpression: z.ZodType<ComplexExpression> = z.lazy(() =>
  z.union([
    simpleExpression,
    notExpression,
    z
      .object({
        op: complexOps,
        vals: z.array(complexExpression).min(2),
      })
      .strict(),
  ]),
);

export const ruleSchema = complexExpression;
export type Rule = z.infer<typeof ruleSchema>;

/**
 * Defines the parameters that can be used in rules to target the modal.
 * The values are provided by the app.
 *
 * @todo is there a way to infer this from the schema?
 */
export type RuleInput = {
  version: string;
  platforms: string[];
  datetime: string;
  authenticated: boolean;
  updateAvailable: boolean;
};

/**
 * Evaluates a rule against the provided input.
 * Given a current app configuration, and the rules defined in the modal,
 * this function determines whether the modal should be shown or not.
 *
 * @param rule the rule from the modal
 * @param state the input from the app (version, platform, etc.)
 */
export function evalRule(rule: Rule, state: RuleInput): boolean {
  if (rule.op === 'and') {
    return rule.vals.every((val) => evalRule(val, state));
  }

  if (rule.op === 'or') {
    return rule.vals.some((val) => evalRule(val, state));
  }

  if (rule.op === 'contains') {
    return state[rule.field].includes(rule.val);
  }

  if (rule.op === 'eq') {
    return state[rule.field] === rule.val;
  }

  if (rule.op === 'neq') {
    return state[rule.field] !== rule.val;
  }

  if (rule.op === 'gt') {
    return state[rule.field] > rule.val;
  }

  if (rule.op === 'gte') {
    return state[rule.field] >= rule.val;
  }

  if (rule.op === 'lt') {
    return state[rule.field] < rule.val;
  }

  if (rule.op === 'lte') {
    return state[rule.field] <= rule.val;
  }

  if (rule.op === 'not') {
    return !evalRule(rule.val, state);
  }

  throw new Error(`Unknown rule operator: ${rule.op}`);
}
