import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IonChip, IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { addOutline, closeOutline } from 'ionicons/icons';

export enum SelectionMode {
  Single,
  Multiple,
}

@Component({
  selector: 'yeekatee-select-button',
  standalone: true,
  imports: [IonChip, IonIcon],
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent {
  @Input() value?: string | null;
  @Input() disabled = false;
  @Input() isSelected = false;
  @Input() mode = SelectionMode.Single;

  @Output() clicked = new EventEmitter<void>();

  protected readonly SelectionMode = SelectionMode;

  constructor() {
    addIcons({ addOutline, closeOutline });
  }
}
