import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RankingType } from '@yeekatee/booking-util-definitions';
import {
  GameAssetTypeSchema,
  GameAssetsSchema,
} from '@yeekatee/instruments-util-discover';
import { InstrumentsApiActions } from '../../instruments/instruments.actions';
import {
  GameOverviewActions,
  GameRankingActions,
  GamesApiActions,
  GamesAssetsActions,
  GamesListActions,
} from '../games.actions';
import {
  GameCompetitionEntity,
  reduceGameCompetition,
  reduceGameCompetitionRankings,
} from '../games.models';

export const FEATURE_KEY = 'competitions';

export interface State extends EntityState<GameCompetitionEntity> {
  loading: boolean;

  assets?: GameAssetsSchema;
  searchQuery?: string;
  searchFilter?: GameAssetTypeSchema;
  rankingType?: RankingType;
}

export const adapter = createEntityAdapter<GameCompetitionEntity>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});

const competitionsReducer = createReducer(
  initialState,
  on(
    GamesListActions.init,
    GameOverviewActions.load,
    GameRankingActions.init,
    GameRankingActions.refresh,
    GameOverviewActions.joinGame,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    GamesApiActions.loadGameCompetitionsSuccess,
    (state, { competitions }): State =>
      adapter.setMany(competitions, {
        ...state,
        loading: false,
      }),
  ),
  on(
    GamesApiActions.loadGameCompetitionSuccess,
    (state, { competition }): State =>
      adapter.upsertOne(reduceGameCompetition(competition), {
        ...state,
        loading: false,
      }),
  ),
  on(
    GamesApiActions.loadGameRankingsSuccess,
    (state, { competition }): State => {
      const [c, rankingType] = reduceGameCompetitionRankings(
        competition,
        state.rankingType,
      );

      return adapter.upsertOne(c, {
        ...state,
        loading: false,
        rankingType,
      });
    },
  ),
  on(
    GameRankingActions.setRankingType,
    (state, { rankingType }): State => ({
      ...state,
      rankingType,
    }),
  ),
  on(
    GamesApiActions.joinGameSuccess,
    GamesApiActions.joinGameFailure,
    GamesApiActions.loadGameCompetitionsFailure,
    GamesApiActions.loadGameCompetitionFailure,
    GamesApiActions.loadGameRankingsFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    InstrumentsApiActions.loadAssetDataSuccess,
    (state, { instruments }): State => ({
      ...state,
      assets: instruments.gameAssets,
    }),
  ),
  on(
    GamesAssetsActions.search,
    (state, { query }): State => ({
      ...state,
      searchQuery: query,
    }),
  ),
  on(
    GamesAssetsActions.filter,
    (state, { filter }): State => ({
      ...state,
      searchFilter: state.searchFilter === filter ? undefined : filter,
    }),
  ),
  on(
    GamesAssetsActions.clearSearch,
    GamesAssetsActions.destroy,
    (state): State => ({
      ...state,
      searchQuery: undefined,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return competitionsReducer(state, action);
}
