import { Action, createReducer, on } from '@ngrx/store';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import { ActivitiesTimelineActions } from '../activity-streams';
import {
  BottomNavigationActions,
  SideNavigationActions,
} from './navbar.actions';
import { NavbarTabType, TAB_ORDER } from './navbar.models';

export const FEATURE_KEY = 'bottomTabs';

export interface NavbarState {
  tab: NavbarTabType;
  triggerSameTabClicked: boolean;
}

export interface NavbarPartialState {
  readonly [FEATURE_KEY]: NavbarState;
}

export const initialState: NavbarState = {
  tab: NavigationRouteNames.HOME,
  triggerSameTabClicked: false,
};

const reducer = createReducer(
  initialState,
  on(
    BottomNavigationActions.init,
    (state, { routeUrl }): NavbarState => ({
      ...state,
      tab:
        TAB_ORDER.find((tab) => routeUrl.startsWith(`/${tab}`)) ??
        NavigationRouteNames.HOME,
    }),
  ),
  on(
    BottomNavigationActions.changeTab,
    SideNavigationActions.changeTab,
    (state, { tab }): NavbarState => ({
      ...state,
      tab,
      triggerSameTabClicked: state.tab === tab,
    }),
  ),
  on(
    SideNavigationActions.clickLogo,
    (state, { tab }): NavbarState => ({
      ...state,
      tab,
    }),
  ),
  on(
    ActivitiesTimelineActions.scrollTopTriggered,
    (state): NavbarState => ({
      ...state,
      triggerSameTabClicked: false,
    }),
  ),
);

export function navbarReducer(state: NavbarState | undefined, action: Action) {
  return reducer(state, action);
}
