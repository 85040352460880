import { createFeature, createSelector } from '@ngrx/store';
import { DateConst, computeHash } from '@yeekatee/booking-util-definitions';
import {
  BookingPeriod,
  CalculationBaseType,
  InvestmentType,
  PortfolioAggregation,
  PortfolioItem,
} from '@yeekatee/client-api-angular';
import { DateTime } from 'luxon';
import { NavigationSelectors } from '../../navigation';
import { ThemeSelectors } from '../../theme';
import { UsersSelectors } from '../../users';
import { memoize } from '../../utils';
import {
  PortfolioChartsData,
  PortfolioReportEntity,
  PortfolioViewType,
  PortfoliosBalances,
  getPortfolioReportChartData,
  getReportAssetExposureChartData,
  getReportPerformanceChartData,
  getReportPortfoliosKeys,
  getReportTopPositionsChartData,
  getReportVolatilityChartData,
  getReportVolatilityVsPerformanceChartData,
  getStoreReportId,
  sortPortfolioReportItems,
} from '../portfolios.models';
import { adapter, name, reducer } from './portfolio-reports.reducer';
import { userPortfoliosFeature } from './user-portfolios.selectors';
import { userPropertiesFeature } from './user-properties.selectors';

export const portfolioReportsFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({
    selectPortfolioReportsState,
    selectPeriod,
    selectCurrency,
    selectPortfolioViewType,
    selectCalculationBaseType,
    selectSplitItems,
    selectInvestmentViewType,
    selectNTopItems,
    selectStartDate,
    selectEndDate,
    selectGeekView,
    selectPortfolioKeys,
  }) => {
    const entitySelectors = adapter.getSelectors(selectPortfolioReportsState);

    const selectAllPortfolioReports = entitySelectors.selectAll;

    const selectPortfolioReportsEntities = entitySelectors.selectEntities;

    const selectReportStartDate = createSelector(
      NavigationSelectors.selectPortfolioStartDateFromRoute,
      selectStartDate,
      (startDate, repStartDate) => startDate ?? repStartDate,
    );

    const selectReportEndDate = createSelector(
      NavigationSelectors.selectPortfolioEndDateFromRoute,
      selectEndDate,
      (endDate, repEndDate) => endDate ?? repEndDate,
    );

    const selectReportPeriod = createSelector(
      NavigationSelectors.selectPortfolioPeriodFromRoute,
      selectPeriod,
      (period, repPeriod) => (period as BookingPeriod | undefined) ?? repPeriod,
    );

    const selectReportCurrency = createSelector(
      NavigationSelectors.selectPortfolioCurrencyFromRoute,
      selectCurrency,
      userPropertiesFeature.selectUserRepCurrency,
      (selectedCurrency, repCurrency, userRepCurrency) =>
        selectedCurrency ?? repCurrency ?? userRepCurrency ?? undefined,
    );

    const selectReportPortfolioView = createSelector(
      NavigationSelectors.selectPortfolioViewFromRoute,
      selectPortfolioViewType,
      (selectedPortfolioView, repPortfolioView) =>
        (selectedPortfolioView as PortfolioViewType | undefined) ??
        repPortfolioView,
    );

    const selectReportCalculationBase = createSelector(
      NavigationSelectors.selectPortfolioCalculationFromRoute,
      selectCalculationBaseType,
      (selectedCalculationBase, repCalculationBase) =>
        (selectedCalculationBase as CalculationBaseType | undefined) ??
        repCalculationBase,
    );

    const selectReportOptions = createSelector({
      currency: selectReportCurrency,
      period: selectReportPeriod,
      splitItems: selectSplitItems,
      investmentViewType: selectInvestmentViewType,
      portfolioViewType: selectReportPortfolioView,
      calculationBaseType: selectReportCalculationBase,
    });

    const selectReportPortfolioKeys = createSelector(
      userPortfoliosFeature.selectDefaultPortfolioKey,
      selectPortfolioKeys,
      userPortfoliosFeature.selectPortfolioKeysInRoute,
      userPortfoliosFeature.selectUserPortfoliosKeys,
      UsersSelectors.isAuthUserSelected,
      (
        defaultKey,
        portfoliosKeysPreferences,
        selectedKeys,
        portfolioKeys,
        isMyUser,
      ) => {
        const keys = selectedKeys.filter((k) => portfolioKeys.includes(k));
        return isMyUser
          ? getReportPortfoliosKeys(keys, portfoliosKeysPreferences, defaultKey)
          : keys;
      },
    );

    const selectPortfolioKey = createSelector(
      selectReportPortfolioKeys,
      (keys) => (!keys?.length || keys.length > 1 ? undefined : keys.at(0)),
    );

    const selectSinglePortfolio = createSelector(
      selectPortfolioKey,
      userPortfoliosFeature.selectUserPortfolios,
      (key, portfolios) =>
        key ? portfolios?.find((p) => p.key === key) : undefined,
    );

    const selectPortfolioName = createSelector(
      selectSinglePortfolio,
      (portfolio) => portfolio?.name ?? undefined,
    );

    const canAddTransactions = createSelector(
      selectSinglePortfolio,
      (portfolio) => !!portfolio?.canAddTransactions,
    );

    const canCopy = createSelector(
      selectSinglePortfolio,
      (portfolio) => !!portfolio?.canCopy,
    );

    const selectReportId = createSelector(
      userPortfoliosFeature.selectUserFromRouteOrExampleId,
      selectReportPortfolioKeys,
      (userId, keys) => computeHash(keys, userId),
    );

    const selectReportById = memoize((reportId?: string) =>
      createSelector(
        selectPortfolioReportsEntities,
        selectReport,
        (entities, report) => entities[reportId ?? ''] ?? report,
      ),
    );

    const selectReport = createSelector(
      selectPortfolioReportsEntities,
      selectReportId,
      selectReportPeriod,
      selectReportCurrency,
      selectReportStartDate,
      selectReportEndDate,
      (entities, reportId, period, currency, startDate, endDate) =>
        entities[
          getStoreReportId(reportId, period, currency, startDate, endDate)
        ],
    );

    const selectReportKey = createSelector(
      selectReport,
      (report) => report?.key ?? undefined,
    );

    const selectReportKeyById = memoize((reportId: string) =>
      createSelector(
        selectReportById(reportId),
        (report) => report?.key ?? undefined,
      ),
    );

    const selectReportItems = createSelector(
      selectReport,
      (report) => report?.items ?? [],
    );

    const selectReportItemsById = memoize((reportId: string) =>
      createSelector(
        selectReportById(reportId),
        (report) => report?.items ?? [],
      ),
    );

    const selectReportStateById = memoize((reportId: string) =>
      createSelector(
        selectReportById(reportId),
        (report) => report?.state ?? undefined,
      ),
    );

    const selectReportState = createSelector(
      selectReport,
      (report) => report?.state ?? undefined,
    );

    const selectReportBalances = createSelector(
      selectAllPortfolioReports,
      (reports): PortfoliosBalances =>
        reports.map((r) => ({
          keys: r.portfolioKeys ?? [],
          balances:
            r?.items?.map((i) => ({
              isCash: i.investmentType === InvestmentType.CashAccount,
              balance: i.balance ?? 0,
              currency: i.currency ?? undefined,
              instrumentId: i.instrument?.id ?? undefined,
              instrumentSymbol: i.instrument?.symbol ?? undefined,
              instrumentMic: i.instrument?.mic ?? undefined,
            })) ?? [],
        })),
    );

    const reportPeriod = (report: PortfolioReportEntity | undefined) => ({
      startDate: report?.reportPeriod?.startDate ?? undefined,
      endDate: report?.reportPeriod?.endDate ?? undefined,
    });

    const selectReportDates = createSelector(selectReport, (report) =>
      reportPeriod(report),
    );

    const selectReportDatesById = memoize((reportId: string) =>
      createSelector(selectReportById(reportId), (report) =>
        reportPeriod(report),
      ),
    );

    const selectUserPortfoliosByReportId = memoize((reportId: string) =>
      createSelector(
        selectReportById(reportId),
        userPortfoliosFeature.selectUserPortfolios,
        (report, portfolios) =>
          portfolios.filter((p) =>
            report?.portfolioKeys?.includes(p.key ?? ''),
          ),
      ),
    );

    const selectMinBookDate = memoize((reportId: string) =>
      createSelector(
        selectUserPortfoliosByReportId(reportId),
        (portfolios): string =>
          portfolios
            .map((p) => p.minBookDate)
            .filter((d): d is string => !!d)
            .sort()
            .at(0) ?? DateConst.BookMin,
      ),
    );

    const selectMaxBookDate = memoize((reportId: string) =>
      createSelector(
        selectUserPortfoliosByReportId(reportId),
        (portfolios): string =>
          portfolios
            .map((p) => p.maxBookDate)
            .filter((d): d is string => !!d)
            .sort()
            .at(-1) ?? DateTime.now().toISODate(),
      ),
    );

    const selectReportChartDataById = memoize(
      (
        reportId = '',
        currency?: string,
        calculationBaseType?: CalculationBaseType,
      ) =>
        createSelector(
          selectReportById(reportId),
          selectReportCurrency,
          selectReportCalculationBase,
          selectNTopItems,
          ThemeSelectors.selectColors,
          ThemeSelectors.selectColorScale,
          selectMinBookDate(reportId),
          selectMaxBookDate(reportId),
          (
            report,
            reportCurrency,
            reportCalculationBaseType,
            nTopItems,
            colors,
            scale,
            minDate,
            maxDate,
          ): PortfolioChartsData => ({
            aggregation: report?.aggregation as PortfolioAggregation,
            evaluation: getPortfolioReportChartData(report, currency, colors),
            assetExposure: getReportAssetExposureChartData(report, scale),
            performance: getReportPerformanceChartData(
              report,
              currency ?? reportCurrency,
              colors,
              calculationBaseType ?? reportCalculationBaseType,
            ),
            volatility: getReportVolatilityChartData(
              report,
              currency ?? reportCurrency,
              colors,
              calculationBaseType ?? reportCalculationBaseType,
            ),
            volatilityVsPerformance: getReportVolatilityVsPerformanceChartData(
              report,
              currency ?? reportCurrency,
              scale,
              calculationBaseType ?? reportCalculationBaseType,
              nTopItems,
            ),
            topPositions: getReportTopPositionsChartData(
              report,
              currency ?? reportCurrency,
              scale,
              calculationBaseType ?? reportCalculationBaseType,
              nTopItems,
            ),
            ...reportPeriod(report),
            minDate,
            maxDate,
          }),
        ),
    );

    const selectReportChartData = selectReportChartDataById();

    const selectReportItem = createSelector(
      NavigationSelectors.selectPortfolioItemIdFromRoute,
      selectReportItems,
      (key, items) => items?.find((i) => i?.key === key),
    );

    const selectReportItemById = memoize((itemId: string, reportId: string) =>
      createSelector(selectReportItemsById(reportId), (items) =>
        items?.find((i) => i?.key === itemId),
      ),
    );

    const selectAllReportItems = createSelector(selectReport, (report) =>
      sortPortfolioReportItems(report),
    );

    const selectActiveReportItems = createSelector(
      selectAllReportItems,
      (report) => report?.filter((p) => p.allocation ?? 0 > 0),
    );

    const selectReportPreferences = createSelector({
      currency: selectCurrency,
      period: selectPeriod,
      splitItems: selectSplitItems,
      investmentViewType: selectInvestmentViewType,
      portfolioViewType: selectPortfolioViewType,
      calculationBaseType: selectCalculationBaseType,
      endDate: selectEndDate,
      startDate: selectStartDate,
      geekView: selectGeekView,
      portfolioKeys: selectPortfolioKeys,
    });

    const selectPortfolioItemSelected = createSelector(
      NavigationSelectors.selectPortfolioEntityIdFromRoute,
      selectReportItems,
      (key, items): PortfolioItem | undefined =>
        items?.find((i) => i?.key === key),
    );

    const selectPortfolioItemSelectedById = memoize(
      (reportId: string, entityId: string) =>
        createSelector(
          selectReportItemsById(reportId),
          (items): PortfolioItem | undefined =>
            items?.find((i) => i?.key === entityId),
        ),
    );

    return {
      ...entitySelectors,
      selectAllPortfolioReports,
      selectPortfolioReportsEntities,
      selectReportPeriod,
      selectReportCurrency,
      selectReportPortfolioView,
      selectReportCalculationBase,
      selectReportOptions,
      selectReportId,
      selectReport,
      selectReportById,
      selectReportKey,
      selectReportKeyById,
      selectReportItems,
      selectReportItemsById,
      selectReportItem,
      selectReportItemById,
      selectReportState,
      selectReportStateById,
      selectReportBalances,
      selectReportDates,
      selectReportDatesById,
      selectReportChartData,
      selectReportChartDataById,
      selectAllReportItems,
      selectActiveReportItems,
      selectReportPortfolioKeys,
      selectPortfolioKey,
      selectSinglePortfolio,
      selectPortfolioName,
      canAddTransactions,
      canCopy,
      selectReportPreferences,
      selectPortfolioItemSelected,
      selectPortfolioItemSelectedById,
    };
  },
});
