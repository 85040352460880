import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: number | string, ...args: unknown[]): unknown {
    const time =
      typeof value === 'string'
        ? DateTime.fromISO(value)
        : DateTime.fromMillis(value);
    return Math.abs(DateTime.now().diff(time, 'seconds').seconds) < 60
      ? $localize`just now`
      : time.toRelative({ style: 'short' });
  }
}
