import { createSelector } from '@ngrx/store';
import { DividendDateFilter } from '@yeekatee/client-api-angular';
import { DividendFilterOption, DividendsSelectors } from '../instruments';
import { selectDividendDateType } from '../instruments/features/dividends.selectors';
import { LocalesSelectors } from '../locales';

const selectDividendCalendarDateTypeI18n = createSelector(
  (): Record<DividendDateFilter, string> => ({
    [DividendDateFilter.ExDate]: $localize`Ex-date`,
    [DividendDateFilter.PayDate]: $localize`Pay date`,
  }),
);

const selectDividendFilterI18n = createSelector(
  (): Record<DividendFilterOption, string> => ({
    [DividendFilterOption.ALL]: $localize`All securities`,
    [DividendFilterOption.ETF]: $localize`ETFs`,
    [DividendFilterOption.STOCK]: $localize`Stocks`,
    [DividendFilterOption.PORTFOLIO]: $localize`Securities in your portfolio`,
    [DividendFilterOption.FAVOURITES]: $localize`Securities in your favourites`,
  }),
);

export const selectDividendCalendarAssetsVM = createSelector(
  DividendsSelectors.selectDividendHeatmap,
  DividendsSelectors.selectDividendsList,
  DividendsSelectors.selectDividendsCountPerDayForDisplayedMonth,
  DividendsSelectors.selectLoading,
  DividendsSelectors.selectDividendDateType,
  DividendsSelectors.selectDividendFilterOptions,
  DividendsSelectors.selectDisplayedMonth,
  DividendsSelectors.selectSelectedDateFilter,
  selectDividendCalendarDateTypeI18n,
  selectDividendFilterI18n,
  LocalesSelectors.getSelectedId,
  (
    calendarHeatmap,
    dividendsList,
    dividendCount,
    loading,
    dateType,
    options,
    displayedMonth,
    selectedDate,
    dateTypeOptionI18n,
    dividendFilterOptionI18n,
    locale,
  ) => ({
    calendarHeatmap,
    dividendsList,
    dividendCount,
    loading,
    dateType,
    options,
    displayedMonth,
    selectedDate,
    selectedDivCalDateType: dateTypeOptionI18n[dateType],
    selectedDivCalSecuritiesFilter: dividendFilterOptionI18n[options],
    locale,
  }),
);

export const selectDividendFilterOptionsVM = createSelector({
  filterOptions: DividendsSelectors.selectDividendFilterOptions,
  dateType: selectDividendDateType,
  dateTypeOptionI18n: selectDividendCalendarDateTypeI18n,
  securitiesFilterOptionI18n: selectDividendFilterI18n,
});
