import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { InstrumentsApiActions } from '../instruments.actions';
import { TimeSeriesEntity } from '../instruments.models';

export const FEATURE_KEY = 'timeSeries';

export interface State extends EntityState<TimeSeriesEntity> {}

export const timeSeriesAdapter = createEntityAdapter<TimeSeriesEntity>();

export const initialState: State = timeSeriesAdapter.getInitialState({});

const timeSeriesReducer = createReducer(
  initialState,
  on(
    InstrumentsApiActions.loadInstrumentSuccess,
    InstrumentsApiActions.loadSingleInstrumentSuccess,
    (state: State, { instrument }) =>
      instrument?.timeSeries
        ? timeSeriesAdapter.upsertMany(
            Object.values(instrument.timeSeries)
              .filter((t) => (t?.items?.length ?? -1) > 0)
              .map((t) => t),
            state,
          )
        : state,
  ),
  on(InstrumentsApiActions.loadTimeSeriesSuccess, (state, { timeSeries }) =>
    timeSeries ? timeSeriesAdapter.upsertOne(timeSeries, state) : state,
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return timeSeriesReducer(state, action);
}
