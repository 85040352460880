import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { InstrumentsApiActions } from '../instruments.actions';

export const FEATURE_KEY = 'discover-page';

export interface DiscoverCategory {
  id: string; // category type
  instrumentIds: string[];
}
export interface State extends EntityState<DiscoverCategory> {}

export const instrumentDiscoverCategoryAdapter: EntityAdapter<DiscoverCategory> =
  createEntityAdapter<DiscoverCategory>();

export const initialState: State =
  instrumentDiscoverCategoryAdapter.getInitialState({});

const createDiscoverCategory = (
  id: string,
  instrumentIds: string[],
): DiscoverCategory => ({
  id,
  instrumentIds,
});

export const reducer = createReducer(
  initialState,
  on(
    InstrumentsApiActions.loadAssetDataSuccess,
    (state, { instruments }): State => {
      const assets: DiscoverCategory[] = Object.keys(
        instruments.discoverPages,
      ).map((key) => {
        // Rewire to match up with routes (to be backward compatible)
        const newKey = key.startsWith('stocks')
          ? `stocks-${key.slice(6)}`
          : key;
        return createDiscoverCategory(
          newKey,
          (instruments.discoverPages as any)[key],
        );
      });

      return instrumentDiscoverCategoryAdapter.upsertMany(assets, state);
    },
  ),
);
