import { createSelector } from '@ngrx/store';
import { AuthSelectors } from '../../auth';
import { InstrumentsSelectors } from '../../instruments';
import { NavigationSelectors } from '../../navigation';
import { UsersSelectors } from '../../users';
import { memoize } from '../../utils';
import {
  CollectionPrefix,
  CollectionsEntity,
  TimelineFilter,
  getCollectionByPrefix,
  getCollectionId,
} from '../activity-streams.models';
import {
  ActivitiesState,
  selectActivitiesState,
} from '../activity-streams.reducer';
import * as fromCollections from './collections.reducer';

const { selectAll, selectEntities } = fromCollections.adapter.getSelectors();

export const getCollectionsState = createSelector(
  selectActivitiesState,
  (state: ActivitiesState) => state.collections,
);

export const getAllCollections = createSelector(
  getCollectionsState,
  (state: fromCollections.State) => selectAll(state),
);

export const getCollectionsEntities = createSelector(
  getCollectionsState,
  (state: fromCollections.State) => selectEntities(state),
);

/**
 * Get the inbox collection for the currently authenticated user
 */
export const selectInboxForAuthenticatedUser = memoize(
  (filterValue: TimelineFilter) =>
    createSelector(
      getCollectionsEntities,
      AuthSelectors.selectUserId,
      (entities, userId): CollectionsEntity =>
        getCollectionByPrefix(
          entities,
          filterValue === TimelineFilter.FOLLOWING
            ? CollectionPrefix.ONLY_FOLLOWING_INBOX
            : CollectionPrefix.INBOX,
          userId,
        ),
    ),
);

/**
 * Get the outbox collection for the currently selected user
 */
export const selectOutboxForSelectedUser = createSelector(
  getCollectionsEntities,
  UsersSelectors.selectUserId,
  (entities, userId): CollectionsEntity =>
    getCollectionByPrefix(entities, CollectionPrefix.OUTBOX, userId),
);

/**
 * Get the shared inbox collection for the currently selected asset
 */
export const selectSharedInboxForSelectedAsset = createSelector(
  getCollectionsEntities,
  InstrumentsSelectors.selectInstrumentId,
  (entities, instrumentId): CollectionsEntity =>
    getCollectionByPrefix(
      entities,
      CollectionPrefix.SHARED_INBOX,
      instrumentId,
    ),
);

/**
 * Get the liked collection for
 * - meaning, all like activities by -
 * the currently authenticated user.
 */
export const selectLikedForAuthenticatedUser = createSelector(
  getCollectionsEntities,
  AuthSelectors.selectUserId,
  (entities, userId): CollectionsEntity =>
    getCollectionByPrefix(entities, CollectionPrefix.LIKED, userId),
);

/**
 * Get the following collection for the currently authenticated user
 */
export const selectFollowingForAuthenticatedUser = createSelector(
  getCollectionsEntities,
  AuthSelectors.selectUserId,
  (entities, userId): CollectionsEntity =>
    getCollectionByPrefix(entities, CollectionPrefix.FOLLOWING, userId),
);

/**
 * Get the following collection for the currently authenticated user
 */
export const selectFollowingForSelectedUser = createSelector(
  getCollectionsEntities,
  UsersSelectors.selectUserId,
  (entities, userId): CollectionsEntity =>
    getCollectionByPrefix(entities, CollectionPrefix.FOLLOWING, userId),
);

/**
 * Get the followers collection for the currently authenticated user
 */
export const selectFollowersForAuthenticatedUser = createSelector(
  getCollectionsEntities,
  AuthSelectors.selectUserId,
  (entities, userId): CollectionsEntity =>
    getCollectionByPrefix(entities, CollectionPrefix.FOLLOWERS, userId),
);

/**
 * Get the followers collection for the currently authenticated user
 */
export const selectFollowersForSelectedUser = createSelector(
  getCollectionsEntities,
  UsersSelectors.selectUserId,
  (entities, userId): CollectionsEntity =>
    getCollectionByPrefix(entities, CollectionPrefix.FOLLOWERS, userId),
);

/**
 * Get the list of all chats
 */
export const selectChatCollections = createSelector(
  getAllCollections,
  (entities) => entities.filter((e) => e.id.match(CollectionPrefix.CHAT)),
);

/**
 * Get all activities ids for the selected chat
 */
export const selectChatItemIds = createSelector(
  NavigationSelectors.selectChatIdFromRoute,
  selectChatCollections,
  (chatId, entities) => {
    const id = getCollectionId(CollectionPrefix.CHAT, chatId);
    return entities.find((e) => e.id === id)?.items ?? [];
  },
);
