import { createSelector } from '@ngrx/store';
import { computeHash, virtualCcy } from '@yeekatee/booking-util-definitions';
import {
  BookingPeriod,
  CalculationBaseType,
  PortfolioItem,
  PortfolioReportState,
  PortfolioReportValues,
  UserPortfolioVisibility,
} from '@yeekatee/client-api-angular';
import { AuthSelectors } from '../auth';
import { GamesSelectors } from '../games';
import {
  CommonReportVM,
  InvestmentViewType,
  Portfolio,
  PortfolioChartsData,
  PortfolioInsightsVM,
  PortfolioPerfRisksVM,
  PortfolioReportEntity,
  PortfoliosComparisonVM,
  PortfoliosReportVM,
  PortfoliosSelectors,
  UserPortfolioVM,
  getPortfolioName,
  getReportValuesByCurrency,
  getReportValuesByCurrencyAndCalculationBase,
  getStoreReportId,
  portfolioReportsFeature,
  sortPortfolioReportItems,
} from '../portfolios';
import {
  userPortfoliosFeature,
  userPropertiesFeature,
} from '../portfolios/portfolios.selectors';
import { ThemeColors, ThemeSelectors } from '../theme';
import { UsersSelectors } from '../users';
import { memoize } from '../utils';

const selectCommonReportVM = createSelector(
  portfolioReportsFeature.selectLoading,
  userPortfoliosFeature.selectLoading,
  portfolioReportsFeature.selectError,
  ThemeSelectors.selectColors,

  // TODO: they still depend on the route
  portfolioReportsFeature.canAddTransactions,
  portfolioReportsFeature.canCopy,
  portfolioReportsFeature.selectReportPortfolioKeys,
  userPortfoliosFeature.selectUserPortfolios,
  UsersSelectors.isAuthUserSelected,
  (
    reportLoading,
    portfoliosLoading,
    error,
    colors,
    canAddTransactions,
    canCopy,
    portfolioKeys,
    portfolios,
    isAuthUserSelected,
  ): CommonReportVM => {
    const ps = portfolios.map(
      (p): Portfolio => ({
        ...p,
        isSelected: !!p.key && !!portfolioKeys?.includes(p.key),
      }),
    );

    const selectedPortfolios = ps.filter((p) => p.isSelected);
    const portfolioName =
      selectedPortfolios.length === 1
        ? selectedPortfolios.at(0)?.name ?? undefined
        : undefined;

    return {
      reportLoading,
      portfoliosLoading,
      error,
      canAddTransactions,
      canCopy: !isAuthUserSelected && canCopy,
      portfolios: ps,
      portfolioName: getPortfolioName(portfolioName, !ps.length),
      isAuthUserSelected,
      noPortfolios: !portfolios.length,
      visibility:
        !isAuthUserSelected &&
        selectedPortfolios.some(
          (p) => p.visibility === UserPortfolioVisibility.ALLOCATION,
        )
          ? UserPortfolioVisibility.ALLOCATION
          : UserPortfolioVisibility.PUBLIC,
      colors,
    };
  },
);

export const selectPortfolioVM = createSelector(
  selectCommonReportVM,
  portfolioReportsFeature.selectReportState,
  portfolioReportsFeature.selectPortfolioKey,
  portfolioReportsFeature.selectReportPeriod,
  portfolioReportsFeature.selectReportCurrency,
  portfolioReportsFeature.selectReportChartData,
  portfolioReportsFeature.selectAllReportItems,
  portfolioReportsFeature.selectActiveReportItems,
  userPropertiesFeature.arePortfoliosSynching,
  userPortfoliosFeature.hasUserPortfolios,
  portfolioReportsFeature.selectInvestmentViewType,
  portfolioReportsFeature.selectReportCalculationBase,
  portfolioReportsFeature.selectReportPortfolioView,
  PortfoliosSelectors.selectPortfolioViewTypeI18n,
  GamesSelectors.selectGameToJoin,
  (
    {
      reportLoading,
      portfoliosLoading,
      error,
      canAddTransactions,
      canCopy,
      portfolios,
      portfolioName,
      isAuthUserSelected,
      noPortfolios,
      visibility,
      colors,
    },
    portfolioState,
    portfolioKey,
    period,
    currency,
    chartsData,
    allPositions,
    activePositions,
    synching,
    hasUserPortfolios,
    investmentViewType,
    calculationBaseType,
    portfolioViewType,
    portfolioViewTypeI18n,
    gameId,
  ): UserPortfolioVM => ({
    reportLoading,
    portfoliosLoading,
    error,
    canAddTransactions,
    canCopy,
    portfolios,
    portfolioName,
    isAuthUserSelected,
    noPortfolios,
    visibility,
    colors,

    chartsData,
    reportValues: getReportValuesByCurrency(chartsData.aggregation, currency),
    positions:
      investmentViewType === InvestmentViewType.All
        ? allPositions
        : activePositions,
    showNoPortfolioCard:
      !reportLoading &&
      (!hasUserPortfolios || portfolioState !== PortfolioReportState.Success),
    portfolioState,
    portfolioKey,

    period,
    currency,
    calculationBaseType,
    portfolioViewType,
    portfolioViewTypeI18n,
    synching,
    gameId,
  }),
);

export const selectPortfolioInsightsVM = memoize(
  (
    userId?: string,
    currency?: string,
    period?: BookingPeriod,
    startDate?: string,
    endDate?: string,
    keys?: string[],
  ) => {
    const reportId = userId
      ? getStoreReportId(
          computeHash(keys, userId),
          period,
          currency,
          startDate,
          endDate,
        )
      : undefined;

    return createSelector(
      portfolioReportsFeature.selectReportChartDataById(reportId, currency),
      portfolioReportsFeature.selectReportCurrency,
      portfolioReportsFeature.selectReportCalculationBase,
      portfolioReportsFeature.selectGeekView,
      (
        chartsData,
        reportCurrency,
        calculationBaseType,
        geekView,
      ): PortfolioInsightsVM => {
        const { netGross, cashFlows } =
          getReportValuesByCurrencyAndCalculationBase(
            chartsData.aggregation,
            currency ?? reportCurrency,
            calculationBaseType,
          ) ?? {};

        return {
          aggregation: chartsData.aggregation,
          periodicCost: netGross?.periodicCost ?? undefined,
          profitLoss: netGross?.ledger?.profitLoss?.return ?? undefined,
          capital: netGross?.ledger?.capital ?? undefined,
          performanceReport: netGross?.performance ?? undefined,
          cashFlows,
          currency: currency ?? reportCurrency,
          calculationBaseType,
          geekView,
        };
      },
    );
  },
);

export const selectPortfolioPerfRisksVM = memoize(
  (
    userId?: string,
    currency?: string,
    period?: BookingPeriod,
    startDate?: string,
    endDate?: string,
    calculationBaseType?: CalculationBaseType,
    keys?: string[],
  ) => {
    const reportId = userId
      ? getStoreReportId(
          computeHash(keys, userId),
          period,
          currency,
          startDate,
          endDate,
        )
      : undefined;

    return createSelector(
      portfolioReportsFeature.selectReportChartDataById(reportId, currency),
      portfolioReportsFeature.selectReportCurrency,
      portfolioReportsFeature.selectReportCalculationBase,
      portfolioReportsFeature.selectLoading,
      ThemeSelectors.selectColors,
      (
        chartsData,
        reportCurrency,
        reportCalculationBaseType,
        loading,
        colors,
      ): PortfolioPerfRisksVM => {
        const reportValues = getReportValuesByCurrencyAndCalculationBase(
          chartsData.aggregation,
          currency ?? reportCurrency,
          calculationBaseType ?? reportCalculationBaseType,
        )?.netGross;

        return {
          aggregation: chartsData.aggregation,
          performanceChart: chartsData.performance,
          volatilityChart: chartsData.volatility,
          volatilityVsPerformanceChart: chartsData.volatilityVsPerformance,
          topPositionsChart: chartsData.topPositions,
          colors,
          performanceReport: reportValues?.performance ?? undefined,
          calculationBaseType: calculationBaseType ?? reportCalculationBaseType,
          loading,
        };
      },
    );
  },
);

export const selectPortfoliosComparisonVM = createSelector(
  UsersSelectors.selectUser,
  AuthSelectors.selectUser,
  (authUser, selectedUser): PortfoliosComparisonVM => ({
    authUser,
    selectedUser,
  }),
);

export const selectSettingsPortfoliosActionsVM = createSelector(
  portfolioReportsFeature.selectLoading,
  (portfolioLoading) => ({
    loading: portfolioLoading,
  }),
);

export const selectNewVirtualPortfolioFormVM = createSelector(
  userPortfoliosFeature.selectLoading,
  (loading) => ({
    loading,
    currencies: [...virtualCcy],
  }),
);

export const selectNewSimulationPortfolioFormVM = createSelector(
  userPortfoliosFeature.selectLoading,
  (loading) => ({
    loading,
  }),
);

export const selectPortfoliosReportVM = memoize(
  (
    userId: string,
    currency?: string,
    period?: BookingPeriod,
    startDate?: string,
    endDate?: string,
    keys?: string[],
  ) => {
    const reportId = getStoreReportId(
      computeHash(keys, userId),
      period,
      currency,
      startDate,
      endDate,
    );

    return createSelector(
      selectCommonReportVM,
      portfolioReportsFeature.selectReportById(reportId),
      portfolioReportsFeature.selectReportChartDataById(reportId, currency),
      UsersSelectors.selectUserById(userId),
      (
        {
          reportLoading,
          portfoliosLoading,
          error,
          canAddTransactions,
          canCopy,
          portfolios,
          portfolioName,
          isAuthUserSelected,
          noPortfolios,
          visibility,
          colors,
        },
        report,
        chartsData,
        user,
      ): PortfoliosReportVM => ({
        reportLoading,
        portfoliosLoading,
        error,
        canAddTransactions,
        canCopy,
        portfolios,
        portfolioName,
        isAuthUserSelected,
        noPortfolios,
        visibility,
        colors,

        chartsData,
        reportValues: getReportValuesByCurrency(
          chartsData.aggregation,
          currency,
        ),
        positions: sortPortfolioReportItems(report),
        showNoPortfolioCard:
          !reportLoading &&
          (error || report?.state !== PortfolioReportState.Success),
        portfolioState: report?.state ?? undefined,
        portfolioKey: keys?.length === 1 ? keys?.[0] : undefined,

        userId: user?.id ?? undefined,
        userAvatar: user?.picture ?? undefined,
      }),
    );
  },
);

export type ReportingAppVM = {
  loading: boolean;
  report?: PortfolioReportEntity;
  chartsData: PortfolioChartsData;
  reportValues?: PortfolioReportValues;
  positions: PortfolioItem[];
  portfolioState?: PortfolioReportState;
  showNoPortfolioCard: boolean;
  colors?: ThemeColors;
};

export const selectReportingAppVM = memoize(
  (
    userId?: string,
    currency?: string,
    period?: BookingPeriod,
    startDate?: string,
    endDate?: string,
    keys?: string[],
    calculationBaseType?: CalculationBaseType,
  ) => {
    const reportId = userId
      ? getStoreReportId(
          computeHash(keys, userId),
          period,
          currency,
          startDate,
          endDate,
        )
      : undefined;

    return createSelector(
      portfolioReportsFeature.selectLoading,
      portfolioReportsFeature.selectReportById(reportId),
      portfolioReportsFeature.selectReportChartDataById(
        reportId,
        currency,
        calculationBaseType,
      ),
      portfolioReportsFeature.selectError,
      ThemeSelectors.selectColors,
      (loading, report, chartsData, error, colors): ReportingAppVM => ({
        loading,
        colors,
        report,
        chartsData,
        reportValues: getReportValuesByCurrency(
          chartsData.aggregation,
          currency,
        ),
        positions: sortPortfolioReportItems(report),
        showNoPortfolioCard:
          !loading && (error || report?.state !== PortfolioReportState.Success),
        portfolioState: report?.state ?? undefined,
      }),
    );
  },
);
