import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../utils';

export const FeedbackApiActions = createActionGroup({
  source: 'Feedback/API',
  events: {
    'Get Canny Token Success': props<{
      token: string;
    }>(),
    'Get Canny Token Failure': errorProps(),
  },
});

export const FeedbackSettingsActions = createActionGroup({
  source: 'Feedback Settings',
  events: {
    'Add Feedback': emptyProps(),
  },
});

export const FeedbackEffectsActions = createActionGroup({
  source: 'Feedback Effects',
  events: {
    'Notify Error': errorProps(),
  },
});
