import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import { GameQuizEntity } from '../games';
import { UsersEntity } from '../users';

export type NavbarTabType =
  | NavigationRouteNames.HOME
  | NavigationRouteNames.PORTFOLIOS
  | NavigationRouteNames.GAMES
  | NavigationRouteNames.FAVOURITES
  | NavigationRouteNames.DISCOVER;

export type TabEntity = {
  icon: {
    filled: string;
    outline: string;
  };
  label: string;
};

export type TabsRecord = Record<NavbarTabType, TabEntity>;

export const tabs = (): TabsRecord => ({
  [NavigationRouteNames.HOME]: {
    icon: {
      outline: 'home-outline',
      filled: 'home',
    },
    label: $localize`Home`,
  },
  [NavigationRouteNames.PORTFOLIOS]: {
    icon: {
      outline: 'stats-chart-outline',
      filled: 'stats-chart',
    },
    label: $localize`Portfolios`,
  },
  [NavigationRouteNames.GAMES]: {
    icon: {
      outline: 'trophy-outline',
      filled: 'trophy',
    },
    label: $localize`Games`,
  },
  [NavigationRouteNames.FAVOURITES]: {
    icon: {
      outline: 'star-outline',
      filled: 'star',
    },
    label: $localize`Favorites`,
  },
  [NavigationRouteNames.DISCOVER]: {
    icon: {
      outline: 'search-outline',
      filled: 'search',
    },
    label: $localize`Discover`,
  },
});

export const TAB_ORDER: NavbarTabType[] = [
  NavigationRouteNames.HOME,
  NavigationRouteNames.PORTFOLIOS,
  NavigationRouteNames.GAMES,
  NavigationRouteNames.FAVOURITES,
  NavigationRouteNames.DISCOVER,
];

export type NavbarVM = {
  tab: NavbarTabType;
  defaultTab: NavbarTabType;
  tabOrder: NavbarTabType[];
  tabs: TabsRecord;
  user?: UsersEntity;
  quizzes?: GameQuizEntity[];
};
