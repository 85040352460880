import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  LaunchModalActions,
  LaunchModalApiActions,
} from './launchmodals.actions';
import { LaunchModalEntity } from './launchmodals.models';

export const name = 'launchmodals';

export interface LaunchModalState extends EntityState<LaunchModalEntity> {
  loaded: boolean;
  error: string | undefined;
  acknowledged: string[];
}

export interface LaunchModalPartialState {
  readonly [name]: LaunchModalState;
}

export const launchModalAdapter = createEntityAdapter<LaunchModalEntity>();

export const initialLaunchModalState: LaunchModalState =
  launchModalAdapter.getInitialState({
    loaded: false,
    acknowledged: [],
    error: undefined,
  });

export const reducer = createReducer(
  initialLaunchModalState,
  on(LaunchModalApiActions.loadLaunchModalsSuccess, (state, { list }) =>
    launchModalAdapter.setAll(list.modals, { ...state, loaded: true }),
  ),
  on(LaunchModalApiActions.loadLaunchModalsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    LaunchModalApiActions.loadAcknowledgedSuccess,
    (state, { acknowledged }) => ({
      ...state,
      acknowledged: acknowledged ?? [],
    }),
  ),
  on(LaunchModalApiActions.loadAcknowledgedFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(LaunchModalActions.acknowledgedModal, (state, { id }) => ({
    ...state,
    acknowledged: [...new Set([...state.acknowledged, id])],
  })),
  // If the user selects a primary action, we automatically acknowledge the modal
  on(LaunchModalActions.selectedAction, (state, { id, action }) => ({
    ...state,
    ...(action.acknowledge
      ? { acknowledged: [...new Set([...state.acknowledged, id])] }
      : {}),
  })),
);
