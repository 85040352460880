import { createSelector } from '@ngrx/store';
import { RankingType } from '@yeekatee/booking-util-definitions';
import {
  BookingPeriod,
  CalculationBaseType,
  GameCompetitionState,
} from '@yeekatee/client-api-angular';
import { GameAssetSchema } from '@yeekatee/instruments-util-discover';
import { DateTime } from 'luxon';
import { ActivitiesSelectors } from '../activity-streams';
import {
  GameCompetitionEntity,
  GameQuizEntity,
  GameRankingVM,
  GamesSelectors,
  HighlightedDates,
  getGameCompetitionAssets,
  getHighlightedDatesAndStreak,
} from '../games';
import { LocalesSelectors } from '../locales';
import {
  PortfolioViewType,
  portfolioReportsFeature,
  userPortfoliosFeature,
} from '../portfolios';
import { UsersSelectors } from '../users';
import { memoize } from '../utils';

type RankingFiltersI18n = Partial<Record<RankingType, string>>; // @TODO remove partial
const selectRankingFiltersI18n = createSelector(
  (): RankingFiltersI18n => ({
    [RankingType.PrtfValue]: $localize`Best portfolio value`,
    [RankingType.TrxCnt]: $localize`Transactions count`,
    [RankingType.TwrByVola]: $localize`Best volatility adjusted performance (TWR)`,
    [RankingType.ModDietz]: $localize`Best Modified Dietz`,
    [RankingType.GameReward]: $localize`Most engaged`,
    [RankingType.Twr]: $localize`Best performance (TWR)`, // @TODO step 1 - add everywhere where RankingType.TwrGross is used
    [RankingType.Vola]: $localize`Lowest volatility`, // @TODO step 1 - add everywhere where RankingType.TwrGross is used
    [RankingType.TotalReturn]: $localize`Best total return`, // @TODO step 1 - add everywhere where RankingType.StdTwrGross is used
    // deprecated
    [RankingType.TotalReturnNet]: $localize`Best total return`, // @TODO step 2 - to be replaced by: RankingType.TotalReturn, // @TODO step 3: delete RankingType.TotalReturnNet
    [RankingType.TwrGross]: $localize`Best performance (TWR)`, // @TODO step 2 - to be replaced by: RankingType.Twr  // @TODO step 3: delete RankingType.TwrGross
    [RankingType.StdTwrGross]: $localize`Lowest volatility`, // @TODO step 2 - to be replaced by: RankingType.Vola  // @TODO step 3: delete RankingType.StdTwrGross
  }),
);

export const selectGameRankingVM = memoize((gameId?: string) =>
  createSelector(
    GamesSelectors.selectGameCompetitionById(gameId),
    GamesSelectors.isGameCompetitionLoading,
    GamesSelectors.selectRankingType,
    GamesSelectors.selectGameOwnPlayer,
    GamesSelectors.isOwnPlayerParticipating,
    GamesSelectors.selectOwnPlayerRanking,
    GamesSelectors.selectOwnPlayerPortfolioKey,
    portfolioReportsFeature.selectReportPeriod,
    ActivitiesSelectors.selectActiveFollowingListForSelectedUser,
    selectRankingFiltersI18n,
    (
      competition,
      loading,
      rankingType,
      player,
      isPlayerParticipating,
      playerRanking,
      portfolioKey,
      reportPeriod,
      followings,
      rankingFiltersI18n,
    ): GameRankingVM => {
      const ranking =
        (competition?.rankings ?? []).find(
          (ranking) => ranking.type === rankingType,
        )?.items ?? [];

      const rankingTypes = competition?.rankingTypes ?? [];

      return {
        currency: competition?.currency ?? undefined,
        reportPeriod,
        portfolioView: competition?.portfolioViewType,
        portfolioCalculation: competition?.portfolioCalculationType,
        ranking,
        rankingTypes: rankingTypes.map((t) => ({
          type: t,
          text: rankingFiltersI18n[t],
        })),
        rankingType,
        player: isPlayerParticipating
          ? {
              userId: player?.id ?? undefined,
              value: playerRanking?.value,
              rank: playerRanking?.rank,
              portfolioKey,
            }
          : undefined,
        loading,
        canSelect: rankingTypes.length > 1,
        participants: competition?.participants ?? 0,
        calculatedUntil: competition?.calculatedUntil ?? undefined,
        title: rankingType ? rankingFiltersI18n[rankingType] : undefined,
        followings: ranking
          .filter(
            (i) =>
              !!followings.find(
                (f) => f?.object.id && i.userId && f?.object.id === i.userId,
              ),
          )
          .slice(0, 3), // Just top 3
      };
    },
  ),
);

export type GameOverviewVM = {
  competition?: GameCompetitionEntity;
  isParticipating: boolean;
  loading: boolean;
  userId?: string;
  portfolioKey?: string;
  currency?: string;
  period?: BookingPeriod;
  portfolioView?: PortfolioViewType;
  portfolioCalculation?: CalculationBaseType;
  language?: string;
  showActions: boolean;
  openQuizzesCount?: number;
};

export const selectGameOverviewVM = memoize((gameId: string) =>
  createSelector(
    GamesSelectors.selectGameCompetitionById(gameId),
    GamesSelectors.isOwnPlayerParticipating,
    GamesSelectors.isGameCompetitionLoading,
    GamesSelectors.isGamePlayersLoading,
    UsersSelectors.selectUserId,
    GamesSelectors.selectOwnPlayerPortfolioKey,
    portfolioReportsFeature.selectReportPeriod,
    LocalesSelectors.getLandingPageLanguage,
    GamesSelectors.selectOpenQuizzesByCompetitionId(gameId),
    (
      competition,
      isParticipating,
      competitionLoading,
      playerLoading,
      userId,
      portfolioKey,
      period,
      language,
      openQuizzes,
    ): GameOverviewVM => ({
      competition,
      showActions: [
        GameCompetitionState.TRIAL,
        GameCompetitionState.STARTED,
      ].includes(competition?.state ?? GameCompetitionState.INITIAL),
      isParticipating,
      loading: competitionLoading || playerLoading,
      userId,
      portfolioKey,
      currency: competition?.currency ?? undefined,
      period,
      portfolioView: competition?.portfolioViewType,
      portfolioCalculation: competition?.portfolioCalculationType,
      language,
      openQuizzesCount: openQuizzes.length,
    }),
  ),
);

export const selectGamesListVM = createSelector({
  competitions: GamesSelectors.getAllGameCompetitions,
  openQuizzesCount: GamesSelectors.selectOpenQuizzesCount,
  loading: GamesSelectors.isGameCompetitionLoading,
});

export type GameQuizzesVM = {
  quizzes: GameQuizEntity[];
  loading: boolean;
  highlightedDates: HighlightedDates[];
  daysStreak: number;
  minDate?: string;
  maxDate?: string;
  selectedDate: string;
  locale?: string;
};

export const selectGameQuizzesVM = memoize((gameId: string) =>
  createSelector(
    GamesSelectors.selectGameCompetitionById(gameId),
    GamesSelectors.selectGameQuizzesById(gameId),
    GamesSelectors.selectGameQuizzesBySelectedDate(gameId),
    GamesSelectors.isGameQuizzesLoading,
    GamesSelectors.selectGameQuizzesDate,
    LocalesSelectors.getSelectedId,
    (
      competition,
      allQuizzes,
      quizzes,
      loading,
      selectedDate,
      locale,
    ): GameQuizzesVM => {
      const { highlightedDates, daysStreak, minDate, maxDate } =
        getHighlightedDatesAndStreak(
          allQuizzes,
          DateTime.now().toISODate(),
          competition?.startDate ?? undefined,
          competition?.endDate ?? undefined,
        );

      return {
        quizzes,
        loading,
        highlightedDates,
        daysStreak,
        minDate,
        maxDate,
        selectedDate,
        locale,
      };
    },
  ),
);

export type GameQuizVM = {
  quiz?: GameQuizEntity;
  title: string;
  loading: boolean;
  index: number;
  total: number;
};

export const selectGameQuizVM = memoize((quizId: string, gameId: string) =>
  createSelector(
    GamesSelectors.selectGameQuizzesById(gameId),
    GamesSelectors.selectGameQuizById(quizId, gameId),
    GamesSelectors.isGameQuizzesLoading,
    (quizzes, quiz, loading): GameQuizVM => ({
      quiz,
      title: quiz?.localizedTitle ?? $localize`Daily quiz`,
      loading,
      index: quiz?.index ?? 0,
      total: quizzes.length,
    }),
  ),
);

export const selectGamePortfolioAssetsIds = createSelector(
  userPortfoliosFeature.selectTradeablePortfolioInRoute,
  GamesSelectors.selectGameCompetitionsEntities,
  GamesSelectors.selectGameAssets,
  (portfolio, competitions, assets) =>
    getGameCompetitionAssets(
      competitions[portfolio?.gameId ?? ''],
      assets,
    )?.map((a) => a.id),
);

export type GameAssetsVM = {
  assets: GameAssetSchema[];
  filter?: {
    stocks: boolean;
    etfs: boolean;
    cryptos: boolean;
  };
};

export const selectGameAssetsVM = memoize((gameId: string) =>
  createSelector(
    GamesSelectors.selectSearchFilteredAssetsById(gameId),
    GamesSelectors.selectSearchFilter,
    (assets, filter): GameAssetsVM => ({
      assets,
      filter: {
        cryptos: filter === 'Crypto',
        etfs: filter === 'Etf',
        stocks: filter === 'Stock',
      },
    }),
  ),
);
