import { BookingPeriod } from '@yeekatee/client-api-angular';
import { ChartNavigation } from './chart-navigation.interface';

export enum PerformanceNavConfig {
  week = '1W',
  month = '1M',
  threeMonths = '3M',
  sixMonths = '6M',
  yearToDate = 'YTD',
  year = '1Y',
  max = 'MAX',
}

export const performanceNavConfig: ChartNavigation = [
  {
    content: PerformanceNavConfig.week,
    value: BookingPeriod.week,
  },
  {
    content: PerformanceNavConfig.month,
    value: BookingPeriod.month,
  },
  {
    content: PerformanceNavConfig.threeMonths,
    value: BookingPeriod.threeMonths,
  },
  {
    content: PerformanceNavConfig.sixMonths,
    value: BookingPeriod.sixMonths,
  },
  {
    content: PerformanceNavConfig.yearToDate,
    value: BookingPeriod.yearToDate,
  },
  {
    content: PerformanceNavConfig.year,
    value: BookingPeriod.year,
  },
  {
    content: PerformanceNavConfig.max,
    value: BookingPeriod.max,
  },
];
