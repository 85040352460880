import { RankingType } from '@yeekatee/booking-util-definitions';
import {
  BookingPeriod,
  CalculationBaseType,
  GameCompetition,
  GameImageType,
  GamePlayer,
  GameRankingItem,
  LocalisedString,
  QuizAnswer,
  QuizQuestion,
} from '@yeekatee/client-api-angular';
import {
  GameAssetSchema,
  GameAssetsSchema,
} from '@yeekatee/instruments-util-discover';
import { getLocalizedString } from '@yeekatee/launch-modal-util';
import { PortfolioViewType } from '../portfolios';
import { ColorMode } from '../theme/theme.reducer';

export interface GameCompetitionEntity
  extends Omit<GameCompetition, '__typename'> {
  portfolioViewType?: PortfolioViewType;
  portfolioCalculationType?: CalculationBaseType;
  rankingTypes?: RankingType[];
}

export type GamePlayerEntity = Omit<GamePlayer, '__typename'>;

export enum GameQuizAnswerType {
  Correct = 'Correct',
  Wrong = 'Wrong',
  CorrectLate = 'CorrectLate',
  WrongLate = 'WrongLate',
}

export interface GameQuizEntity
  extends Omit<QuizQuestion, '__typename' | 'question' | 'answers'> {
  question?: LocalisedString;
  answers?: QuizAnswer[];
  answerType?: GameQuizAnswerType;
  index?: number;
  localizedTitle?: string;
  localizedQuestion?: string;
  localizedAnswers?: (QuizAnswer & { localizedText?: string })[];
  localizedExplanation?: string;
  givenReward?: number;
}

export interface RankingTypeVM {
  type?: RankingType;
  text?: string;
}

export interface GameRankingVM {
  currency?: string;
  ranking: GameRankingItem[];
  rankingTypes: RankingTypeVM[];
  rankingType?: RankingType;
  loading: boolean;
  participants: number;
  calculatedUntil?: string;
  player?: GameRankingItem;
  canSelect: boolean;
  title?: string;
  followings: GameRankingItem[];
  reportPeriod?: BookingPeriod;
  portfolioView?: PortfolioViewType;
  portfolioCalculation?: CalculationBaseType;
}

export const reduceQuizEntity = (quiz: GameQuizEntity): GameQuizEntity => {
  const { startDate, correctAnswers, userAnswers, userAnswerDate } = quiz;

  if (!correctAnswers || !userAnswers || !userAnswerDate || !startDate)
    return quiz;

  let answerType =
    userAnswers.length === correctAnswers.length &&
    userAnswers.every((a) => correctAnswers.includes(a))
      ? GameQuizAnswerType.Correct
      : GameQuizAnswerType.Wrong;

  if (userAnswerDate > startDate) {
    answerType =
      answerType === GameQuizAnswerType.Correct
        ? GameQuizAnswerType.CorrectLate
        : GameQuizAnswerType.WrongLate;
  }

  return { ...quiz, answerType };
};

export const reduceQuizEntities = (
  quizzes: GameQuizEntity[],
): GameQuizEntity[] => quizzes.map((q) => reduceQuizEntity(q));

export const extendQuizEntity = (
  quiz: GameQuizEntity,
  locale: string,
  mode: ColorMode,
  position: number,
): GameQuizEntity => ({
  ...quiz,
  index: position + 1,
  localizedTitle: getLocalizedString(quiz.title ?? undefined, locale),
  localizedQuestion: getLocalizedString(quiz.question, locale),
  localizedAnswers: quiz.answers?.map((a) => ({
    ...a,
    localizedText: getLocalizedString(a.text, locale),
  })),
  localizedExplanation: getLocalizedString(
    quiz.explanation ?? undefined,
    locale,
  ),
  ...applyThemeMode(mode, quiz),
});

const imageTypeColorModeMap: Record<GameImageType, ColorMode> = {
  [GameImageType.LIGHT]: ColorMode.LIGHT,
  [GameImageType.DARK]: ColorMode.DARK,
};

export const applyThemeMode = <
  T extends GameCompetitionEntity | GameQuizEntity,
>(
  mode: ColorMode,
  entity: T | undefined,
): T | undefined => {
  if (!entity) return undefined;

  const { imageUrl, imageUrls } = entity;
  const defaultImageUrl = imageUrl ?? './assets/game/placeholder.png';

  if (!imageUrls) return { ...entity, imageUrl: defaultImageUrl };

  const { url } =
    imageUrls.find(({ type }) => imageTypeColorModeMap[type] === mode) ?? {};

  return { ...entity, imageUrl: url ?? defaultImageUrl };
};

export type HighlightedDates = {
  date: string;
  textColor: string;
  backgroundColor: string;
  answeredOnTime: boolean | undefined;
};

type AnsweredDates = Record<
  string,
  { startDate: string; answeredOnTime: boolean | undefined }
>;

export const getHighlightedDatesAndStreak = (
  quizzes: GameQuizEntity[],
  today: string,
  startDate?: string,
  endDate?: string,
): {
  highlightedDates: HighlightedDates[];
  daysStreak: number;
  minDate: string;
  maxDate: string;
} => {
  const minDate = startDate ?? today;
  const maxDate = !endDate || today <= endDate ? today : endDate;

  const answeredDates = Object.values(
    quizzes
      .filter(
        ({ startDate }) =>
          !!startDate && minDate <= startDate && startDate <= maxDate,
      )
      .reduce(
        (acc, { startDate, answerType, userAnswerDate }): AnsweredDates => {
          if (!startDate) return acc;

          const answeredOnTime =
            !answerType || !userAnswerDate
              ? undefined // no answer provided for this quiz
              : answerType === GameQuizAnswerType.Correct ||
                answerType === GameQuizAnswerType.Wrong;

          acc[startDate] ??= { startDate, answeredOnTime };
          acc[startDate].answeredOnTime &&= answeredOnTime;

          return acc;
        },
        {} as AnsweredDates,
      ),
  );

  const highlightedDates: HighlightedDates[] = answeredDates.map(
    ({ startDate, answeredOnTime }) => {
      const cls = answeredOnTime ? 'success' : 'danger';

      return {
        date: startDate,
        textColor: `var(--ion-color-${cls}-contrast)`,
        backgroundColor: `rgba(var(--ion-color-${cls}-rgb), 0.6)`,
        answeredOnTime,
      };
    },
  );

  /**
   * 1 - Today answered -> return daysStreak + 1
   * 2 - Not today answered -> return daysStreak + 1
   * 3 - Today not answered -> return daysStreak
   * 4 - Not today not answered -> reset to 0
   */
  const daysStreak = highlightedDates.reduce(
    (daysStreak, d) =>
      d.answeredOnTime ? daysStreak + 1 : d.date === today ? daysStreak : 0,
    0,
  );

  return { highlightedDates, daysStreak, minDate, maxDate };
};

export const reduceGameCompetition = (
  competition: GameCompetition,
): GameCompetitionEntity => ({
  ...competition,
  portfolioViewType: PortfolioViewType.TWR,
  portfolioCalculationType: CalculationBaseType.Gross,
});

export const reduceGameCompetitionRankings = (
  competition: GameCompetition,
  currentRankingType?: RankingType,
): [GameCompetitionEntity, RankingType | undefined] => {
  const mainRanking = competition.rankings?.at(0);
  return [
    {
      ...competition,
      rankingTypes: (competition.rankings ?? [])
        .map((ranking) => ranking.type)
        .filter((type): type is RankingType => !!type),
    },
    currentRankingType ??
      ((mainRanking?.type ?? undefined) as RankingType | undefined),
  ];
};

export const getGameCompetitionAssets = (
  competition?: GameCompetition,
  assets?: GameAssetsSchema,
): GameAssetSchema[] | undefined => {
  if (!competition || !assets) return;

  const { assetsId } = competition;
  return assetsId && assetsId in assets
    ? assets[assetsId as keyof GameAssetsSchema]
    : assets.default;
};
