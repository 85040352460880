import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
  standalone: true,
})
export class FormatNumberPipe extends DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    super(locale);
  }

  override transform(value: any): any {
    if (value == null) return undefined;

    if (isNaN(value)) return value;

    const numSplit = `${value}`.split('.');
    const decimals = numSplit.length > 1 ? numSplit[1] : '';

    return super.transform(
      value,
      `1.${decimals.length}-${decimals.length}`,
      this.locale,
    );
  }
}
