<ion-item lines="none">
  @if (showAvatar) {
    <ion-avatar slot="start">
      <ion-skeleton-text [animated]="true" />
    </ion-avatar>
  }
  <ion-label>
    <h3>
      <ion-skeleton-text style="width: 50%" [animated]="true" />
    </h3>
    <p>
      <ion-skeleton-text style="width: 80%" [animated]="true" />
    </p>
  </ion-label>
  <ion-label dir="rtl">
    <h3>
      <ion-skeleton-text style="width: 50%" [animated]="true" />
    </h3>
    <p>
      <ion-skeleton-text style="width: 80%" [animated]="true" />
    </p>
  </ion-label>
</ion-item>
