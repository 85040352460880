import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromInstruments from './features/instruments.reducer';
import * as fromStories from './features/stories.reducer';

export const FEATURE_KEY = 'marketNews';

export interface MarketNewsState {
  readonly [fromInstruments.FEATURE_KEY]: fromInstruments.State;
  readonly [fromStories.FEATURE_KEY]: fromStories.State;
}

export function reducers(state: MarketNewsState | undefined, action: Action) {
  return combineReducers(
    {
      [fromInstruments.FEATURE_KEY]: fromInstruments.reducer,
      [fromStories.FEATURE_KEY]: fromStories.reducer,
    },
    {
      [fromInstruments.FEATURE_KEY]: fromInstruments.initialState,
      [fromStories.FEATURE_KEY]: fromStories.initialState,
    },
  )(state, action);
}
export const selectMarketNewsState =
  createFeatureSelector<MarketNewsState>(FEATURE_KEY);
