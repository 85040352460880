import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ENVIRONMENT, Environment } from '@yeekatee/shared-util-environment';
import { ImgFallbackDirective } from '../directives/img-fallback.directive';

export enum AvatarSize {
  Tiny,
  Small,
  Medium,
  Large,
}

@Component({
  standalone: true,
  imports: [NgClass, ImgFallbackDirective],
  selector: 'yeekatee-avatar',
  templateUrl: './avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() cssClass = '';
  @Input() src?: string | null = '';
  @Input() defaultSrc = this.environment.fallbackUserAvatar;
  @Input() size = AvatarSize.Small;

  protected AvatarSize = AvatarSize;

  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment) {}
}
