import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import {
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from '@angular/cdk/scrolling';
import { Directive, Input, OnChanges, forwardRef } from '@angular/core';
import { isPlatform } from '@ionic/angular/standalone';

@Directive({
  selector: 'cdk-virtual-scroll-viewport[dynamicSize]',
  standalone: true,
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: (d: VirtualScrollDirective) => d._scrollStrategy,
      deps: [forwardRef(() => VirtualScrollDirective)],
    },
  ],
})
export class VirtualScrollDirective implements OnChanges {
  /** The size in iOS of the items in the list (in pixels). */
  @Input()
  get iosSize(): number {
    return this._iosSize;
  }
  set iosSize(value: NumberInput) {
    this._iosSize = coerceNumberProperty(value);
  }
  _iosSize = 59.5;

  /** The size in Material Design of the items in the list (in pixels). */
  @Input()
  get mdSize(): number {
    return this._mdSize;
  }
  set mdSize(value: NumberInput) {
    this._mdSize = coerceNumberProperty(value);
  }
  _mdSize = 61;

  /**
   * The minimum amount of buffer rendered beyond the viewport (in pixels).
   * If the amount of buffer dips below this number, more items will be rendered. Defaults to 100px.
   */
  @Input()
  get minBufferPx(): number {
    return this._minBufferPx;
  }
  set minBufferPx(value: NumberInput) {
    this._minBufferPx = coerceNumberProperty(value);
  }
  _minBufferPx = 100;

  /**
   * The number of pixels worth of buffer to render for when rendering new items. Defaults to 200px.
   */
  @Input()
  get maxBufferPx(): number {
    return this._maxBufferPx;
  }
  set maxBufferPx(value: NumberInput) {
    this._maxBufferPx = coerceNumberProperty(value);
  }
  _maxBufferPx = 200;

  private readonly itemSize = () =>
    isPlatform('ios') ? this.iosSize : this.mdSize;

  /** The scroll strategy used by this directive. */
  _scrollStrategy = new FixedSizeVirtualScrollStrategy(
    this.itemSize(),
    this.minBufferPx,
    this.maxBufferPx,
  );

  ngOnChanges() {
    this._scrollStrategy.updateItemAndBufferSize(
      this.itemSize(),
      this.minBufferPx,
      this.maxBufferPx,
    );
  }
}
