@if (isIos) {
  @if (noRouting) {
    <ion-back-button
      class="block"
      i18n-text
      text="Back"
      [defaultHref]="defaultHref ?? baseRoute"
      [routerLink]="baseRoute"
    />
  } @else {
    <ion-back-button
      class="block"
      i18n-text
      text="Back"
      [defaultHref]="defaultHref ?? baseRoute"
    />
  }
} @else {
  @if (noRouting) {
    <ion-back-button
      class="block"
      [defaultHref]="defaultHref ?? baseRoute"
      [routerLink]="baseRoute"
    />
  } @else {
    <ion-back-button class="block" [defaultHref]="defaultHref ?? baseRoute" />
  }
}
