/**
 * Interface for the 'Activities' data
 */
import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import {
  NoteContentTree,
  formatNoteContent,
  mapNoteContentTree,
} from '@yeekatee/activities-util-functions';
import {
  ActivityStreamsActivity,
  ActivityStreamsActivityType,
  ActivityStreamsActor,
  ActivityStreamsActorType,
  ActivityStreamsCollectionPage,
  ActivityStreamsImage,
  ActivityStreamsLink,
  ActivityStreamsObject,
  ActivityStreamsObjectType,
} from '@yeekatee/client-api-angular';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import { DateTime } from 'luxon';
import { Observable, concatMap, first, map, of } from 'rxjs';
import {
  ActivitiesEffectsActions,
  ActivitiesTimelineActions,
} from '../activity-streams/actions';
import { HomePageActions } from '../home/home.actions';
import { UsersEntity } from '../users';

export enum SearchFilterType {
  Person = 'Person',
  FinancialInstrument = 'FinancialInstrument',
}

export enum TimelineFilter {
  FOR_YOU = 'FOR_YOU',
  FOLLOWING = 'FOLLOWING',
}

// API Types
// Types as returned by the GraphQL API
export enum CollectionPrefix {
  FOLLOWERS = 'FOLLOWERS#',
  FOLLOWING = 'FOLLOWING#',
  INBOX = 'INBOX#',
  LIKED = 'LIKED#',
  LIKES = 'LIKES#',
  OUTBOX = 'OUTBOX#',
  REPLIES = 'REPLIES#',
  SHARED_INBOX = 'SHAREDINBOX#',
  ONLY_FOLLOWING_INBOX = 'ONLYFOLLOWINGINBOX#',
  CHAT = 'CHAT#',
}

export const ActivityPrefix = 'ay_';
export const ObjectPrefix = 'ao_';

export type CollectionId = `${CollectionPrefix}${string}`;
export type ActivityId = `ay_${string}`;
export type ObjectId = `ao_${string}`;

/**
 * Returned when fetching a collection of Activities
 */
export interface ActivitiesCollection {
  id: CollectionId;
  items?: ActivityStreamsActivity[];
  totalItems?: number;
  nextToken?: string | null;
}

// Store Models definitions
// How objects are kept in the store
export type StoreObject = Omit<ActivityStreamsObject, '__typename'>;
export type StoreLink = Omit<ActivityStreamsLink, '__typename'>;

export interface ImageEntity extends Omit<ActivityStreamsImage, '__typename'> {
  type: ActivityStreamsObjectType.Image;
  content: string;
  url: string | StoreLink; // TODO: remove `string` type once we migrate urls to Link type
  key: string; // TODO: remove field once we migrate urls to Link type
  width: number;
  height: number;
  name: string;
}

export interface TagEntity extends StoreObject {
  type:
    | ActivityStreamsObjectType.Person
    | ActivityStreamsObjectType.FinancialInstrument;
  id: string;
  name: string;
}

export interface NoteEntity extends StoreObject {
  type: ActivityStreamsObjectType.Note;
  id: string;
  content?: string;
  inReplyTo?: string;
  attributedTo?: string;
  image?: ImageEntity[];
  tag?: TagEntity[];
  url?: StoreLink[];
}

export const isNoteEntity = (
  object: StoreObject | StoreActor | ObjectsEntity | undefined,
): object is NoteEntity => object?.type === ActivityStreamsObjectType.Note;

export interface ReplyEntity extends NoteEntity {
  inReplyTo: string;
}

export const isReplyEntity = (note: NoteEntity): note is ReplyEntity =>
  !!note.inReplyTo;

export type StoreActor = Omit<ActivityStreamsActor, '__typename'>;

export interface PersonEntity extends StoreActor {
  type: ActivityStreamsActorType.Person;
  id: string;
  name?: string;
  summary: string;
}

export const isPersonEntity = (
  actor: StoreObject | StoreActor | ObjectsEntity | undefined,
): actor is PersonEntity => actor?.type === ActivityStreamsActorType.Person;

export type ActorsEntity = PersonEntity;

export type ObjectsEntity = NoteEntity | ReplyEntity | ActorsEntity;

/**
 * A *Thin Activity* represents any activity, as stored in the Store,
 * where actor and object become references to the objects substore.
 */
export type ThinActivity = Omit<
  ActivityStreamsActivity,
  '__typename' | 'object' | 'actor' | 'result'
> & {
  id: string;
  actor: string;
  object: string;
  result?: ActivityStreamsObjectType;
  parent?: string;
};

export interface LikeEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Like;
}

export interface FollowEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Follow;
}

export interface CreateEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Create;
}

export interface UndoEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Undo;
}

export interface AcceptEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Accept;
}

export interface RejectEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Reject;
}

export interface DeleteEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Delete;
}

export interface FlagEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Flag;
}

export interface TombstoneEntity extends ThinActivity {
  type: ActivityStreamsActivityType.Tombstone;
}

export type ActivitiesEntity =
  | LikeEntity
  | FollowEntity
  | CreateEntity
  | UndoEntity
  | AcceptEntity
  | RejectEntity
  | DeleteEntity
  | FlagEntity
  | TombstoneEntity;

export const isCreateEntity = (
  activity: ActivitiesEntity | ActivityStreamsActivity | undefined,
): activity is CreateEntity =>
  activity?.type === ActivityStreamsActivityType.Create;

export interface CollectionsEntity {
  id: CollectionId; // The ID is composed of the type and parent ID
  items: string[]; // List of IDs
  nextToken?: string | null; // Pagination
  totalItems?: number;
  loading?: boolean;
  refreshing?: boolean;
}

// View Models definitions
export interface TypedCollection<T> {
  items?: T[];
  totalItems?: number;
}

// Timeline logic

/**
 * Most likely a note, object that can be shown on a user timeline,
 * and can be interacted with
 */
export interface InteractiveTimelineObject {
  id: string;
  to?: PersonEntity;
  actor: PersonEntity;
  isMyActivity: boolean;
  object: InteractiveNoteObject;
  myLikeActivityId?: string;
  likes?: TypedCollection<InteractiveLikeObject>;
  replies?: TypedCollection<InteractiveTimelineObject>;
  replyingTo?: PersonEntity;
  collectionId?: string;
  published?: string;
  publishedShort?: string;
}

export interface InteractiveNoteObject extends NoteEntity {
  contentTree?: NoteContentTree;
  contentFormatted?: string;
}

/**
 * Transform a plain Activity object into an InteractiveTimelineObject.
 * Take the replies and likes from its collections,
 * and nest them into the object, to use it in views.
 */
export function mapActivityToTimelineNote(
  activityId: string | null | undefined,
  allActivities: Dictionary<ActivitiesEntity>,
  allObjects: Dictionary<ObjectsEntity>,
  allCollections: Dictionary<CollectionsEntity> | undefined,
  allUsers: Dictionary<UsersEntity>,
  userId?: string,
): InteractiveTimelineObject | undefined {
  if (!activityId) return;
  const activity = allActivities?.[activityId];

  if (!activity || activity.type !== ActivityStreamsActivityType.Create)
    return undefined;

  const actor =
    (allUsers?.[activity.actor] &&
      userEntityToPerson(allUsers[activity.actor])) ??
    allObjects?.[activity.actor];
  const object = allObjects?.[activity.object];

  // ! Currently, this prevents Activities without an Actor of type Person from showing
  if (
    !actor ||
    !object ||
    actor.type !== ActivityStreamsActorType.Person ||
    object.type !== ActivityStreamsObjectType.Note
  )
    return undefined;

  const myLikeActivityId = allCollections?.[
    getCollectionId(CollectionPrefix.LIKED, userId)
  ]?.items
    .map((id) => allActivities[id])
    .find((activity) => activity?.object === object.id)?.id;

  const likesCollection =
    allCollections?.[getCollectionId(CollectionPrefix.LIKES, object.id)];

  const repliesCollection =
    allCollections?.[getCollectionId(CollectionPrefix.REPLIES, object.id)];

  const attributedToId =
    allObjects?.[object.inReplyTo ?? '']?.attributedTo ?? '';
  const attributedTo =
    (allUsers?.[attributedToId] &&
      userEntityToPerson(allUsers[attributedToId])) ??
    allObjects?.[attributedToId];
  const replyingTo =
    attributedTo?.type === ActivityStreamsActorType.Person
      ? attributedTo
      : undefined;

  return {
    id: activityId,
    actor,
    isMyActivity: actor.id === userId,
    object: mapNoteToNoteContentTree(object),
    myLikeActivityId,
    replyingTo,
    likes: {
      totalItems: likesCollection?.totalItems,
      items: likesCollection?.items
        .map((id) =>
          mapActivityToLikeObject(
            id,
            allActivities,
            allObjects,
            allCollections,
            allUsers,
            userId,
          ),
        )
        .filter((activity): activity is InteractiveLikeObject => !!activity),
    },
    replies: {
      totalItems: repliesCollection?.totalItems,
      items: repliesCollection?.items
        .map((id) =>
          mapActivityToTimelineNote(
            id,
            allActivities,
            allObjects,
            allCollections,
            allUsers,
            userId,
          ),
        )
        .filter(
          (activity): activity is InteractiveTimelineObject => !!activity,
        ),
    },
  };
}

export function mapNoteToNoteContentTree(
  note: NoteEntity,
): InteractiveNoteObject {
  if (!note.content) return note;

  const tag = note.tag ?? [];

  const contentTree = mapNoteContentTree(note.content);
  const paragrahps = contentTree.content;
  for (let np = 0; np < paragrahps.length; np++) {
    const elems = paragrahps[np].content;
    for (let ne = 0; ne < elems.length; ne++) {
      const e = elems[ne];

      // Add navigation to links and tags
      if (e.type === 'link' && !e.link) {
        e.link = e.content.content;
      } else if (e.type === 'tag') {
        const t = tag.find((t) => t.name === e.content.content);
        if (t?.id && t?.type) {
          e.link =
            t.type === ActivityStreamsObjectType.Person
              ? `/${NavigationRouteNames.USERS}/${t.id}`
              : `/${NavigationRouteNames.INSTRUMENTS}/${t.id}`;
        }
      }
    }
  }

  return {
    ...note,
    contentTree,
    contentFormatted: formatNoteContent(note.content),
  };
}

const userEntityToPerson = (user?: UsersEntity): PersonEntity | undefined =>
  user && {
    type: ActivityStreamsActorType.Person,
    id: user.id ?? '',
    name: user.name ?? '',
    summary: user.preferred_username ?? '',
    icon: user.picture ?? '',
    location: user.location ?? '',
  };

export interface TagSearchModel {
  id: string;
  label: string;
  name?: string;
  avatar: string;
  type: SearchFilterType;
}

// Follow logic

/**
 * Object that can be shown on a follow list,
 * and can be interacted with
 */
export interface InteractiveFollowObject {
  id: string;
  actor: PersonEntity;
  object: PersonEntity;
  status: FollowActivityStatus;
  refActivityStatus?: FollowActivityStatusObject;
}

export interface FollowActivityStatusObject {
  id: string;
  status: FollowActivityStatus;
}

export enum FollowActivityStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
}

/**
 * Transform a plain Activity object into an InteractiveFollowObject.
 * Take the persons from the store and nest them into the object, to use it in views.
 */
export function mapFollowActivityToPersons(
  activityId: string,
  allActivities: Dictionary<ActivitiesEntity>,
  allObjects: Dictionary<ObjectsEntity>,
  followType: 'following' | 'followers',
  ownCollection: CollectionsEntity,
  allUsers: Dictionary<UsersEntity>,
): InteractiveFollowObject | undefined {
  const activity = allActivities?.[activityId];

  if (!activity || activity.type !== ActivityStreamsActivityType.Follow)
    return undefined;

  const actor =
    (allUsers?.[activity.actor] &&
      userEntityToPerson(allUsers[activity.actor])) ??
    allObjects?.[activity.actor];
  const object =
    (allUsers?.[activity.object] &&
      userEntityToPerson(allUsers[activity.object])) ??
    allObjects?.[activity.object];

  // ! Currently, this prevents Activities without an Actor of type Person from showing
  if (
    !actor ||
    !object ||
    actor.type !== ActivityStreamsActorType.Person ||
    object.type !== ActivityStreamsActorType.Person
  )
    return undefined;

  const refObjectId = followType === 'following' ? object.id : actor.id;
  const refActivityStatus = findFollowActivityWithStatus(
    ownCollection,
    allActivities,
    (_activity: ActivitiesEntity) => _activity.object === refObjectId,
  );

  return {
    id: activityId,
    actor,
    object,
    status: mapFollowActivityWithStatus(activity),
    refActivityStatus,
  };
}

export function findFollowActivityWithStatus(
  collection: CollectionsEntity,
  allActivities: Dictionary<ActivitiesEntity>,
  compareFn: (activity: ActivitiesEntity) => boolean,
): FollowActivityStatusObject | undefined {
  const activity = collection.items
    .map((item) => allActivities?.[item])
    .find((activity) => !!activity && compareFn(activity));

  if (!activity || activity.type !== ActivityStreamsActivityType.Follow)
    return undefined;

  return {
    id: activity.id,
    status: mapFollowActivityWithStatus(activity),
  };
}

function mapFollowActivityWithStatus(
  activity: ActivitiesEntity,
): FollowActivityStatus {
  return !activity || activity.result === ActivityStreamsObjectType.Reject
    ? FollowActivityStatus.INACTIVE
    : activity.result === ActivityStreamsObjectType.Accept
      ? FollowActivityStatus.ACTIVE
      : FollowActivityStatus.PENDING;
}

// Like logic

/**
 * Object that can be shown on a follow list,
 * and can be interacted with
 */
export interface InteractiveLikeObject {
  id: string;
  actor: PersonEntity;
  object: NoteEntity;
  refActivityStatus?: FollowActivityStatusObject;
}

/**
 * Transform a plain Activity object into an InteractiveLikeObject.
 * Take the persons from the store and nest them into the object, to use it in views.
 */
export function mapActivityToLikeObject(
  activityId: string,
  allActivities: Dictionary<ActivitiesEntity>,
  allObjects: Dictionary<ObjectsEntity>,
  allCollections: Dictionary<CollectionsEntity> | undefined,
  allUsers: Dictionary<UsersEntity>,
  userId?: string,
): InteractiveLikeObject | undefined {
  const activity = allActivities?.[activityId];

  if (!activity || activity.type !== ActivityStreamsActivityType.Like)
    return undefined;

  const actor =
    (allUsers?.[activity.actor] &&
      userEntityToPerson(allUsers[activity.actor])) ??
    allObjects?.[activity.actor];
  const object = allObjects?.[activity.object];

  if (
    !actor ||
    !object ||
    actor.type !== ActivityStreamsActorType.Person ||
    object.type !== ActivityStreamsObjectType.Note
  )
    return undefined;

  const ownCollectionId = getCollectionId(CollectionPrefix.FOLLOWING, userId);
  const ownCollection = allCollections?.[ownCollectionId] ?? {
    id: ownCollectionId,
    items: [],
    loading: false,
  };

  const refActivityStatus = findFollowActivityWithStatus(
    ownCollection,
    allActivities,
    (_activity: ActivitiesEntity) => _activity.object === actor.id,
  );

  return {
    id: activityId,
    actor,
    object,
    refActivityStatus,
  };
}

/**
 * Transform a plain chat activity object into an InteractiveTimelineObject.
 * If the actor is the authenticated user then we switch the actor with the
 * recipient so that it simplifies the logic in the presentational components
 */
export const mapActivityToChatListItem = (
  activityId: string | null | undefined,
  allActivities: Dictionary<ActivitiesEntity>,
  allObjects: Dictionary<ObjectsEntity>,
  allUsers: Dictionary<UsersEntity>,
  userId?: string,
  collectionId?: string,
): InteractiveTimelineObject | undefined => {
  if (!activityId) return;
  const activity = allActivities?.[activityId];

  if (
    !activity ||
    !activity.to ||
    activity.type !== ActivityStreamsActivityType.Create
  )
    return undefined;

  // !TODO: add a design for multiple recipients
  const to = activity.to[0] ?? 0;
  const actor =
    (allUsers?.[activity.actor] &&
      userEntityToPerson(allUsers[activity.actor])) ??
    allObjects?.[activity.actor];
  const object = allObjects?.[activity.object];
  const recipient = allUsers?.[to] && userEntityToPerson(allUsers[to]);

  // ! Currently, this prevents Activities without an Actor of type Person from showing
  if (
    !actor ||
    !object ||
    !recipient ||
    actor.type !== ActivityStreamsActorType.Person ||
    object.type !== ActivityStreamsObjectType.Note
  )
    return undefined;

  const today = DateTime.now();
  const publishedDateTime = DateTime.fromISO(object.published);
  const publishedDate = publishedDateTime.toLocaleString(DateTime.DATE_SHORT);
  const publishedTime = publishedDateTime.toLocaleString(DateTime.TIME_SIMPLE);
  const publishedShort =
    today.toLocaleString() === publishedDate ? publishedTime : publishedDate;
  const published =
    today.toLocaleString() === publishedDate
      ? publishedTime
      : `${publishedDate} ${publishedTime}`;

  return {
    id: activityId,
    isMyActivity: actor?.id === userId,
    to: recipient,
    actor,
    object: mapNoteToNoteContentTree(object),
    collectionId: collectionId?.replace(CollectionPrefix.CHAT, ''),
    published,
    publishedShort,
  };
};

/**
 * Get a collection ID.
 */
export const getCollectionId = (
  prefix: CollectionPrefix,
  suffix?: string,
): CollectionId => (suffix ? `${prefix}${suffix}` : prefix);

/**
 * Get a certain collection by prefix.
 */
export const getCollectionByPrefix = (
  entities: Dictionary<CollectionsEntity>,
  prefix: CollectionPrefix,
  suffix?: string,
): CollectionsEntity => {
  const id = getCollectionId(prefix, suffix);
  return entities?.[id] ?? { id, items: [], totalItems: 0 };
};

export const getActivitiesCollection = (
  collectionId: CollectionId,
  collection: ActivityStreamsCollectionPage,
  setTotal = true,
): ActivitiesCollection => ({
  id: collectionId,
  items: collection?.items?.filter(
    (activity): activity is ActivityStreamsActivity => !!activity,
  ),
  ...(setTotal ? { totalItems: collection?.totalItems ?? 0 } : {}),
  nextToken: collection?.nextToken,
});

export const triggerTimelineLoadAction = (
  action: Action,
  selector: Observable<CollectionsEntity>,
  filterValue: TimelineFilter,
) =>
  of(action).pipe(
    // We want to trigger every executions, because they can come in parallel
    concatMap(({ type }) =>
      selector.pipe(
        map((inbox) => ({ type, filterValue, inbox })),
        first(),
      ),
    ),
    map(({ type, filterValue, inbox }) => {
      const isRefresh = [
        HomePageActions.refreshForYou.type.toString(),
        HomePageActions.refreshFollowing.type.toString(),
      ].includes(type);

      const untilId =
        type === ActivitiesTimelineActions.scrollBottom.type
          ? inbox.nextToken ?? undefined
          : undefined;
      const sinceId = isRefresh ? inbox.items.at(0) : undefined;
      const props = {
        collectionId: inbox.id,
        sinceId,
        untilId,
        loading: type === HomePageActions.init.type,
        refreshing: isRefresh,
      };

      return filterValue === TimelineFilter.FOLLOWING
        ? ActivitiesEffectsActions.triggerFollowingTimelineLoad(props)
        : ActivitiesEffectsActions.triggerForYouTimelineLoad(props);
    }),
  );
