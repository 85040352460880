import { createSelector } from '@ngrx/store';
import chroma from 'chroma-js';
import { FavouritesSelectors } from '../../favourites';
import { portfolioReportsFeature } from '../../portfolios';

import {
  DividendDateFilter,
  SecuritiesType,
} from '@yeekatee/client-api-angular';
import { DateTime } from 'luxon';
import { ThemeSelectors } from '../../theme/index';
import {
  DividendCalendarEntity,
  DividendFilterOption,
  LocationFilter,
} from '../instruments.models';
import {
  InstrumentsState,
  selectInstrumentsState,
} from '../instruments.reducer';
import { FEATURE_KEY } from './dividends.reducer';

export const dividendsSliceSelector = createSelector(
  selectInstrumentsState,
  (state: InstrumentsState) => state[FEATURE_KEY],
);

export const selectLoading = createSelector(
  dividendsSliceSelector,
  (state) => state.loading,
);

export const selectDividendFilterOptions = createSelector(
  dividendsSliceSelector,
  (state) => {
    if (state.selectedSecurityType === SecuritiesType.ETF)
      return DividendFilterOption.ETF;
    if (state.selectedSecurityType === SecuritiesType.Stock)
      return DividendFilterOption.STOCK;
    if (state.selectedLocationFilter === LocationFilter.FAVOURITES)
      return DividendFilterOption.FAVOURITES;
    if (state.selectedLocationFilter === LocationFilter.PORTFOLIO)
      return DividendFilterOption.PORTFOLIO;
    return DividendFilterOption.ALL;
  },
);

export const selectDividendsCalendar = createSelector(
  dividendsSliceSelector,
  (state) => state.dividendCalendar,
);

export const selectLocationFilter = createSelector(
  dividendsSliceSelector,
  (state) => state.selectedLocationFilter,
);

export const selectDividendDateType = createSelector(
  dividendsSliceSelector,
  (state) => state.selectedDividendDateType,
);

export const selectSecuritiesTypeFilter = createSelector(
  dividendsSliceSelector,
  (state) => state.selectedSecurityType,
);

export const selectSelectedYear = createSelector(
  dividendsSliceSelector,
  (state) => state.selectedYear,
);

export const selectSelectedMonth = createSelector(
  dividendsSliceSelector,
  (state) => state.selectedMonth,
);

/**
 * The full calendar range is the current selected month plus the previous and next month.
 * This is needed to get the dividends for the previous and next month when the user swipes to the next month.
 * We precompute everything to have a smooth experience.
 */
export const selectFullCalendarRange = createSelector(
  selectSelectedYear,
  selectSelectedMonth,
  (selectedYear, selectedMonth) => ({
    from: DateTime.fromObject({
      year: selectedYear,
      month: selectedMonth,
      day: 1,
    })
      .minus({ month: 1 })
      .startOf('month')
      .toFormat('yyyy-MM-dd'),
    to: DateTime.fromObject({
      year: selectedYear,
      month: selectedMonth,
      day: 1,
    })
      .plus({ month: 1 })
      .endOf('month')
      .toFormat('yyyy-MM-dd'),
  }),
);

export const selectDividendApiParameters = createSelector(
  selectDividendDateType,
  selectFullCalendarRange,
  (dateFilter, { from, to }) => ({ dateFilter, from, to }),
);

/**
 * We know we should luxon, but since the selector is performance sensitive
 * and we do this comparison in a loop below we do it like this:
 * comparing strings is faster than comparing luxon objects.
 * We also don't have to think about memoization issues.
 */
export const selectSelectedDateFilter = createSelector(
  dividendsSliceSelector,
  (state) =>
    state.selectedDay
      ? `${state.selectedYear}-${state.selectedMonth
          .toString()
          .padStart(2, '0')}-${state.selectedDay.toString().padStart(2, '0')}`
      : undefined,
);

export const selectDividendsBySecurityType = createSelector(
  selectDividendsCalendar,
  selectSecuritiesTypeFilter,
  (dividendCalendar, securitiesFilter) =>
    securitiesFilter
      ? dividendCalendar.filter((a) => a.secType === securitiesFilter)
      : dividendCalendar,
);

const selectDividendsByFavourites = createSelector(
  selectDividendsCalendar,
  FavouritesSelectors.selectFavouriteInstrumentsIds,
  (dividendCalendar, favourites) => getCalEntries(dividendCalendar, favourites),
);

const selectDividendsPortfolio = createSelector(
  selectDividendsCalendar,
  portfolioReportsFeature.selectActiveReportItems,
  (dividendCalendar, positions) =>
    getCalEntries(
      dividendCalendar,
      positions.map((p) => p.instrument?.id ?? undefined),
    ),
);

const getCalEntries = (
  dividendCalendar: DividendCalendarEntity[],
  instruments: (string | undefined)[],
) =>
  instruments
    .map((i) => dividendCalendar.find((a) => a.uuid === i))
    .filter((e): e is DividendCalendarEntity => !!e);

export const selectDividendsByLocationFilter = createSelector(
  selectDividendsBySecurityType,
  selectLocationFilter,
  selectDividendsByFavourites,
  selectDividendsPortfolio,
  (dividendCalendar, locationFilter, favourites, portfolio) => {
    if (locationFilter === LocationFilter.FAVOURITES) return favourites;
    if (locationFilter === LocationFilter.PORTFOLIO) return portfolio;
    return dividendCalendar;
  },
);

const selectHeatmapColorScale = createSelector(
  ThemeSelectors.selectColors,
  (colors) => chroma.scale([colors!.light, colors!.primary]).mode('lab'),
);

export const selectDividendWithDateType = createSelector(
  selectDividendsByLocationFilter,
  selectDividendDateType,
  (dividendCalendar, dividendDateType) =>
    dividendCalendar.map((dce) => ({
      ...dce,
      selectedDate:
        dividendDateType === DividendDateFilter.ExDate
          ? dce.exDate
          : dce.payDate,
    })),
);

/**
 * Just the dividends that are currently displayed in the calendar.
 * This is different from the dividends in the 3-month range,
 * since the heatmap must be pre-computed for them too,
 * but the list shows only the dividends for the currently selected month.
 */
export const selectDividendsForDisplayedMonth = createSelector(
  selectDividendWithDateType,
  selectSelectedYear,
  selectSelectedMonth,
  (dividendCalendar, selectedYear, selectedMonth) =>
    dividendCalendar.filter((dce) => {
      const [year, month] = dce.selectedDate.split('-');
      return +year === selectedYear && +month === selectedMonth;
    }),
);

export const selectDisplayedMonth = createSelector(
  selectSelectedYear,
  selectSelectedMonth,
  (year, month) =>
    DateTime.fromObject({ year, month, day: 1 }).toFormat('yyyy-MM-dd'),
);

export const selectDividendsCountPerDay = createSelector(
  selectDividendWithDateType,
  (dividendCalendar) => {
    type FrequencyMap = Record<string, number>;
    type MonthMap = Record<string, FrequencyMap>;

    const months: MonthMap = {};

    for (const dce of dividendCalendar) {
      const [, month] = dce.selectedDate.split('-');
      months[month] = months[month] || {};
      months[month][dce.selectedDate] =
        (months[month][dce.selectedDate] || 0) + 1;
    }
    return months;
  },
);

export const selectDividendsCountPerDayForDisplayedMonth = createSelector(
  selectDividendsCountPerDay,
  selectSelectedMonth,
  (dividendCalendar, selectedMonth) => {
    const selectedMonthStr = selectedMonth.toString().padStart(2, '0');
    const month = dividendCalendar[selectedMonthStr];
    if (!month) return undefined;

    const monthList = Object.entries(month);

    return {
      total: monthList.reduce((acc, [, frequency]) => acc + frequency, 0),
      days: monthList.map(([date, frequency]) => ({ date, frequency })),
    };
  },
);

export const selectDividendHeatmap = createSelector(
  selectDividendsCountPerDay,
  selectHeatmapColorScale,
  (months, colorScale) => {
    type HeatmapEntry = {
      date: string;
      textColor: string;
      backgroundColor: string;
    };
    const heatmap: Array<HeatmapEntry> = [];

    for (const map of Object.values(months)) {
      const maxFrequency = Math.max(...Object.values(map));
      for (const [date, frequency] of Object.entries(map)) {
        const [backgroundColor, textColor] = getColorByFrequency(
          frequency,
          maxFrequency,
          colorScale,
        );
        heatmap.push({ date, textColor, backgroundColor });
      }
    }
    return heatmap;
  },
);

function getColorByFrequency(
  frequency: number,
  maxFrequency: number,
  colorScale: chroma.Scale,
) {
  const color = colorScale(frequency / maxFrequency).hex();
  const contrastColor =
    chroma.contrast(color, '#000000') > 4.5 ? '#000000' : '#ffffff';
  return [color, contrastColor];
}

export const selectDividendsList = createSelector(
  selectDividendsForDisplayedMonth,
  selectSelectedDateFilter,
  (dividendCalendar, selectedDate) =>
    selectedDate
      ? dividendCalendar
          .filter((dce) => dce.selectedDate === selectedDate)
          .sort(
            (a, b) =>
              new Date(a?.selectedDate).getTime() -
              new Date(b?.selectedDate).getTime(),
          )
      : undefined,
);
