import { BookingCcy, BookingMetal } from './booking-util-ccy';

/******************************************************************************
 ******************************************************************************
 * CURRENCIES
 *
 * DO NOT CHANGE !!!
 ******************************************************************************/
export const yeekateeConfigMetals: readonly BookingMetal[] = [
  'XAU', // feinunze gold / troy ounce 1oz = 31,1034768g
  'XAG', // feinunze silver / troy ounce 1oz = 31,1034768g
  'XPT', // feinunze platin / troy ounce 1oz = 31,1034768g
  'XPD', // feinunze paladium / troy ounce 1oz = 31,1034768g
] as const;

export const yeekateeConfigCcyIdx: Partial<Record<BookingCcy, number>> = {
  CHF: 0,
  EUR: 1,
  GBP: 2,
  CAD: 3,
  JPY: 4,
  CNY: 5,
  HKD: 6,
  SGD: 7,
  AUD: 8,
  AED: 9,
  KRW: 10,
  MXN: 11,
  DKK: 12,
  SEK: 13,
  NOK: 14,
  PLN: 15,
  HUF: 16,
  ZAR: 17,
  ILS: 18,
  ISK: 19,
  NZD: 20,
  RUB: 21,
  USD: undefined,
} as const;

export const yeekateeConfigCcyLength =
  Math.max(...Object.values(yeekateeConfigCcyIdx).filter(Boolean)) + 1;

export const yeekateeCcys: readonly BookingCcy[] = Object.keys(
  yeekateeConfigCcyIdx,
) as BookingCcy[];

/** @deprecated renamed to yeekateeCcys  */
export const itemCcy: readonly BookingCcy[] = yeekateeCcys;

/** @deprecated */
export const virtualCcy: readonly BookingCcy[] = [
  'CHF', // Swiss Franc
  'EUR', // Euro
  'USD', // US Dollar
  'GBP', // Pound Sterling
] as const;

/******************************************************************************
 ******************************************************************************
 * TRANSACTIONS
 ******************************************************************************/

/******************************************************************************
 * yeekatee transation type keys
 *
 * DO NOT CHANGE VALUES !!!
 ******************************************************************************/
export const enum TrxTypeKey {
  // money accounts
  InPayment = 'PI',
  OutPayment = 'PO',
  TransferMacc = 'TM',
  Forex = 'FX',
  /** @deprecated */ ForexHalf = 'FXH', // if exact match crebit/cedit was not possible

  // deposit money
  OpenDeposit = 'OD',
  IncreaseDeposit = 'ID',
  DecreaseDeposit = 'DD',
  CloseDeposit = 'CD',
  InterestDeposit = 'RD',

  // Futures
  VarMargin = 'VM',

  // securities by/sell/delivery
  Buy = 'B',
  BuyOpen = 'BO',
  BuyClose = 'BC',
  Sell = 'S',
  SellOpen = 'SO',
  SellClose = 'SC',

  DeliveryFi = 'DF',
  TransferFi = 'TF',

  // generic corporate actions
  Execution = 'EX',
  Redemption = 'RP',
  Expiry = 'EXP',
  CorpAction = 'CA',

  // cash returns for investment
  CashReturn = 'RC', // generic
  StockDividend = 'SD', // stock/scrip dividend, Stockdividende/Aktiendividende
  Bonus = 'BS', // bonus shares/Gratisaktien
  Dividend = 'DI',
  Distribution = 'DS',
  InterestMacc = 'RM',
  Retention = 'RT',

  // specific corporate actions
  CapitalCall = 'CC',
  Split = 'SP',
  SplitSwap = 'SW',
  Spinoff = 'SF',
  RightDistribution = 'RF',
  RightExecution = 'RX',
  Merger = 'ME',
  SwapFi = 'FS',
  Writeoff = 'WO',

  // Expenses of any kind
  ChargesMacc = 'CM', // charges based on macc, not used with flanks
  ChargesFi = 'CF', // charges based on investments

  // Expenses on account/portfolio
  GeneralExpenses = 'C0',
  AdvisoryFee = 'C1',
  CustodyFee = 'C2',

  // product open/close
  Open = 'OP', // not used with flanks
  Close = 'CL', // not used with flanks

  // unkonwn/reversal transaction
  Other = 'OTH',
  Delete = 'Del', // the transaction is deleted

  // technical cases
  Valuation = 'V',
  BalCheck = 'CHK', // target balances
  Init = 'INIT',

  // yeekatee
  GameReward = 'GR',
}

/******************************************************************************
 * yeekatee investment type keys
 *
 * DO NOT CHANGE VALUES !!!
 ******************************************************************************/
export const enum InvTypeKey {
  CashAccount = 'CA',
  SavingsAccount = 'SA',
  OtherMoneyAccount = 'OA',

  TermDeposit = 'TD',
  CallDeposit = 'CD',
  Deposit = 'DP', // Deposit, if not known whether it is call or term

  PreciousMetalAccount = 'PA', // as label just write metal account and omit precious

  CryptoCurrency = 'Y',

  ETF = 'E',
  Fund = 'F', // mutal fund

  Share = 'S',
  Right = 'R',
  VariableIncome = 'VI', // more generic than share

  Future = 'FT',
  Other = 'O',

  CFD = 'CFD', // contract for difference

  // to be implemented
  NonBankableAsset = 'NB',
  FixedIncome = 'FI',
  FXFWD = 'FXFWD',
  FWD = 'FWD',
  Derivate = 'D',
  Liability = 'L',

  // yeekatee
  YeekateeCoin = 'YC',
  GameReward = 'GR',
}

/******************************************************************************
 ******************************************************************************
 * TREES
 ******************************************************************************/
export const enum TreeKey {
  Currency = '901',
  InvType = '902',
}

/******************************************************************************
 ******************************************************************************
 * USER
 ******************************************************************************/
export const EXAMPLE_USER_ID = 'EXAMPLE01';
export const EXAMPLE_PRTF_01 = EXAMPLE_USER_ID + '#P01';
export const EXAMPLE_PRTF_02 = EXAMPLE_USER_ID + '#P02';

/******************************************************************************
 ******************************************************************************
 * DATES
 * pre defined book date ranges
 ******************************************************************************/
export const enum DateConst {
  BookMin = '2013-01-01',
  BookMax = '4999-12-31',
}

export const enum UnixDateConst {
  BookMin = 15_706,
  BookMax = 1_106_684,
}
