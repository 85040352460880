import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IonAvatar,
  IonChip,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
} from '@ionic/angular/standalone';
import { ENVIRONMENT, Environment } from '@yeekatee/shared-util-environment';
import {
  CountryCode,
  ParseError,
  PhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';
import { Country, countries } from '../country-code/countries';
import { CountryCodeComponent } from '../country-code/country-code.component';

type OnChangeFn = (phone: string) => void;
type OnTouchedFn = () => void;

@Component({
  selector: 'yeekatee-phone-input',
  templateUrl: './phone-input.component.html',
  standalone: true,
  imports: [
    CountryCodeComponent,
    IonAvatar,
    IonChip,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneInputComponent,
    },
  ],
})
export class PhoneInputComponent implements ControlValueAccessor {
  @ViewChild('phoneInput') phoneInput?: IonInput;
  @ViewChild('countryCodeModal') countryCodeModal?: IonModal;

  private phoneNumber?: PhoneNumber;

  private touched = false;
  protected disabled = false;

  protected country?: Country;
  protected value?: string;

  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment) {
    this.updateCountryWithIsoCode(this.environment.defaultCountryIso);
  }

  private onChange: OnChangeFn = () => {};
  private onTouched: OnTouchedFn = () => {};

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(phone: string): void {
    this.parseInput(phone);
  }

  protected onInput() {
    const value = (this.phoneInput?.value as string) ?? '';
    this.parseInput(value);
  }

  protected onCountryCodeSelect(countryCode: Country) {
    this.updateCountryWithIsoCode(countryCode.isoCode);
    const previousInput = this.phoneNumber?.formatNational();
    if (previousInput) this.parseInput(previousInput);
    this.countryCodeModal?.dismiss();
  }

  private parseInput(input: string) {
    const defaultCountry = this.country?.isoCode as CountryCode;

    try {
      this.phoneNumber = parsePhoneNumberWithError(input, {
        defaultCountry,
      });
      this.updateCountry();
    } catch (e) {
      if (e instanceof ParseError) {
        this.phoneNumber = undefined;
      } else {
        throw e;
      }
    }

    this.updateFormControl();
    this.refreshDisplay();
  }

  /**
   * Refresh the ion-input value to show the formatted phone number.
   *
   * @private
   */
  private refreshDisplay() {
    this.value = this.phoneNumber?.formatInternational();
  }

  /**
   * Dispatch the phone number to the form control, in E.164 format.
   * @private
   */
  private updateFormControl() {
    this.markAsTouched();
    this.onChange(this.phoneNumber?.number ?? '');
  }

  /**
   * Updates the country based on the current phone number.
   * When user is typing, the dial code can update after only a couple of chars.
   * The ISO code instead will only change once the number is valid,
   * but it's more precise.
   *
   * @private
   */
  private updateCountry() {
    if (this.phoneNumber?.country) {
      this.updateCountryWithIsoCode(this.phoneNumber.country);
    } else {
      this.updateCountryWithCallingCode(
        `+${this.phoneNumber?.countryCallingCode}`,
      );
    }
  }

  /**
   * Updates the country with the given ISO code, if available.
   *
   * @param isoCode
   * @private
   */
  private updateCountryWithIsoCode(isoCode?: string) {
    if (!isoCode) return;
    if (isoCode === this.country?.isoCode) return;
    this.country = countries.find((country) => country.isoCode === isoCode);
  }

  /**
   * Updates the country with the given calling code, if available.
   *
   * @param callingCode
   * @private
   */
  private updateCountryWithCallingCode(callingCode?: string) {
    if (!callingCode) return;
    if (callingCode === this.country?.dialCode) return;
    this.country = countries.find(
      (country) => country.dialCode === callingCode,
    );
  }
}
