import { createActionGroup, props } from '@ngrx/store';
import { NavbarTabType } from './navbar.models';

export const BottomNavigationActions = createActionGroup({
  source: 'Bottom Navigation',
  events: {
    Init: props<{ routeUrl: string }>(),
    'Change Tab': props<{ tab: NavbarTabType }>(),
  },
});

export const SideNavigationActions = createActionGroup({
  source: 'Side Navigation',
  events: {
    'Change Tab': props<{ tab: NavbarTabType }>(),
    'Click Logo': props<{ tab: NavbarTabType }>(),
  },
});
