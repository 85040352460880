import { createFeature, createSelector } from '@ngrx/store';
import { EXAMPLE_USER_ID } from '@yeekatee/booking-util-definitions';
import { UsersSelectors } from '../../users';
import { adapter, name, reducer } from './user-properties.reducer';

export const userPropertiesFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectUserPropertiesState, selectEntities }) => {
    // If the user is not authenticated, use the example user id from the booking util definitions
    const selectUserOrExampleId = createSelector(
      UsersSelectors.selectAuthenticatedUser,
      (user) => user?.id ?? EXAMPLE_USER_ID,
    );

    const selectUserProperties = createSelector(
      selectEntities,
      selectUserOrExampleId,
      (entities, userId) => (userId ? entities?.[userId] : undefined),
    );

    const selectUserRepCurrency = createSelector(
      selectUserProperties,
      (properties) => properties?.reportCurrency ?? undefined,
    );

    const selectUserValCurrencies = createSelector(
      selectUserProperties,
      (properties) =>
        properties?.valuationCurrencies ?? ['CHF', 'EUR', 'GBP', 'USD'],
    );

    const selectPortfoliosBanksSynching = createSelector(
      selectUserProperties,
      (properties) => properties?.banksSynching ?? [],
    );

    const arePortfoliosSynching = createSelector(
      selectUserProperties,
      (properties) => !!properties?.banksSynching?.length,
    );

    const selectUserCurrencies = createSelector(
      selectUserRepCurrency,
      selectUserValCurrencies,
      (repCurrency, valCurrencies) =>
        !!repCurrency && !!valCurrencies
          ? {
              repCurrency,
              valCurrencies,
            }
          : undefined,
    );

    return {
      ...adapter.getSelectors(selectUserPropertiesState),
      selectUserOrExampleId,
      selectUserProperties,
      selectUserRepCurrency,
      selectUserValCurrencies,
      selectPortfoliosBanksSynching,
      arePortfoliosSynching,
      selectUserCurrencies,
    };
  },
});
