import { createSelector } from '@ngrx/store';
import { AccountLinkingSelectors } from '../account-linking';
import { LocalesSelectors } from '../locales';
import { userPropertiesFeature } from '../portfolios';
import { ThemeSelectors } from '../theme';

export const selectCallbackViewModel = createSelector(
  AccountLinkingSelectors.selectAccountLinkingState,
  AccountLinkingSelectors.selectCallbackParams,
  AccountLinkingSelectors.selectSelectedBank,
  (state, callbackParams, selectedBank) => ({
    loading: state.loading,
    exchangeError: state.error,
    callbackError: callbackParams.error,
    callbackDelayed: callbackParams.delayed,
    selectedBank,
  }),
);

export const selectInitViewModel = createSelector(
  AccountLinkingSelectors.selectSupportedBanks,
  AccountLinkingSelectors.selectBankRequestModalState,
  userPropertiesFeature.selectPortfoliosBanksSynching,
  (banks, bankRequestModalState, banksSynching) => ({
    banks: banks.map((b) => ({
      ...b,
      synching: banksSynching.indexOf(b.id) > -1,
    })),
    bankRequestModalState,
  }),
);

export const selectLinkingInfoViewModel = createSelector(
  AccountLinkingSelectors.selectCurrentBankFlanks,
  LocalesSelectors.getLandingPageLanguage,
  ThemeSelectors.selectThemeMode,
  (bank, language, mode) => ({
    bank,
    language,
    imgSrc: `./assets/icon/account-linking-yfb-${mode}.svg`,
  }),
);

export const selectCredentialsViewModel = createSelector(
  AccountLinkingSelectors.selectAccountLinkingState,
  AccountLinkingSelectors.selectCurrentBankFlanks,
  (state, bank) => ({
    loading: state.loading,
    bank,
    pendingSca: state.pendingSca,
    scaState: state.scaState,
  }),
);
