import { createAction, props } from '@ngrx/store';
import { AvailableLocale } from './locales.models';

/**
 * The localization initializer shall dispatch this action,
 * with the locale selected during the initialization.
 */
export const loadLocaleSuccess = createAction(
  '[Locales Initializer] Load Locales Success',
  props<{ loadedLocale: AvailableLocale; useDeviceLocale: boolean }>(),
);

export const loadLocaleFailure = createAction(
  '[Locales Initializer] Load Locales Failure',
  props<{ error: unknown }>(),
);

export const languageSettingsSave = createAction(
  '[Settings Language] Save',
  props<{ locale: AvailableLocale }>(),
);

export const setPreferredLocale = createAction(
  '[Settings Language] Set Preferred Locale',
  props<{ locale: AvailableLocale; reload: boolean }>(),
);
