import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { renderGrid } from '@giphy/js-components';
import { GifsResult, GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  ModalController,
  ViewDidEnter,
} from '@ionic/angular/standalone';
import { ENVIRONMENT, Environment } from '@yeekatee/shared-util-environment';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'yeekatee-gif-selector',
  standalone: true,
  imports: [
    CloseButtonComponent,

    IonButtons,
    IonContent,
    IonHeader,
    IonSearchbar,
    IonTitle,
    IonToolbar,
  ],
  templateUrl: './gif-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GifSelectorComponent implements AfterViewInit, ViewDidEnter {
  @ViewChild('container', { static: true }) container?: ElementRef;
  @ViewChild(IonSearchbar, { static: true }) searchbar?: IonSearchbar;

  private gf = new GiphyFetch(this.environment.giphyDataKey);
  private removeCallback?: () => void;

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly modalCtrl: ModalController,
  ) {}

  async ionViewDidEnter(): Promise<void> {
    await this.searchbar?.setFocus();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.search(), 100);
  }

  protected async searchGif(event: Event) {
    const searchText = (event as CustomEvent).detail.value;
    this.search(searchText);
  }

  private async selectGif(gif: string) {
    return await this.modalCtrl.dismiss(gif, 'select');
  }

  protected async cancel() {
    return await this.modalCtrl.dismiss(null, 'cancel');
  }

  private search(text?: string) {
    if (!this.container) return;

    const fetchGifs = (offset: number) =>
      text
        ? this.gf.search(text, { sort: 'recent', offset, limit: 15 })
        : this.gf.trending({ offset, limit: 15 });

    this.removeCallback?.call(null);
    this.removeCallback = this.render(this.container.nativeElement, fetchGifs);
  }

  private render(
    mountNode: HTMLElement,
    fetchGifs: (offset: number) => Promise<GifsResult>,
  ) {
    return renderGrid(
      {
        hideAttribution: true,
        user: {},
        fetchGifs,
        gutter: 6,
        noLink: true,
        onGifClick: (gif: IGif) => {
          this.selectGif(gif.images.fixed_width_downsampled.url);
        },
        width: mountNode.offsetWidth,
        columns: mountNode.offsetWidth > 500 ? 3 : 2,
      },
      mountNode,
    );
  }
}
