@if (userId) {
  <ion-item
    class="item-legacy"
    [routerLink]="
      noRouting
        ? []
        : [baseRoute ?? defaultBaseRoute, NavigationRouteNames.USERS, userId]
    "
    [button]="true"
  >
    <ion-avatar slot="start">
      <yeekatee-avatar [src]="picture" />
    </ion-avatar>
    <ion-label class="ion-text-nowrap">
      <h3>&#64;{{ username }}</h3>
      @if (name) {
        <p>{{ name }}</p>
      } @else if (location) {
        <p>{{ location }}</p>
      }
      <ng-content select="[labelParagraph]" />
    </ion-label>

    <ng-content />
  </ion-item>
} @else {
  <yeekatee-item-skeleton />
}
