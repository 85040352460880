import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  IonAvatar,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { Observable, map, startWith } from 'rxjs';
import { CloseButtonComponent } from '../close-button/close-button.component';
import { FlagEmojiPipe } from '../pipes/flag-emoji.pipe';
import { Country, CountryList, countries } from './countries';

enum PromotedCountries {
  CH = 'CH',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
}

@Component({
  selector: 'yeekatee-country-code',
  standalone: true,
  imports: [
    AsyncPipe,
    CloseButtonComponent,
    FlagEmojiPipe,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonSearchbar,
    IonText,
    IonTitle,
    IonToolbar,
    IonChip,
    IonAvatar,
    ReactiveFormsModule,
  ],
  templateUrl: './country-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryCodeComponent {
  @Output() countryCodeSelect = new EventEmitter<Country>();
  @Output() cancel = new EventEmitter<void>();

  protected countryList$: Observable<CountryList>;
  protected searchQuery = new FormControl('');
  protected readonly top = 'Top';

  constructor() {
    this.countryList$ = this.searchQuery.valueChanges.pipe(
      startWith(''),
      map((query) => this.refreshList(query)),
    );
  }

  protected getKeys(countryList: CountryList): string[] {
    return Object.keys(countryList);
  }

  /**
   * Returns a filtered list where the countries are grouped by their first letter.
   * @param query
   * @private
   */
  private refreshList(query: string | null = '') {
    let letter = '0';
    const countryList = { [this.top]: [] } as CountryList;
    countries
      .filter(
        (country) =>
          country
            .name()
            .toLowerCase()
            .indexOf(query?.toLowerCase() ?? '') > -1,
      )
      .forEach((country) => {
        const countryFirstLetter =
          country.isoCode in PromotedCountries ? this.top : country.name()[0];
        if (letter !== countryFirstLetter) {
          letter = countryFirstLetter;
          if (!countryList[letter]?.length) countryList[letter] = [];
        }
        countryList[letter].push(country);
      });
    return countryList;
  }
}
