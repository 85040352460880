import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RankingType } from '@yeekatee/booking-util-definitions';
import {
  GameCompetition,
  GamePlayer,
  LocalisedString,
} from '@yeekatee/client-api-angular';
import { GameAssetTypeSchema } from '@yeekatee/instruments-util-discover';
import { errorProps } from '../utils';
import { GameQuizEntity } from './games.models';

/**
 * The responses coming from the games effects.
 */
export const GamesEffectsActions = createActionGroup({
  source: 'Games Effects',
  events: {
    'Copy Game Url': props<{ url: string }>(),
    'Share Game Url': props<{ url: string }>(),
    'Game Url Copied': emptyProps(),
    'Notify Error': errorProps(),
  },
});

/**
 * Actions triggered from the games list component.
 */
export const GamesListActions = createActionGroup({
  source: 'Games List Component',
  events: {
    Init: emptyProps(),
    Refresh: emptyProps(),
    'Redirect To Auth': props<{ lastRoute: string }>(),
  },
});

/**
 * Actions triggered from the game ranking component.
 */
export const GameRankingActions = createActionGroup({
  source: 'Game Ranking Component',
  events: {
    Init: props<{
      gameId: string;
    }>(),
    Refresh: props<{
      gameId: string;
    }>(),
    'Set Ranking Type': props<{
      rankingType: RankingType;
    }>(),
  },
});

/**
 * Actions triggered from the game player item component.
 */
export const GamePlayerItemActions = createActionGroup({
  source: 'Game Player Item Component',
  events: {
    'Load User': props<{
      userId: string;
    }>(),
  },
});

/**
 * Actions triggered from the game overview component.
 */
export const GameOverviewActions = createActionGroup({
  source: 'Game Overview Component',
  events: {
    Load: props<{
      gameId: string;
    }>(),
    'Join Game': props<{
      gameId: string;
    }>(),
    'Share Game': props<{
      gameId: string;
    }>(),
  },
});

/**
 * Actions triggered from the game quizzes component.
 */
export const GameQuizzesActions = createActionGroup({
  source: 'Game Quizzes Component',
  events: {
    Init: props<{
      gameId: string;
    }>(),
    'Change Date': props<{
      selectedDate: string;
    }>(),
  },
});

/**
 * Actions triggered from the game quiz component.
 */
export const GameQuizActions = createActionGroup({
  source: 'Game Quiz Component',
  events: {
    Init: props<{
      quizId: string;
      gameId: string;
    }>(),
    'Answer Quiz': props<{
      id: string;
      answers: string[];
    }>(),
  },
});

/**
 * Actions triggered from the games assets component.
 */
export const GamesAssetsActions = createActionGroup({
  source: 'Games Assets Component',
  events: {
    Search: props<{
      query?: string;
    }>(),
    Filter: props<{
      filter: GameAssetTypeSchema;
    }>(),
    'Clear Search': emptyProps(),
    Destroy: emptyProps(),
  },
});

/**
 * The responses coming from the games backend.
 */
export const GamesApiActions = createActionGroup({
  source: 'Games API',
  events: {
    'Join Game Success': props<{
      portfolioKey: string;
      gameId: string;
    }>(),
    'Join Game Failure': errorProps(),

    'Load Game Competitions Success': props<{
      competitions: GameCompetition[];
    }>(),
    'Load Game Competitions Failure': errorProps(),

    'Load Game Competition Success': props<{
      competition: GameCompetition;
    }>(),
    'Load Game Competition Failure': errorProps(),

    'Load Game Rankings Success': props<{
      competition: GameCompetition;
    }>(),
    'Load Game Rankings Failure': errorProps(),

    'Load Game Player Success': props<{
      player: GamePlayer;
    }>(),
    'Load Game Player Failure': errorProps(),

    'Load Open Quizzes Success': props<{
      quizzes: GameQuizEntity[];
    }>(),
    'Load Open Quizzes Failure': errorProps(),

    'Load Game Quizzes Success': props<{
      quizzes: GameQuizEntity[];
    }>(),
    'Load Game Quizzes Failure': errorProps(),

    'Load Game Quiz Success': props<{
      quiz: GameQuizEntity;
    }>(),
    'Load Game Quiz Failure': errorProps(),

    'Answer Game Quiz Success': props<{
      id: string;
      correctAnswers: string[];
      explanation?: LocalisedString;
      userAnswers: string[];
      userAnswerDate: string;
      reward?: number;
    }>(),
    'Answer Game Quiz Failure': errorProps(),
  },
});
