import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IonIcon,
  IonLabel,
  IonTitle,
  isPlatform,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { chevronDownOutline } from 'ionicons/icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonIcon, IonLabel, IonTitle, NgClass],
  selector: 'yeekatee-title',
  standalone: true,
  templateUrl: './title.component.html',
})
export class TitleComponent {
  @Input() title?: string;
  @Input() button: BooleanInput = false;

  @Output() titleClicked = new EventEmitter<void>();

  protected readonly isIos = isPlatform('ios');

  constructor() {
    addIcons({ chevronDownOutline });
  }
}
