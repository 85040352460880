<ion-item>
  <ion-chip slot="start" (click)="countryCodeModal.present()" role="button">
    <ion-avatar
      ><img
        [alt]="country?.isoCode"
        [src]="'/assets/flags/1x1/' + country?.isoCode + '.svg'"
    /></ion-avatar>
    <ion-label>{{ country?.dialCode }}</ion-label>
  </ion-chip>
  <ion-input
    #phoneInput
    name="phone_number"
    label="Phone number"
    labelPlacement="floating"
    placeholder="Enter your phone number"
    i18n-label
    i18n-placeholder
    type="tel"
    required="true"
    (ionInput)="onInput()"
    [disabled]="disabled"
    [value]="value"
  />
</ion-item>

<ion-modal #countryCodeModal>
  <ng-template>
    <yeekatee-country-code
      (cancel)="countryCodeModal.dismiss()"
      (countryCodeSelect)="onCountryCodeSelect($event)"
      class="ion-page"
    />
  </ng-template>
</ion-modal>
