import { z } from 'zod';
import {
  Action,
  ActionBrowser,
  ActionDispatch,
  ActionNavigate,
  ActionStore,
  actionSchema,
} from './actions';
import { ruleSchema } from './rules';
import { localizedString } from './string';

export const imageSchema = z.object({
  src: z.string().url(),
  alt: z.string(),
  width: z.number().int().positive(),
  height: z.number().int().positive(),
});

/**
 * The Launch Modal is used to show a message to users in exceptional cases.
 * For example, to inform them about a new feature or a change in the app.
 * The modal may require the user to perform an action.
 * Rules can be used to target the modal,
 * e.g. only show it after/before a certain date,
 * only to Android or iOS users, only for a certain version of the app, etc.
 * Messages must be localized and have a value for each supported language.
 * The user can dismiss the modal. This means that it will not be shown again.
 */
export const launchModalSchemav1 = z.object({
  version: z.literal(1),
  id: z.string().ulid(),
  image: imageSchema.optional(),
  title: localizedString.optional(),
  description: localizedString.optional(),
  rules: ruleSchema.optional(), // ? Should we make this required?
  actions: z.array(actionSchema).optional(),
});

// Once we have more than one version of the schema, use z.union to combine them
// export const launchModalSchema = z.union([launchModalSchemav1, ...]);
export const launchModalSchema = launchModalSchemav1;
export type LaunchModalV1 = z.infer<typeof launchModalSchemav1>;
export type LaunchModal = z.infer<typeof launchModalSchema>;

/**
 * Multiple modals can be configured at once.
 * This is useful for example in case we have different messages to convey
 * to users of a previous or current app version, or for different platforms.
 */
export const launchModalsConfigv1 = z.object({
  version: z.literal(1),
  modals: z.array(launchModalSchemav1),
});

export const launchModalsConfig = launchModalsConfigv1;
export type LaunchModalsConfigV1 = z.infer<typeof launchModalsConfigv1>;
export type LaunchModalsConfig = z.infer<typeof launchModalsConfig>;
export type LaunchModalAction = Action;
export type LaunchModalActionBrowser = ActionBrowser;
export type LaunchModalActionDispatch = ActionDispatch;
export type LaunchModalActionStore = ActionStore;
export type LaunchModalActionNavigate = ActionNavigate;
