import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonIcon, IonItem, IonLabel } from '@ionic/angular/standalone';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import { addIcons } from 'ionicons';
import { flameOutline } from 'ionicons/icons';
import { FormatNumberPipe } from '../pipes/format-number.pipe';

@Component({
  selector: 'yeekatee-game-banner',
  standalone: true,
  imports: [IonIcon, IonItem, IonLabel, RouterLink, FormatNumberPipe],
  templateUrl: './game-banner.component.html',
})
export class GameBannerComponent {
  @Input() gameId?: string;
  protected readonly NavigationRouteNames = NavigationRouteNames;

  constructor() {
    addIcons({
      flameOutline,
    });
  }
}
