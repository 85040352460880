import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonProgressBar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { User } from '@yeekatee/client-api-angular';
import { ENVIRONMENT, Environment } from '@yeekatee/shared-util-environment';
import {
  NavigationRouteNames,
  extractBaseRoute,
} from '@yeekatee/shared-util-routes';
import { addIcons } from 'ionicons';
import { chatbubbleEllipsesOutline } from 'ionicons/icons';
import { AvatarComponent, AvatarSize } from '../avatar/avatar.component';
import { LogoComponent } from '../logo/logo.component';
import { TitleComponent } from '../title/title.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AvatarComponent,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonSpinner,
    IonProgressBar,
    LogoComponent,
    RouterLink,
    RouterModule,
    TitleComponent,
    IonIcon,
  ],
  selector: 'yeekatee-header',
  standalone: true,
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() user?: User;
  @Input() title?: string;
  @Input() loading?: boolean;
  @Input() clickableTitle = false;

  @Output() titleClicked = new EventEmitter<void>();
  @Output() routedToAuth = new EventEmitter<void>();

  protected readonly NavigationRouteNames = NavigationRouteNames;
  readonly baseRoute = extractBaseRoute(this.router.url);

  constructor(
    private readonly router: Router,
    @Inject(ENVIRONMENT) protected readonly environment: Environment,
  ) {
    addIcons({ chatbubbleEllipsesOutline });
  }

  protected readonly AvatarSize = AvatarSize;

  protected routeToAuth(shouldRoute: boolean): void {
    shouldRoute && this.routedToAuth.emit();
  }
}
