import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  ActivityStreamsActivity,
  ActivityStreamsObjectType,
} from '@yeekatee/client-api-angular';
import * as ActivitiesActions from '../actions';
import { ChatEffectsActions, ChatListActions } from '../actions';
import {
  ActivitiesAssetFeedViewActions,
  ActivitiesEffectsActions,
  ActivitiesNoteViewActions,
  ActivitiesUserViewActions,
} from '../actions/activities-groups.actions';
import { ActivitiesEntity } from '../activity-streams.models';

export const FEATURE_KEY = 'activities';

export interface State extends EntityState<ActivitiesEntity> {
  loading?: boolean;
  chatsLoading?: boolean;
}

export const adapter: EntityAdapter<ActivitiesEntity> =
  createEntityAdapter<ActivitiesEntity>();

export const initialState: State = adapter.getInitialState({
  // TODO: to be moved to the collections
  loading: false,
  chatsLoading: false,
});

const activitiesReducer = createReducer(
  initialState,
  on(
    ActivitiesUserViewActions.initialLoad,
    ActivitiesAssetFeedViewActions.initialLoad,
    ActivitiesNoteViewActions.initialLoad,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    ChatListActions.init,
    ChatEffectsActions.loadChatRoom,
    (state): State => ({
      ...state,
      chatsLoading: true,
    }),
  ),
  on(
    ActivitiesActions.loadPersonInboxCollectionSuccess,
    ActivitiesActions.loadPersonInboxCollectionFailure,
    ActivitiesActions.loadPersonOutboxCollectionSuccess,
    ActivitiesActions.loadPersonOutboxCollectionFailure,
    ActivitiesActions.loadAssetSharedInboxCollectionSuccess,
    ActivitiesActions.loadAssetSharedInboxCollectionFailure,
    ActivitiesActions.loadNotesRepliesCollectionSuccess,
    ActivitiesActions.loadNotesRepliesCollectionFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    ActivitiesActions.loadChatCollectionSuccess,
    ActivitiesActions.loadChatCollectionFailure,
    ChatEffectsActions.emptyChatList,
    (state): State => ({
      ...state,
      chatsLoading: false,
    }),
  ),
  on(
    ActivitiesActions.loadPersonInboxCollectionSuccess,
    ActivitiesActions.loadPersonOutboxCollectionSuccess,
    ActivitiesActions.loadAssetSharedInboxCollectionSuccess,
    ActivitiesActions.loadPersonLikedCollectionSuccess,
    ActivitiesActions.loadNotesLikesCollectionSuccess,
    ActivitiesActions.loadNotesRepliesCollectionSuccess,
    ActivitiesActions.loadPersonFollowingCollectionSuccess,
    ActivitiesActions.loadPersonFollowersCollectionSuccess,
    ActivitiesActions.loadChatCollectionSuccess,
    (state, collection): State =>
      collection.items
        ? adapter.upsertMany(extractActivitiesEntity(collection.items), state)
        : state,
  ),
  on(
    ActivitiesActions.apiUnlikeNote,
    ActivitiesActions.apiUnfollowUser,
    (state, { activity }): State =>
      activity.object?.id
        ? adapter.removeOne(activity.object.id, state)
        : state,
  ),
  on(
    ActivitiesActions.apiCreateNote,
    ActivitiesActions.apiLikeNote,
    ActivitiesActions.followUserResult,
    ActivitiesActions.apiDeleteNote,
    ActivitiesEffectsActions.handleNotification,
    (state, { activity }): State => {
      const thinActivity = castApiActivityToStoreThinActivity(activity);
      return thinActivity ? adapter.upsertOne(thinActivity, state) : state;
    },
  ),
  on(
    ActivitiesActions.apiGetActivitySuccess,
    (state, { activity, parent }): State => {
      const thinActivity =
        activity && castApiActivityToStoreThinActivity(activity);
      thinActivity && (thinActivity.parent = parent?.id ?? undefined);
      const newState = thinActivity
        ? adapter.upsertOne(thinActivity, state)
        : state;

      const thinParent = parent && castApiActivityToStoreThinActivity(parent);
      return thinParent ? adapter.upsertOne(thinParent, newState) : newState;
    },
  ),
  on(
    // Handle separately to avoid showing twice accept / reject modal
    ActivitiesEffectsActions.handleFollowNotification,
    (state, { activity }): State => {
      const existingActivity = activity.id
        ? state.entities[activity.id]
        : undefined;
      if (existingActivity?.result === ActivityStreamsObjectType.Accept)
        return state;

      const thinActivity = castApiActivityToStoreThinActivity(activity);
      return thinActivity ? adapter.upsertOne(thinActivity, state) : state;
    },
  ),
  on(
    ActivitiesActions.apiAcceptFollow,
    ActivitiesEffectsActions.handleAcceptFollowNotification,
    (state, { activity }): State => {
      const storeEntity = activity.object?.id
        ? state.entities[activity.object.id]
        : undefined;
      const thinActivity = storeEntity
        ? { ...storeEntity, result: ActivityStreamsObjectType.Accept }
        : undefined;
      return thinActivity ? adapter.upsertOne(thinActivity, state) : state;
    },
  ),
  on(ActivitiesActions.apiRejectFollow, (state, { activity }): State => {
    const storeEntity = activity.object?.id
      ? state.entities[activity.object.id]
      : undefined;
    const thinActivity = storeEntity
      ? { ...storeEntity, result: ActivityStreamsObjectType.Reject }
      : undefined;
    return thinActivity ? adapter.upsertOne(thinActivity, state) : state;
  }),
);

/**
 * Activities in the store are kept as *thin activities*,
 * this means that the plain objects are part of the objects sub-store.
 * The activities are mere pointers to an actor and object in the store.
 *
 * @param activities as they come from the API
 */
const extractActivitiesEntity = (
  activities: ActivityStreamsActivity[],
): ActivitiesEntity[] =>
  activities
    .map((activity) => castApiActivityToStoreThinActivity(activity))
    .filter((activity): activity is ActivitiesEntity => !!activity);

/**
 * Convert a single API Activity into a Thin Activity,
 * made of string references to the actor and object.
 *
 * @param activity
 */
const castApiActivityToStoreThinActivity = (
  activity: ActivityStreamsActivity,
): ActivitiesEntity | undefined => {
  if (
    !activity ||
    !activity.id ||
    !activity.type ||
    !activity.object?.id ||
    !activity.actor?.id
  )
    return undefined;

  return {
    type: activity.type,
    id: activity.id,
    to: activity.to,
    object: activity.object.id,
    actor: activity.actor.id,
    result: activity.result?.type ?? undefined,
  };
};

export function reducer(state: State | undefined, action: Action) {
  return activitiesReducer(state, action);
}
