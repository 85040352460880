import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TimeSeriesPeriod } from '@yeekatee/client-api-angular';
import {
  PortfolioItemActions,
  PortfoliosAddTransactionActions,
} from '../../portfolios';
import {
  DiscoverInstrumentCategoryViewActions,
  DiscoverViewActions,
  InstrumentsApiActions,
  InstrumentsEffectsActions,
  InstrumentViewActions,
} from '../instruments.actions';
import {
  InstrumentsEntity,
  SortingCategory,
  SortingCategoryKey,
  SortingType,
} from '../instruments.models';

const DEFAULT_TIMESERIES_PERIOD = TimeSeriesPeriod.year;

export const FEATURE_KEY = 'instruments';

export interface State extends EntityState<InstrumentsEntity> {
  loadingInstrument: boolean;
  loadingTimeSeries: boolean; //@TODO the loading should be implemented on the TimeSeriesEntity level
  loadingQuote: boolean; //@TODO should be removed, now that we have the loading on the QuotesEntity
  loadingDiscoverOverview: boolean;
  showListView: boolean;
  sortingType: SortingType;
  sortingCategory: SortingCategory;
  selectedTimeSeries: TimeSeriesPeriod;
  discoverOverviewAssets: Record<string, InstrumentsEntity[]>;
}

export const instrumentsAdapter: EntityAdapter<InstrumentsEntity> =
  createEntityAdapter<InstrumentsEntity>();

export const initialState: State = instrumentsAdapter.getInitialState({
  // set initial required properties
  loadingInstrument: false,
  loadingTimeSeries: false,
  loadingQuote: false,
  loadingDiscoverOverview: false,
  selectedTimeSeries: DEFAULT_TIMESERIES_PERIOD,
  showListView: true,
  discoverOverviewAssets: {},
  sortingType: SortingType.AtoZ,
  sortingCategory: {
    [SortingCategoryKey.STOCK]: SortingType.AtoZ,
    [SortingCategoryKey.ETF]: SortingType.AtoZ,
    [SortingCategoryKey.CRYPTO]: SortingType.AtoZ,
    [SortingCategoryKey.CURRENCY]: SortingType.AtoZ,
    [SortingCategoryKey.METAL]: SortingType.AtoZ,
    [SortingCategoryKey.COMMODITY]: SortingType.AtoZ,
    [SortingCategoryKey.INDEX]: SortingType.AtoZ,
    [SortingCategoryKey.FAVOURITES]: SortingType.AtoZ,
  },
});

export const reducer = createReducer(
  initialState,
  on(
    InstrumentViewActions.showInstrument,
    PortfolioItemActions.showPortfolioItem,
    PortfoliosAddTransactionActions.loadInstrument,
    (state: State) => ({
      ...state,
      loadingInstrument: true,
    }),
  ),
  on(InstrumentsEffectsActions.listingOrPeriodChanged, (state: State) => ({
    ...state,
    loadingInstrument: true,
    loadingTimeSeries: true,
    loadingQuote: true,
  })),
  on(InstrumentViewActions.periodChanged, (state: State, { period }) => ({
    ...state,
    selectedTimeSeries: period,
    loadingTimeSeries: true,
  })),
  on(
    InstrumentsApiActions.loadInstrumentsSuccess,
    (state: State, { instruments }) =>
      instrumentsAdapter.upsertMany(instruments, state),
  ),
  on(
    InstrumentsApiActions.loadDiscoverOverviewAssetsSuccess,
    (state, { instrumentsOverview }) => {
      const allInstruments = Object.values(instrumentsOverview).flat();
      return instrumentsAdapter.upsertMany(allInstruments, {
        ...state,
        discoverOverviewAssets: instrumentsOverview,
        loadingDiscoverOverview: false,
      });
    },
  ),
  on(InstrumentsApiActions.loadQuoteSuccess, (state: State) => ({
    ...state,
    loadingQuote: false,
  })),
  on(InstrumentsApiActions.loadQuoteFailure, (state: State) => ({
    ...state,
    loadingQuote: false,
  })),
  on(
    InstrumentsApiActions.loadTimeSeriesSuccess,
    (state: State, { period, timeSeries }) => ({
      ...state,
      selectedTimeSeries: timeSeries?.period ?? period,
      loadingTimeSeries: false,
    }),
  ),
  on(
    InstrumentsApiActions.loadInstrumentFailure,
    InstrumentsApiActions.loadTimeSeriesFailure,
    (state) => ({
      ...state,
      loadingInstrument: false,
      loadingTimeSeries: false,
      loadingQuote: false,
    }),
  ),
  on(
    InstrumentsApiActions.loadInstrumentSuccess,
    InstrumentsApiActions.loadSingleInstrumentSuccess,
    (state: State, { instrument }) =>
      instrument
        ? instrumentsAdapter.upsertOne(instrument, {
            ...state,
            loadingInstrument: false,
          })
        : {
            ...state,
            loadingInstrument: false,
          },
  ),
  on(InstrumentsApiActions.searchInstrumentsSuccess, (state, { instruments }) =>
    instrumentsAdapter.upsertMany(instruments, state),
  ),
  on(InstrumentViewActions.listingChanged, (state: State) => ({
    ...state,
    loadingInstrument: true,
  })),
  on(
    DiscoverInstrumentCategoryViewActions.toggleDiscoverInstrumentCategoryView,
    (state: State, { listView }) => ({
      ...state,
      showListView: listView,
    }),
  ),
  on(
    DiscoverViewActions.enterDiscoverPage,
    InstrumentsApiActions.loadDiscoverOverviewAssetsFailure,
    (state: State) => ({
      ...state,
      loadingDiscoverOverview: true,
    }),
  ),
  on(
    DiscoverInstrumentCategoryViewActions.sortDiscoverInstrumentCategoryView,
    (state: State, { sortingType, categoryKey }) => {
      const newSortingCategory: SortingCategory = { ...state.sortingCategory };

      newSortingCategory[categoryKey] = sortingType;
      return {
        ...state,
        sortingCategory: newSortingCategory,
        sortingType: sortingType,
      };
    },
  ),
);
