import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonSkeletonText,
} from '@ionic/angular/standalone';

@Component({
  imports: [IonAvatar, IonItem, IonLabel, IonSkeletonText],
  standalone: true,
  selector: 'yeekatee-item-skeleton',
  templateUrl: './item-skeleton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSkeletonComponent {
  @Input() showAvatar = false;
}
