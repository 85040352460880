import { Pipe, PipeTransform } from '@angular/core';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

@Pipe({
  name: 'flagEmoji',
  standalone: true,
})
export class FlagEmojiPipe implements PipeTransform {
  transform(input?: string, kind?: 'COUNTRY' | 'LOCALE'): string | undefined {
    if (!input) return;

    let country = input;

    if (kind === 'LOCALE') {
      const locale = new Intl.Locale(input);
      country = locale.region ?? locale.language;
    }

    return getUnicodeFlagIcon(country);
  }
}
