import { createSelector } from '@ngrx/store';
import { MarketNewsOrderingType } from '@yeekatee/client-api-angular';
import { InstrumentsSelectors } from '../instruments';
import { MarketNewsSelectors, MarketNewsTimelineVM } from '../market-news';
import { MarketNewsVM } from '../market-news/market-news.models';

type MarketNewsOrderingTypeI18n = Record<MarketNewsOrderingType, string>;
const selectMarketNewsOrderingTypeI18n = createSelector(
  (): MarketNewsOrderingTypeI18n => ({
    [MarketNewsOrderingType.latest]: $localize`Latest news`,
    [MarketNewsOrderingType.top]: $localize`Top news`,
    [MarketNewsOrderingType.popular]: $localize`Popular news`,
  }),
);

export const selectMarketNewsVM = createSelector(
  MarketNewsSelectors.selectLoadingMarketNews,
  MarketNewsSelectors.selectMarketNewsStories,
  InstrumentsSelectors.selectInstrument,
  MarketNewsSelectors.selectIsEndOfNews,
  MarketNewsSelectors.selectOrderingType,
  selectMarketNewsOrderingTypeI18n,
  (
    loading,
    stories,
    instrument,
    isEndOfNews,
    orderingType,
    marketNewsOrderingTypeI18n,
  ) =>
    ({
      loading,
      stories,
      instrument,
      isEndOfNews,
      orderingType,
      marketNewsOrderingTypeI18n,
    }) satisfies MarketNewsVM,
);

export const selectMarketNewsTimelineVM = createSelector(
  MarketNewsSelectors.selectMarketNewsTimelineLoading,
  MarketNewsSelectors.selectMarketNewsTimeline,
  MarketNewsSelectors.selectIsEndOfTimeline,
  (loading, stories, isEndOfTimeline): MarketNewsTimelineVM => ({
    loading,
    stories,
    isEndOfTimeline,
  }),
);
