import { createSelector } from '@ngrx/store';
import {
  calculationBaseTypeI18n,
  investmentViewType18n,
  portfolioViewTypeI18n,
  splitOptionsI18n,
} from './portfolios.models';

export * from './features/portfolio-reports.selectors';
export * from './features/transactions-reports.selectors';
export * from './features/user-portfolios.selectors';
export * from './features/user-properties.selectors';

export const selectPortfolioInvestmentViewsI18n = createSelector(
  investmentViewType18n,
);

export const selectPortfolioCalculationBasesI18n = createSelector(
  calculationBaseTypeI18n,
);

export const selectPortfolioSplitOptionsI18n = createSelector(splitOptionsI18n);

export const selectPortfolioViewTypeI18n = createSelector(
  portfolioViewTypeI18n,
);
