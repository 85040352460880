import {
  CreateCredentialsSCADetails,
  SubmitScaErrors,
} from '@yeekatee/client-api-angular';
import { CreateCredentialsErrors } from '@yeekatee/client-api-typescript';

/**
 * - `INVALID_CREDENTIALS`: The provided credentials are invalid.
 * - `INVALID_SCA`: The provided SCA is invalid.
 * - `CREDENTIALS_ALREADY_EXIST`: The credentials provided
 *    already exist in our system.
 * - `INTERNAL_ERROR`: An unexpected error from the Flanks API.
 * - `ABORTED_BY_USER`: This is an special error code.
 *    If any other error happens and the redirection is not configured
 *    for said error, the user will have an "EXIT" button
 *    if rollback for ABORTED_BY_USER is set. When pressing the "EXIT" button,
 *    the user will be redirected like any other kind of error,
 *    but the error code will be ABORTED_BY_USER.
 *
 * @see https://docs.flanks.io/link-errors#link-error-codes
 */
export type FlanksCallbackErrors =
  | 'INVALID_CREDENTIALS'
  | 'INVALID_SCA'
  | 'CREDENTIALS_ALREADY_EXIST'
  | 'INTERNAL_ERROR'
  | 'ABORTED_BY_USER';

export const getCreateCredentialsErrorI18n = (
  error: CreateCredentialsErrors,
) => {
  const translations: Record<CreateCredentialsErrors, string> = {
    userAlreadyExists: $localize`User already exists. Try again in a while.`,
    invalidCredentials: $localize`Username or password do not match`,
    bankNotImplementedError: $localize`Requested bank is currently not available`,
    scaTypeNotAllowed: $localize`SCA authentication failed. Choose another SCA type.`,
    userInteractionNeeded: $localize`Your bank or broker requires your action in your web or app access. Login and double check, please.`,
    internalError: $localize`Something went wrong with our partner. Try again in a bit, please.`,
    unknownError: $localize`An unexpected error occurred`,
  };
  return translations[error];
};

export const getSubmitScaErrorI18n = (error: SubmitScaErrors) => {
  const translations: Record<SubmitScaErrors, string> = {
    loginError: $localize`Web access of your financial provider is not available`,
    invalidScaCode: $localize`SCA key does not match requirements. Double check it, please.`,
    credentialsTokenNotFound: $localize`Credentials are expired. Restart the linking from start, please.`,
    userInteractionNeeded: $localize`Your bank or broker requires your action in your web or app access. Login and double check, please.`,
    internalError: $localize`Something went wrong with our partner. Try again in a bit, please.`,
  };
  return translations[error];
};

export type StoreCreateCredentialsSCADetails = Omit<
  CreateCredentialsSCADetails,
  '__typename'
> | null;

export enum ScaTypesSpecialTextInput {
  renta4 = 'renta4',
  bigportugal = 'bigportugal',
  netcash = 'netcash',
  creditagricoleindosuez = 'creditagricoleindosuez',
}

export enum ScaTypesPhoneInput {
  revolut = 'revolut',
  traderepublic = 'traderepublic',
}

export enum ScaTypesNoSCAInput {
  app = 'app',
  call = 'call',
  synchronousApp = 'synchronousApp',
  notNeeded = 'notNeeded',
}

export enum ScaState {
  success = 'success',
  abort = 'abort',
}

export type RequestBankInput = {
  name: string;
  country: string;
};
