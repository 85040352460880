import { createSelector } from '@ngrx/store';
import { COGNITO_GROUP_MODERATORS } from '@yeekatee/shared-util-const';
import {
  ActivitiesSelectors,
  ActivityPrefix,
  InteractiveTimelineObject,
  TimelineFilter,
} from '../activity-streams';
import { AuthSelectors } from '../auth';
import { GamesSelectors } from '../games';
import { NavbarSelectors } from '../navbar';
import { NavigationSelectors } from '../navigation';
import { UsersSelectors } from '../users';
import { memoize } from '../utils';

export const selectFollowingsListVM = createSelector(
  ActivitiesSelectors.selectActiveFollowingListForSelectedUser,
  ActivitiesSelectors.getActivitiesLoading,
  ActivitiesSelectors.selectFollowingListForSelectedUserLoaded,
  AuthSelectors.selectUserId,
  (activities, loading, loaded, myUserId) => ({
    activities,
    loading,
    loaded,
    myUserId,
  }),
);

export const selectFollowersListVM = createSelector(
  ActivitiesSelectors.selectActiveFollowersListForSelectedUser,
  ActivitiesSelectors.getActivitiesLoading,
  ActivitiesSelectors.selectFollowersListForSelectedUserLoaded,
  UsersSelectors.isAuthUserSelected,
  AuthSelectors.selectUserId,
  (activities, loading, loaded, isAuthUserSelected, myUserId) => ({
    activities,
    loading,
    loaded,
    isMeSelected: isAuthUserSelected,
    myUserId,
  }),
);

export const selectLikesListVM = createSelector(
  ActivitiesSelectors.selectCurrentActivity,
  ActivitiesSelectors.getActivitiesLoading,
  ActivitiesSelectors.selectLikesListForSelectedActivityLoaded,
  AuthSelectors.selectUserId,
  (activity, loading, loaded, myUserId) => ({
    likes: activity?.likes?.items ?? [],
    loading,
    loaded,
    myUserId,
  }),
);

export const selectPendingListVM = createSelector(
  ActivitiesSelectors.selectPendingFollowersListForSelectedUser,
  ActivitiesSelectors.getActivitiesLoading,
  (activities, loading) => ({
    activities,
    loading,
  }),
);

export type TimelineVM = {
  activities: InteractiveTimelineObject[];
  loaded: boolean;
  loading: boolean | undefined;
  myUserId: string | undefined;
  isModerator: boolean;
  doScrollTop: boolean;
  gameId?: string;
};

export const selectTimelineVM = memoize((filterValue: TimelineFilter) =>
  createSelector(
    ActivitiesSelectors.selectTimelineForCurrentUser(filterValue),
    ActivitiesSelectors.selectTimelineLoaded(filterValue),
    ActivitiesSelectors.selectTimelineLoading(filterValue),
    UsersSelectors.selectAuthenticatedUser,
    AuthSelectors.selectUser,
    NavbarSelectors.selectHomeTriggerSameTabClicked,
    GamesSelectors.selectGameToJoin,
    (
      activities,
      loaded,
      loading,
      me,
      authUser,
      doScrollTop,
      gameId,
    ): TimelineVM => ({
      activities,
      loaded,
      loading,
      myUserId: me?.id ?? undefined,
      isModerator: !!authUser?.groups?.includes(COGNITO_GROUP_MODERATORS),
      doScrollTop,
      gameId,
    }),
  ),
);

export const selectUserActivitiesVM = createSelector(
  ActivitiesSelectors.selectActivitiesForSelectedUser,
  ActivitiesSelectors.selectOutboxLoaded,
  ActivitiesSelectors.getActivitiesLoading,
  UsersSelectors.selectAuthenticatedUser,
  AuthSelectors.selectUser,
  (activities, loaded, loading, me, authUser) => ({
    activities,
    loaded,
    loading,
    myUserId: me?.id ?? undefined,
    isModerator: authUser?.groups?.includes(COGNITO_GROUP_MODERATORS),
  }),
);

export const selectNoteViewVM = createSelector(
  ActivitiesSelectors.selectActivityId,
  ActivitiesSelectors.selectCurrentActivity,
  ActivitiesSelectors.selectRepliesListForSelectedActivityLoaded,
  ActivitiesSelectors.getActivitiesLoading,
  ActivitiesSelectors.selectTotalRepliesListForSelectedActivity,
  UsersSelectors.selectAuthenticatedUser,
  AuthSelectors.selectUser,
  (activityId, activity, loaded, loading, totalReplies, me, authUser) => ({
    activity,
    loaded,
    /**
     * If the ID is not an activity it means the navigation is still
     * in process and therefore the data returned by the selector
     * outdated.
     */
    loading: !activityId?.startsWith(ActivityPrefix) || loading,
    totalReplies,
    myUserId: me?.id ?? undefined,
    isModerator: authUser?.groups?.includes(COGNITO_GROUP_MODERATORS),
  }),
);

export const selectChatListVM = createSelector(
  ActivitiesSelectors.selectChatList,
  AuthSelectors.selectUser,
  ActivitiesSelectors.getChatsLoading,
  NavbarSelectors.selectNavbarState,
  (activities, sender, loading, { tab }) => ({
    activities,
    sender,
    loading,
    tab,
  }),
);

export const selectChatRoomVM = createSelector(
  ActivitiesSelectors.selectChatRoomActivities,
  NavigationSelectors.selectChatToFromRoute,
  UsersSelectors.getUsersEntities,
  AuthSelectors.selectUser,
  ActivitiesSelectors.getChatsLoading,
  (activities, to, users, me, loading) => ({
    activities,
    to: to ? users?.[to] : undefined,
    me,
    loading,
  }),
);
