import { JSONContent } from '@tiptap/core';
import {
  MarketNewsOrderingType,
  MarketNewsSentimentChart,
  MarketNewsStory,
} from '@yeekatee/client-api-angular';
import {
  setChartFill,
  setDynamicBorderColor,
} from '@yeekatee/shared-charts-util-functions';
import { ChartData, ScriptableContext } from 'chart.js';
import { DateTime } from 'luxon';
import { AssetEntity } from '../instruments';
import { ThemeColors } from '../theme';

export type MarketNewsStoryEntity = Omit<MarketNewsStory, '__typename'>;

export interface MarketNewsInstrumentEntity {
  id: string;
  stories?: string[];
  nextToken?: string;
  orderingType?: MarketNewsOrderingType;
  sentimentChart?: MarketNewsSentimentChart;
}

export type MarketNewsSentimentStatistics = {
  averageSentiment?: number;
  averageConfidence?: number;
};

/**
 * Market News Home
 */
export type MarketNewsTimelineVM = {
  loading?: boolean;
  stories?: MarketNewsStoryEntity[];
  isEndOfTimeline: boolean;
};

/**
 * Instrument view models
 */
export type MarketNewsVM = {
  loading?: boolean;
  stories?: Array<MarketNewsStory>;
  instrument?: AssetEntity;
  isEndOfNews?: boolean;
  orderingType?: MarketNewsOrderingType;
  marketNewsOrderingTypeI18n: Partial<Record<MarketNewsOrderingType, string>>;
};

// Utils
export function mapSentimentChartToChartData(
  sentimentChart?: MarketNewsSentimentChart,
  colors?: ThemeColors,
  fill = true,
): ChartData<'bar'> | undefined {
  if (!sentimentChart || !colors) return;

  const itemCount = sentimentChart?.labels?.length ?? 0;

  const setup = {
    labels: new Array<string>(itemCount),
    data: new Array<number>(itemCount),
  };
  let nullCnt = 0;
  for (let i = 0; i <= itemCount; i++) {
    if (sentimentChart.labels && sentimentChart.values) {
      const dateItem = sentimentChart.labels[i];
      const valItem = sentimentChart.values[i];
      if (!dateItem) continue;

      setup.labels[i] = DateTime.fromFormat(dateItem, 'dd.MM').toLocaleString(
        DateTime.DATE_MED,
      );
      if (valItem === null) nullCnt = nullCnt + 1;
      setup.data[i] = valItem ?? 0;
    }
  }

  if (nullCnt > 10) {
    setup.data = [];
  }

  const { labels, data } = setup;
  const refValue = 0;
  const borderColor = (context: ScriptableContext<'bar'>) =>
    setDynamicBorderColor(
      context,
      colors.uptrend.css(),
      colors.downtrend.css(),
      refValue,
    );

  const traceConfig = {
    data,
    pointRadius: 0,
    pointBorderColor: borderColor,
    pointBackgroundColor: borderColor,
    borderColor,
    borderWidth: 2,
  };

  const fillConfig = fill
    ? {
        backgroundColor: (context: ScriptableContext<'bar'>) => {
          if (context.parsed.y > 0)
            return setChartFill(context, colors.uptrend, refValue, 'bottom');
          else return setChartFill(context, colors.downtrend, refValue, 'top');
        },
      }
    : false;

  return {
    labels,
    datasets: [{ ...traceConfig, ...fillConfig }],
  };
}

export const prepMarketNewsPost = (
  story: MarketNewsStory,
  instrument?: AssetEntity,
): JSONContent => {
  const instrumentId = instrument?.id ?? story.instrumentId;
  const instrumentName = instrument?.name ?? story.instrumentName;
  const { title, url } = story;

  return [
    {
      type: 'paragraph',
    },
    {
      type: 'paragraph',
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: title,
        },
      ],
    },
    {
      type: 'paragraph',
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: $localize`Read more` + ': ',
        },
        {
          type: 'mention$',
          attrs: {
            id: instrumentId,
            label: instrumentName,
          },
        },
        {
          type: 'text',
          text: ' ',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: $localize`Tagged asset` + ': ',
        },
        {
          type: 'text',
          marks: [
            {
              type: 'link',
              attrs: {
                href: url,
                target: '_blank',
                rel: 'noopener noreferrer nofollow',
                class: null,
              },
            },
          ],
          text: url,
        },
        {
          type: 'text',
          text: ' ',
        },
      ],
    },
  ];
};
