import { InviteCode, User } from '@yeekatee/client-api-angular';

export type InviteCodeEntity = InviteCode;

export enum InvitationStates {
  Share = 'Share',
  Used = 'Used',
}

export type Invitation = InviteCode & {
  usedByUser?: User;
  status: InvitationStates;
};

export type InvitationList = Array<Invitation>;

export type InvitationListVm = {
  invitations: InvitationList;
  hasOwnCode: boolean;
  loadingList: boolean;
  loadingCode: boolean;
};

export const createInvitationList = (
  invitations: InviteCode[],
  user: User[],
): InvitationList =>
  invitations.map((invitation) => ({
    ...invitation,
    usedByUser: invitation?.usedBy
      ? user.find((user) => user.id === invitation?.usedBy)
      : undefined,
    code: formatCode(invitation.code),
    status: getInvitationStatus(invitation),
  }));

const formatCode = (code?: string | null) =>
  code ? `${code.slice(0, 4)}-${code.slice(4, 8)}` : undefined;

const getInvitationStatus = (invitation: InviteCode) =>
  invitation.usedBy ? InvitationStates.Used : InvitationStates.Share;
