import { Action, createReducer, on } from '@ngrx/store';
import { ThemeActions } from './theme.actions';
import { RawThemeColors } from './theme.model';

export const FEATURE_KEY = 'theme';

export enum ColorMode {
  DARK = 'dark',
  LIGHT = 'light',
}

export type ThemeState = {
  mode: ColorMode;
  colors?: RawThemeColors;
  isWebThemeActive: boolean;
};

export interface ThemePartialState {
  readonly [FEATURE_KEY]: ThemeState;
}

export const initialState: ThemeState = {
  mode: ColorMode.LIGHT,
  isWebThemeActive: false,
};

const reducer = createReducer(
  initialState,
  on(
    ThemeActions.darkModeSelected,
    ThemeActions.lightModeSelected,
    (state, { mode, colors }) => ({ ...state, mode, colors }),
  ),
  on(ThemeActions.changeWebTheme, (state, { isActive }) => ({
    ...state,
    isWebThemeActive: isActive,
  })),
);

export const themeReducer = (state: ThemeState | undefined, action: Action) =>
  reducer(state, action);
