import { createSelector } from '@ngrx/store';
import {
  InstrumentsState,
  selectInstrumentsState,
} from '../instruments.reducer';
import { FEATURE_KEY, timeSeriesAdapter } from './time-series.reducer';

export const timeSeriesSliceSelector = createSelector(
  selectInstrumentsState,
  (state: InstrumentsState) => state[FEATURE_KEY],
);

const { selectEntities } = timeSeriesAdapter.getSelectors();

export const selectTimeSeriesEntities = createSelector(
  timeSeriesSliceSelector,
  selectEntities,
);
