import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ActivityStreamsActivity,
  ActivityStreamsNote,
} from '@yeekatee/client-api-angular';
import { errorProps } from '../../utils';
import {
  ActivitiesEntity,
  CollectionId,
  ImageEntity,
  InteractiveTimelineObject,
  StoreLink,
  TagEntity,
  TimelineFilter,
} from '../activity-streams.models';

export const ActivitiesEffectsActions = createActionGroup({
  source: 'Activities Effects',
  events: {
    'Handle Follow Notification': props<{
      activity: ActivityStreamsActivity;
    }>(),
    'Handle Accept Follow Notification': props<{
      activity: ActivityStreamsActivity;
      inReplyTo?: ActivitiesEntity;
    }>(),
    'Handle Notification': props<{
      activity: ActivityStreamsActivity;
    }>(),
    'Trigger Following Timeline Load ': props<{
      collectionId: CollectionId;
      sinceId?: string;
      untilId?: string;
      loading?: boolean;
      refreshing?: boolean;
    }>(),
    'Trigger For You Timeline Load ': props<{
      collectionId: CollectionId;
      sinceId?: string;
      untilId?: string;
      loading?: boolean;
      refreshing?: boolean;
    }>(),
    'Share Post Url': props<{
      url: string;
    }>(),
    'Copy Post Url': props<{
      url: string;
    }>(),
    'Post Url Copied': emptyProps(),
    'Notify Error': errorProps(),
  },
});

export const ActivitiesNoteCreateActions = createActionGroup({
  source: 'Activities Note Create',
  events: {
    /**
     * This action should -
     *       First, insert a Note object and a Create activity in the objects and activities substores.
     *       Then,
     *       if `inReplyTo` is set,
     *         create a Note under the target's REPLIES collection,
     *       else,
     *          create a Note under the own user's INBOX
     */
    'Create Note': props<{
      content: string;
      inReplyTo?: string;
      image?: ImageEntity[];
      tag?: TagEntity[];
      url?: StoreLink[];
    }>(),
    'Create Chat': props<{
      content: string;
      inReplyTo?: string;
      image?: ImageEntity[];
      tag?: TagEntity[];
      url?: StoreLink[];
    }>(),
    'Show Notification Char Limit Exceeded': props<{
      charCountLimit: number;
    }>(),
  },
});

export const ActivitiesTimelineActions = createActionGroup({
  source: 'Activities Timeline',
  events: {
    'Scroll Bottom': props<{ filterValue: TimelineFilter }>(),
    'Like Note': props<{ noteId: string }>(),
    'Unlike Note': props<{ likeId: string; noteId: string }>(),
    'Delete Note': props<{ activity: InteractiveTimelineObject }>(),
    'Flag Note': props<{ activity: InteractiveTimelineObject }>(),
    'Share Note': props<{ activityId: string }>(),
    'Scroll Top Triggered': emptyProps(),
    'Redirect To Auth': props<{ lastRoute: string }>(),
  },
});

export const ActivitiesNoteViewActions = createActionGroup({
  source: 'Activities Note View',
  events: {
    'Initial Load': emptyProps(),
    Navigated: emptyProps(),
    Refresh: emptyProps(),
    'Scroll Bottom': emptyProps(),
    'Like Note': props<{ noteId: string }>(),
    'Unlike Note': props<{ likeId: string; noteId: string }>(),
    'Delete Note': props<{ activity: InteractiveTimelineObject }>(),
    'Flag Note': props<{ activity: InteractiveTimelineObject }>(),
    'Share Note': props<{ activityId: string }>(),
  },
});

export const ActivitiesUserViewActions = createActionGroup({
  source: 'User Activities View',
  events: {
    'Initial Load': emptyProps(),
    Navigated: emptyProps(),
    Refresh: emptyProps(),
    'Scroll Bottom': emptyProps(),
    'Like Note': props<{ noteId: string }>(),
    'Unlike Note': props<{ likeId: string; noteId: string }>(),
    'Delete Note': props<{ activity: InteractiveTimelineObject }>(),
    'Flag Note': props<{ activity: InteractiveTimelineObject }>(),
    'Share Note': props<{ activityId: string }>(),
  },
});

export const ActivitiesAssetFeedViewActions = createActionGroup({
  source: 'Activities Asset Feed View',
  events: {
    'Initial Load': emptyProps(),
    Navigated: emptyProps(),
    Refresh: emptyProps(),
    'Scroll Bottom': emptyProps(),
    'Like Note': props<{ noteId: string }>(),
    'Unlike Note': props<{ likeId: string; noteId: string }>(),
    'Delete Note': props<{ activity: InteractiveTimelineObject }>(),
    'Flag Note': props<{ activity: InteractiveTimelineObject }>(),
    'Share Note': props<{ activityId: string }>(),
  },
});

export const ActivitiesFollowingActions = createActionGroup({
  source: 'Activities Following List',
  events: {
    'Initial Load': emptyProps(),
    'Scroll Bottom': emptyProps(),
    'Follow User': props<{ userId: string }>(),
    'Unfollow User': props<{ followId: string; userId: string }>(),
    'Revoke User': props<{ followId: string; userId: string }>(),
  },
});

export const ActivitiesFollowersActions = createActionGroup({
  source: 'Activities Followers List',
  events: {
    'Initial Load': emptyProps(),
    'Scroll Bottom': emptyProps(),
    'Follow User': props<{ userId: string }>(),
    'Unfollow User': props<{ followId: string; userId: string }>(),
    'Revoke User': props<{ followId: string; userId: string }>(),
    'Remove User': props<{ followId: string }>(),
  },
});

export const ActivitiesLikesActions = createActionGroup({
  source: 'Activities Likes List',
  events: {
    'Initial Load': emptyProps(),
    'Scroll Bottom': emptyProps(),
    'Follow User': props<{ userId: string }>(),
    'Unfollow User': props<{ followId: string; userId: string }>(),
    'Revoke User': props<{ followId: string; userId: string }>(),
  },
});

export const ActivitiesPendingActions = createActionGroup({
  source: 'Activities Pending List',
  events: {
    'Initial Load': emptyProps(),
    'Accept Follow': props<{ followId: string }>(),
    'Reject Follow': props<{ followId: string }>(),
  },
});

export const ActivitiesApiActions = createActionGroup({
  source: 'Activities API',
  events: {
    'Load Notes Totals Success': props<{
      notes: ActivityStreamsNote[];
    }>(),
    'Load Notes Totals Failure': errorProps(),

    'Flag Bank Success': props<{ activity: ActivityStreamsActivity }>(),
    'Flag Bank Failure': errorProps(),

    'Flag Instrument Success': props<{ activity: ActivityStreamsActivity }>(),
    'Flag Instrument Failure': errorProps(),

    'Flag Note Success': props<{ activity: ActivityStreamsActivity }>(),
    'Flag Note Failure': errorProps(),

    'Flag User Success': props<{ activity: ActivityStreamsActivity }>(),
    'Flag User Failure': errorProps(),
  },
});
