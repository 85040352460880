import { createSelector } from '@ngrx/store';
import { getQuoteId } from '@yeekatee/shared-util-typesafe-dynamodb';
import {
  AssetEntity,
  CryptoEntity,
  DiscoverInstrumentCategoryType,
  DividendsSelectors,
  EtfEntity,
  ForexEntity,
  IndexEntity,
  InstrumentsSelectors,
  QuotesSelectors,
  StockEntity,
} from '../instruments';
import { LocalesSelectors } from '../locales';

export const selectDiscoverSummaryVM = createSelector(
  InstrumentsSelectors.selectLoadingDiscoverOverview,
  InstrumentsSelectors.selectDiscoverOverviewAssets,
  InstrumentsSelectors.selectInstrumentsEntities,
  QuotesSelectors.selectQuotesEntities,
  LocalesSelectors.getSelectedId,
  DividendsSelectors.selectDividendHeatmap,
  DividendsSelectors.selectSelectedDateFilter,
  (
    loading,
    discoverOverviewAssets,
    instruments,
    quotes,
    locale,
    dividendHeatmap,
    dividendSelectedDate,
  ) => {
    const assets = Object.keys(discoverOverviewAssets).reduce(
      (allAssets, category) => {
        const categoryAssets = discoverOverviewAssets[category];
        categoryAssets.forEach((asset) => {
          const instrument = instruments[asset.id];
          const listing = instrument?.listings?.[0];
          const assetWithQuote = {
            ...instrument,
            quote:
              quotes[
                getQuoteId(
                  instrument?.id ?? '',
                  listing?.symbol ?? '',
                  listing?.mic,
                )
              ],
          } as AssetEntity;

          if (!assetWithQuote) return;

          switch (category) {
            case DiscoverInstrumentCategoryType.STOCKS_LARGE_CAP:
              allAssets.stocksLargeCaps.push(assetWithQuote as StockEntity);
              break;
            case DiscoverInstrumentCategoryType.STOCKS_TOP_TECH:
              allAssets.stocksTopTechCompanies.push(
                assetWithQuote as StockEntity,
              );
              break;
            case DiscoverInstrumentCategoryType.ETFS:
              allAssets.etfs.push(assetWithQuote as EtfEntity);
              break;
            case DiscoverInstrumentCategoryType.INDICES:
              allAssets.indices.push(assetWithQuote as IndexEntity);
              break;
            case DiscoverInstrumentCategoryType.CRYPTOS:
              allAssets.cryptos.push(assetWithQuote as CryptoEntity);
              break;
            case DiscoverInstrumentCategoryType.CURRENCIES:
              allAssets.currencies.push(assetWithQuote as ForexEntity);
              break;
            case DiscoverInstrumentCategoryType.METALS:
              allAssets.metals.push(assetWithQuote as ForexEntity);
              break;
            case DiscoverInstrumentCategoryType.COMMODITIES:
              allAssets.commodities.push(assetWithQuote as ForexEntity);
              break;
            default:
              // Handle any other categories if necessary
              break;
          }
        });
        return allAssets;
      },
      {
        stocksTopTechCompanies: [] as StockEntity[],
        stocksLargeCaps: [] as StockEntity[],
        etfs: [] as EtfEntity[],
        indices: [] as IndexEntity[],
        currencies: [] as ForexEntity[],
        cryptos: [] as CryptoEntity[],
        metals: [] as ForexEntity[],
        commodities: [] as ForexEntity[],
      },
    );

    return {
      loading,
      locale,
      dividendHeatmap,
      dividendSelectedDate,
      categories: [
        {
          route: DiscoverInstrumentCategoryType.INDICES,
          items: assets.indices,
          name: $localize`Popular indices`,
        },
        {
          route: DiscoverInstrumentCategoryType.STOCKS_TOP_TECH,
          items: assets.stocksTopTechCompanies,
          name: $localize`Trending tech companies`,
        },
        {
          route: DiscoverInstrumentCategoryType.STOCKS_LARGE_CAP,
          items: assets.stocksLargeCaps,
          name: $localize`Large caps`,
        },
        {
          route: DiscoverInstrumentCategoryType.ETFS,
          items: assets.etfs,
          name: $localize`Popular ETFs`,
        },
        {
          route: DiscoverInstrumentCategoryType.CRYPTOS,
          items: assets.cryptos,
          name: $localize`Top crypto currencies`,
        },
        {
          route: DiscoverInstrumentCategoryType.CURRENCIES,
          items: assets.currencies,
          name: $localize`Popular currencies`,
        },
        {
          route: DiscoverInstrumentCategoryType.METALS,
          items: assets.metals,
          name: $localize`Popular precious metals`,
        },
        {
          route: DiscoverInstrumentCategoryType.COMMODITIES,
          items: assets.commodities,
          name: $localize`Popular commodities`,
        },
      ],
    };
  },
);
