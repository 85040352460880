import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  InvitationsAPIActions,
  InvitationsFormActions,
  InviteListActions,
} from './invitations.actions';
import { InviteCodeEntity } from './invitations.models';

export const name = 'invitations';

export interface State extends EntityState<InviteCodeEntity> {
  loadingCode: boolean;
  loadingList: boolean;
  ownInviteCode: InviteCodeEntity | undefined;
  error: any | undefined;
  nextToken: string | undefined | null;
}

export interface InvitationsPartialState {
  readonly [name]: State;
}

export const adapter = createEntityAdapter<InviteCodeEntity>();

export const initialState: State = adapter.getInitialState({
  loadingCode: false,
  loadingList: false,
  ownInviteCode: undefined,
  error: undefined,
  nextToken: undefined,
});

export const reducer = createReducer(
  initialState,
  on(
    InviteListActions.initListCodes,
    (state): State => ({
      ...state,
      loadingList: true,
    }),
  ),
  on(
    InviteListActions.createInviteCode,
    InvitationsFormActions.userEnteredCode,
    (state): State => ({
      ...state,
      loadingCode: true,
    }),
  ),
  on(
    InvitationsAPIActions.getCodesSuccess,
    (state): State => ({
      ...state,
      loadingList: false,
      error: undefined,
    }),
  ),
  on(
    InvitationsAPIActions.createCodeSuccess,
    InvitationsAPIActions.consumeCodeSuccess,
    (state): State => ({
      ...state,
      loadingCode: false,
      error: undefined,
    }),
  ),
  on(
    InvitationsAPIActions.getCodesFailure,
    (state, { error }): State => ({
      ...state,
      loadingList: false,
      error,
    }),
  ),
  on(
    InvitationsAPIActions.createCodeFailure,
    InvitationsAPIActions.consumeCodeFailure,
    (state, { error }): State => ({
      ...state,
      loadingCode: false,
      error,
    }),
  ),
  on(
    InvitationsAPIActions.getCodesSuccess,
    (state, { inviteCodes }): State =>
      inviteCodes ? adapter.upsertMany(inviteCodes, state) : state,
  ),
  on(
    InvitationsAPIActions.createCodeSuccess,
    (state, { invitation }): State =>
      invitation ? adapter.upsertOne(invitation, state) : state,
  ),
  on(
    InvitationsAPIActions.getOwnCodeSuccess,
    (state, { code }): State => ({
      ...state,
      ownInviteCode: code,
    }),
  ),
);
