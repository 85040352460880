import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonSkeletonText, IonText } from '@ionic/angular/standalone';
import { DynamicTranslationPipe } from '@yeekatee/i18n/feature-dynamic-translation';

@Component({
  selector: 'yeekatee-read-more-paragraphs',
  standalone: true,
  imports: [
    DynamicTranslationPipe,
    IonSkeletonText,
    IonText,
    AsyncPipe,
    NgTemplateOutlet,
  ],
  templateUrl: './read-more-paragraphs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreParagraphsComponent {
  @Input() paragraphs?: string[];
  @Input() minParagraphCnt = 1;
  @Input() i18n = true;

  protected readMore = false;
}
