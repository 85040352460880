<ion-header>
  <ion-toolbar>
    <ion-title i18n>GIF selector</ion-title>
    <ion-buttons slot="start">
      <yeekatee-close-button (click)="cancel()" />
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      (ionInput)="searchGif($event)"
      [debounce]="300"
      i18n-placeholder
      placeholder="Search for a GIF"
    />
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" [fullscreen]="true">
  <div #container></div>
</ion-content>
