/**
 * In this file we define Actions that originate from our Activity Streams API
 */

import { createAction, props } from '@ngrx/store';
import { errorProps } from '../../utils';
import { ActivitiesCollection } from '../activity-streams.models';

const createLoadCollectionSuccessAction = (
  parent: Capitalize<string>,
  type: Capitalize<string>,
) =>
  createAction(
    `[Activities/API] Load ${parent} ${type} Collection Success`,
    props<ActivitiesCollection>(),
  );

const createLoadCollectionFailureAction = (
  parent: Capitalize<string>,
  type: Capitalize<string>,
) =>
  createAction(
    `[Activities/API] Load ${parent} ${type} Collection Failure`,
    errorProps(),
  );

/**
 * Create the tuple of actions needed when retrieving a collection from the API.
 *
 * Destructure it as follows, to have consistent variable names:
 *
 * ```
 * const {
 *   success: load<Parent><Collection>Success,
 *   failure: load<Parent><Collection>Failure,
 * } = createLoadCollectionActions('Parent', 'Collection');
 * ```
 *
 * @param parent
 * @param type
 */
const createLoadCollectionActions = (
  parent: Capitalize<string>, // TODO Template-typed parent and type
  type: Capitalize<string>,
) => ({
  success: createLoadCollectionSuccessAction(parent, type),
  failure: createLoadCollectionFailureAction(parent, type),
});

export const {
  success: loadNotesLikesCollectionSuccess,
  failure: loadNotesLikesCollectionFailure,
} = createLoadCollectionActions('Notes', 'Likes');

export const {
  success: loadNotesRepliesCollectionSuccess,
  failure: loadNotesRepliesCollectionFailure,
} = createLoadCollectionActions('Notes', 'Replies');

export const {
  success: loadPersonInboxCollectionSuccess,
  failure: loadPersonInboxCollectionFailure,
} = createLoadCollectionActions('Person', 'Inbox');

export const {
  success: loadPersonOutboxCollectionSuccess,
  failure: loadPersonOutboxCollectionFailure,
} = createLoadCollectionActions('Person', 'Outbox');

export const {
  success: loadPersonLikedCollectionSuccess,
  failure: loadPersonLikedCollectionFailure,
} = createLoadCollectionActions('Person', 'Liked');

export const {
  success: loadPersonFollowingCollectionSuccess,
  failure: loadPersonFollowingCollectionFailure,
} = createLoadCollectionActions('Person', 'Following');

export const {
  success: loadPersonFollowersCollectionSuccess,
  failure: loadPersonFollowersCollectionFailure,
} = createLoadCollectionActions('Person', 'Followers');

export const {
  success: loadAssetSharedInboxCollectionSuccess,
  failure: loadAssetSharedInboxCollectionFailure,
} = createLoadCollectionActions('Asset', 'SharedInbox');

export const {
  success: loadChatCollectionSuccess,
  failure: loadChatCollectionFailure,
} = createLoadCollectionActions('Person', 'Chat');
