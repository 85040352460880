<ng-template #paragraphItem let-item="item">
  @if (i18n) {
    @if (item | dynamicTranslation | async; as itemDesc) {
      <p>{{ itemDesc.text }}</p>
    } @else {
      <ion-skeleton-text [animated]="true" style="width: 100%" />
      <ion-skeleton-text [animated]="true" style="width: 100%" />
    }
  } @else {
    {{ item }}
  }
</ng-template>

@for (item of paragraphs; track item; let i = $index) {
  @if (readMore) {
    @if (i >= 0) {
      <div class="mb-5">
        <ng-container
          *ngTemplateOutlet="
            paragraphItem;
            context: {
              item: item
            }
          "
        />
        @if (i === paragraphs?.length! - 1) {
          <a (click)="readMore = !readMore">
            <ion-text class="font-bold text-sm underline" color="primary" i18n
              >read less</ion-text
            >
          </a>
        }
      </div>
    }
  } @else {
    @if (i <= minParagraphCnt - 1) {
      <div class="mb-5">
        <ng-container
          *ngTemplateOutlet="
            paragraphItem;
            context: {
              item: item
            }
          "
        />
        @if (i === minParagraphCnt - 1) {
          <a (click)="readMore = !readMore">
            <ion-text class="font-bold text-sm underline" color="primary" i18n
              >read more</ion-text
            >
          </a>
        }
      </div>
    }
  }
}
