import { BookingCcy } from "./booking-util-ccy";

/******************************************************************************
 * keys yeekatee transation sources
 ******************************************************************************/
export const enum TrxSource {
  Injected = 1,
  User = 2,
  Flanks = 3,
  Bank = 4,
  Generated = 5,
}

export type TrxSourceType = TrxSource;

export type TrxSourceRec = { key: TrxSourceType; name: string };
export type TrxSourceTable = Record<TrxSourceType, TrxSourceRec>;

/******************************************************************************
 * keys yeekatee transation injection types
 *
 ******************************************************************************/
export const enum TrxInjectType {
  Always = 1, // always inject
  IfMissing = 2, // inject if missing, but no cash (also adjusts)
  Adjust = 3, // adjust existing trx, set correct EX/record date
}

/******************************************************************************
 * virtual trade status
 *
 * MAY HAVE LONGER VALUES
 * VALUES MAY BE CHANGED
 ******************************************************************************/
export enum TradeStatus {
  OK = 'OK', // transaction generated
  OkCashAdjusted = 'OkCashAdjusted', // buy but cash had to be reduced
  OkFiAdjusted = 'OkFiAdjusted', // sell but amount had to be reduced
  //
  UnknownFi = 'UnknownFi', // instrument id not known
  InvalidFi = 'InvalidFi', // instrument not allowed for virtual portofolio like futures
  NotAllowedFi = 'NotAllowedFi', // instrument outside allowed universum
  NoFiBalance = 'NoFiBalance', // no instrument balance to sell for
  NoCashBalance = 'NoCashBalance', // no cash balance to buy for
  CashToSmall = 'CashToSmall', // cash amount to small for buys
  Intraday = 'Intraday', // intraday transaction not allowed
  TooManyTrxDay = 'TooManyTrxDay', // too many transaction on same day
  OldTimestamp = 'OldTimestamp', // price timestamp too old, to prevent transactions between closing of exchange and transaction injection
  InvalidPortfolio = 'InvalidPortfolio', // portfolio is unknown,closed or not virtual
  InvalidBalances = 'InvalidBalances', // could not get balance
  InvalidCashAmount = 'InvalidCashAmount', // invalid cash amount, e.g. violates allowed decimals or not a number
  InvalidFiAmount = 'InvalidFiAmount', // invalid instrument amount, e.g. violates allowed decimals or not a number
  InvalidPrice = 'InvalidPrice', // invalid or missing price
  InvalidExchange = 'InvalidExchange', // invalid or missing exchange
  InvalidCurrency = 'InvalidCurrency', // invalid or missing currency
  MultiCurrency = 'MultiCurrency', // more than one currency for the same instrument
  MultiTransaction = 'MultiTransaction', // more than one transaction in same bulk for the same instrument
  AllocationTooHigh = 'AllocationTooHigh', // allocation too high for an instrument
  TooManyInvestments = 'TooManyInvestments', // too many investments
  //
  BookingError = 'BookingError', // could not write booking
  //
  NotProcessed = 'NotProcessed', // not processed
  //
  Timeout = 'Timeout', // concurrency situation, please try later agagin
  //
  OutsideTrxsWindow = 'OutsideTrxsWindow',
}

/******************************************************************************
 ******************************************************************************
 * USER TRANSACTIONS
 ******************************************************************************/
type isoDate = string;

export enum UserTrxType {
  Valuation = 1,
  BuySell = 2,
  Payment = 3,
  Forex = 4,
  Expense = 5,
  GeneralExpense = 6,
  OpenClose = 7,
}

export type UserTrx =
  | UserTrxValuation
  | UserTrxBuySell
  | UserTrxPayment
  | UserTrxForex
  | UserTrxExpensePortfolio
  | UserTrxOpenClose;

export interface UserTrxMacc {
  currency: BookingCcy; // mobile form: by default use listing currency
  trxPosId?: string; // in case close
  //
  amount: number; // if listing currency === money account currency => net amount = instrument gross amount + expense
  exchange?: string; // // mobile form: no support  -- if listing currency !== money account
}
export interface UserTrxFi {
  id: string; // mobile form: user must select listing => fiId + currency
  currency: BookingCcy;
  trxPosId?: string; // in case of sell/valuation of an item without FiId
  //
  amount?: number; // mobile form: user must enter amount -- if undefinded then money weighted
  price?: string; // mobile form: no support
  gross: number; // mobile form: user must enter gross amount
  expense?: number; // mobile form: user may enter expense
  // accruedInterest?: number,
}

export interface UserTrxBase {
  type: UserTrxType;
  trxType: string;
  id?: string; // unique
  text?: string; // transaction text, cut at 100 characters
  instrument?: UserTrxFi;
}

// only for items without instrument id (fiId)
export interface UserTrxValuation extends UserTrxBase {
  type: UserTrxType.Valuation;
  date: isoDate;
  instrument: UserTrxFi;
}

export interface UserTrxBuySell extends UserTrxBase {
  type: UserTrxType.BuySell;
  trxDate: isoDate;
  valueDate?: isoDate;
  macc?: UserTrxMacc;
  instrument: UserTrxFi;
}

export interface UserTrxPayment extends UserTrxBase {
  type: UserTrxType.Payment;
  valueDate: isoDate;
  macc: UserTrxMacc; // no exchange rate
}

export interface UserTrxExpenseInstrument extends UserTrxBase {
  type: UserTrxType.Expense;
  valueDate: isoDate;
  macc: UserTrxMacc;
  instrument: UserTrxFi;
}

export interface UserTrxExpensePortfolio extends UserTrxBase {
  type: UserTrxType.GeneralExpense;
  valueDate: isoDate;
  macc: UserTrxMacc;
}

export interface UserTrxForex extends UserTrxBase {
  type: UserTrxType.Forex;
  trxDate: isoDate; // tbd.
  valueDate: isoDate;
  macc1: UserTrxMacc; // fix leg
  macc2: UserTrxMacc; // variable leg with exchange rate
}

export interface UserTrxOpenClose extends UserTrxBase {
  type: UserTrxType.OpenClose;
  date: isoDate;
  macc: UserTrxMacc;
}

/******************************************************************************
 * USER transaction status
 *
 * MAY HAVE LONGER VALUES
 * VALUES MAY BE CHANGED
 ******************************************************************************/
export enum UserTrxStatus {
  OK = 'OK', // transaction generated
  OKNotBooked = 'OKNotBooked', // Processed and OK but booking not written due to other not OK transactions
  InvalidPortfolio = 'InvalidPortfolio', // portfolio is unknown,closed or not simulation
  InvalidType = 'InvalidType', // unkown transaction type
  BookingError = 'BookingError', // could not write booking
  NotProcessed = 'NotProcessed', // not processed
}
export enum UserTrxPropertyStatus {
  Missing = 'Missing', // property missing
  Unknown = 'Unknown', // reference/Id unkown
  Invalid = 'Invalid', // invalid/not allowed value
  NoListingCurrency = 'NoListingCurrency', // currency is not a listing currency
  PriceGrossMissmatch = 'PriceGrossMissmatch', // price/gross do not match
  ExchangeMissmatch = 'ExchangeMissmatch', // exchange missmatch
  WrongSign = 'WrongSign', // amount has wrong sign in the context of the transaction type
  ForexCurrencyMissmatch = 'ForexCurrencyMissmatch', // currency must differ
  ValueTrxDateMissmatch = 'ValueTrxDateMissmatch', // value date must be >= trs date
}
