import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import { GamesSelectors } from '../games';
import { UsersSelectors } from '../users';
import { NavbarVM, TAB_ORDER, tabs } from './navbar.models';
import { FEATURE_KEY, NavbarState } from './navbar.reducer';

export const selectNavbarState =
  createFeatureSelector<NavbarState>(FEATURE_KEY);

export const selectNavbarTab = createSelector(
  selectNavbarState,
  ({ tab }) => tab,
);

export const selectHomeTriggerSameTabClicked = createSelector(
  selectNavbarState,
  ({ tab, triggerSameTabClicked }) =>
    tab === NavigationRouteNames.HOME && triggerSameTabClicked,
);

export const selectNavbarVM = createSelector(
  selectNavbarState,
  UsersSelectors.selectAuthenticatedUser,
  GamesSelectors.selectOpenQuizzes,
  ({ tab }, user, quizzes): NavbarVM => ({
    tab,
    defaultTab: TAB_ORDER[0],
    tabOrder: TAB_ORDER,
    tabs: tabs(),
    user,
    quizzes,
  }),
);
