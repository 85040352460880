import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthAPIActions, AuthEffectsActions } from '../auth/auth.actions';
import {
  UsersApiActions,
  UsersOnboardingUsernameActions,
  UsersProfileActions,
  UsersSettingsDeleteAccountActions,
  UsersSettingsPrivacyActions,
  UsersSettingsProfileActions,
  UsersSettingsUsernameActions,
} from './users.actions';
import { UsersEntity } from './users.models';

export const USERS_FEATURE_KEY = 'users';

export interface State extends EntityState<UsersEntity> {
  loading: boolean;
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: State;
}

export const usersAdapter: EntityAdapter<UsersEntity> =
  createEntityAdapter<UsersEntity>();

export const initialState: State = usersAdapter.getInitialState({
  loading: false,
});

const usersReducer = createReducer(
  initialState,
  on(
    // TODO This duplication of data in the store can produce unexpected results
    //      We should review interactions between auth user and user profiles
    AuthEffectsActions.getCachedUserSuccess,
    AuthEffectsActions.getCurrentUserSuccess,
    AuthEffectsActions.refreshCurrentUserRouteBackSuccess,
    AuthEffectsActions.refreshCurrentUserSuccess,
    AuthAPIActions.signInSuccess,
    UsersApiActions.loadUserSuccess,
    UsersApiActions.updateUserSuccess,
    UsersApiActions.updateUserWithLocaleSuccess,
    (state, { user }) => (user ? usersAdapter.upsertOne(user, state) : state),
  ),
  on(UsersApiActions.searchUsersSuccess, (state, { users }) =>
    usersAdapter.upsertMany(users, state),
  ),
  on(
    UsersProfileActions.onInit,
    UsersProfileActions.onRefresh,
    UsersSettingsProfileActions.onInit,
    UsersProfileActions.updateUser,
    UsersSettingsProfileActions.updateUser,
    UsersSettingsPrivacyActions.updateUser,
    UsersSettingsDeleteAccountActions.deleteUser,
    UsersSettingsUsernameActions.updateUser,
    UsersOnboardingUsernameActions.updateUser,
    (state): State => ({ ...state, loading: true }),
  ),
  on(
    UsersApiActions.loadUserSuccess,
    UsersApiActions.loadUserFailure,
    UsersApiActions.updateUserSuccess,
    UsersApiActions.updateUserFailure,
    UsersApiActions.updateLocaleSuccess,
    UsersApiActions.updateLocaleFailure,
    UsersApiActions.deleteUserSuccess,
    UsersApiActions.deleteUserFailure,
    UsersApiActions.updateUserWithLocaleSuccess,
    (state): State => ({ ...state, loading: false }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
