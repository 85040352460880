@if (navigationInput) {
  <div class="px-2">
    @for (button of navigationInput; track button.value) {
      <ion-button
        size="small"
        fill="clear"
        (click)="buttonChange.emit(button.value)"
        class="min-w-fit ion-no-margin ion-no-padding rounded-3xl text-sm"
        [style.width.%]="100 / navigationInput.length"
        [ngClass]="{
          'bg-ion_primary text-ion_primary_contrast':
            activeButton === button.value,
          'bg-transparent text-ion_primary': activeButton !== button.value
        }"
        >{{ button.content }}</ion-button
      >
    }
  </div>
}
