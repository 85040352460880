import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const HomePageActions = createActionGroup({
  source: 'Home Page',
  events: {
    Init: emptyProps(),
    'Refresh Market News': emptyProps(),
    'Refresh Following': emptyProps(),
    'Refresh For You': emptyProps(),
    'Redirect To Auth': props<{ lastRoute: string }>(),
  },
});
