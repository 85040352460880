import { z } from 'zod';
import { localizedString } from './string';

const baseAction = z.object({
  label: localizedString,
  primary: z.boolean().default(false).optional(),
  acknowledge: z.boolean().default(false).optional(),
});

export const actionBrowser = baseAction.extend({
  type: z.literal('browser'),
  url: z.string().url(),
});
export type ActionBrowser = z.infer<typeof actionBrowser>;

export const actionDispatch = baseAction.extend({
  type: z.literal('dispatch'),
  action: z.string(),
  payload: z.object({}).passthrough().optional().default({}),
});
export type ActionDispatch = z.infer<typeof actionDispatch>;

export const actionStore = baseAction.extend({
  type: z.literal('store'),
});
export type ActionStore = z.infer<typeof actionStore>;

export const actionNavigate = baseAction.extend({
  type: z.literal('navigate'),
  path: z.string(),
});
export type ActionNavigate = z.infer<typeof actionNavigate>;

export const actionSchema = z.discriminatedUnion('type', [
  actionBrowser,
  actionDispatch,
  actionStore,
  actionNavigate,
]);
export type Action = z.infer<typeof actionSchema>;
