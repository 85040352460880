import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonIcon, IonItem, IonLabel } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { informationCircleOutline } from 'ionicons/icons';

@Component({
  selector: 'yeekatee-formula-box',
  standalone: true,
  imports: [IonIcon, IonItem, IonLabel],
  templateUrl: './formula-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormulaBoxComponent {
  @Input() title: string | undefined;
  @Input() result: string | undefined;
  @Input() formula: string | undefined;
  @Input() alternateformula: boolean | undefined;
  @Input() info: string | undefined;

  constructor() {
    addIcons({ informationCircleOutline });
  }
}
