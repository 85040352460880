import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { IonText } from '@ionic/angular/standalone';

type ParagraphSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'yeekatee-read-more',
  standalone: true,
  imports: [IonText, NgClass],
  templateUrl: './read-more.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreComponent implements AfterViewInit {
  protected readonly buttonTexts = [$localize`read less`, $localize`read more`];

  protected readonly paragraphCss: Record<ParagraphSize, string> = {
    small: 'line-clamp-2',
    medium: 'line-clamp-4',
    large: 'line-clamp-6',
  };

  @ViewChild('container') container?: ElementRef;
  @Input() size: ParagraphSize = 'medium';

  protected readMore = true;
  protected showButton = false;

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showButton = this.overflow();
      this.ref.markForCheck();
    }, 500);
  }

  protected toggle(): boolean {
    this.readMore = !this.readMore;
    return false;
  }

  private overflow(): boolean {
    const offsetHeight = this.container?.nativeElement.offsetHeight ?? 0;
    const scrollHeight = this.container?.nativeElement.scrollHeight ?? 0;
    return offsetHeight < scrollHeight;
  }
}
