import { createSelector } from '@ngrx/store';
import {
  CalculationBaseType,
  VisibilityLevel,
} from '@yeekatee/client-api-angular';
import { ActivitiesSelectors, FollowActivityStatus } from '../activity-streams';
import { AuthSelectors } from '../auth';
import { FavouritesSelectors } from '../favourites';
import {
  PortfolioViewType,
  portfolioReportsFeature,
  userPortfoliosFeature,
} from '../portfolios';
import { ThemeSelectors } from '../theme';
import { UsersProfileViewModel, UsersSelectors } from '../users';

export const selectUserProfileVM = createSelector(
  UsersSelectors.selectUser,
  UsersSelectors.selectLoading,
  UsersSelectors.isAuthUserSelected,
  UsersSelectors.isSelectedUserStored,
  AuthSelectors.selectUser,
  FavouritesSelectors.selectedUserIsFavourite,
  ActivitiesSelectors.selectFollowingStatus,
  ActivitiesSelectors.selectFollowerStatus,
  ActivitiesSelectors.selectTotalFollowingForSelectedUser,
  ActivitiesSelectors.selectTotalFollowersForSelectedUser,
  ActivitiesSelectors.selectTotalPendingFollowersForSelectedUser,
  userPortfoliosFeature.selectUserPortfolios,
  userPortfoliosFeature.selectLoading,
  portfolioReportsFeature.selectReportPeriod,
  portfolioReportsFeature.selectReportCurrency,
  ThemeSelectors.selectColors,
  (
    user,
    loadingUser,
    isMyself,
    isSelectedUserStored,
    authUser,
    isFavourite,
    following,
    follower,
    totalFollowing,
    totalFollowers,
    totalPending,
    portfolios,
    portfoliosLoading,
    period,
    currency,
    colors,
  ): UsersProfileViewModel => ({
    user,
    // Show skeleton only if the user is not yet stored
    loadingUser: loadingUser && !isSelectedUserStored,
    isMyself,
    authUser,
    isFavourite,
    following,
    follower,
    totalFollowing,
    totalFollowers,
    totalPending,
    showFullProfile: showFullProfile(
      isMyself,
      user?.visibility,
      following?.status,
    ),
    defaultPortfolioKey: portfolios.at(0)?.key ?? undefined,
    portfoliosLoading,
    currency,
    period,
    portfolioView: PortfolioViewType.TWR,
    portfolioCalculation: CalculationBaseType.Gross,
    colors,
  }),
);

const showFullProfile = (
  isMyself?: boolean,
  visibility?: VisibilityLevel | null,
  followingStatus?: FollowActivityStatus,
): boolean =>
  (isMyself ?? false) ||
  (visibility ? visibility === VisibilityLevel.Public : false) ||
  (followingStatus ? followingStatus === FollowActivityStatus.ACTIVE : false);
