/******************************************************************************
 * GAME RANKING TYPES
 * VALUES MAY NOT BE CHANGED
 ******************************************************************************/
export enum RankingType {
  PrtfValue = 'PrtfValue', // portfolio value at end of period
  TrxCnt = 'TrxCnt', // transaction count within period
  Twr = 'Twr', // time weighted performance over period
  Vola = 'Vola', // volatility based on twr over period
  TwrByVola = 'TwrByVola', // time wheighted return div by volatility
  TotalReturn = 'TotalReturn', // total return over period
  ModDietz = 'ModDietz', // modified dietz over period
  GameReward = 'GameReward', // game rewards gained

  /** @deprecated */ TwrGross = 'TwrGross', // time weighted performance over period
  /** @deprecated */ VolaGross = 'VolaGross', // volatility based on twr over period
  /** @deprecated */ StdTwrGross = 'StdTwrGross', // volatility based on twr over period
  /** @deprecated */ TotalReturnNet = 'TotalReturnNet', // total return over period
}

/******************************************************************************
 * GAME REWARD TYPES
 * VALUES MAY NOT BE CHANGED
 ******************************************************************************/
export enum RewardType {
  TrxDays = 1,
  Join = 2,
  Referral = 3,
  Quiz = 4,
}
