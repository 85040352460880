<ion-item lines="none">
  <ion-input
    [label]="label"
    labelPlacement="stacked"
    [placeholder]="placeholder"
    [value]="selectedDate | date: dateFormat"
    [maskito]="dateMask"
    [maskitoElement]="maskPredicate"
    (ionInput)="onInputChange($event)"
  />
  <ion-button slot="end" fill="clear" [id]="modalId">
    <ion-icon slot="icon-only" name="calendar-outline" />
  </ion-button>
</ion-item>

<ion-modal [keepContentsMounted]="true" [trigger]="modalId">
  <ng-template>
    <ion-datetime
      size="fixed"
      presentation="date"
      [value]="selectedDate || today"
      [max]="today"
      [min]="minDate || undefined"
      [locale]="appLocale"
      [isDateEnabled]="isDateEnabled"
      [firstDayOfWeek]="1"
      [showDefaultButtons]="true"
      i18n-doneText
      doneText="Done"
      i18n-cancelText
      cancelText="Cancel"
      (ionChange)="onDateChange($event)"
      yeekateeFirstDayWeek
    />
  </ng-template>
</ion-modal>
