<div [ngClass]="cssClass" class="avatar grid items-center justify-items-center">
  <div
    class="rounded-full"
    [class.h-7]="size === AvatarSize.Tiny"
    [class.h-10]="size === AvatarSize.Small"
    [class.h-16]="size === AvatarSize.Medium"
    [class.h-32]="size === AvatarSize.Large"
  >
    <img
      yeekateeImgFallback
      [src]="src"
      [defaultSrc]="defaultSrc"
      alt="Avatar"
      loading="lazy"
    />
  </div>
</div>
