import { provideState } from '@ngrx/store';
import * as fromAccountLinking from './account-linking/account-linking.reducer';
import * as fromActivities from './activity-streams/activity-streams.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromFavourites from './favourites/favourites.reducer';
import * as fromGames from './games/games.reducer';
import * as fromInstruments from './instruments/instruments.reducer';
import { invitationsFeature } from './invitations';
import * as fromLocales from './locales/locales.reducer';
import * as fromMarketNews from './market-news/market-news.reducer';
import * as fromNavbar from './navbar/navbar.reducer';
import * as fromNotifications from './notifications/notifications.reducer';
import {
  portfolioReportsFeature,
  transactionsFeature,
  userPortfoliosFeature,
} from './portfolios';
import { userPropertiesFeature } from './portfolios/features/user-properties.selectors';
import * as fromTheme from './theme/theme.reducer';
import * as fromUsers from './users/users.reducer';

export const STATE_PROVIDERS = [
  provideState({
    name: fromAccountLinking.FEATURE_KEY,
    reducer: fromAccountLinking.accountLinkingReducer,
  }),
  provideState({
    name: fromActivities.FEATURE_KEY,
    reducer: fromActivities.reducers,
  }),
  provideState({
    name: fromAuth.AUTH_FEATURE_KEY,
    reducer: fromAuth.authReducer,
  }),
  provideState({
    name: fromFavourites.FAVOURITES_FEATURE_KEY,
    reducer: fromFavourites.favouritesReducer,
  }),
  provideState({
    name: fromGames.FEATURE_KEY,
    reducer: fromGames.reducers,
  }),
  provideState({
    name: fromInstruments.INSTRUMENTS_FEATURE_KEY,
    reducer: fromInstruments.reducers,
  }),
  provideState({
    name: fromLocales.LOCALES_FEATURE_KEY,
    reducer: fromLocales.localesReducer,
  }),
  provideState({
    name: fromMarketNews.FEATURE_KEY,
    reducer: fromMarketNews.reducers,
  }),
  provideState({
    name: fromNavbar.FEATURE_KEY,
    reducer: fromNavbar.navbarReducer,
  }),
  provideState({
    name: fromNotifications.FEATURE_KEY,
    reducer: fromNotifications.notificationsReducer,
  }),
  provideState(invitationsFeature),
  provideState(portfolioReportsFeature),
  provideState(transactionsFeature),
  provideState(userPropertiesFeature),
  provideState(userPortfoliosFeature),
  provideState({
    name: fromTheme.FEATURE_KEY,
    reducer: fromTheme.themeReducer,
  }),
  provideState({
    name: fromUsers.USERS_FEATURE_KEY,
    reducer: fromUsers.reducer,
  }),
];
