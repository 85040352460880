import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  ActivityStreamsActivity,
  ActivityStreamsActor,
  ActivityStreamsObject,
} from '@yeekatee/client-api-angular';
import * as ActivitiesActions from '../actions';
import { ActivitiesEffectsActions } from '../actions/activities-groups.actions';
import {
  isCreateEntity,
  isNoteEntity,
  isPersonEntity,
  ObjectsEntity,
} from '../activity-streams.models';

export const FEATURE_KEY = 'objects';

export type State = EntityState<ObjectsEntity>;

export const adapter = createEntityAdapter<ObjectsEntity>();

export const initialState: State = adapter.getInitialState({});

const objectsReducer = createReducer(
  initialState,
  on(
    ActivitiesActions.loadPersonInboxCollectionSuccess,
    ActivitiesActions.loadPersonOutboxCollectionSuccess,
    ActivitiesActions.loadAssetSharedInboxCollectionSuccess,
    ActivitiesActions.loadNotesRepliesCollectionSuccess,
    ActivitiesActions.loadPersonFollowingCollectionSuccess,
    ActivitiesActions.loadPersonFollowersCollectionSuccess,
    ActivitiesActions.loadChatCollectionSuccess,
    (state, collection): State =>
      collection.items
        ? adapter.upsertMany(
            extractObjectsFromActivities(collection.items),
            state,
          )
        : state,
  ),
  on(ActivitiesActions.apiCreateNote, (state, { activity }): State => {
    if (!activity.context) return state;
    const objects = extractObjectsFromActivities([activity]);
    return objects ? adapter.upsertMany(objects, state) : state;
  }),
  on(
    ActivitiesActions.loadNotesLikesCollectionSuccess,
    ActivitiesActions.loadPersonLikedCollectionSuccess,
    (state, collection): State =>
      collection.items
        ? adapter.upsertMany(
            extractObjectsFromActivities(collection.items),
            state,
          )
        : state,
  ),
  on(
    ActivitiesActions.apiCreateNoteSuccess,
    ActivitiesActions.apiCreateNote,
    ActivitiesActions.apiLikeNote,
    ActivitiesActions.followUserResult,
    ActivitiesEffectsActions.handleNotification,
    ActivitiesEffectsActions.handleFollowNotification,
    (state, { activity }): State => {
      const objects = extractObjectsFromActivities([activity]);
      return objects ? adapter.upsertMany(objects, state) : state;
    },
  ),
  on(
    ActivitiesActions.apiGetActivitySuccess,
    (state, { activity, parent }): State => {
      const objects = extractObjectsFromActivities([
        ...(activity ? [activity] : []),
        ...(parent ? [parent] : []),
      ]);
      return objects.length ? adapter.upsertMany(objects, state) : state;
    },
  ),
  on(
    ActivitiesActions.apiDeleteNote,
    (state, { activity }): State =>
      activity?.object?.id
        ? adapter.removeOne(activity.object.id, state)
        : state,
  ),
);

/**
 * Given a list of activities (as it may be coming from a Collection API),
 * convert it into a list of ObjectsEntity, as kept in the store.
 *
 * @param activities
 * @param extractObject
 */
const extractObjectsFromActivities = (
  activities?: ActivityStreamsActivity[],
): ObjectsEntity[] =>
  activities?.length
    ? activities
        .flatMap((activity) =>
          isCreateEntity(activity)
            ? [
                castApiObjectToStoreObject(activity?.object),
                castApiActorToStoreObject(activity?.actor),
              ]
            : [castApiActorToStoreObject(activity?.actor)],
        )
        .filter((entity): entity is ObjectsEntity => !!entity)
    : [];

/**
 * When possible, convert an object as received by the API
 * into the correct, typed, representation for our store.
 *
 * @param object
 */
const castApiObjectToStoreObject = (
  object?: ActivityStreamsObject | ActivityStreamsActor | null,
): ObjectsEntity | undefined => {
  if (!object?.id && !object?.type) return undefined;
  if (!isNoteEntity(object) && !isPersonEntity(object)) return undefined;

  if (isPersonEntity(object) && object.name) {
    return {
      id: object.id,
      name: object.name,
      type: object.type,
      summary: object.summary,
      icon: object.icon,
      location: object.location,
    };
  }

  if (isNoteEntity(object)) {
    return {
      id: object.id,
      content: object.content,
      type: object.type,
      inReplyTo: object.inReplyTo,
      attributedTo: object.attributedTo,
      image: (object.image ?? []).map((i) => ({
        id: i.id,
        type: i.type,
        content: i.content,
        height: i.height,
        width: i.width,
        url: i.url,
        key: i.key,
        name: i.name,
      })),
      tag: (object.tag ?? []).map((t) => ({
        id: t.id,
        name: t.name,
        type: t.type,
      })),
      url: (object.url ?? []).map((u) => ({
        name: u.name,
        href: u.href,
        type: u.type,
      })),
      published: object.published,
    };
  }

  return undefined;
};

/**
 * When possible, convert an actor as received by the API
 * into the correct, typed, representation for our store.
 *
 * @param actor
 */
const castApiActorToStoreObject = (
  actor?: ActivityStreamsActor | null,
): ObjectsEntity | undefined => {
  if (!actor?.id) return undefined;
  if (!isPersonEntity(actor)) return undefined;

  return {
    id: actor.id,
    name: actor.name,
    type: actor.type,
    summary: actor.summary,
    icon: actor.icon,
    location: actor.location,
  };
};

export function reducer(state: State | undefined, action: Action) {
  return objectsReducer(state, action);
}
