<div class="border-t-4 border-ion_secondary">
  <ion-item lines="none">
    <ion-icon
      name="information-circle-outline"
      slot="start"
      color="secondary"
    />
    <ion-label>
      <h2>{{ title }}</h2>
    </ion-label>
  </ion-item>
  <div class="px-4 py-3">
    <p
      ><b>{{ result }}</b> = {{ formula }}</p
    >
    @if (alternateformula) {
      <p class="mt-2"
        ><b>{{ result }}</b> = {{ alternateformula }}</p
      >
    }
    <p class="text-sm italic font-normal mt-2">{{ info }}</p>
  </div>
</div>
