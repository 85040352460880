import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SortingType } from '../instruments';
import { errorProps } from '../utils';
import { FavouritesEntity } from './favourites.models';

export const FavouritesApiActions = createActionGroup({
  source: 'Favourites/API',
  events: {
    'Load Favourites Success': props<{
      favourites: FavouritesEntity[];
      loadUsers?: boolean;
      loadInstruments?: boolean;
    }>(),
    'Load Favourites Failure': errorProps(),
    'Load Favourite Success': props<{
      favourite: FavouritesEntity;
    }>(),
    'Load Favourite Failure': errorProps(),

    // User
    'Add User Success': props<{
      favourites: FavouritesEntity[];
    }>(),
    'Add User Failure': errorProps(),
    'Remove User Success': props<{
      favourites: FavouritesEntity[];
    }>(),
    'Remove User Failure': errorProps(),

    // Instrument
    'Add Instrument Success': props<{
      favourites: FavouritesEntity[];
    }>(),
    'Add Instrument Failure': errorProps(),
    'Remove Instrument Success': props<{
      favourites: FavouritesEntity[];
    }>(),
    'Remove Instrument Failure': errorProps(),
  },
});

export const FavouritesListActions = createActionGroup({
  source: 'Favourites List',
  events: {
    Leave: emptyProps(),
    Init: emptyProps(),

    'Remove User': props<{ userId: string }>(),
    'Remove Instrument': props<{
      instrumentId: string;
      symbol: string;
      mic?: string;
    }>(),

    'Sort Discover Instrument Category View': props<{
      sortingType: SortingType;
    }>(),

    'Redirect To Auth': props<{ lastRoute: string }>(),
  },
});
