import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { MarketNewsIdentifier } from '@yeekatee/client-api-angular';
import { HomePageActions } from '../../home/home.actions';
import { MarketNewsApiActions } from '../market-news.actions';
import { MarketNewsStoryEntity } from '../market-news.models';

export const FEATURE_KEY = 'stories';

export type State = EntityState<MarketNewsStoryEntity> & {
  timeline: {
    loading: boolean;
    identifiers: Omit<MarketNewsIdentifier, '__typename'>[];
    stories: MarketNewsStoryEntity[];
  };
};

export const mStoriesAdapter = createEntityAdapter<MarketNewsStoryEntity>();
export const initialState: State = mStoriesAdapter.getInitialState({
  timeline: { loading: false, identifiers: [], stories: [] },
});

const mapInput = (identifiers: MarketNewsIdentifier[]) =>
  identifiers.map(({ identifier, instrumentId, nextToken }) => ({
    identifier,
    instrumentId,
    nextToken,
  })) satisfies Omit<MarketNewsIdentifier, '__typename'>[];

const mStoriesReducer = createReducer(
  initialState,
  on(
    MarketNewsApiActions.loadMarketNewsSuccess,
    MarketNewsApiActions.loadMoreMarketNewsSuccess,
    (state: State, { stories }) => mStoriesAdapter.upsertMany(stories, state),
  ),
  on(
    MarketNewsApiActions.loadMarketNewsTimelineSuccess,
    (state: State, { stories, identifiers }) => ({
      ...state,
      timeline: {
        loading: false,
        identifiers: mapInput(identifiers),
        stories,
      },
    }),
  ),
  on(
    MarketNewsApiActions.loadMoreMarketNewsTimelineSuccess,
    (state: State, { stories, identifiers }) => ({
      ...state,
      timeline: {
        loading: false,
        identifiers: mapInput(identifiers),
        stories: [...(state.timeline.stories ?? []), ...stories],
      },
    }),
  ),
  on(
    MarketNewsApiActions.loadMarketNewsTimelineFailure,
    MarketNewsApiActions.loadMoreMarketNewsTimelineFailure,
    (state: State) => ({
      ...state,
      timeline: { ...state.timeline, loading: false },
    }),
  ),
  on(
    HomePageActions.init,
    HomePageActions.refreshMarketNews,
    (state: State) => ({
      ...state,
      timeline: { ...state.timeline, loading: true },
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return mStoriesReducer(state, action);
}
