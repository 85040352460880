import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

/**
 * Ionic ion-datetime component enhanced to emit on month change.
 * While this does look like a hack, it is currently the only way to get the
 * month and year from the Ionic calendar component.
 *
 * @todo convince Ionic to add a proper API for this, we should tell them
 *       that Flutter has this feature.
 *
 * @see https://github.com/ionic-team/ionic-framework/issues/25730
 * @see https://api.flutter.dev/flutter/material/CalendarDatePicker/onDisplayedMonthChanged.html
 */
@Directive({
  selector: 'ion-datetime[yeekateeMonthChangeEvent]',
  standalone: true,
})
export class MonthChangeEventDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() ionMonthChange = new EventEmitter<{
    month: number;
    year: number;
  }>();

  private observer?: MutationObserver;

  constructor(private readonly el: ElementRef) {}

  ngOnInit() {
    this.observer = new MutationObserver(() => this.onCalendarMonthChange());
  }

  ngAfterViewInit() {
    // A timeout to ensure the calendar is rendered before we try to observe it.
    // TODO This is a hack, we should find a better way to do this.
    setTimeout(() => {
      const calendarBodyEl =
        this.el.nativeElement.shadowRoot?.querySelector('.calendar-body');

      if (!calendarBodyEl || !this?.observer) return;

      this.observer.observe(calendarBodyEl, {
        subtree: true,
        childList: true,
      });
    }, 100);
  }

  ngOnDestroy() {
    this?.observer?.disconnect();
  }

  private onCalendarMonthChange() {
    // This is ridiculous, but it works...
    // Ionic renders 3 months in the calendar, the first and last one are not visible.
    // The middle one is the one that is visible. We get the first `button`
    // element of the middle month to extract the month and year from data attributes.
    const calendarDayEl = this.el.nativeElement.shadowRoot?.querySelector(
      'div.calendar-month:nth-child(2) > div > div.calendar-day-wrapper > button',
    );

    if (!calendarDayEl) return;

    const monthAttr = calendarDayEl.getAttribute('data-month');
    const yearAttr = calendarDayEl.getAttribute('data-year');

    if (!monthAttr || !yearAttr) return;

    const month = +monthAttr;
    const year = +yearAttr;

    this.ionMonthChange.emit({ month, year });
  }
}
