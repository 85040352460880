import { createSelector } from '@ngrx/store';
import { AuthSelectors } from '../auth';
import { userPropertiesFeature } from '../portfolios/features/user-properties.selectors';

export const selectCurrencySettingsVM = createSelector(
  AuthSelectors.selectUser,
  userPropertiesFeature.selectUserRepCurrency,
  userPropertiesFeature.selectUserValCurrencies,
  (user, repCurrency, valCurrencies) => ({
    userId: user?.id,
    repCurrency,
    valCurrencies,
  }),
);
