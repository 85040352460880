import { ApolloLink, fromPromise, InMemoryCache } from '@apollo/client/core';
import { DefaultOptions } from '@apollo/client/core/ApolloClient';
import { Environment } from '@yeekatee/shared-util-environment';
import { HttpLink } from 'apollo-angular/http';
import { Auth } from 'aws-amplify';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';

export function createApollo(environment: Environment, ngHttpLink: HttpLink) {
  const url = environment.aws.aws_appsync_graphqlEndpoint;

  const region = environment.aws.aws_appsync_region;

  const cognitoAuthLink = createAuthLink({
    url,
    region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
  });

  const iamAuthLink = createAuthLink({
    url,
    region,
    auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: () => Auth.currentCredentials(),
    },
  });

  const httpLink = ngHttpLink.create({ uri: url });

  const isAuthenticated$ = () =>
    fromPromise(
      Auth.currentUserPoolUser()
        .then(() => true)
        .catch(() => false),
    );

  const splitAuthLink = new ApolloLink((operation, forward) =>
    isAuthenticated$().flatMap((isAuthenticated) =>
      (isAuthenticated ? cognitoAuthLink : iamAuthLink).request(
        operation,
        forward,
      ),
    ),
  );

  // TODO: Should we introduce a retry link here?
  // const retryLink = new RetryLink();

  const link = ApolloLink.from([splitAuthLink, httpLink]);

  const cache = new InMemoryCache({
    typePolicies: {
      PortfolioReport: {
        keyFields: [
          'id',
          'reportPeriod',
          ['startDate', 'endDate'],
          'aggregation',
          ['reportValues', ['currency']],
          'items',
          ['id'],
        ],
      },
      OwnPortfolioReport: {
        keyFields: [
          'id',
          'reportPeriod',
          ['startDate', 'endDate'],
          'aggregation',
          ['reportValues', ['currency']],
          'items',
          ['id'],
        ],
      },
      PortfolioItem: {
        keyFields: ['id', 'reportValues', ['currency']],
      },
      OwnPortfolioItem: {
        keyFields: ['id', 'reportValues', ['currency']],
      },
      PortfolioInstrument: {
        keyFields: ['id', 'symbol', 'mic', 'name'],
      },
      IStock: {
        keyFields: ['id', 'symbol', 'mic'],
      },
      IForex: {
        keyFields: ['id', 'symbol'],
      },
      IIndex: {
        keyFields: ['id', 'symbol'],
      },
    },
    possibleTypes: {
      INode: [
        'User',
        'Stock',
        'TimeSeries',
        'Etf',
        'Forex',
        'Crypto',
        'Index',
        'GameReward',
        'Account',
      ],
      AuthUser: ['User'],
      Instrument: ['Stock', 'Etf', 'Forex', 'Crypto', 'Index', 'GameReward'],
      IStock: ['Stock', 'Etf'],
      IInstrument: ['Stock', 'Etf', 'Forex', 'Crypto', 'Index', 'GameReward'],
      IForex: ['Forex', 'Crypto', 'GameReward'],
      IIndex: ['Index'],
      InstrumentFundamentals: [
        'StockFundamentals',
        'EtfFundamentals',
        'ForexFundamentals',
        'CryptoFundamentals',
        'IndexFundamentals',
        'GameRewardFundamentals',
      ],
    },
  });

  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  return {
    link,
    cache,
    defaultOptions,
  };
}
