import { TimeSeriesPeriod } from '@yeekatee/client-api-typescript';

export const getQuoteId = (
  instrumentId?: string | null,
  symbol?: string | null,
  mic?: string | null,
) => `${instrumentId}#${symbol}${mic ? `#${mic}` : ''}`;

export const destructureQuoteId = (quoteId: string) => {
  const result = quoteId.split('#');
  return { id: result.at(0), symbol: result.at(1), mic: result.at(2) ?? null };
};

export const getTimeSeriesId = (
  period: string | TimeSeriesPeriod,
  instrumentId: string,
  symbol: string,
  mic?: string | null,
) => `${instrumentId}#${period}#${symbol}${mic ? `#${mic}` : ''}`;
