import { Action, createReducer, on } from '@ngrx/store';
import { ActivitiesTimelineActions } from '../activity-streams/actions/activities-groups.actions';
import { FavouritesListActions } from '../favourites/favourites.actions';
import { GamesListActions } from '../games/games.actions';
import { HomePageActions } from '../home';
import { DiscoverViewActions } from '../instruments/instruments.actions';
import { PortfolioActions } from '../portfolios/portfolios.actions';
import { UsersApiActions } from '../users/users.actions';
import {
  AuthAPIActions,
  AuthCapacitorActions,
  AuthEffectsActions,
  AuthEmailConfirmActions,
  AuthEmailSettingsActions,
  AuthForgotPasswordActions,
  AuthForgotPasswordEmailConfirmActions,
  AuthGuardActions,
  AuthLaunchActions,
  AuthPasswordConfirmSettingsActions,
  AuthPasswordSettingsActions,
  AuthPhoneConfirmActions,
  AuthSignInActions,
  AuthSignUpActions,
} from './auth.actions';
import { SignInCredentials, User, ViewState } from './models';

export const AUTH_FEATURE_KEY = 'auth';

export interface State {
  view: ViewState;
  loading: boolean;
  userId?: string;
  user?: User;
  username?: string;
  challengeDestination?: string;
  lastRoute?: string;
  updatedCredentials?: SignInCredentials;
}

export interface AuthPartialState {
  [AUTH_FEATURE_KEY]: State;
}

export const initialState: State = {
  view: ViewState.Launch,
  loading: false,
};

const reducer = createReducer(
  initialState,
  on(AuthAPIActions.signOutFailure, () => initialState),
  on(
    AuthLaunchActions.toSignUp,
    AuthEmailConfirmActions.backToSignUp,
    (state) => ({
      ...state,
      view: ViewState.SignUp,
    }),
  ),
  on(AuthAPIActions.signUpSuccess, (state, { user }) => ({
    ...state,
    view: ViewState.ConfirmEmail,
    challengeDestination: user.challengeName,
  })),
  on(
    AuthLaunchActions.toSignIn,
    AuthPhoneConfirmActions.backToSignIn,
    AuthForgotPasswordActions.backToSignIn,
    (state) => ({
      ...state,
      view: ViewState.SignIn,
    }),
  ),
  on(
    AuthSignUpActions.backToLaunch,
    AuthSignInActions.backToLaunch,
    (state) => ({ ...state, view: ViewState.Launch }),
  ),
  on(
    AuthSignInActions.toForgotPassword,
    AuthForgotPasswordEmailConfirmActions.backToForgotPassword,
    (state) => ({
      ...state,
      view: ViewState.ForgotPassword,
    }),
  ),
  on(AuthAPIActions.resetPasswordSuccess, (state, { destination }) => ({
    ...state,
    view: ViewState.ConfirmPassword,
    challengeDestination: destination,
  })),
  on(
    AuthForgotPasswordActions.resetPassword,
    AuthPasswordSettingsActions.resetPassword,
    (state, { credentials }) => ({
      ...state,
      updatedCredentials: credentials,
    }),
  ),
  on(AuthAPIActions.confirmPasswordSuccess, (state) => ({
    ...state,
    view: ViewState.SignIn,
    updatedCredentials: undefined,
  })),
  on(
    AuthLaunchActions.launchInit,
    AuthSignUpActions.signUp,
    AuthEmailConfirmActions.resendCode,
    AuthEmailConfirmActions.verifyCode,
    AuthSignInActions.signIn,
    AuthPhoneConfirmActions.verifyCode,
    AuthForgotPasswordActions.resetPassword,
    AuthPasswordSettingsActions.resetPassword,
    AuthForgotPasswordEmailConfirmActions.resendCode,
    AuthPasswordConfirmSettingsActions.resendCode,
    AuthForgotPasswordEmailConfirmActions.verifyCode,
    AuthPasswordConfirmSettingsActions.verifyCode,
    AuthCapacitorActions.federatedSignInCallback,
    (state) => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    AuthAPIActions.signUpSuccess,
    AuthAPIActions.signUpFailure,
    AuthAPIActions.resendSignUpSuccess,
    AuthAPIActions.resendSignUpFailure,
    AuthAPIActions.confirmSignUpSuccess,
    AuthAPIActions.confirmSignUpFailure,
    AuthAPIActions.signInSuccess,
    AuthAPIActions.signInFailure,
    AuthAPIActions.confirmSignInSuccess,
    AuthAPIActions.confirmSignInFailure,
    AuthAPIActions.resetPasswordSuccess,
    AuthAPIActions.resetPasswordFailure,
    AuthAPIActions.confirmPasswordSuccess,
    AuthAPIActions.confirmPasswordSettingsSuccess,
    AuthAPIActions.confirmPasswordFailure,
    AuthAPIActions.confirmPasswordSettingsFailure,
    AuthAPIActions.signInPresentedChallenge,
    AuthEffectsActions.federatedSignInSuccess,
    AuthEffectsActions.federatedSignInFailure,
    AuthEffectsActions.getCurrentUserSuccess,
    AuthEffectsActions.getCurrentUserFailure,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    AuthGuardActions.redirectingToAuth,
    AuthEffectsActions.redirectToAuth,
    AuthEffectsActions.parsedCustomOAuthStateSuccess,
    HomePageActions.redirectToAuth,
    ActivitiesTimelineActions.redirectToAuth,
    PortfolioActions.redirectToAuth,
    GamesListActions.redirectToAuth,
    FavouritesListActions.redirectToAuth,
    DiscoverViewActions.redirectToAuth,
    (state, { lastRoute }) => ({
      ...state,
      lastRoute,
    }),
  ),
  on(AuthCapacitorActions.loadUsernameSuccess, (state, { username }) => ({
    ...state,
    username,
  })),
  on(
    AuthEffectsActions.getCachedUserSuccess,
    AuthEffectsActions.getCurrentUserSuccess,
    AuthEffectsActions.refreshCurrentUserRouteBackSuccess,
    AuthEffectsActions.refreshCurrentUserSuccess,
    AuthAPIActions.signInSuccess,
    AuthAPIActions.signUpSuccess,
    AuthEffectsActions.federatedSignInSuccess,
    AuthEmailSettingsActions.setEmail,
    (state, { user }) => ({
      ...state,
      userId: user.id,
      username: user.email,
      user: { ...state.user, ...user },
    }),
  ),
  on(
    UsersApiActions.updateUserSuccess,
    (state, { user: { preferred_username } }) => ({
      ...state,
      user: { ...state.user, preferred_username },
    }),
  ),
);

export function authReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
