import { defineEnvironment } from './default-environment';

/**
 * Just for automated tests.
 */
export const testEnvironment = defineEnvironment(
  {
    AwsAccount: '123456789012',
    CognitoIdentityPoolId: 'test',
    CognitoUserPoolId: 'test',
    CognitoUserPoolWebClientId: 'test',
    PinpointAppId: 'test',
    Region: 'eu-west-1',
    S3Bucket: 'test',
  },
  {
    production: false,
    oauthRedirectUrl: '',
    sentry: {
      enabled: false,
      enableNative: false,
      dsn: '',
      captureNgrx: false,
    },
    featuresConfig: { enableMarketNews: true },
    flanksLinkToken: { local: 'test', native: 'test' },
  },
);
