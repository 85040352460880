export enum SCATypesFlanksYeekatee {
  notNeeded = 'notNeeded',
  sms = 'sms',
  email = 'email',
  app = 'app',
  call = 'call',
  code = 'code',
  codeDevice = 'codeDevice',
  synchronousApp = 'synchronousApp',
  coordinate = 'coordinate',
  coordinateCard = 'coordinateCard',
  secureKey = 'secureKey',
  qrInput = 'qrInput',
  qrAccept = 'qrAccept',
  qrCodeOrOk = 'qrCodeOrOk',
  cryptoCalculator = 'cryptoCalculator',
  cardReader = 'cardReader',
  digipass = 'digipass',
  miscellaneous = 'miscellaneous',
  bankId = 'bankId',
  updatableQr = 'updatableQr',
  googleAuthenticator = 'googleAuthenticator',
}

export enum InfoTypes {
  nextBusinessDayDelay = 'nextBusinessDayDelay',
  qrCodeAuthentication = 'qrCodeAuthentication',
  swissQuoteIndividual = 'swissQuoteIndividual',
  coinbaseIndividual = 'coinbaseIndividual',
  etoroIndividual = 'etoroIndividual',
}

export interface BaseBank {
  id: string;
  flanksId: string;
  name: string;
  logo?: string;
  integration: string;
  enabled: boolean;
  info?: InfoTypes;
  howtoLink?: string;

  /**
   * ID previously used by the booking engine, for backwards compatibility
   *
   * @deprecated use the id property instead
   */
  bookingEngineId: string;
}

export interface FlanksSupportedBank extends BaseBank {
  integration: 'flanks';

  /**
   * The ID used by Flanks in the App Link.
   */
  flanksId: string;
  /**
   * Flanks signature of a banks mfa
   */
  scas?: Array<SCATypesFlanksYeekatee>;
}

export interface YeeSupportedBank extends BaseBank {
  integration: 'yeekatee';
}
