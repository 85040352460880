/**
 * Interface for the 'Favourites' data
 */
import { Dictionary } from '@ngrx/entity';
import {
  FavouriteType,
  TimeSeriesItem,
  TimeSeriesPeriod,
} from '@yeekatee/client-api-angular';
import {
  getQuoteId,
  getTimeSeriesId,
} from '@yeekatee/shared-util-typesafe-dynamodb';
import { ChartData } from 'chart.js';
import {
  InstrumentsEntity,
  QuotesEntity,
  TimeSeriesEntity,
  mapTimeSeriesToChartData,
} from '../instruments';
import { ThemeColors } from '../theme/theme.model';
import { UsersEntity } from '../users';

export interface FavouritesEntity {
  id: string; // Primary ID
  type: 'FINANCIAL_INSTRUMENT' | 'USER';
  objectId: string;
  symbol?: string;
  mic?: string;
}

export type FavouritesInstrument = Partial<InstrumentsEntity> & {
  sparkline?: ChartData<'line', TimeSeriesItem[]>;
};

export function mapToFavouriteInstrument(
  instrument?: InstrumentsEntity,
  quotesEntities?: Dictionary<QuotesEntity>,
  timeSeriesEntities?: Dictionary<TimeSeriesEntity>,
  colors?: ThemeColors,
): FavouritesInstrument | undefined {
  if (!instrument) return undefined;

  const { id, symbol, mic } = instrument;
  const defaultFavourite = {
    id,
    name: instrument?.name,
    symbol,
    mic,
  };
  if (!instrument?.id || !symbol) return defaultFavourite;

  const day = timeSeriesEntities?.[
    getTimeSeriesId(TimeSeriesPeriod.day, id, symbol, mic)
  ]?.items as TimeSeriesItem[];

  const quote = quotesEntities?.[getQuoteId(id, symbol, mic)];

  return {
    ...defaultFavourite,
    logoUrl: instrument.logoUrl,
    quote,
    latestPrice: instrument.latestPrice,
    type: instrument.type,
    sparkline:
      day && quote?.previousClose
        ? mapTimeSeriesToChartData(
            day,
            TimeSeriesPeriod.day,
            quote.previousClose,
            colors,
            undefined,
            false,
            true,
          )
        : undefined,
  };
}

export type FavouriteHeader = {
  kind: 'header';
  type: FavouriteType;
  button: boolean;
};

export const favInstrumentsHeader: FavouriteHeader = {
  kind: 'header',
  type: FavouriteType.FINANCIAL_INSTRUMENT,
  button: true,
};

export const favUsersHeader: FavouriteHeader = {
  kind: 'header',
  type: FavouriteType.USER,
  button: false,
};

export type FavouriteInstrumentItem = {
  kind: 'instrument';
  instrument?: FavouritesInstrument;
};

export type FavouriteUserItem = {
  kind: 'user';
  user: UsersEntity;
};

export type FavouriteListItem =
  | FavouriteHeader
  | FavouriteInstrumentItem
  | FavouriteUserItem;
