import {
  BalanceSheetHist,
  Crypto,
  DistributionFrequency,
  DistributionPolicy,
  Dividend,
  DividendCalendar,
  EarningsCall,
  Etf,
  Forex,
  ForexType,
  GameReward,
  Holding,
  IncomeStatementHist,
  Index,
  Instrument,
  InstrumentFundamentals,
  Listing,
  MainAssetClass,
  Maybe,
  Quote,
  ReplicationType,
  SectorType,
  Stock,
  TimeSeries,
  TimeSeriesItem,
  TimeSeriesPeriod,
} from '@yeekatee/client-api-angular';

import { Dictionary } from '@ngrx/entity';
import {
  setChartFill,
  setDynamicBorderColor,
} from '@yeekatee/shared-charts-util-functions';
import { Country, countries } from '@yeekatee/shared-ui';
import { getQuoteId } from '@yeekatee/shared-util-typesafe-dynamodb';
import {
  ChartData,
  ChartDataset,
  Color,
  ComplexFillTarget,
  ScriptableContext,
} from 'chart.js';
import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import { FavouritesInstrument } from '../favourites';
import { ThemeColors } from '../theme/theme.model';

export type QuotesEntity = Quote & { loading?: boolean };

export type TimeSeriesEntity = TimeSeries;

type BaseInstrumentsEntity = {
  type?: 'Stock' | 'Etf' | 'Forex' | 'Crypto' | 'Index' | 'GameReward';
  id: string; // Primary ID
  name?: string;
  baseCurrency?: string;
  symbol?: string;
  isin?: string;
  mic?: string;
  fundamentals?: InstrumentFundamentals;
  timeSeries?: TimeSeries[];
  quote?: QuotesEntity;
  latestPrice?: Price;
  logoUrl?: string;
  ytdPricePerf?: number;
  priceDelay?: string;
  logoPath?: string;
  defaultListingCurrency?: string;
  rank?: number;
};

export type DividendCalendarEntity = DividendCalendar & {
  logoUrl?: string;
};

export type InstrumentsEntity = Omit<Instrument, 'timeSeries'> &
  BaseInstrumentsEntity;

export type FinancialChartItem = {
  date: string;
  value: number;
};
export type FinancialChartData = ChartData<'bar', FinancialChartItem[]>;

export type FinancialChart = {
  annually?: FinancialChartData;
  quarterly?: FinancialChartData;
};

export type FinancialCharts = {
  incomeStatement?: FinancialChart;
  balanceSheet?: FinancialChart;
  fiscalDates: {
    annually?: string[];
    quarterly?: string[];
  };
};

export type StockEntity = Stock &
  BaseInstrumentsEntity & {
    type: 'Stock';
    sectorImage?: string;
    sectorNameI18n?: string;
    yearlyPricePerf?: ChartData<'bar'>;
    yearlyDividends?: YearDistr[];
    lastDividend?: LastFullDividend;
    financialCharts?: FinancialCharts;
  };

export enum IconType {
  COUNTRY,
  SECTOR,
  ASSET,
}

export type AllocationWithIcon = Omit<Holding, '__typename'> & {
  image?: string;
  iconType?: IconType;
};

export type EtfEntity = Etf &
  BaseInstrumentsEntity & {
    type: 'Etf';
    riskCategory?: string; // Calculated value in the selector based on the fundSize
    oneYearVolatility?: number;
    oneYearVolatilityRiskScore?: number;
    yearlyPricePerf?: ChartData<'bar'>;
    yearlyDividends?: YearDistr[];
    lastDividend?: LastFullDividend;
    distributionPolicyI18n: string;
    distributionFrequencyI18n: string;
    replicationTypeI18n: string;
    mainAssetClassI18n: string;
    sectors: AllocationWithIcon[];
    countries: AllocationWithIcon[];
    investmentOpportunities: AllocationWithIcon[];
    top10Holdings: AllocationWithIcon[];
  };

export type IndexEntity = Index &
  BaseInstrumentsEntity & {
    type: 'Index';
    sectors: AllocationWithIcon[];
    constitutes: AllocationWithIcon[];
    investmentOpportunities: AllocationWithIcon[];
  };

export type ForexEntity = Forex &
  BaseInstrumentsEntity & {
    type: 'Forex';
    investmentOpportunities: AllocationWithIcon[];
  };

export type CryptoEntity = Crypto &
  BaseInstrumentsEntity & {
    type: 'Crypto';
    investmentOpportunities: AllocationWithIcon[];
  };

export type GameRewardEntity = GameReward &
  BaseInstrumentsEntity & {
    type: 'GameReward';
  };

export type AssetEntity =
  | InstrumentsEntity
  | StockEntity
  | EtfEntity
  | ForexEntity
  | CryptoEntity
  | IndexEntity
  | GameRewardEntity;

// TODO Align with Rthub.Prices
export type Price = {
  price: number;
  timestamp?: number;
  dayVolume?: string;
  currency?: string;
};

export type EarningsCallEntity = EarningsCall & { announcementText?: string };

export const isStockEntity = (
  instrument: AssetEntity | undefined | null,
): instrument is StockEntity => instrument?.type === 'Stock';

export const isEtfEntity = (
  instrument: AssetEntity | undefined | null,
): instrument is EtfEntity => instrument?.type === 'Etf';

export const isCryptoEntity = (
  instrument: AssetEntity | undefined | null,
): instrument is CryptoEntity => instrument?.type === 'Crypto';

export const isForexEntity = (
  instrument: AssetEntity | undefined | null,
): instrument is ForexEntity => instrument?.type === 'Forex';

export const isIndexEntity = (
  instrument: AssetEntity | undefined | null,
): instrument is IndexEntity => instrument?.type === 'Index';

export const isGameRewardEntity = (
  instrument: AssetEntity | undefined | null,
): instrument is GameRewardEntity => instrument?.type === 'GameReward';

export const isTradeable = (
  instrument: AssetEntity | undefined | null,
): boolean =>
  isStockEntity(instrument) ||
  isEtfEntity(instrument) ||
  isCryptoEntity(instrument);

export const isNonPreciousMetalForex = (instrument: AssetEntity): boolean =>
  isForexEntity(instrument) && instrument.listings?.[0]?.type !== ForexType.PM;

export function extractLogoPath(logoUrl: string | undefined) {
  return logoUrl?.substring(0, logoUrl?.lastIndexOf('/') + 1);
}

export enum LocationFilter {
  PORTFOLIO = 'Portfolio',
  FAVOURITES = 'Favourites',
}

export enum DividendFilterOption {
  STOCK = 'Stock',
  ETF = 'ETF',
  ALL = 'All',
  PORTFOLIO = 'Portfolio',
  FAVOURITES = 'Favourites',
}

export enum MarketMic {
  XFRA = 'XFRA',
  XNYS = 'XNYS',
  XPAR = 'XPAR',
  XSWX = 'XSWX',
  XETR = 'XETR',
  XHKG = 'XHKG',
  XLON = 'XLON',
  XTSE = 'XTSE',
  MERK = 'MERK',
  XNGS = 'XNGS',
  XBRU = 'XBRU',
  XAMS = 'XAMS',
  XOSL = 'XOSL',
  XOAS = 'XOAS',
  XNMS = 'XNMS',
  XSTO = 'XSTO',
  XCSE = 'XCSE',
  XSHG = 'XSHG',
  XLIS = 'XLIS',
  XHEL = 'XHEL',
  ALXP = 'ALXP',
  XMSM = 'XMSM',
  XESM = 'XESM',
  XMIL = 'XMIL',
  XJPX = 'XJPX',
  ALXL = 'ALXL',
  ARCX = 'ARCX',
  BATS = 'BATS',
}

// These delays only apply for Stocks and ETFs provided by TwelveData. When we start adding more price providers we should shift this to the backend.
// Now everything depends on TwelveData anyway.
// One more note: Indices such as GDAX for example are calculated by Xetra but seem to be real-time.
export const exchangeMarketMics: Record<MarketMic, string> = {
  // 15 Minutes Delay - Euronext Exchanges
  [MarketMic.XAMS]: '15min', // Euronext Amsterdam
  [MarketMic.XBRU]: '15min', // Euronext Brussels
  [MarketMic.XLIS]: '15min', // Euronext Lisbon
  [MarketMic.ALXL]: '15min', // Euronext Growth Lisbon
  [MarketMic.XPAR]: '15min', // Euronext Paris
  [MarketMic.ALXP]: '15min', // Euronext Growth Paris (Not sure about this one)
  [MarketMic.XMSM]: '15min', // Euronext Dublin
  [MarketMic.XESM]: '15min', // Euronext Growth Dublin
  // 15 Minutes Delay - German Exchanges
  [MarketMic.XETR]: '15min', // Deutsche Börse Xetra
  [MarketMic.XFRA]: '15min', // Frankfurt Stock Exchange
  // 15 Minutes Delay - Varous Exchanges
  [MarketMic.XOAS]: '15min', // Euronext Expand Oslo (No real-time for this one, presumably 15min)
  [MarketMic.MERK]: '15min', // Euronext Growth Oslo (No real-time for this one, presumably 15min)
  [MarketMic.XLON]: '15min', // Not specifically listed on twelvedata but appears 15min delay
  // 20 Minutes Delay
  [MarketMic.XMIL]: '20min',
  [MarketMic.XJPX]: '20min', // Tokyo Stock Exchange
  // 30 Minutes Delay
  [MarketMic.XSWX]: '30min', // Swiss Stock Exchange
  [MarketMic.XSHG]: '30min', // Shanghai Stock Exchange

  // Real-time data  - U.S. and Canadan Exchanges
  [MarketMic.ARCX]: 'Real-time', // NYSE ARCA
  [MarketMic.XNYS]: 'Real-time', // New York Stock Exchange (NYSE)
  [MarketMic.XNMS]: 'Real-time', // NASDAQ/NGS (GLOBAL SELECT MARKET)
  [MarketMic.XNGS]: 'Real-time', // NASDAQ/NMS (GLOBAL MARKET)
  [MarketMic.BATS]: 'Real-time',
  [MarketMic.XTSE]: 'Real-time', // Toronto Stock Exchange (TSX)
  // Real-time data  - Nordics / NASDAQ
  [MarketMic.XSTO]: 'Real-time', // NASDAQ STOCKHOLM AB (Stockholm)
  [MarketMic.XHEL]: 'Real-time', // NASDAQ HELSINKI LTD (Helsinki)
  [MarketMic.XCSE]: 'Real-time', // NASDAQ COPENHAGEN A/S (Copenhagen)
  [MarketMic.XHKG]: '', // ??
  [MarketMic.XOSL]: '', // Oslo Stock Exchange not supported
};

export const exchangeMarketMicsToCountry: Record<MarketMic, string> = {
  // 15 Minutes Delay - Euronext Exchanges
  [MarketMic.XAMS]: 'NL', // Euronext Amsterdam
  [MarketMic.XBRU]: 'BE', // Euronext Brussels
  [MarketMic.XLIS]: 'PL', // Euronext Lisbon
  [MarketMic.ALXL]: 'PL', // Euronext Growth Lisbon
  [MarketMic.XPAR]: 'FR', // Euronext Paris
  [MarketMic.ALXP]: 'FR', // Euronext Growth Paris (Not sure about this one)
  [MarketMic.XMSM]: 'IE', // Euronext Dublin
  [MarketMic.XESM]: 'IE', // Euronext Growth Dublin
  // 15 Minutes Delay - German Exchanges
  [MarketMic.XETR]: 'DE', // Deutsche Börse Xetra
  [MarketMic.XFRA]: 'DE', // Frankfurt Stock Exchange
  // 15 Minutes Delay - Varous Exchanges
  [MarketMic.XOAS]: 'NO', // Euronext Expand Oslo (No real-time for this one, presumably 15min)
  [MarketMic.MERK]: 'NO', // Euronext Growth Oslo (No real-time for this one, presumably 15min)
  [MarketMic.XLON]: 'GB', // Not specifically listed on twelvedata but appears 15min delay
  // 20 Minutes Delay
  [MarketMic.XMIL]: 'IT',
  [MarketMic.XJPX]: 'JP', // Tokyo Stock Exchange
  // 30 Minutes Delay
  [MarketMic.XSWX]: 'CH', // Swiss Stock Exchange
  [MarketMic.XSHG]: 'CN', // Shanghai Stock Exchange

  // Real-time data  - U.S. and Canadan Exchanges
  [MarketMic.ARCX]: 'US', // NYSE ARCA
  [MarketMic.XNYS]: 'US', // New York Stock Exchange (NYSE)
  [MarketMic.XNMS]: 'US', // NASDAQ/NGS (GLOBAL SELECT MARKET)
  [MarketMic.XNGS]: 'US', // NASDAQ/NMS (GLOBAL MARKET)
  [MarketMic.BATS]: 'US',
  [MarketMic.XTSE]: 'CA', // Toronto Stock Exchange (TSX)
  // Real-time data  - Nordics / NASDAQ
  [MarketMic.XSTO]: 'SE', // NASDAQ STOCKHOLM AB (Stockholm)
  [MarketMic.XHEL]: 'FI', // NASDAQ HELSINKI LTD (Helsinki)
  [MarketMic.XCSE]: 'DK', // NASDAQ COPENHAGEN A/S (Copenhagen)
  [MarketMic.XHKG]: 'CN', // ??
  [MarketMic.XOSL]: 'NO', // Oslo Stock Exchange not supported
};

export const sectorImage: Record<SectorType, string | undefined> = {
  [SectorType.Materials]: 'materials.svg',
  [SectorType.CommunicationServices]: 'communication-services.svg',
  [SectorType.ConsumerCyclical]: 'consumer-cyclical.svg',
  [SectorType.ConsumerDefensive]: 'consumer-defensive.svg',
  [SectorType.ConsumerDiscretionary]: 'consumer-discretionary.svg',
  [SectorType.Energy]: 'energy.svg',
  [SectorType.Financials]: 'financial-services.svg',
  [SectorType.Healthcare]: 'healthcare.svg',
  [SectorType.Industrials]: 'industrials.svg',
  [SectorType.InformationTechnology]: 'technology.svg',
  [SectorType.RealEstate]: 'real-estate.svg',
  [SectorType.Utilities]: 'utilities.svg',
  [SectorType.Other]: undefined, // Default is industrials
};

export const sectorI18nType = (sectorType: SectorType): string => {
  const sectorTypeI18n: Record<SectorType, string> = {
    [SectorType.Materials]: $localize`Materials`,
    [SectorType.CommunicationServices]: $localize`Communication services`,
    [SectorType.ConsumerCyclical]: $localize`Consumer cyclical`,
    [SectorType.ConsumerDefensive]: $localize`Consumer defensive`,
    [SectorType.ConsumerDiscretionary]: $localize`Consumer discretionary`,
    [SectorType.Energy]: $localize`Energy`,
    [SectorType.Financials]: $localize`Financials`,
    [SectorType.Healthcare]: $localize`Healthcare`,
    [SectorType.Industrials]: $localize`Industrials`,
    [SectorType.InformationTechnology]: $localize`Information technology`,
    [SectorType.RealEstate]: $localize`Real estate`,
    [SectorType.Utilities]: $localize`Utilities`,
    [SectorType.Other]: $localize`Other`,
  };
  return sectorTypeI18n[sectorType];
};

export const distributionPolicyTypeI18n = (
  distrPolicyType: DistributionPolicy,
): string => {
  const distrPolicyI18n: Record<DistributionPolicy, string> = {
    [DistributionPolicy.Accumulating]: $localize`Accumulating`,
    [DistributionPolicy.Distributing]: $localize`Distributing`,
  };
  return distrPolicyI18n[distrPolicyType];
};

export const distributionFrequencyTypeI18n = (
  distrFreqType: DistributionFrequency,
): string => {
  const distrFreqType18n: Record<DistributionFrequency, string> = {
    [DistributionFrequency.Annually]: $localize`Annually`,
    [DistributionFrequency.Monthly]: $localize`Monthly`,
    [DistributionFrequency.Quarterly]: $localize`Quarterly`,
    [DistributionFrequency.SemiAnnually]: $localize`Semiannually`,
  };
  return distrFreqType18n[distrFreqType];
};

export const replicationTypeI18n = (replType: ReplicationType): string => {
  const replType18n: Record<ReplicationType, string> = {
    [ReplicationType.PhysicalFullReplication]: $localize`Physical (Full replication)`,
    [ReplicationType.PhysicalOptimizedSampling]: $localize`Physical (Optimized sampling)`,
    [ReplicationType.Physical]: $localize`Physical`,
    [ReplicationType.IndirectUnfundedSwap]: $localize`Indirect (Unfunded Swap)`,
    [ReplicationType.Indirect]: $localize`Indirect`,
    [ReplicationType.Synthetic]: $localize`Synthetic`,
  };
  return replType18n[replType];
};

export const mainAssetClassI18n = (replType: MainAssetClass): string => {
  const replType18n: Record<MainAssetClass, string> = {
    [MainAssetClass.Bonds]: $localize`Bonds`,
    [MainAssetClass.Alternative]: $localize`Alternative`,
    [MainAssetClass.Commodities]: $localize`Commodities`,
    [MainAssetClass.Equities]: $localize`Equities`,
    [MainAssetClass.FixedIncome]: $localize`Fixed income`,
    [MainAssetClass.MoneyMarket]: $localize`Money market`,
    [MainAssetClass.PreferredEquities]: $localize`Preferred equities`,
  };
  return replType18n[replType];
};

export enum SortingType {
  GainersFirst = 'GainersFirst',
  LosersFirst = 'LosersFirst',
  AtoZ = 'AtoZ',
  ZtoA = 'ZtoA',
  DividendYield = 'DividendYield',
  ROA = 'ROA',
  ROE = 'ROE',
  OPERATING_MARGIN = 'OPERATING_MARGIN',
  PROFIT_MARGIN = 'PROFIT_MARGIN',
  PRICE_TO_SALES = 'PRICE_TO_SALES',
  BETA = 'BETA',
  TRAILING_PE = 'TRAILING_PE',
  MARKET_CAP = 'MARKET_CAP',
  LOWEST_ETF_TER = 'LOWEST_ETF_TER',
  FUND_SIZE = 'FUND_SIZE',
  RANK = 'RANK',
}

export enum SortingCategoryKey {
  STOCK = 'STOCK',
  ETF = 'ETF',
  CRYPTO = 'CRYPTO',
  CURRENCY = 'CURRENCY',
  METAL = 'METAL',
  COMMODITY = 'COMMODITY',
  INDEX = 'INDEX',
  FAVOURITES = 'FAVOURITES',
}

export type SortingCategory = {
  [key in SortingCategoryKey]: SortingType;
};

/**
 * Detail pages of the instrument category
 */
export enum DiscoverInstrumentCategoryType {
  INDICES = 'indices',
  CURRENCIES = 'currencies',
  METALS = 'metals',
  COMMODITIES = 'commodities',
  CRYPTOS = 'cryptos',
  ETFS = 'etfs',
  STOCKS_TOP_TECH = 'stocks-TopTech',
  STOCKS_LARGE_CAP = 'stocks-LargeCap',
  STOCKS_CH = 'stocks-CH',
  STOCKS_DE = 'stocks-DE',
  STOCKS_US = 'stocks-US',
  STOCKS_GB = 'stocks-GB',
  STOCKS_JP = 'stocks-JP',
  STOCKS_FR = 'stocks-FR',
  STOCKS_IT = 'stocks-IT',
  STOCKS_ES = 'stocks-ES',
  STOCKS_CN = 'stocks-CN',
  STOCKS_NL = 'stocks-NL',
  STOCKS_SE = 'stocks-SE',
  STOCKS_NO = 'stocks-NO',
  STOCKS_AU = 'stocks-AU',
  STOCKS_CA = 'stocks-CA',
  STOCKS_SECTOR_MATERIALS = 'stocks-Materials',
  STOCKS_SECTOR_COMMUNICATIONSERVICES = 'stocks-CommunicationServices',
  STOCKS_SECTOR_CONSUMERCYCLICAL = 'stocks-ConsumerCyclical',
  STOCKS_SECTOR_CONSUMERDEFENSIVE = 'stocks-ConsumerDefensive',
  STOCKS_SECTOR_CONSUMERDISCRETIONARY = 'stocks-ConsumerDiscretionary',
  STOCKS_SECTOR_ENERGY = 'stocks-Energy',
  STOCKS_SECTOR_FINANCIALS = 'stocks-Financials',
  STOCKS_SECTOR_HEALTHCARE = 'stocks-Healthcare',
  STOCKS_SECTOR_INDUSTRIALS = 'stocks-Industrials',
  STOCKS_SECTOR_INFORMATIONTECHNOLOGY = 'stocks-InformationTechnology',
  STOCKS_SECTOR_REALESTATE = 'stocks-RealEstate',
  STOCKS_SECTOR_UTILITIES = 'stocks-Utilities',
}

export const discoverInstrumentCategoryTitleI18n = (
  replType: DiscoverInstrumentCategoryType,
): string => {
  const replType18n: Record<DiscoverInstrumentCategoryType, string> = {
    [DiscoverInstrumentCategoryType.INDICES]: $localize`Indices`,
    [DiscoverInstrumentCategoryType.METALS]: $localize`Precious metals`,
    [DiscoverInstrumentCategoryType.COMMODITIES]: $localize`Commodities`,
    [DiscoverInstrumentCategoryType.CURRENCIES]: $localize`Currencies`,
    [DiscoverInstrumentCategoryType.CRYPTOS]: $localize`Crypto assets`,
    [DiscoverInstrumentCategoryType.ETFS]: $localize`ETFs`,
    [DiscoverInstrumentCategoryType.STOCKS_TOP_TECH]: $localize`Trending tech companies`,
    [DiscoverInstrumentCategoryType.STOCKS_LARGE_CAP]: $localize`Large caps`,
    [DiscoverInstrumentCategoryType.STOCKS_CH]: $localize`Swiss stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_DE]: $localize`German stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_US]: $localize`US stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_GB]: $localize`UK stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_JP]: $localize`Japanese stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_FR]: $localize`French stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_IT]: $localize`Italian stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_ES]: $localize`Spanish stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_CN]: $localize`Chinese stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_NL]: $localize`Dutch stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_SE]: $localize`Swedish stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_NO]: $localize`Norwegian stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_AU]: $localize`Australian stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_CA]: $localize`Canadian stocks`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_MATERIALS]: $localize`Materials`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_COMMUNICATIONSERVICES]: $localize`Communication services`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_CONSUMERCYCLICAL]: $localize`Consumer cyclical`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_CONSUMERDEFENSIVE]: $localize`Consumer defensive`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_CONSUMERDISCRETIONARY]: $localize`Consumer discretionary`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_ENERGY]: $localize`Energy`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_FINANCIALS]: $localize`Financials`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_HEALTHCARE]: $localize`Healthcare`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_INDUSTRIALS]: $localize`Industrials`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_INFORMATIONTECHNOLOGY]: $localize`Information technology`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_REALESTATE]: $localize`Real estate`,
    [DiscoverInstrumentCategoryType.STOCKS_SECTOR_UTILITIES]: $localize`Utilities`,
  };
  return replType18n[replType];
};

export function etfFundSizeRiskCategory(fundSize: number | undefined | null) {
  if (fundSize) {
    if (fundSize > 5.0e8) {
      return 'low';
    } else if (fundSize < 5.0e8 && fundSize > 1.0e8) {
      return 'medium';
    } else {
      return 'high';
    }
  }
  return '-';
}

export function getSortingTypePerCategory(
  route?: string,
  sortingCategory?: SortingCategory,
): { sortingType: SortingType | undefined; categoryKey: SortingCategoryKey } {
  if (!sortingCategory || !route) {
    return {
      sortingType: SortingType.AtoZ,
      categoryKey: SortingCategoryKey.STOCK,
    };
  }
  if (!sortingCategory)
    return {
      sortingType: sortingCategory[SortingCategoryKey.STOCK],
      categoryKey: SortingCategoryKey.STOCK,
    };
  if (route.toLowerCase().startsWith('favourites')) {
    return {
      sortingType: sortingCategory[SortingCategoryKey.FAVOURITES],
      categoryKey: SortingCategoryKey.FAVOURITES,
    };
  }
  if (route.toLowerCase().startsWith('etfs')) {
    return {
      sortingType: sortingCategory[SortingCategoryKey.ETF],
      categoryKey: SortingCategoryKey.ETF,
    };
  }
  if (route.toLowerCase().startsWith('crypto')) {
    return {
      sortingType: sortingCategory[SortingCategoryKey.CRYPTO],
      categoryKey: SortingCategoryKey.CRYPTO,
    };
  }
  if (route.toLowerCase().startsWith('currencies')) {
    return {
      sortingType: sortingCategory[SortingCategoryKey.CURRENCY],
      categoryKey: SortingCategoryKey.CURRENCY,
    };
  }
  if (route.toLowerCase().startsWith('metals')) {
    return {
      sortingType: sortingCategory[SortingCategoryKey.METAL],
      categoryKey: SortingCategoryKey.METAL,
    };
  }
  if (route.toLowerCase().startsWith('commodities')) {
    return {
      sortingType: sortingCategory[SortingCategoryKey.COMMODITY],
      categoryKey: SortingCategoryKey.COMMODITY,
    };
  }
  if (route.toLowerCase().startsWith('indices')) {
    return {
      sortingType: sortingCategory[SortingCategoryKey.INDEX],
      categoryKey: SortingCategoryKey.INDEX,
    };
  }

  return {
    sortingType: sortingCategory.STOCK,
    categoryKey: SortingCategoryKey.STOCK,
  };
}

export function numQuoteUps(
  instrumentIds?: string[],
  instruments?: Dictionary<InstrumentsEntity>,
  quotesEntities?: Dictionary<QuotesEntity>,
) {
  return (
    instrumentIds?.filter((id) => {
      const instrument = id ? instruments?.[id] : undefined;
      const quote =
        quotesEntities?.[getQuoteId(id, instrument?.symbol, instrument?.mic)];

      return (quote?.change ?? 0) > 0;
    }).length ?? 0
  );
}

export const numQuoteUpsDownsInFavourites = (
  favouriteInstruments?: (FavouritesInstrument | undefined)[],
) =>
  favouriteInstruments?.reduce(
    (count, instrument) => {
      const change = instrument?.quote?.change;

      if (!change) return count;

      return {
        ups: count.ups + +(change > 0),
        downs: count.downs + +(change < 0),
      };
    },
    { ups: 0, downs: 0 } satisfies { ups: number; downs: number },
  ) ?? { ups: 0, downs: 0 };

export function mapETFData(etf: EtfEntity) {
  // Map sector allocation name and image
  const sectorsAlloc: AllocationWithIcon[] = [];
  sectorsAlloc.push(
    ...(etf?.fundamentals?.sectors?.map((allocation) => {
      const imagePath = allocation.name
        ? sectorImage[allocation.name as SectorType]
        : undefined;
      return {
        ...allocation,
        image: imagePath ? 'assets/icon/sectors/' + imagePath : undefined,
        name: allocation.name
          ? sectorI18nType(allocation.name as SectorType)
          : $localize`Other`,
        iconType: IconType.SECTOR,
      };
    }) || []),
  );

  // Map country allocation image
  const countryAlloc: AllocationWithIcon[] = [];
  countryAlloc.push(
    ...(etf?.fundamentals?.countries?.map((allocation) => {
      const country = countries.find(
        (country: Country) => country.isoCode === allocation.name,
      );
      return {
        ...allocation,
        name: country ? country.name() : $localize`Other`,
        image: country
          ? '/assets/flags/1x1/' + allocation.name + '.svg'
          : undefined,
        iconType: IconType.COUNTRY,
      };
    }) || []),
  );

  // Map top 10 positions (look through)
  const logoPath = extractLogoPath(etf.logoUrl);
  const top10HoldingAlloc: AllocationWithIcon[] = [];
  top10HoldingAlloc.push(
    ...(etf?.fundamentals?.top10Holdings?.map((allocation) => ({
      ...allocation,
      image: logoPath ? logoPath + allocation.uuid + '.png' : undefined,
      name: allocation.name
        ? sectorI18nType(allocation.name as SectorType)
        : '',
      iconType: IconType.ASSET,
    })) || []),
  );

  return { sectorsAlloc, countryAlloc, top10HoldingAlloc };
}

export function mapIndexData(index: IndexEntity) {
  // Map sector allocation name and image
  const sectorsAlloc: AllocationWithIcon[] = [];
  sectorsAlloc.push(
    ...(index?.fundamentals?.sectors?.map((allocation) => ({
      ...allocation,
      image: allocation.name
        ? 'assets/icon/sectors/' + sectorImage[allocation.name as SectorType]
        : undefined,
      name: allocation.name
        ? sectorI18nType(allocation.name as SectorType)
        : '',
      iconType: IconType.SECTOR,
    })) || []),
  );

  // Map constitutes
  const logoPath = extractLogoPath(index.logoUrl);
  const constituentsAlloc: AllocationWithIcon[] = [];
  constituentsAlloc.push(
    ...(index?.fundamentals?.constitutes?.map((allocation) => ({
      ...allocation,
      image: logoPath ? logoPath + allocation.uuid + '.png' : undefined,
      iconType: IconType.ASSET,
    })) || []),
  );

  // Map investment opportunities
  const investmentOpportunities: AllocationWithIcon[] = [];
  investmentOpportunities.push(
    ...(index?.fundamentals?.investmentOpportunities?.map((item) => ({
      ...item,
      image: logoPath ? logoPath + item.uuid + '.png' : undefined,
      iconType: IconType.ASSET,
    })) || []),
  );

  return { sectorsAlloc, constituentsAlloc, investmentOpportunities };
}

export function mapForexData(forex: ForexEntity | CryptoEntity) {
  // Map investment opportunities
  const logoPath = extractLogoPath(forex.logoUrl);
  const investmentOpportunities: AllocationWithIcon[] = [];
  investmentOpportunities.push(
    ...(forex?.fundamentals?.investmentOpportunities?.map((item) => ({
      ...item,
      image: logoPath ? logoPath + item.uuid + '.png' : undefined,
      iconType: IconType.ASSET,
    })) || []),
  );

  return { investmentOpportunities };
}
export function mapTimeSeriesToChartData(
  timeSeries?: Maybe<TimeSeriesItem[]>,
  period?: TimeSeriesPeriod | null,
  previousClose?: number | null,
  colors?: ThemeColors,
  label?: string,
  fill = true,
  drawReferenceLine = false,
): ChartData<'line', TimeSeriesItem[]> | undefined {
  if (!period || !timeSeries || !timeSeries.length || !colors)
    return { datasets: [] };

  const refValue =
    period === TimeSeriesPeriod.day
      ? previousClose
      : timeSeries[timeSeries.length - 1].price;

  const referenceLine = {
    data: timeSeries.map((t) => ({ ...t, price: refValue })),
    pointRadius: 0,
    borderColor: colors.neutral.alpha(0.5).css(),
    borderDash: [3, 3],
    borderWidth: 1,
  };

  const borderColor = (context: ScriptableContext<'line'>) =>
    setDynamicBorderColor(
      context,
      colors.uptrend.css(),
      colors.downtrend.css(),
      refValue,
    );

  const traceConfig = {
    data: timeSeries,
    label,
    pointRadius: 0,
    pointBorderColor: borderColor,
    pointBackgroundColor: borderColor,
    borderColor,
    borderWidth: 2,
    tension: 0.3,
  };

  const fillConfig = fill
    ? {
        fill: ((context: ScriptableContext<'line'>): ComplexFillTarget => ({
          target: {
            value: refValue ?? 0,
          },
          above: setChartFill(
            context,
            colors.uptrend,
            refValue,
            'bottom',
          ) as Color,
          below: setChartFill(
            context,
            colors.downtrend,
            refValue,
            'top',
          ) as Color,
        })) as unknown as string, // hack because of weird type definition in Chart.js
      }
    : false;

  return {
    datasets: [
      { ...traceConfig, ...fillConfig },
      ...(drawReferenceLine ? [referenceLine] : []),
    ],
  };
}

export type YearDistr = {
  distributions: Dividend[];
  totYearlyConvDistr: number; // The yearly sum of the current dividend in listing currency
  totYearlyDistributionMain: number;
  year: string;
};

export type CurryAmount = {
  currency: string;
  amount: number;
};

export type LastFullDividend = {
  amount: number;
  currency: string;
  yield: number;
  year: string;
};

export function getNumYears(
  distributions: (Dividend | null)[] | undefined | null,
): number {
  const years: number[] = [];
  if (distributions && distributions.length > 0)
    for (let i = 0; i < distributions.length; i++) {
      const item = distributions[i];
      if (!item?.payDate || !item?.amount) continue;
      const year = new Date(item.payDate).getFullYear();
      const idx = years.findIndex((y) => y === year);
      if (idx !== -1) {
        years[idx] = year;
      } else {
        years.push(year);
      }
    }
  return years.length;
}

export function calcYearlyDistribution(
  distributions: (Dividend | null)[] | undefined | null,
  maxYearCount: number,
  currency: string | undefined | null,
): YearDistr[] {
  const yearlyDistr: Array<YearDistr> = [];

  if (distributions && distributions.length > 0 && currency) {
    // Calculate Yearly Distribution Performance
    let yearCnt = 0;
    let currentYear = new Date().getFullYear();
    const startingYear = currentYear;

    for (let i = 0; i < maxYearCount; i++) {
      const yr = {
        year: String(currentYear - i),
        totYearlyConvDistr: 0,
        totYearlyDistributionMain: 0,
        distributions: [],
      } as YearDistr;
      yearlyDistr.push(yr);
    }

    for (let i = 0; i < distributions.length; i++) {
      const item = distributions[i];
      if (!item?.payDate || !item?.amount) continue;
      const year = new Date(item.payDate).getFullYear();

      // New Year
      if (year < currentYear) {
        yearCnt = startingYear - year;
      }
      if (yearCnt >= maxYearCount) break;

      // Summarize the total distribution in listing currency p.a
      // Exception is the British Pound as prices are in pence and dividend in pound. The dividend itself should not be reported in pence
      if (currency !== item.currency) {
        const convertedItem = item.convertedDividends?.find((c) => {
          if (c.currency === 'GBP' && currency === 'GBX') {
            return c.currency?.replace('GBP', 'GBX') === currency;
          } else {
            return c.currency === currency;
          }
        });
        if (convertedItem && convertedItem.amount && convertedItem.currency) {
          yearlyDistr[yearCnt].totYearlyConvDistr =
            yearlyDistr[yearCnt].totYearlyConvDistr +
            (convertedItem.currency === 'GBP' && currency === 'GBX'
              ? convertedItem.amount * 100
              : convertedItem.amount);
        }
      }
      // Also, summarize the total distributions in original currency p.a
      yearlyDistr[yearCnt].totYearlyDistributionMain =
        yearlyDistr[yearCnt].totYearlyDistributionMain +
        (item?.currency === 'GBP' && currency === 'GBX'
          ? item.amount * 100
          : item.amount);
      // Store original distributions
      yearlyDistr[yearCnt].distributions.push(item);

      currentYear = year;
    }
  }

  return yearlyDistr;
}

export const priceChange = () => $localize`Price change`;
export const dividendYield = () => $localize`Dividend yield`;

export function calcYearlyPricePerf(
  fiveYear: TimeSeriesItem[] | undefined | null,
  yearlyDistributions: YearDistr[],
  colors: ThemeColors,
): ChartData<'bar'> | undefined {
  if (fiveYear && fiveYear.length > 0) {
    const trace1 = {
      label: new Array<string>(),
      data: new Array<number>(),
    };

    const trace2 = {
      label: new Array<string>(),
      data: new Array<number>(),
    };

    let lastItemOfLastYear: TimeSeriesItem | undefined;
    let currentYear = new Date().getFullYear();
    const initialYear = currentYear;
    let p: number | undefined;
    let yearCnt = 0;
    const maxYearCount = 4;

    // Populate initial array as we always want to show 4 columns (not all funds have a history that is older than 4 years)
    for (let i = 0; i < maxYearCount; i++) {
      trace1.label[i] = String(currentYear - i);
      trace1.data[i] = Number(0);

      trace2.label[i] = String(currentYear - i);
      trace2.data[i] = Number(0);
    }
    // Calculate Yearly Price Performance
    for (let i = 0; i < fiveYear.length; i++) {
      const item = fiveYear[i];
      if (!item?.datetime || !item?.price) continue;
      const year = new Date(item.datetime).getFullYear();
      // New Year
      if (year < currentYear) {
        if (yearCnt >= maxYearCount) break;
        yearCnt = yearCnt + 1;
        // Handle current year
        if (initialYear === currentYear && fiveYear[0] && fiveYear[0].price) {
          p = fiveYear[0]?.price / item?.price - 1;
          lastItemOfLastYear = item;

          if (
            lastItemOfLastYear &&
            lastItemOfLastYear.price &&
            yearlyDistributions[yearCnt - 1]
          ) {
            const yd = yearlyDistributions[yearCnt - 1];
            if (yd) {
              // If yearly converted total is 0 revert to main
              const d =
                (yd.totYearlyConvDistr
                  ? yd.totYearlyConvDistr
                  : yd.totYearlyDistributionMain) / lastItemOfLastYear?.price;
              trace2.label[yearCnt - 1] = String(year + 1);
              trace2.data[yearCnt - 1] = d * 100;
            }
          }
        } else {
          if (lastItemOfLastYear && lastItemOfLastYear.price) {
            p = lastItemOfLastYear?.price / item?.price - 1;
            lastItemOfLastYear = item;

            if (
              lastItemOfLastYear &&
              lastItemOfLastYear.price &&
              yearlyDistributions[yearCnt - 1]
            ) {
              const yd = yearlyDistributions[yearCnt - 1];
              if (yd) {
                const d =
                  (yd.totYearlyConvDistr
                    ? yd.totYearlyConvDistr
                    : yd.totYearlyDistributionMain) / lastItemOfLastYear?.price;
                trace2.label[yearCnt - 1] = String(year + 1);
                trace2.data[yearCnt - 1] = d * 100;
              }
            }
          }
        }
        if (p) {
          trace1.label[yearCnt - 1] = String(year + 1);
          trace1.data[yearCnt - 1] = p * 100;
        }

        currentYear = year;
      } else {
        currentYear = year;
      }
    }

    const borderWidth = 1;

    return yearlyDistributions.length > 0
      ? {
          labels: trace1.label.reverse(),
          datasets: [
            {
              data: trace1.data.reverse(),
              label: priceChange(),
              borderWidth,
              borderColor: colors.primary.css(),
              backgroundColor: colors.primary.alpha(0.2).css(),
            },
            {
              data: trace2.data.reverse(),
              label: dividendYield(),
              borderWidth,
              borderColor: colors.secondary.css(),
              backgroundColor: colors.secondary.alpha(0.2).css(),
            },
          ],
        }
      : {
          labels: trace1.label.reverse(),
          datasets: [
            {
              data: trace1.data.reverse(),
              label: priceChange(),
              borderWidth,
              borderColor: colors.primary.css(),
              backgroundColor: colors.primary.alpha(0.2).css(),
            },
          ],
        };
  }
  return undefined;
}

export function calcLastDividendYield(
  yearlyDistributions: YearDistr[],
  distributions: (Dividend | null)[] | undefined | null,
  quote?: Quote,
): LastFullDividend | undefined {
  if (
    distributions &&
    yearlyDistributions?.length > 1 &&
    quote &&
    quote.close
  ) {
    const prev = yearlyDistributions[1]?.distributions?.length ?? 0;
    const current = yearlyDistributions[0]?.distributions?.length ?? 0;

    if (prev === 0 && current === 0) return undefined; // If both are 0 then there is no last year
    if (prev > current) {
      // Take last years dividend since current year's dividend is not complete yet based on previous year
      const amt =
        distributions[1]?.currency === quote?.currency
          ? yearlyDistributions[1]?.totYearlyDistributionMain
          : yearlyDistributions[1]?.totYearlyConvDistr;

      const div = {
        amount: amt,
        currency: quote?.currency,
        yield: (amt ?? 0) / quote?.close,
        year: yearlyDistributions[1]?.year,
      } as LastFullDividend;
      return div;
    }
    // Current year complete
    else {
      const amt =
        distributions[0]?.currency === quote?.currency
          ? yearlyDistributions[0]?.totYearlyDistributionMain
          : yearlyDistributions[0]?.totYearlyConvDistr;

      const div = {
        amount: amt,
        currency: quote?.currency,
        yield: (amt ?? 0) / quote?.close,
        year: yearlyDistributions[0]?.year,
      } as LastFullDividend;
      return div;
    }
  }

  return undefined;
}

export function calcYTDPricePerf(
  year: TimeSeriesItem[] | undefined | null,
): number {
  if (year) {
    const intraday = [];
    const currentYear = new Date().getFullYear();

    for (let i = 0; i <= year.length; i++) {
      const item = year[i];
      if (!item?.datetime || !item?.price) continue;
      const y = new Date(item.datetime).getFullYear();
      // New Year
      if (y < currentYear) {
        intraday.push(item);
        break;
      }
      intraday.push(item);
    }
    const p0 = intraday[intraday.length - 1]?.price; // soy
    const p1 = intraday[0]?.price; // current
    if (p0 && p1) return p1 / p0 - 1;
    return 0;
  }
  return 0;
}

export function calcVolatility(year: TimeSeriesItem[] | undefined | null) {
  if (year) {
    // Calculate the daily returns
    const dailyReturns = [];
    for (let i = 1; i < year.length; i++) {
      const cp = year?.[i]?.price;
      const pp = year?.[i - 1]?.price;
      if (cp && pp) {
        dailyReturns.push((cp - pp) / pp);
      }
    }

    // Calculate the average daily return
    let sum = 0;
    for (let i = 0; i < dailyReturns.length; i++) {
      sum += dailyReturns[i];
    }
    const avgDailyReturn = sum / dailyReturns.length;

    // Calculate the standard deviation of daily returns
    sum = 0;
    for (let i = 0; i < dailyReturns.length; i++) {
      sum += Math.pow(dailyReturns[i] - avgDailyReturn, 2);
    }
    const stdDev = Math.sqrt(sum / dailyReturns.length);

    // Calculate the volatility
    const volatility = stdDev * Math.sqrt(dailyReturns.length);
    const riskScore = evalVolatilityRiskCategory(volatility);

    return { volatility, riskScore };
  }
  return undefined;
}

function evalVolatilityRiskCategory(oneYearVolatility: number): number {
  if (oneYearVolatility) {
    if (oneYearVolatility > 0.25) {
      return 7;
    } else if (oneYearVolatility < 0.25 && oneYearVolatility > 0.15) {
      return 6;
    } else if (oneYearVolatility < 0.15 && oneYearVolatility > 0.1) {
      return 5;
    } else if (oneYearVolatility < 0.1 && oneYearVolatility > 0.05) {
      return 4;
    } else if (oneYearVolatility < 0.05 && oneYearVolatility > 0.02) {
      return 3;
    } else if (oneYearVolatility < 0.02 && oneYearVolatility > 0.005) {
      return 2;
    } else {
      return 1;
    }
  }
  return 0;
}

export function getAssetEntity(
  instrument?: InstrumentsEntity | null,
  year?: TimeSeriesEntity | null,
  fiveYears?: TimeSeriesEntity | null,
  quote?: QuotesEntity,
  colors?: ThemeColors,
): AssetEntity | undefined {
  let asset: AssetEntity | undefined = undefined;

  if (!instrument || !colors) return asset;

  const [yearItems, fiveYearsItems] = [year, fiveYears].map((t) => t?.items);

  if (isStockEntity(instrument)) {
    const yd = calcYearlyDistribution(
      instrument.fundamentals?.dividends,
      10,
      quote?.currency,
    );
    asset = {
      ...instrument,
      defaultListingCurrency: instrument?.listings?.[0]?.currency ?? undefined,
      priceDelay: quote?.mic
        ? exchangeMarketMics[quote?.mic as MarketMic]
        : undefined,
      ytdPricePerf: calcYTDPricePerf(yearItems),
      yearlyPricePerf: calcYearlyPricePerf(fiveYearsItems, yd, colors),
      lastDividend: calcLastDividendYield(
        yd,
        instrument.fundamentals?.dividends,
        quote,
      ),
      yearlyDividends: yd,
      sectorImage: instrument.fundamentals?.companyProfile?.sector
        ? sectorImage[instrument.fundamentals?.companyProfile?.sector]
        : undefined,

      sectorNameI18n: instrument.fundamentals?.companyProfile?.sector // translate sector names
        ? sectorI18nType(instrument.fundamentals.companyProfile.sector)
        : undefined,
    };
  } else if (isEtfEntity(instrument)) {
    const ed = mapETFData(instrument);
    const vo = calcVolatility(yearItems);
    const yd = calcYearlyDistribution(
      instrument.fundamentals?.distributions,
      10,
      quote?.currency,
    );

    asset = {
      ...instrument,
      priceDelay: quote?.mic
        ? exchangeMarketMics[quote?.mic as MarketMic]
        : undefined,
      ytdPricePerf: calcYTDPricePerf(yearItems),
      yearlyDividends: yd,
      lastDividend: calcLastDividendYield(
        yd,
        instrument.fundamentals?.distributions,
        quote,
      ),
      yearlyPricePerf: calcYearlyPricePerf(fiveYearsItems, yd, colors),
      oneYearVolatilityRiskScore: vo?.riskScore,
      oneYearVolatility: vo?.volatility,
      riskCategory: etfFundSizeRiskCategory(instrument.fundamentals?.fundSize),
      logoPath: extractLogoPath(instrument.logoUrl),
      distributionPolicyI18n: instrument.fundamentals?.distributionPolicy // translate distribution policy
        ? distributionPolicyTypeI18n(
            instrument.fundamentals?.distributionPolicy,
          )
        : undefined,
      distributionFrequencyI18n: instrument.fundamentals?.distributionFrequency // translate distribution frequency
        ? distributionFrequencyTypeI18n(
            instrument.fundamentals?.distributionFrequency,
          )
        : undefined,
      replicationTypeI18n: instrument.fundamentals?.replication // translate replication type
        ? replicationTypeI18n(instrument.fundamentals?.replication)
        : undefined,
      mainAssetClassI18n: instrument.fundamentals?.mainAssetClass // translate main asset class
        ? mainAssetClassI18n(instrument.fundamentals?.mainAssetClass)
        : undefined,

      fundamentals: {
        ...instrument.fundamentals,
        sectors: [],
        countries: [],
      },
      sectors: ed?.sectorsAlloc,
      countries: ed?.countryAlloc,
      top10Holdings: ed?.top10HoldingAlloc,
    };
  } else if (isForexEntity(instrument) || isCryptoEntity(instrument)) {
    const fd = mapForexData(instrument);
    asset = {
      ...instrument,
      priceDelay: 'Real-Time',
      ytdPricePerf: calcYTDPricePerf(yearItems),
      logoPath: extractLogoPath(instrument.logoUrl),
      investmentOpportunities: fd?.investmentOpportunities,
    };
  } else if (isIndexEntity(instrument)) {
    const id = mapIndexData(instrument);
    asset = {
      ...instrument,
      priceDelay: quote?.mic
        ? exchangeMarketMics[quote?.mic as MarketMic]
        : undefined,
      ytdPricePerf: calcYTDPricePerf(yearItems),
      sectors: id?.sectorsAlloc,
      constitutes: id?.constituentsAlloc,
      investmentOpportunities: id?.investmentOpportunities,
    };
  } else if (isGameRewardEntity(instrument)) {
    asset = {
      ...instrument,
      priceDelay: 'Real-Time',
      logoPath: extractLogoPath(instrument.logoUrl),
    };
  }

  return asset ? { ...asset, quote } : undefined;
}

export function findListing(
  instrument?: InstrumentsEntity,
  symbol?: string | null,
  mic?: string | null,
): Listing | undefined {
  const defaultListing = instrument?.listings?.[0];
  // We do a loosely check because either symbol or mic are strings that can be null or undefined
  const listing = instrument?.listings?.find(
    (l) => l.symbol == symbol && l.mic == mic,
  );
  return listing ?? defaultListing;
}

export function computeFinancialCharts(
  instrument?: InstrumentsEntity,
  colors?: ThemeColors,
): FinancialCharts | undefined {
  const MAX_FISCAL_COUNT = 5;

  if (!instrument || !isStockEntity(instrument) || !colors) return;

  const chartConfig = (color: chroma.Color) => ({
    backgroundColor: color.alpha(0.2).css(),
    borderColor: color.css(),
    borderWidth: 1,
  });

  const balanceSheet =
    instrument?.fundamentals?.statistics?.financials?.balanceSheet;
  const incomeStatement =
    instrument?.fundamentals?.statistics?.financials?.incomeStatement;

  const balanceSheetDataSet = (
    balanceSheet?: Maybe<BalanceSheetHist[]>,
  ): ChartDataset<'bar', FinancialChartItem[]>[] =>
    balanceSheet
      ? [
          {
            data: balanceSheet
              .filter((h) => !!h.fiscalDate && !!h.assets?.totalAssets)
              .map((h) => ({
                date: h.fiscalDate ?? '',
                value: h.assets?.totalAssets ?? 0,
              }))
              ?.splice(0, MAX_FISCAL_COUNT),
            label: $localize`Total assets`,
            ...chartConfig(colors.primary),
          },
          {
            data: balanceSheet
              .filter(
                (h) => !!h.fiscalDate && !!h.liabilities?.totalLiabilities,
              )
              .map((h) => ({
                date: h.fiscalDate ?? '',
                value: h.liabilities?.totalLiabilities ?? 0,
              }))
              ?.splice(0, MAX_FISCAL_COUNT),
            label: $localize`Total liabilities`,
            ...chartConfig(colors.secondary),
          },
        ]
      : [];

  const incomeStatementDataSet = (
    incomeStatement?: Maybe<IncomeStatementHist[]>,
  ) =>
    incomeStatement
      ? [
          {
            data: incomeStatement
              .filter((h) => !!h.fiscalDate && !!h.sales)
              .map((h) => ({
                date: h.fiscalDate ?? '',
                value: h.sales ?? 0,
              }))
              ?.splice(0, MAX_FISCAL_COUNT),
            label: $localize`Revenue`,
            ...chartConfig(colors.primary),
          },
          {
            data: incomeStatement
              .filter((h) => !!h.fiscalDate && !!h.netIncome)
              .map((h) => ({
                date: h.fiscalDate ?? '',
                value: h.netIncome ?? 0,
              }))
              ?.splice(0, MAX_FISCAL_COUNT),
            label: $localize`Net income`,
            ...chartConfig(colors.secondary),
          },
        ]
      : [];

  const fiscalDates = {
    annually: incomeStatement?.history
      ?.map((h) => DateTime.fromISO(h.fiscalDate ?? '').toFormat('yyyy'))
      ?.splice(0, MAX_FISCAL_COUNT),
    quarterly: incomeStatement?.quarterlyHistory
      ?.map((h) => DateTime.fromISO(h.fiscalDate ?? '').toFormat('LLL yyyy'))
      ?.splice(0, MAX_FISCAL_COUNT),
  };

  return {
    balanceSheet: {
      annually: {
        datasets: balanceSheetDataSet(balanceSheet?.history),
      },
      quarterly: {
        datasets: balanceSheetDataSet(balanceSheet?.quarterlyHistory),
      },
    },
    incomeStatement: {
      annually: {
        datasets: incomeStatementDataSet(incomeStatement?.history),
      },
      quarterly: {
        datasets: incomeStatementDataSet(incomeStatement?.quarterlyHistory),
      },
    },
    fiscalDates,
  };
}

export function updateQuotePercentageChange(
  instrumentChart: ChartData<'line', TimeSeriesItem[]>,
  period: TimeSeriesPeriod,
  instrument: AssetEntity,
): AssetEntity | undefined {
  const firstEntry =
    period === TimeSeriesPeriod.day
      ? instrument.quote?.previousClose
      : instrumentChart.datasets.at(0)?.data.at(-1)?.price;
  const lastEntry = instrument.quote?.close;

  if (!firstEntry || !lastEntry) return instrument;

  const quote = {
    ...instrument.quote,
    percentChange: ((lastEntry - firstEntry) / firstEntry) * 100,
    change: lastEntry - firstEntry,
  };

  return { ...instrument, quote };
}

export const pennySterling = 'GBX';
export const poundSterling = 'GBP';

export const getListingCurrency = (listing?: Listing): string | undefined =>
  normalizeCurrency(listing?.currency ?? listing?.tradingCurrency ?? undefined);

export const normalizeCurrency = (currency?: string): string | undefined =>
  currency === pennySterling ? poundSterling : currency;
