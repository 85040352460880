import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromCompetitions from './features/games-competitions.reducer';
import * as fromPlayers from './features/games-players.reducer';
import * as fromQuizzes from './features/games-quizzes.reducer';

export interface GamesState {
  readonly [fromCompetitions.FEATURE_KEY]: fromCompetitions.State;
  readonly [fromPlayers.FEATURE_KEY]: fromPlayers.State;
  readonly [fromQuizzes.FEATURE_KEY]: fromQuizzes.State;
}

export const FEATURE_KEY = 'games';

export interface GamesPartialState {
  readonly [FEATURE_KEY]: GamesState;
}

export function reducers(state: GamesState | undefined, action: Action) {
  return combineReducers(
    {
      [fromCompetitions.FEATURE_KEY]: fromCompetitions.reducer,
      [fromPlayers.FEATURE_KEY]: fromPlayers.reducer,
      [fromQuizzes.FEATURE_KEY]: fromQuizzes.reducer,
    },
    {
      [fromCompetitions.FEATURE_KEY]: fromCompetitions.initialState,
      [fromPlayers.FEATURE_KEY]: fromPlayers.initialState,
      [fromQuizzes.FEATURE_KEY]: fromQuizzes.initialState,
    },
  )(state, action);
}

export const selectGamesState = createFeatureSelector<GamesState>(FEATURE_KEY);
