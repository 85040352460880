import { createSelector } from '@ngrx/store';
import {
  favInstrumentsHeader,
  FavouriteInstrumentItem,
  FavouriteListItem,
  FavouritesSelectors,
  FavouriteUserItem,
  favUsersHeader,
  mapToFavouriteInstrument,
} from '../favourites';
import {
  InstrumentsSelectors,
  numQuoteUpsDownsInFavourites,
  QuotesSelectors,
  SortingType,
  TimeSeriesSelectors,
} from '../instruments';
import { ThemeSelectors } from '../theme';

export const selectFavouriteInstruments = createSelector(
  FavouritesSelectors.selectFavouriteInstruments,
  QuotesSelectors.selectQuotesEntities,
  TimeSeriesSelectors.selectTimeSeriesEntities,
  ThemeSelectors.selectColors,
  (instruments, quotes, timeSeries, colors) =>
    instruments.map((i) =>
      mapToFavouriteInstrument(i, quotes, timeSeries, colors),
    ),
);

export const selectFavouritesListCounts = createSelector(
  selectFavouriteInstruments,
  (favouriteInstruments) => ({
    ...numQuoteUpsDownsInFavourites(favouriteInstruments),
    numFavouriteInstruments: favouriteInstruments.length,
  }),
);

const selectFavouritesListModelSortingByGainers = createSelector(
  selectFavouriteInstruments,
  (favourites) =>
    favourites
      ?.slice()
      .sort((a, b) => (b?.quote?.change ?? 0) - (a?.quote?.change ?? 0)),
);

const selectFavouritesListModelSortingByLosers = createSelector(
  selectFavouritesListModelSortingByGainers,
  (favourites) => favourites?.slice()?.reverse(),
);

const selectFavouritesListModelSortingByNameAZ = createSelector(
  selectFavouriteInstruments,
  (favourites) =>
    favourites?.slice().sort((a, b) => {
      const nameA = a?.name?.toLowerCase() ?? '';
      const nameB = b?.name?.toLowerCase() ?? '';
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }),
);

const selectFavouritesListModelSortingByNameZA = createSelector(
  selectFavouritesListModelSortingByNameAZ,
  (favourites) => favourites?.slice()?.reverse(),
);

export const selectSortedInstruments = createSelector(
  FavouritesSelectors.selectFavouriteInstruments,
  InstrumentsSelectors.selectSortingType,
  selectFavouritesListModelSortingByGainers,
  selectFavouritesListModelSortingByLosers,
  selectFavouritesListModelSortingByNameAZ,
  selectFavouritesListModelSortingByNameZA,
  (
    favouitesListItems,
    sortingType,
    sortingByGainers,
    sortingByLosers,
    sortingByNameAZ,
    sortingBynameZA,
  ) => {
    switch (sortingType) {
      case SortingType.GainersFirst:
        return sortingByGainers;
      case SortingType.LosersFirst:
        return sortingByLosers;
      case SortingType.AtoZ:
        return sortingByNameAZ;
      case SortingType.ZtoA:
        return sortingBynameZA;
      default:
        return favouitesListItems;
    }
  },
);

export const selectFavouritesListModel = createSelector(
  FavouritesSelectors.selectFavouriteUsers,
  selectSortedInstruments,
  (users, instruments): FavouriteListItem[] => {
    let items: FavouriteListItem[] = [];
    if (instruments?.length) {
      items = [
        ...items,
        favInstrumentsHeader,
        ...instruments.map(
          (instrument): FavouriteInstrumentItem => ({
            kind: 'instrument',
            instrument,
          }),
        ),
      ];
    }
    if (users?.length) {
      items = [
        ...items,
        favUsersHeader,
        ...users.map((user): FavouriteUserItem => ({ kind: 'user', user })),
      ];
    }
    return items;
  },
);

export const selectFavouritesListVM = createSelector({
  favourites: selectFavouritesListModel,
  sortingType: InstrumentsSelectors.selectSortingTypeVM,
  loaded: FavouritesSelectors.selectFavouritesLoaded,
  quoteStatistic: selectFavouritesListCounts,
});
