import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  DividendDateFilter,
  Listing,
  PortfolioInstrument,
  Quote,
  TimeSeries,
  TimeSeriesPeriod,
} from '@yeekatee/client-api-angular';
import { DiscoverData } from '@yeekatee/instruments-util-discover';
import { errorProps } from '../utils';
import {
  DividendCalendarEntity,
  DividendFilterOption,
  InstrumentsEntity,
  QuotesEntity,
  SortingCategoryKey,
  SortingType,
} from './instruments.models';

export const InstrumentsApiActions = createActionGroup({
  source: 'Instruments/API',
  events: {
    'Load Instrument Success': props<{ instrument?: InstrumentsEntity }>(),
    'Load Instrument Failure': errorProps(),

    // ! TODO Just keeping this separate to avoid the price subscribe side effect
    'Load Single Instrument Success': props<{
      instrument?: InstrumentsEntity;
    }>(),
    'Load Single Instrument Failure': errorProps(),

    'Load Instruments Success': props<{ instruments: InstrumentsEntity[] }>(),
    'Load Instruments Failure': errorProps(),

    'Load Quote Success': props<{ quote: Quote }>(),
    'Load Quote Failure': props<{
      error: any;
      instrument?: InstrumentsEntity;
    }>(),

    'Load Report Quotes Success': props<{
      reportId: string;
      currency?: string;
      quotes: Quote[];
    }>(),
    'Load Report Quotes Failure': props<{
      error: any;
      instruments: PortfolioInstrument[];
    }>(),

    'Load Time Series Success': props<{
      period: TimeSeriesPeriod;
      timeSeries?: TimeSeries;
    }>(),
    'Load Time Series Failure': errorProps(),

    'Load Asset Data Success': props<{
      instruments: DiscoverData;
    }>(),
    'Load Discover Data Failure': errorProps(),

    'Load Discover Overview Assets Success': props<{
      instrumentsOverview: Record<string, InstrumentsEntity[]>;
    }>(),
    'Load Discover Overview Assets Failure': errorProps(),

    'Load Discover Instrument Category Success': props<{
      instrument: InstrumentsEntity;
    }>(),
    'Load Discover Instrument Category Failure': errorProps(),

    'Load Dividend Calendar Success': props<{
      dividendCalendar: DividendCalendarEntity[];
    }>(),
    'Load Dividend Calendar Failure': errorProps(),

    'Price Update': props<{ quoteUpdate: Update<QuotesEntity> }>(),

    'Search Instruments Success': props<{ instruments: InstrumentsEntity[] }>(),
    'Search Instruments Failure': errorProps(),
  },
});

export const CapacitorCalendarActions = createActionGroup({
  source: 'Capacitor Calendar',
  events: {
    'Add Earnings To Calendar Success': emptyProps(),
    'Add Earnings To Calendar Failure': errorProps(),
  },
});

export const ICalFileGeneratorActions = createActionGroup({
  source: 'iCal File Generator',
  events: {
    'Download Earnings iCal File Success': emptyProps(),
    'Download Earnings iCal File Failure': errorProps(),
  },
});

export const DiscoverViewActions = createActionGroup({
  source: 'Discover View',
  events: {
    'Enter Discover Page': emptyProps(),
    'Reload Discover Page': emptyProps(),
    'Search Fab Clicked': emptyProps(),
    'Select Dividend Date': props<{
      date: string;
    }>(),
    'Set Dividend Year and Month': props<{
      year: number;
      month: number;
    }>(),
    'Redirect To Auth': props<{ lastRoute: string }>(),
  },
});

export const DiscoverInstrumentCategoryViewActions = createActionGroup({
  source: 'Discover Instrument Category View',
  events: {
    'Init Discover Instrument Category Page': emptyProps(),
    'Enter Discover Instrument Category Page': emptyProps(),
    'Reload Discover Instrument Category Page': emptyProps(),
    'Toggle Discover Instrument Category View': props<{
      listView: boolean;
    }>(),
    'Sort Discover Instrument Category View': props<{
      sortingType: SortingType;
      categoryKey: SortingCategoryKey;
    }>(),
  },
});

export const DividendCalendarViewActions = createActionGroup({
  source: 'Dividend Calendar View',
  events: {
    'Init Dividend Calendar Page': emptyProps(),
    'Select Date': props<{
      date: string;
    }>(),
    'Set Year and Month': props<{
      year: number;
      month: number;
    }>(),
    'Reset Full Month': emptyProps(),
  },
});

export const DividendCalendarOptionsViewActions = createActionGroup({
  source: 'Discover View',
  events: {
    'Set Sorting Date Type': props<{
      dateTypeOption: DividendDateFilter;
    }>(),
    'Set Dividend Filter Option': props<{
      filter: DividendFilterOption;
    }>(),
  },
});

export const InstrumentsEffectsActions = createActionGroup({
  source: 'Instruments Effects',
  events: {
    'On Init': emptyProps,
    'Subscribe Instrument Price': props<{
      id: string;
      symbol: string;
      mic?: string;
    }>(),
    'Unsubscribe Instrument Price': props<{
      id: string;
      symbol: string;
      mic?: string;
    }>(),
    'Price Update': props<{ quote: QuotesEntity }>(),
    'Listing Or Period Changed': emptyProps(),
    'Fetch Quote': props<{ instrument: InstrumentsEntity }>(),
    'Portfolio Items Loaded': props<{
      reportId: string;
      currency?: string;
      instruments: PortfolioInstrument[];
    }>(),
    'Add Event To Calendar': props<{
      title: string;
      startDate: string;
    }>(),
    'Download ICal File': props<{
      id: string;
      symbol: string;
      title: string;
      description: string;
      startDate: string;
    }>(),
    'Notify Error': errorProps(),
  },
});

export const InstrumentViewActions = createActionGroup({
  source: 'Instrument View',
  events: {
    'Show Instrument': props<{ instrumentId: string }>(),
    'Listing Changed': props<{ listing: Listing }>(),
    Leave: props<{ instrumentId: string }>(),
    'Period Changed': props<{ period: TimeSeriesPeriod }>(),
    'Add Favourite Instrument': props<{
      instrumentId: string;
      symbol: string;
      mic?: string;
    }>(),
    'Remove Favourite Instrument': props<{
      instrumentId: string;
      symbol: string;
      mic?: string;
    }>(),
    'Add Earnings To Calendar': props<{
      id: string;
      symbol: string;
      title: string;
      description: string;
      startDate: string;
    }>(),
  },
});

export const InstrumentStatsActions = createActionGroup({
  source: 'Instrument Stats',
  events: {
    'Flag Instrument': emptyProps(),
    'Show Clipboard Notification': props<{ saved: string }>(),
  },
});

export const InstrumentLazyItemActions = createActionGroup({
  source: 'Instrument Lazy Item',
  events: {
    'Show Instrument': props<{ instrumentId: string }>(),
  },
});
