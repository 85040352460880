import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { GifSelectorComponent } from './gif-selector.component';

@Injectable({ providedIn: 'root' })
export class GifSelectorService {
  constructor(private modalCtrl: ModalController) {}

  async open(callback: (data?: string, role?: string) => void) {
    const modal = await this.modalCtrl.create({
      component: GifSelectorComponent,
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    callback(data, role);
  }
}
