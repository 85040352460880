import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Camera, CameraDirection, CameraResultType } from '@capacitor/camera';
import { ENVIRONMENT, Environment } from '@yeekatee/shared-util-environment';
import { Auth, Storage } from 'aws-amplify';
import * as blurhash from 'blurhash';
import { Observable, combineLatest, from, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ulid } from 'ulid';

interface UploadProgress {
  /**
   * Bytes sent to S3 so far.
   */
  loaded: number;
  /**
   * Total bytes to send to S3.
   */
  total: number;
}

interface PutResult {
  key: string;
}

export interface PhotoImage {
  blob: Blob;
  content: string;
  url: string;
  key?: string;
  width: number;
  height: number;
}

export enum ImageResolutionSize {
  LOW = 256,
  MEDIUM = 512,
  HIGH = 1024,
}

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  private readonly renderer = this.rendererFactory2.createRenderer(null, null);

  private readonly baseURL: string;

  constructor(
    private readonly rendererFactory2: RendererFactory2,
    @Inject(ENVIRONMENT) private readonly environment: Environment,
  ) {
    this.baseURL = this.environment.cloudFrontDistributionDomainName;
  }

  /**
   * Take a picture via Capacitor Camera plugin.
   */
  getPhoto(): Observable<string> {
    const rawImage = from(
      Camera.getPhoto({
        quality: 50,
        allowEditing: true,
        resultType: CameraResultType.Base64,
        direction: CameraDirection.Front,
      }),
    );

    return rawImage.pipe(
      map((image) => {
        if (!image.base64String) throw new Error('could not get image');
        return `data:image/webp;base64,${image.base64String}`;
      }),
    );
  }

  /**
   * Upload photo to S3 under the user's own path.
   *
   * @param image
   * @param options
   */
  uploadImage(
    image: string,
    options?: { name?: string; size?: number },
  ): Observable<PhotoImage> {
    const name = options?.name ?? ulid();
    const size = options?.size ?? ImageResolutionSize.LOW;
    return this.generateImage(image, size).pipe(
      switchMap((image) =>
        this.uploadObject(`${name}.webp`, image.blob, 'protected').pipe(
          filter((event): event is string => typeof event === 'string'),
          map((key) => ({
            ...image,
            url: `${this.baseURL}/${key}`,
            key,
          })),
        ),
      ),
    );
  }

  /**
   * Take a picture via Capacitor Camera plugin, then upload it to S3 under the user's own path.
   *
   * @param name
   */
  getPhotoAndUpload(name?: string): Observable<PhotoImage> {
    return this.getPhoto().pipe(
      switchMap((image) => this.uploadImage(image, { name })),
    );
  }

  /**
   * Upload an object to S3 as the currently authenticated user.
   * @param key The final part of the object key, without the prefix {protected|private}/{identity-id}/
   * @param object The
   * @param level `protected` or `private`.
   *              - Protected objects can be read by any authenticated user who have their paths.
   *              - Private objects are only readable by the creator, or by others through pre-signed URLs.
   */
  private uploadObject(
    key: string,
    object: Blob,
    level: 'protected' | 'private',
  ): Observable<UploadProgress | string> {
    return combineLatest([
      from(Auth.currentUserCredentials()),
      this.uploadWithProgress(key, object, level),
    ]).pipe(
      map(([{ identityId }, uploadEvent]) => {
        if (this.uploadEventIsProgressEvent(uploadEvent)) return uploadEvent;
        return `${level}/${identityId}/${uploadEvent.key}`;
      }),
    );
  }

  /**
   * Given an upload event, verify if it's a progress or completion event.
   * @param event
   */
  private uploadEventIsProgressEvent(
    event: UploadProgress | PutResult,
  ): event is UploadProgress {
    return (<UploadProgress>event).loaded !== undefined;
  }

  private uploadWithProgress(
    key: string,
    object: Blob,
    level: 'protected' | 'private',
  ): Observable<UploadProgress | PutResult> {
    return new Observable((subscriber) => {
      Storage.put(key, object, {
        level,
        progressCallback: (progress: UploadProgress) => {
          subscriber.next(progress);
        },
      }).then((value) => {
        subscriber.next(value);
        subscriber.complete();
      });
    });
  }

  /**
   * Take the image with the URI specified in imageSrc, and resize according to size
   *
   * @param imageSrc
   * @param size
   * @private
   */
  generateImage(imageSrc: string, size?: number): Observable<PhotoImage> {
    return from(
      new Promise<PhotoImage>((resolve) => {
        // Let's create an img element to hold the image
        const image: HTMLImageElement = this.renderer.createElement('img');
        image.crossOrigin = 'Anonymous';

        image.onload = () => {
          // Let's create a canvas to resize the image
          const canvas: HTMLCanvasElement =
            this.renderer.createElement('canvas');
          const ctx = canvas.getContext('2d');

          [canvas.width, canvas.height] = this.resizeDimensions(
            image.width,
            image.height,
            size,
          );

          // Resize image
          ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);

          const [imgWidth, imgHeight] = this.resizeDimensions(
            image.width,
            image.height,
            32,
          );

          const imageData = ctx?.getImageData(0, 0, imgWidth, imgHeight);

          canvas.toBlob((blob) => {
            if (!blob) {
              throw new Error('Could not resize image');
            }

            resolve({
              blob,
              content: imageData ? this.encodeImageToBlurhash(imageData) : '',
              height: canvas.height,
              width: canvas.width,
              url: imageSrc,
            });
          }, 'image/webp');
        };

        // Assign input image data to trigger resizing
        image.src = imageSrc;
      }),
    );
  }

  private encodeImageToBlurhash(imageData: ImageData) {
    return blurhash.encode(
      imageData.data,
      imageData.width,
      imageData.height,
      4,
      4,
    );
  }

  decodeImageFromBlurhash(hash: string, width: number, height: number): string {
    const canvas: HTMLCanvasElement = this.renderer.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const [canvasWidth, canvasHeight] = this.resizeDimensions(
      width,
      height,
      32,
    );
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const pixels = blurhash.decode(hash, canvas.width, canvas.height);
    const imageData = new ImageData(pixels, canvas.width, canvas.height);
    ctx?.putImageData(imageData, 0, 0);

    return canvas.toDataURL();
  }

  private resizeDimensions(
    width: number,
    height: number,
    size?: number,
  ): number[] {
    const aspectRatio = width / height;
    let newWidth = width,
      newHeight = height;

    if (size) {
      // Maintain aspect ratio
      if (width >= height) {
        newHeight = size;
        newWidth = size * aspectRatio;
      } else {
        newWidth = size;
        newHeight = size / aspectRatio;
      }
    }

    return [newWidth, newHeight];
  }
}
