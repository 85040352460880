import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, NotificationsState } from './notifications.reducer';

export const getNotificationsState =
  createFeatureSelector<NotificationsState>(FEATURE_KEY);

export const selectNotification = createSelector(
  getNotificationsState,
  (notification: NotificationsState) => notification,
);
