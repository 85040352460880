import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonItem, IonLabel, IonSkeletonText } from '@ionic/angular/standalone';
import { NoteCardSkeletonComponent } from '../note-card-skeleton/note-card-skeleton.component';

@Component({
  selector: 'yeekatee-notes-list-skeleton',
  templateUrl: './notes-list-skeleton.component.html',
  standalone: true,
  imports: [IonItem, IonLabel, IonSkeletonText, NoteCardSkeletonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesListSkeletonComponent {
  @Input() isDetailView = false;
  @Input() nCards = 4;
}
