import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { InviteCode, PaginatedInviteCodes } from '@yeekatee/client-api-angular';

export const InvitationsFormActions = createActionGroup({
  source: 'Invitations Form',
  events: {
    'User Entered Code': props<{ code: string }>(),
  },
});

export const InvitationsAPIActions = createActionGroup({
  source: 'Invitations/API',
  events: {
    'Create Code Success': props<{ invitation: InviteCode }>(),
    'Create Code Failure': props<{ error: any }>(),

    'Consume Code Success': emptyProps(),
    'Consume Code Failure': props<{ error: any }>(),

    'Get Codes Success': props<PaginatedInviteCodes>(),
    'Get Codes Failure': props<{ error: any }>(),

    'Get Own Code Success': props<{ code: InviteCode }>(),
    'Get Own Code Failure': props<{ error: any }>(),
  },
});

export const InvitationsEffectsActions = createActionGroup({
  source: 'Invitations Effects',
  events: {
    'Notify Error': props<{ error: any }>(),
  },
});

export const InviteListActions = createActionGroup({
  source: 'Settings Invite',
  events: {
    'Init List Codes': emptyProps(),
    'Get More Codes': emptyProps(),
    'Create Invite Code': emptyProps(),
    'Show Clipboard Notification': props<{ saved: string }>(),
  },
});
