import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AddSimulationTransactionInput,
  BookingPeriod,
  CalculationBaseType,
  GetPriceAndXrateInput,
} from '@yeekatee/client-api-angular';
import { AssetEntity } from '../instruments';
import { errorProps } from '../utils';
import {
  InvestmentViewType,
  PortfolioReportEntity,
  PortfolioReportInputs,
  PortfolioViewType,
  ReportPreferences,
  TransactionTradeType,
  TransactionsReportEntity,
  UserPortfolioEntity,
  UserPropertiesEntity,
} from './portfolios.models';

/**
 * Actions triggered from the own portfolio view.
 */
export const PortfolioActions = createActionGroup({
  source: 'Portfolio',
  events: {
    'Load Portfolio Report': props<PortfolioReportInputs>(),
    'Refresh Portfolio Report': emptyProps(),
    'Set Dates': props<{ startDate: string; endDate: string }>(),
    'Set Chart Period': props<{ period: BookingPeriod }>(),
    'Reset Period To Default': emptyProps(),
    'Set Portfolios': props<{ portfolioKeys: string[] }>(),
    'Redirect To Auth': props<{ lastRoute: string }>(),
  },
});

/**
 * Actions triggered from the own portfolio view.
 */
export const PortfolioOptionsActions = createActionGroup({
  source: 'Portfolio Options',
  events: {
    'Set Chart Period': props<{ period: BookingPeriod }>(),
    'Set Report Currency': props<{ currency: string }>(),
    'Set Include Split Items': props<{ splitItems: boolean }>(),
    'Set Calculation Base': props<{
      calculationBaseType: CalculationBaseType;
    }>(),
    'Set Investment View Type': props<{
      investmentViewType: InvestmentViewType;
    }>(),
    'Set Portfolio View': props<{ portfolioViewType: PortfolioViewType }>(),
  },
});

/**
 * Actions triggered from the game portfolio view.
 */
export const GamePortfolioActions = createActionGroup({
  source: 'Game Portfolio',
  events: {
    'Set Chart Period': props<{ period: BookingPeriod }>(),
  },
});

/**
 * Actions triggered from the user's portfolio transactions.
 */
export const TransactionsListActions = createActionGroup({
  source: 'Transactions List',
  events: {
    Init: emptyProps(),
    'Load Report': emptyProps(),
    'Load More': emptyProps(),
  },
});

/**
 * Actions triggered from the transaction details page.
 */
export const TransactionDetailsActions = createActionGroup({
  source: 'Transactions List',
  events: {
    'Delete Transaction': props<{
      portfolioKey: string;
      transactionKey: string;
      transactionsVersion: string;
    }>(),
  },
});

/**
 * Actions triggered from the user's portfolios list.
 */
export const PortfoliosListActions = createActionGroup({
  source: 'Portfolios List',
  events: {
    'Load List': emptyProps(),
    'Reload List': emptyProps(),
    'Delete Portfolio': props<{ portfolio: UserPortfolioEntity }>(),
    'Resync Portfolio': props<{ portfolio: UserPortfolioEntity }>(),
    'Rename Portfolio': props<{
      portfolio: UserPortfolioEntity;
      name: string;
    }>(),
    'Delete All Transactions': props<{ portfolioKey: string }>(),
  },
});

/**
 * Actions triggered from the currency settings view.
 */
export const PortfoliosSettingsCurrencyActions = createActionGroup({
  source: 'Settings Currency',
  events: {
    'Update Report Currency': props<{ userId: string; currency: string }>(),
  },
});

/**
 * Actions triggered from the user's portfolio item view.
 */
export const PortfolioItemActions = createActionGroup({
  source: 'Portfolio Item',
  events: {
    'Show Portfolio Item': emptyProps(),
    'Toggle Geek View': emptyProps(),
  },
});

/**
 * Actions triggered from the data management view.
 */
export const PortfoliosSettingsActions = createActionGroup({
  source: 'Settings Data Management',
  events: {
    'Recalculate Portfolio Figures': emptyProps(),
    'Delete All Portfolios': emptyProps(),
  },
});

/**
 * Actions triggered from the new simulation portfolio form.
 */
export const PortfoliosNewSimulationActions = createActionGroup({
  source: 'New Simulation Portfolio Form',
  events: {
    'Add Simulation Portfolio': props<{
      displayName: string;
      cashEnabled?: boolean;
    }>(),
  },
});

/**
 * Actions triggered from the add transactions view.
 */
export const PortfoliosAddTransactionsActions = createActionGroup({
  source: 'Add Transactions',
  events: {
    Enter: emptyProps(),
    'Buy Button Click': emptyProps(),
    'Sell Button Click': emptyProps(),
  },
});

/**
 * Actions triggered from the add transaction view.
 */
export const PortfoliosAddTransactionActions = createActionGroup({
  source: 'Add Transaction',
  events: {
    'Load Portfolio Report': emptyProps(),
    'Load Instrument': props<{ id: string; symbol?: string; mic?: string }>(),
    'Add Manual Transaction': props<{
      transaction: AddSimulationTransactionInput;
      portfolio: UserPortfolioEntity;
    }>(),
    'Add Trade Transaction': props<{
      instrument: AssetEntity;
      tradeType: TransactionTradeType;
      quantity?: number;
      portfolio: UserPortfolioEntity;
    }>(),
    'Set Portfolio': props<{
      portfolioKey: string;
    }>(),
  },
});

/**
 * Actions triggered from the manual transaction view.
 */
export const PortfoliosManualTransactionActions = createActionGroup({
  source: 'Manual Transaction',
  events: {
    'Get Price And Xrate': props<GetPriceAndXrateInput>(),
  },
});

/**
 * Actions triggered from the trade form view.
 */
export const PortfoliosTradeFormActions = createActionGroup({
  source: 'Trade Form',
  events: {
    'Fetch XRate Failure': errorProps(),
    'Realtime Price Failure': errorProps(),
  },
});

/**
 * Actions triggered from the portfolios reports view.
 */
export const PortfoliosReportsActions = createActionGroup({
  source: 'Portfolios Reports',
  events: {
    'Set Portfolios Report': props<{
      currency?: string;
      endDate?: string;
      period?: BookingPeriod;
      portfolioKeys?: string[];
      startDate?: string;
      userId?: string;
    }>(),
    'Set Portfolios': props<{ portfolioKeys: string[] }>(),
    'Set Chart Period': props<{ period: BookingPeriod }>(),
    'Set Dates': props<{
      startDate: string;
      endDate: string;
    }>(),
    'Copy Portfolio': props<{ portfolioKey: string; displayName: string }>(),
  },
});

/**
 * The responses coming from the portfolios web workers.
 */
export const PortfoliosWebWorkersActions = createActionGroup({
  source: 'Portfolios Web Workers',
  events: {
    'Valuate Portfolio Success': props<{
      report: PortfolioReportEntity;
    }>(),
    'Valuate Portfolio Failure': errorProps(),
  },
});

/**
 * The responses coming from the portfolios cache.
 */
export const PortfoliosCacheActions = createActionGroup({
  source: 'Portfolios Cache',
  events: {
    'Cache Update Success': emptyProps(),
    'Cache Update Failure': errorProps(),
  },
});

/**
 * The responses coming from the portfolios effects.
 */
export const ReportsEffectsActions = createActionGroup({
  source: 'Reports Effects',
  events: {
    'Load Portfolio Report': props<PortfolioReportInputs>(),
    'No Portfolios': emptyProps(),
    'Notify Error': errorProps(),
  },
});

/**
 * The responses coming from the transactions effects.
 */
export const TransactionsEffectsActions = createActionGroup({
  source: 'Transactions Effects',
  events: {
    'Load Transactions Report': emptyProps(),
    'Notify Error': errorProps(),
  },
});

/**
 * The responses coming from the portfolios effects.
 */
export const UserPropertiesEffectsActions = createActionGroup({
  source: 'User Properties Effects',
  events: {
    'Update Portfolio Booking Dates': props<{
      portfolioUpdate: Update<UserPortfolioEntity>;
    }>(),

    'Notify Error': errorProps(),
    'Handle Notification': emptyProps(),
  },
});

/**
 * The responses coming from the portfolios backend.
 */
export const PortfoliosApiActions = createActionGroup({
  source: 'Portfolios API',
  events: {
    'Load Portfolios Report Success': props<{
      report: PortfolioReportEntity;
      period?: BookingPeriod;
      currency?: string;
    }>(),
    'Load Portfolios Report Failure': errorProps(),

    'Load Portfolio Report Success': props<{
      report: PortfolioReportEntity;
      period?: BookingPeriod;
    }>(),
    'Load Portfolio Report Failure': errorProps(),

    'Load Portfolio Items Success': props<{
      report: PortfolioReportEntity;
    }>(),
    'Load Portfolio Items Failure': errorProps(),

    'Load User Transactions Success': props<{
      report: TransactionsReportEntity;
    }>(),
    'Load User Transactions Failure': errorProps(),

    'Load More User Transactions Success': props<{
      report: TransactionsReportEntity;
    }>(),
    'Load More User Transactions Failure': errorProps(),

    'Load User Properties Success': props<{
      properties: UserPropertiesEntity;
      portfolios: UserPortfolioEntity[];
    }>(),
    'Load User Properties Failure': errorProps(),

    'Update Report Currency Success': emptyProps(),
    'Update Report Currency Failure': errorProps(),

    'Recalculate User Portfolio Figures Success': emptyProps(),
    'Recalculate User Portfolio Figures Failure': errorProps(),

    'Delete Portfolio Success': emptyProps(),
    'Delete Portfolio Failure': errorProps(),

    'Delete Portfolios Success': emptyProps(),
    'Delete Portfolios Failure': errorProps(),

    'Delete All Transactions Success': props<{
      portfolioKey: string;
      userId: string;
    }>(),
    'Delete All Transactions Failure': errorProps(),

    'Rename Portfolio Success': emptyProps(),
    'Rename Portfolio Failure': errorProps(),

    'Copy Portfolio Success': emptyProps(),
    'Copy Portfolio Failure': errorProps(),

    'Add Virtual Portfolio Success': props<{
      portfolioKey: string;
    }>(),
    'Add Virtual Portfolio Failure': errorProps(),

    'Add Virtual Transaction Success': props<{
      portfolioKey: string;
      userId: string;
    }>(),
    'Add Virtual Transaction Failure': errorProps(),

    'Add Simulation Portfolio Success': props<{ portfolioKey: string }>(),
    'Add Simulation Portfolio Failure': errorProps(),

    'Add Simulation Transaction Success': props<{
      portfolioKey: string;
      userId: string;
    }>(),
    'Add Simulation Transaction Failure': errorProps(),

    'Delete Simulation Transaction Success': props<{
      portfolioKey: string;
      userId: string;
    }>(),
    'Delete Simulation Transaction Failure': errorProps(),

    'Get Price And Xrate Success': props<{
      price?: string;
      xrate?: string;
    }>(),
    'Get Price And Xrate Failure': errorProps(),

    'Load Report Preferences Success': props<ReportPreferences | undefined>(),
    'Load Report Preferences Failure': errorProps(),

    'Update Report Preferences Success': props<ReportPreferences>(),
    'Update Report Preferences Failure': errorProps(),

    'Load User Portfolios Success': props<{
      portfolios: UserPortfolioEntity[];
    }>(),
    'Load User Portfolios Failure': errorProps(),
  },
});

/**
 * Actions triggered from the performance and risk component.
 */
export const PortfolioPerfRisksActions = createActionGroup({
  source: 'Portfolio Performance and Risk Component',
  events: {
    'Load Portfolio Items Report': props<{
      userId?: string;
      currency?: string;
      period?: BookingPeriod;
      startDate?: string;
      endDate?: string;
      portfolioKeys?: string[];
    }>(),
  },
});

/**
 * Actions triggered from the own portfolio.
 */
export const PortfolioInsightsActions = createActionGroup({
  source: 'Portfolio Insight',
  events: {
    'Toggle geek view': emptyProps(),
  },
});
