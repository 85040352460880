import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../utils';
import {
  LaunchModalsList,
  UserSelectedLaunchModalAction,
} from './launchmodals.models';

/**
 * Getting the list of launch modals from the "API" (i.e. the JSON in the CDN)
 */
export const LaunchModalApiActions = createActionGroup({
  source: 'Launch Modal/API',
  events: {
    'Load LaunchModals Success': props<{ list: LaunchModalsList }>(),
    'Load LaunchModals Failure': errorProps(),

    'Load Acknowledged Success': props<{ acknowledged?: string[] }>(),
    'Load Acknowledged Failure': errorProps(),

    'Persist Acknowledged Success': emptyProps(),
    'Persist Acknowledged Failure': errorProps(),
  },
});

/**
 * Actions that can be triggered by the user on the launch modal
 */
export const LaunchModalActions = createActionGroup({
  source: 'Launch Modal',
  events: {
    'Acknowledged Modal': props<{ id: string; dontShowAgain?: boolean }>(),
    'Selected Action': props<{
      id: string;
      action: UserSelectedLaunchModalAction;
    }>(),
  },
});

export const LaunchModalEffectsActions = createActionGroup({
  source: 'Launch Modal/Effects',
  events: {
    'On Init': emptyProps(),

    'Dispatch Browser Action': props<{ url: string }>(),
    'Browser Action Success': emptyProps(),
    'Browser Action Failure': errorProps(),

    'Dispatch NgRx Action': props<{
      actionType: string;
      actionPayload: object;
    }>(),
    // We don't need to handle the success/failure of the dispatched action,
    // since we're effectively dispatching a separate action,
    // that will then have its own lifecycle.

    'Dispatch App Store Action': emptyProps(),
    'App Store Action Success': emptyProps(),
    'App Store Action Failure': errorProps(),

    'Dispatch Navigate Action': props<{ path: string }>(),

    'Unknown Action': props<{ action: unknown }>(),
  },
});
