import { Action, createReducer, on } from '@ngrx/store';
import { NavigationRouteNames } from '@yeekatee/shared-util-routes';
import {
  AccountLinkingApiActions,
  AccountLinkingEffectsActions,
  getCreateCredentialsErrorI18n,
  getSubmitScaErrorI18n,
} from '../account-linking';
import {
  ActivitiesApiActions,
  ActivitiesEffectsActions,
  ActivitiesNoteCreateActions,
} from '../activity-streams/';
import { AuthAPIActions, AuthEffectsActions } from '../auth';
import {
  getSimulationTransactionTranslation,
  getVirtualTransactionErrorI18n,
  handleError,
} from '../errors';
import { FeedbackEffectsActions } from '../feedback';
import { GamesApiActions, GamesEffectsActions } from '../games';
import {
  CapacitorCalendarActions,
  ICalFileGeneratorActions,
  InstrumentStatsActions,
  InstrumentsEffectsActions,
} from '../instruments';
import { InvitationsEffectsActions, InviteListActions } from '../invitations';
import {
  PortfoliosApiActions,
  ReportsEffectsActions,
  TransactionsEffectsActions,
  UserPropertiesEffectsActions,
} from '../portfolios';
import { RthubAPIActions } from '../rthub';
import { NotificationsComponentActions } from './notifications.actions';
import { NotificationEntity } from './notifications.model';

export const FEATURE_KEY = 'notifications';

export type NotificationsState = NotificationEntity;

export interface NotificationsPartialState {
  readonly [FEATURE_KEY]: NotificationsState;
}

export const initialState: NotificationsState = {};

const reducer = createReducer(
  initialState,
  on(
    AccountLinkingEffectsActions.notifyError,
    ActivitiesEffectsActions.notifyError,
    AuthEffectsActions.notifyError,
    FeedbackEffectsActions.notifyError,
    InstrumentsEffectsActions.notifyError,
    ReportsEffectsActions.notifyError,
    TransactionsEffectsActions.notifyError,
    UserPropertiesEffectsActions.notifyError,
    InvitationsEffectsActions.notifyError,
    (state, { error }) => ({
      ...state,
      message: handleError(error),
      icon: 'alert-circle',
      redirectPath: undefined,
    }),
  ),
  on(PortfoliosApiActions.addVirtualTransactionFailure, (state, { error }) => ({
    ...state,
    message: getVirtualTransactionErrorI18n(error) || handleError(error),
    icon: 'alert-circle',
    redirectPath: undefined,
  })),
  on(AccountLinkingApiActions.createCredentialsFailure, (state, { error }) => ({
    ...state,
    message: getCreateCredentialsErrorI18n(error.message) || handleError(error),
    icon: 'alert-circle',
    redirectPath: undefined,
  })),
  on(AccountLinkingApiActions.submitScaFailure, (state, { error }) => ({
    ...state,
    message: getSubmitScaErrorI18n(error.message) || handleError(error),
    icon: 'alert-circle',
    redirectPath: undefined,
  })),
  on(
    PortfoliosApiActions.addSimulationTransactionFailure,
    (state, { error }) => ({
      ...state,
      message: getSimulationTransactionTranslation(error) || handleError(error),
      icon: 'alert-circle',
      redirectPath: undefined,
    }),
  ),
  on(
    PortfoliosApiActions.addVirtualTransactionSuccess,
    PortfoliosApiActions.addSimulationTransactionSuccess,
    PortfoliosApiActions.deleteSimulationTransactionSuccess,
    (state) => ({
      ...state,
      message: $localize`Operation performed successfully`,
      icon: 'information-circle-outline',
      redirectPath: undefined,
    }),
  ),
  on(
    PortfoliosApiActions.addVirtualPortfolioSuccess,
    PortfoliosApiActions.addSimulationPortfolioSuccess,
    PortfoliosApiActions.copyPortfolioSuccess,
    (state) => ({
      ...state,
      message: $localize`Portfolio created successfully`,
      icon: 'information-circle-outline',
      redirectPath: undefined,
    }),
  ),
  on(GamesApiActions.joinGameSuccess, (state) => ({
    ...state,
    message: $localize`You joined the game successfully`,
    icon: 'information-circle-outline',
    redirectPath: undefined,
  })),
  on(AuthAPIActions.confirmEmailSuccess, (state) => ({
    ...state,
    message: $localize`Your email is changed`,
    icon: 'mail-outline',
    redirectPath: undefined,
  })),
  on(
    AuthAPIActions.confirmPasswordSettingsSuccess,
    AuthAPIActions.confirmPasswordSuccess,
    (state) => ({
      ...state,
      message: $localize`Your password is changed`,
      icon: 'lock-closed-outline',
      redirectPath: undefined,
    }),
  ),
  on(
    ActivitiesNoteCreateActions.showNotificationCharLimitExceeded,
    (state, { charCountLimit }) => ({
      ...state,
      message: $localize`Post limit is ${charCountLimit} characters`,
      icon: 'warning-outline',
      redirectPath: undefined,
    }),
  ),
  on(
    InstrumentStatsActions.showClipboardNotification,
    InviteListActions.showClipboardNotification,
    (state, { saved }) => ({
      ...state,
      message: $localize`${saved} saved to your clipboard`,
      icon: 'clipboard-outline',
      redirectPath: undefined,
    }),
  ),
  on(
    ActivitiesEffectsActions.postUrlCopied,
    GamesEffectsActions.gameUrlCopied,
    (state) => ({
      ...state,
      message: $localize`Link saved to your clipboard`,
      icon: 'clipboard-outline',
      redirectPath: undefined,
    }),
  ),
  on(RthubAPIActions.messageReceived, (state, { data }) =>
    data.type === 'accountLinked'
      ? {
          ...state,
          message: data?.message,
          icon: 'information-circle-outline',
          redirectPath: NavigationRouteNames.PORTFOLIOS,
        }
      : state,
  ),
  on(ActivitiesApiActions.flagInstrumentSuccess, (state) => ({
    ...state,
    message: $localize`Reported instrument will be reviewed`,
    icon: 'glasses-outline',
    redirectPath: undefined,
  })),
  on(ActivitiesApiActions.flagUserSuccess, (state) => ({
    ...state,
    message: $localize`Reported user will be reviewed`,
    icon: 'glasses-outline',
    redirectPath: undefined,
  })),
  on(ActivitiesApiActions.flagNoteSuccess, (state) => ({
    ...state,
    message: $localize`Reported post will be reviewed`,
    icon: 'glasses-outline',
    redirectPath: undefined,
  })),
  on(ActivitiesApiActions.flagBankSuccess, (state) => ({
    ...state,
    message: $localize`Thank you very much for your request. We get back to you`,
    icon: 'mail-outline',
  })),
  on(CapacitorCalendarActions.addEarningsToCalendarSuccess, (state) => ({
    ...state,
    message: $localize`The event was created successfully in your calendar`,
    icon: 'calendar-outline',
  })),
  on(CapacitorCalendarActions.addEarningsToCalendarFailure, (state) => ({
    ...state,
    message: $localize`The event could not be created in your calendar`,
    icon: 'alert-outline',
  })),
  on(ICalFileGeneratorActions.downloadEarningsICalFileSuccess, (state) => ({
    ...state,
    message: $localize`The event file was downloaded successfully`,
    icon: 'calendar-outline',
  })),
  on(ICalFileGeneratorActions.downloadEarningsICalFileFailure, (state) => ({
    ...state,
    message: $localize`The event file could not be downloaded`,
    icon: 'alert-outline',
  })),
  on(NotificationsComponentActions.dismiss, (state) => ({
    ...state,
    message: undefined,
    icon: undefined,
    redirectPath: undefined,
  })),
);

export function notificationsReducer(
  state: NotificationsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
