import { createFeature } from '@ngrx/store';
import { adapter, name, reducer } from './invitations.reducer';

export const invitationsFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectInvitationsState }) => {
    const entitySelectors = adapter.getSelectors(selectInvitationsState);
    return {
      ...entitySelectors,
    };
  },
});
