import { BankKey } from './deprecated-bank-keys';
import {
  FlanksSupportedBank,
  InfoTypes,
  SCATypesFlanksYeekatee,
  YeeSupportedBank,
} from './types';

export const supportedBanks: {
  [bankId: string]: FlanksSupportedBank | YeeSupportedBank;
} = {
  'flanks://trade.swissquote.ch/#swissquote': {
    flanksId: 'swissquote',
    bookingEngineId: BankKey.Swissquote,
    name: 'Swissquote',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/swissquote.png',
    id: 'flanks://trade.swissquote.ch/#swissquote',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.coordinateCard,
      SCATypesFlanksYeekatee.synchronousApp,
    ],
    integration: 'flanks',
    info: InfoTypes.swissQuoteIndividual,
  },
  'flanks://www.interactivebrokers.co.uk/#interactivebrokers': {
    flanksId: 'interactivebrokers',
    bookingEngineId: BankKey.InteractiveBrokers,
    name: 'Interactive Brokers',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/interactivebrokers.png',
    id: 'flanks://www.interactivebrokers.co.uk/#interactivebrokers',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
    info: InfoTypes.nextBusinessDayDelay,
  },
  'flanks://trader.degiro.nl/#degiro': {
    flanksId: 'degiro',
    bookingEngineId: BankKey.DEGIRO,
    name: 'DEGIRO',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/degiro.png',
    id: 'flanks://trader.degiro.nl/#degiro',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.code,
      SCATypesFlanksYeekatee.googleAuthenticator,
    ],
    integration: 'flanks',
    info: InfoTypes.nextBusinessDayDelay,
  },
  'flanks://connect-ch1.ubs.com/#ubsch': {
    flanksId: 'ubsch',
    bookingEngineId: BankKey.UBS,
    name: 'UBS CH',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ubs.png',
    id: 'flanks://connect-ch1.ubs.com/#ubsch',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.cardReader],
    integration: 'flanks',
  },
  'flanks://www.saxotrader.com/#saxobank': {
    flanksId: 'saxobank',
    bookingEngineId: BankKey.Saxobank,
    name: 'Saxo Bank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/saxobank.png',
    id: 'flanks://www.saxotrader.com/#saxobank',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.bancsabadell.com/#urquijo': {
    flanksId: 'urquijo',
    bookingEngineId: BankKey.BancoSabadell,
    name: 'Banco Sabadell Urquijo',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/urquijo.png',
    id: 'flanks://www.bancsabadell.com/#urquijo',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://particulares.bancosantander.es/#popular': {
    flanksId: 'popular',
    bookingEngineId: BankKey.BancoPopular,
    name: 'Banco Popular',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/popular.png',
    id: 'flanks://particulares.bancosantander.es/#popular',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://bancaonline.bankinter.com/#bankinter': {
    flanksId: 'bankinter',
    bookingEngineId: BankKey.Bankinter,
    name: 'Bankinter',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bankinter.png',
    id: 'flanks://bancaonline.bankinter.com/#bankinter',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  // 'flanks://empresas.bankinter.com/#bankinter-e': {
  //   flanksId: 'bankinter-e',
  //   name: 'Bankinter Empresas',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bankinter.png',
  //   id: 'flanks://empresas.bankinter.com/#bankinter-e',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.notNeeded,SCATypesFlanksYeekatee.sms],
  //   integration: 'flanks',
  //
  // },
  'flanks://www.cajamar.es/#cajamar': {
    flanksId: 'cajamar',
    bookingEngineId: BankKey.Cajamar,
    name: 'Cajamar',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/cajamar.png',
    id: 'flanks://www.cajamar.es/#cajamar',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.morabanc.ad/#morabanc': {
    flanksId: 'morabanc',
    bookingEngineId: BankKey.Morabanc,
    name: 'MoraBanc',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/morabanc.png',
    id: 'flanks://www.morabanc.ad/#morabanc',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.caixabank.es/#bankia': {
    flanksId: 'bankia',
    bookingEngineId: BankKey.Caixabank,
    name: 'Bankia',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bankia.png',
    id: 'flanks://www.caixabank.es/#bankia',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.app,
      SCATypesFlanksYeekatee.sms,
    ],
    integration: 'flanks',
  },
  'flanks://www.allianz.es/#allianz': {
    flanksId: 'allianz',
    bookingEngineId: BankKey.Allianz,
    name: 'Allianz ES',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/allianz.png',
    id: 'flanks://www.allianz.es/#allianz',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://comercial.creditandorragroup.ad/#creditandorra': {
    flanksId: 'creditandorra',
    bookingEngineId: BankKey.CreditAndorra,
    name: 'Credit Andorra',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/creditandorra.png',
    id: 'flanks://comercial.creditandorragroup.ad/#creditandorra',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://areaprivada.unicajabanco.es/#unicaja': {
    flanksId: 'unicaja',
    bookingEngineId: BankKey.Unicaja,
    name: 'Unicaja',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/unicaja.png',
    id: 'flanks://areaprivada.unicajabanco.es/#unicaja',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://investors.altamarcapital.com/#altamar': {
    flanksId: 'altamar',
    bookingEngineId: BankKey.Altamar,
    name: 'Altamar',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/altamar.png',
    id: 'flanks://investors.altamarcapital.com/#altamar',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://eportfolio-ewm.bgl.lu/#bnp': {
    flanksId: 'bnp',
    bookingEngineId: BankKey.BNP,
    name: 'BNP Paribas Wealth Management',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bnp.png',
    id: 'flanks://eportfolio-ewm.bgl.lu/#bnp',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.cryptoCalculator],
    integration: 'flanks',
  },
  // 'flanks://empresas3.gruposantander.es/#popular-e': {
  //   flanksId: 'popular-e',
  //   name: 'Banco Popular Empresas',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/popular-e.png',
  //   id: 'flanks://empresas3.gruposantander.es/#popular-e',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.notNeeded,SCATypesFlanksYeekatee.sms],
  //   integration: 'flanks',
  //
  // },
  'flanks://particulares.bancosantander.es/#santander': {
    flanksId: 'santander',
    bookingEngineId: BankKey.BancoSantander,
    name: 'Banco Santander',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/santander.png',
    id: 'flanks://particulares.bancosantander.es/#santander',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaturis': {
    flanksId: 'caixaturis',
    bookingEngineId: BankKey.Cajamar, // ? Caixa Turis ?
    name: 'Caixa Turis',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixaturis.png',
    id: 'flanks://www.cajamar.es/#caixaturis',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#cajacheste': {
    flanksId: 'cajacheste',
    bookingEngineId: BankKey.Cajamar, // ? Caixa Cheste ?
    name: 'Caja Cheste',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/cajacheste.png',
    id: 'flanks://www.cajamar.es/#cajacheste',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixapetrer': {
    flanksId: 'caixapetrer',
    bookingEngineId: BankKey.Cajamar, // ? Caixa Petrer ?
    name: 'Caixa Petrer',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixapetrer.png',
    id: 'flanks://www.cajamar.es/#caixapetrer',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://magallanesvalue.com/#magallanes': {
    flanksId: 'magallanes',
    bookingEngineId: BankKey.MagallanesValue,
    name: 'Magallanes Value',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/magallanes.png',
    id: 'flanks://magallanesvalue.com/#magallanes',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.bancsabadell.com/#sabadellcam': {
    flanksId: 'sabadellcam',
    bookingEngineId: BankKey.BancoSabadell, // ? Sabadell CAM ?
    name: 'Sabadell CAM',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/sabadellcam.png',
    id: 'flanks://www.bancsabadell.com/#sabadellcam',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://clientes.selfbank.es/#selfbank': {
    flanksId: 'selfbank',
    bookingEngineId: BankKey.SelfBank,
    name: 'Self Bank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/selfbank.png',
    id: 'flanks://clientes.selfbank.es/#selfbank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixacallosa': {
    flanksId: 'caixacallosa',
    bookingEngineId: BankKey.Cajamar, // ? Caixa Callosa ?
    name: 'Caixa Callosa',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixacallosa.png',
    id: 'flanks://www.cajamar.es/#caixacallosa',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://accesoprivado.ayg.es/#ayg': {
    flanksId: 'ayg',
    bookingEngineId: BankKey.AYG,
    name: 'A&G Banco',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ayg.png',
    id: 'flanks://accesoprivado.ayg.es/#ayg',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://portal.kutxabank.es/#kutxabank': {
    flanksId: 'kutxabank',
    bookingEngineId: BankKey.Kutxabank,
    name: 'Kutxabank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/kutxabank.png',
    id: 'flanks://portal.kutxabank.es/#kutxabank',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.ibercaja.es/#ibercaja': {
    flanksId: 'ibercaja',
    bookingEngineId: BankKey.Ibercaja,
    name: 'Ibercaja',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ibercaja.png',
    id: 'flanks://www.ibercaja.es/#ibercaja',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://secure.edmond-de-rothschild.eu/#edmondderothschild': {
    flanksId: 'edmondderothschild',
    bookingEngineId: BankKey.EDR,
    name: 'Edmond de Rothschild Luxembourg',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/edmondderothschild.png',
    id: 'flanks://secure.edmond-de-rothschild.eu/#edmondderothschild',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaruralalqueries': {
    flanksId: 'caixaruralalqueries',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural Alqueries',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_alqueries.png',
    id: 'flanks://www.cajamar.es/#caixaruralalqueries',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://ing.ingdirect.es/#ing': {
    flanksId: 'ing',
    bookingEngineId: BankKey.ING,
    name: 'ING',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ING.png',
    id: 'flanks://ing.ingdirect.es/#ing',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.app,
      SCATypesFlanksYeekatee.sms,
    ],
    integration: 'flanks',
  },
  'flanks://www.openbank.es/#openbank': {
    flanksId: 'openbank',
    bookingEngineId: BankKey.Openbank,
    name: 'Openbank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/openbank.png',
    id: 'flanks://www.openbank.es/#openbank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://ebanking2.ca-indosuez.es/#creditagricoleindosuez': {
    flanksId: 'creditagricoleindosuez',
    bookingEngineId: BankKey.CreditAgricole,
    name: 'Credit Agricole Indosuez',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/creditagricoleindosuez.png',
    id: 'flanks://ebanking2.ca-indosuez.es/#creditagricoleindosuez',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixasantvicent': {
    flanksId: 'caixasantvicent',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Sant Vicent',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_sant_vicent.png',
    id: 'flanks://www.cajamar.es/#caixasantvicent',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaxilxes': {
    flanksId: 'caixaxilxes',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural de Xilxes',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_xilxes.png',
    id: 'flanks://www.cajamar.es/#caixaxilxes',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.inversis.com/#myinvestor': {
    flanksId: 'myinvestor',
    bookingEngineId: BankKey.MyInvestor,
    name: 'MyInvestor',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/myinvestor.png',
    id: 'flanks://www.inversis.com/#myinvestor',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.r4.com/#renta4': {
    flanksId: 'renta4',
    bookingEngineId: BankKey.Renta4,
    name: 'Renta 4',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/renta4.png',
    id: 'flanks://www.r4.com/#renta4',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.edm.es/#edm': {
    flanksId: 'edm',
    bookingEngineId: BankKey.EDM,
    name: 'EDM',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/edm.jpg',
    id: 'flanks://www.edm.es/#edm',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://particulares.bancosantander.es/#oficinadirecta': {
    flanksId: 'oficinadirecta',
    bookingEngineId: BankKey.OficinaDirecta,
    name: 'Oficina Directa',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/oficinadirecta.png',
    id: 'flanks://particulares.bancosantander.es/#oficinadirecta',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.miscellaneous,
    ],
    integration: 'flanks',
  },
  'flanks://particulares.bancosantander.es/#pastor': {
    flanksId: 'pastor',
    bookingEngineId: BankKey.BancoPastor,
    name: 'Banco Pastor',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/pastor.png',
    id: 'flanks://particulares.bancosantander.es/#pastor',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.caixabank.es/#imaginbank': {
    flanksId: 'imaginbank',
    bookingEngineId: BankKey.Imaginbank,
    name: 'imagin',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/imaginbank.png',
    id: 'flanks://www.caixabank.es/#imaginbank',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.app,
      SCATypesFlanksYeekatee.sms,
    ],
    integration: 'flanks',
  },
  'flanks://clientes.rentamarkets.com/#rentamarkets': {
    flanksId: 'rentamarkets',
    bookingEngineId: BankKey.RentaMarkets,
    name: 'Renta Markets',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/rentamarkets.png',
    id: 'flanks://clientes.rentamarkets.com/#rentamarkets',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.bbva.es/#unnim': {
    flanksId: 'unnim',
    bookingEngineId: BankKey.Unnim,
    name: 'Unnim',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/unnim.png',
    id: 'flanks://www.bbva.es/#unnim',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.cobasam.com/#cobas': {
    flanksId: 'cobas',
    bookingEngineId: BankKey.Cobas,
    name: 'Cobas AM',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/cobas.png',
    id: 'flanks://www.cobasam.com/#cobas',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://telemarch.bancamarch.es/#bancamarchbroker': {
    flanksId: 'bancamarchbroker',
    bookingEngineId: BankKey.BancaMarch,
    name: 'Banca March',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bancamarchbroker.png',
    id: 'flanks://telemarch.bancamarch.es/#bancamarchbroker',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://clientes-bancocaminos.grupocaminos.es/#bancocaminos': {
    flanksId: 'bancocaminos',
    bookingEngineId: BankKey.BancoCaminos,
    name: 'Banco Caminos',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bancocaminos.png',
    id: 'flanks://clientes-bancocaminos.grupocaminos.es/#bancocaminos',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://acceso.ebnbanco.com/#ebn': {
    flanksId: 'ebn',
    bookingEngineId: BankKey.EBN,
    name: 'EBN',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ebn.png',
    id: 'flanks://acceso.ebnbanco.com/#ebn',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://indexacapital.com/#indexacapital': {
    flanksId: 'indexacapital',
    bookingEngineId: BankKey.IndexaCapital,
    name: 'Indexa Capital',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/indexacapital.png',
    id: 'flanks://indexacapital.com/#indexacapital',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://empresas3.gruposantander.es/#santander-e': {
    flanksId: 'santander-e',
    bookingEngineId: BankKey.BancoSantander,
    name: 'Banco Santander Empresas',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/santander-e.png',
    id: 'flanks://empresas3.gruposantander.es/#santander-e',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaruralalmenara': {
    flanksId: 'caixaruralalmenara',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural Almenara',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_almenara.png',
    id: 'flanks://www.cajamar.es/#caixaruralalmenara',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://direct.credit-suisse.com/#creditsuissech': {
    flanksId: 'creditsuissech',
    bookingEngineId: BankKey.CreditSuisse,
    name: 'Credit Suisse CH',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/creditsuissech.png',
    id: 'flanks://direct.credit-suisse.com/#creditsuissech',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.qrCodeOrOk],
    integration: 'flanks',
    info: InfoTypes.qrCodeAuthentication,
  },
  'flanks://dbonline.deutsche-bank.es/#deutsche': {
    flanksId: 'deutsche',
    bookingEngineId: BankKey.DeutscheBank,
    name: 'Deutsche Bank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/deutsche.png',
    id: 'flanks://dbonline.deutsche-bank.es/#deutsche',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://idp.juliusbaer.com/#juliusbaer': {
    flanksId: 'juliusbaer',
    bookingEngineId: BankKey.JuliusBaer,
    name: 'Julius Baer',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/juliusbaer.png',
    id: 'flanks://idp.juliusbaer.com/#juliusbaer',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://gesiuris.com/#gesiuris': {
    flanksId: 'gesiuris',
    bookingEngineId: BankKey.Gesiuris,
    name: 'Gesiuris',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/gesiuris.png',
    id: 'flanks://gesiuris.com/#gesiuris',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://mylo.lombardodier.com/#lombardodier': {
    flanksId: 'lombardodier',
    bookingEngineId: BankKey.LomardOdier,
    name: 'Lombard Odier',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/lombardodier.png',
    id: 'flanks://mylo.lombardodier.com/#lombardodier',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.qrAccept],
    integration: 'flanks',
  },
  'flanks://authorization.andbank.com/#andbankandorra': {
    flanksId: 'andbankandorra',
    bookingEngineId: BankKey.Andbank,
    name: 'Andbank Andorra',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/andbankandorra.png',
    id: 'flanks://authorization.andbank.com/#andbankandorra',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://login.vontobel.com/#vontobel': {
    flanksId: 'vontobel',
    bookingEngineId: BankKey.Vontobel,
    name: 'Vontobel',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/vontobel.png',
    id: 'flanks://login.vontobel.com/#vontobel',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://areacliente.santaluciaam.es/#santalucia': {
    flanksId: 'santalucia',
    bookingEngineId: BankKey.SantaLucia,
    name: 'Santa Lucia AM',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/santalucia.jpg',
    id: 'flanks://areacliente.santaluciaam.es/#santalucia',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.caixabank.es/#bankia-e': {
    flanksId: 'bankia-e',
    bookingEngineId: BankKey.Caixabank,
    name: 'Bankia Empresas',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bankia-e.jpg',
    id: 'flanks://www.caixabank.es/#bankia-e',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.app,
      SCATypesFlanksYeekatee.sms,
    ],
    integration: 'flanks',
  },
  'flanks://www.axa.es/#axa': {
    flanksId: 'axa',
    bookingEngineId: BankKey.AXA,
    name: 'AXA',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/axa.png',
    id: 'flanks://www.axa.es/#axa',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaruraltorrent': {
    flanksId: 'caixaruraltorrent',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural Torrent',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_torrent.png',
    id: 'flanks://www.cajamar.es/#caixaruraltorrent',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.grupocooperativocajamar.es/#caixaruralvilareal': {
    flanksId: 'caixaruralvilareal',
    bookingEngineId: BankKey.GrupoCooperativoCajamar,
    name: 'Caixa Rural Vila-Real',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_vilareal.png',
    id: 'flanks://www.grupocooperativocajamar.es/#caixaruralvilareal',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://npc.bger.mc/#edmondderothschildmonaco': {
    flanksId: 'edmondderothschildmonaco',
    bookingEngineId: BankKey.EDR,
    name: 'Edmond de Rothschild Monaco',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/edmondderothschildmonaco.png',
    id: 'flanks://npc.bger.mc/#edmondderothschildmonaco',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://www.unicajabanco.es/#liberbank': {
    flanksId: 'liberbank',
    bookingEngineId: BankKey.Liberbank,
    name: 'Liberbank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/liberbank.jpg',
    id: 'flanks://www.unicajabanco.es/#liberbank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://private.pictet.com/#pictet': {
    flanksId: 'pictet',
    bookingEngineId: BankKey.Pictet,
    name: 'Pictet',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/pictet.jpg',
    id: 'flanks://private.pictet.com/#pictet',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.qrAccept, SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://www.bancsabadell.com/#sabadellconsultivo': {
    flanksId: 'sabadellconsultivo',
    bookingEngineId: BankKey.BancoSabadell,
    name: 'Banco Sabadell Consultivo',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/sabadellconsultivo.png',
    id: 'flanks://www.bancsabadell.com/#sabadellconsultivo',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.duxinversores.com/#duxinversores': {
    flanksId: 'duxinversores',
    bookingEngineId: BankKey.DuxInversores,
    name: 'Dux Inversores',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/duxinversores.png',
    id: 'flanks://www.duxinversores.com/#duxinversores',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://creandonlinebanking.es/#bancoalcala': {
    flanksId: 'bancoalcala',
    bookingEngineId: BankKey.BancoAlcala,
    name: 'Banco Alcala',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bancoalcala.jpg',
    id: 'flanks://creandonlinebanking.es/#bancoalcala',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://areaprivada.bekaassetmanagement.com/#bekaassetmanagement': {
    flanksId: 'bekaassetmanagement',
    bookingEngineId: BankKey.Beka,
    name: 'Beka Asset Management',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bekaassetmanagement.png',
    id: 'flanks://areaprivada.bekaassetmanagement.com/#bekaassetmanagement',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://altairfinance.es/#altairfinance': {
    flanksId: 'altairfinance',
    bookingEngineId: BankKey.AltairFinance,
    name: 'Altair Finance',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/altairfinance.png',
    id: 'flanks://altairfinance.es/#altairfinance',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.inversis.com/#andbank': {
    flanksId: 'andbank',
    bookingEngineId: BankKey.Andbank,
    name: 'Andbank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/andbank.png',
    id: 'flanks://www.inversis.com/#andbank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://areacliente.belgraviacapital.es/#belgraviacapital': {
    flanksId: 'belgraviacapital',
    bookingEngineId: BankKey.BelgraviaCapital,
    name: 'Belgravia Capital',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/belgraviacapital.png',
    id: 'flanks://areacliente.belgraviacapital.es/#belgraviacapital',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://clientes.bestinver.es/#bestinver': {
    flanksId: 'bestinver',
    bookingEngineId: BankKey.Bestinver,
    name: 'Bestinver',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bestinver.png',
    id: 'flanks://clientes.bestinver.es/#bestinver',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.mutua.es/#mutuactivos': {
    flanksId: 'mutuactivos',
    bookingEngineId: BankKey.MutuaActivos,
    name: 'Mutuactivos',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/mutuactivos.png',
    id: 'flanks://www.mutua.es/#mutuactivos',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://bancaelectronica.evobanco.com/#evo': {
    flanksId: 'evo',
    bookingEngineId: BankKey.EVOBanco,
    name: 'EVO Banco',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/evo.png',
    id: 'flanks://bancaelectronica.evobanco.com/#evo',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://gesconsult.com/#gesconsult': {
    flanksId: 'gesconsult',
    bookingEngineId: BankKey.Gesconsult,
    name: 'Gesconsult',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/gesconsult.png',
    id: 'flanks://gesconsult.com/#gesconsult',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.caixabank.es/#caixabank': {
    flanksId: 'caixabank',
    bookingEngineId: BankKey.Caixabank,
    name: 'CaixaBank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixabank.png',
    id: 'flanks://www.caixabank.es/#caixabank',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.app,
      SCATypesFlanksYeekatee.sms,
    ],
    integration: 'flanks',
  },
  'flanks://www.inversis.com/#inversis': {
    flanksId: 'inversis',
    bookingEngineId: BankKey.BancoInversis,
    name: 'Banco Inversis',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/inversis.png',
    id: 'flanks://www.inversis.com/#inversis',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://areacliente.metagestion.com/#metagestion': {
    flanksId: 'metagestion',
    bookingEngineId: BankKey.MetaGestion,
    name: 'Metagestión',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/metagestion.png',
    id: 'flanks://areacliente.metagestion.com/#metagestion',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.varianza.com/#varianza': {
    flanksId: 'varianza',
    bookingEngineId: BankKey.Varianza,
    name: 'Varianza',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/varianza.png',
    id: 'flanks://www.varianza.com/#varianza',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://clientes.dunascapital.com/#dunascapital': {
    flanksId: 'dunascapital',
    bookingEngineId: BankKey.DunasCapital,
    name: 'Dunas Capital',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/dunascapital.png',
    id: 'flanks://clientes.dunascapital.com/#dunascapital',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://ebanking.efginternational.com/#efg': {
    flanksId: 'efg',
    bookingEngineId: BankKey.EFG,
    name: 'EFG',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/efg.png',
    id: 'flanks://ebanking.efginternational.com/#efg',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.codeDevice],
    integration: 'flanks',
  },
  'flanks://ebanking.ubp.com/#ubpwealth': {
    flanksId: 'ubpwealth',
    bookingEngineId: BankKey.UBP,
    name: 'UBP Wealth',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ubpwealth.png',
    id: 'flanks://ebanking.ubp.com/#ubpwealth',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.miscellaneous],
    integration: 'flanks',
  },
  'flanks://areacliente.azvalor.com/#azvalor': {
    flanksId: 'azvalor',
    bookingEngineId: BankKey.AZValor,
    name: 'Azvalor',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/azvalor.png',
    id: 'flanks://areacliente.azvalor.com/#azvalor',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.mapfre.es/#mapfre': {
    flanksId: 'mapfre',
    bookingEngineId: BankKey.Mapfre,
    name: 'MAPFRE AM',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/mapfre.png',
    id: 'flanks://www.mapfre.es/#mapfre',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.bancsabadell.com/#activobank': {
    flanksId: 'activobank',
    bookingEngineId: BankKey.ActivoBank,
    name: 'ActivoBank ES',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/activobank.png',
    id: 'flanks://www.bancsabadell.com/#activobank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://atica.bankinter.com/#bankinteratica': {
    flanksId: 'bankinteratica',
    bookingEngineId: BankKey.Bankinter,
    name: 'Bankinter Atica',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bankinteratica.png',
    id: 'flanks://atica.bankinter.com/#bankinteratica',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.call],
    integration: 'flanks',
  },
  'flanks://canalonline.gvcgaesco.es/#gaescobeka': {
    flanksId: 'gaescobeka',
    bookingEngineId: BankKey.GVCGaesco,
    name: 'GVC Gaesco Beka',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/gaescobeka.png',
    id: 'flanks://canalonline.gvcgaesco.es/#gaescobeka',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.inversis.com/#andbankagente': {
    flanksId: 'andbankagente',
    bookingEngineId: BankKey.Andbank,
    name: 'Andbank Agentes',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/andbankagente.jpg',
    id: 'flanks://www.inversis.com/#andbankagente',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#creditocooperativo': {
    flanksId: 'creditocooperativo',
    bookingEngineId: BankKey.CreditoCooperativo,
    name: 'Banco de Crédito Cooperativo',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/credito_cooperativo.png',
    id: 'flanks://www.cajamar.es/#creditocooperativo',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaruralalginet': {
    flanksId: 'caixaruralalginet',
    bookingEngineId: BankKey.Cajamar,
    name: "Caixa Rural d'Alginet",
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_alginet.png',
    id: 'flanks://www.cajamar.es/#caixaruralalginet',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaruralnules': {
    flanksId: 'caixaruralnules',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural Nules',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_nules.png',
    id: 'flanks://www.cajamar.es/#caixaruralnules',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaruralburriana': {
    flanksId: 'caixaruralburriana',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural Burriana',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_burriana.png',
    id: 'flanks://www.cajamar.es/#caixaruralburriana',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.caixabank.es/#bancacivica': {
    flanksId: 'bancacivica',
    bookingEngineId: BankKey.Caixabank,
    name: 'Banca Cívica',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/banca_civica.png',
    id: 'flanks://www.caixabank.es/#bancacivica',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.sms,
      SCATypesFlanksYeekatee.app,
    ],
    integration: 'flanks',
  },
  'flanks://ebanking-ch.ubs.com/#ubschpersonal': {
    flanksId: 'ubschpersonal',
    bookingEngineId: BankKey.UBS,
    name: 'UBS CH Personal',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ubschpersonal.png',
    id: 'flanks://ebanking-ch.ubs.com/#ubschpersonal',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.cardReader, SCATypesFlanksYeekatee.qrAccept],
    integration: 'flanks',
    info: InfoTypes.qrCodeAuthentication,
  },
  'flanks://www.valentum.es/#valentum': {
    flanksId: 'valentum',
    bookingEngineId: BankKey.Valentum,
    name: 'Valentum',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/valentum.png',
    id: 'flanks://www.valentum.es/#valentum',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://clientes.buyandhold.es/#buyandhold': {
    flanksId: 'buyandhold',
    bookingEngineId: BankKey.BuyAndHold,
    name: 'Buy & Hold',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/buyandhold.png',
    id: 'flanks://clientes.buyandhold.es/#buyandhold',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.etoro.com/#etoro': {
    flanksId: 'etoro',
    bookingEngineId: BankKey.ETORO,
    name: 'eToro',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/etoro.png',
    id: 'flanks://www.etoro.com/#etoro',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
    info: InfoTypes.etoroIndividual,
  },
  'flanks://sec.novobanco.pt/#novobanco': {
    flanksId: 'novobanco',
    bookingEngineId: BankKey.Novobanco,
    name: 'novobanco',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/novobanco.png',
    id: 'flanks://sec.novobanco.pt/#novobanco',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.bancsabadell.com/#sabadellgallego': {
    flanksId: 'sabadellgallego',
    bookingEngineId: BankKey.BancoSabadell,
    name: 'SabadellGallego',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/sabadellgallego.png',
    id: 'flanks://www.bancsabadell.com/#sabadellgallego',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://areacliente.loretoinversiones.com/#loretoinversiones': {
    flanksId: 'loretoinversiones',
    bookingEngineId: BankKey.LoretoInversiones,
    name: 'Loreto Inversiones',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/loretoinversiones.png',
    id: 'flanks://areacliente.loretoinversiones.com/#loretoinversiones',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://areaprivada.unicajabanco.es/#bancocastilla': {
    flanksId: 'bancocastilla',
    bookingEngineId: BankKey.BancoCastilla,
    name: 'Banco de Castilla-La Mancha',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bancocastilla.png',
    id: 'flanks://areaprivada.unicajabanco.es/#bancocastilla',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://www.andbank.com/#andbankmonaco': {
    flanksId: 'andbankmonaco',
    bookingEngineId: BankKey.Andbank,
    name: 'Andbank Monaco',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/andbankmonaco.png',
    id: 'flanks://www.andbank.com/#andbankmonaco',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://www.caixaenginyers.com/#caixaenginyers': {
    flanksId: 'caixaenginyers',
    bookingEngineId: BankKey.Caixabank, // ? Caja de Ingenieros ?
    name: 'Caja de Ingenieros',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixaenginyers.png',
    id: 'flanks://www.caixaenginyers.com/#caixaenginyers',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://nbnet.novobanco.es/#novobanco-e': {
    flanksId: 'novobanco-e',
    bookingEngineId: BankKey.Novobanco,
    name: 'novobanco Empresas',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/novobanco-e.png',
    id: 'flanks://nbnet.novobanco.es/#novobanco-e',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://app.finizens.com/#finizens': {
    flanksId: 'finizens',
    bookingEngineId: BankKey.Finizens,
    name: 'Finizens',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/finizens.jpg',
    id: 'flanks://app.finizens.com/#finizens',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://cbsclients.santandersecuritiesservices.es/#caceis': {
    flanksId: 'caceis',
    bookingEngineId: BankKey.Caceis,
    name: 'Caceis',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caceis.jpg',
    id: 'flanks://cbsclients.santandersecuritiesservices.es/#caceis',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://be.ceca.es/#patrivalor': {
    flanksId: 'patrivalor',
    bookingEngineId: BankKey.Patrivalor,
    name: 'Patrivalor',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/patrivalor.png',
    id: 'flanks://be.ceca.es/#patrivalor',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://telemarch.bancamarch.es/#bancamarchlux': {
    flanksId: 'bancamarchlux',
    bookingEngineId: BankKey.BancaMarch,
    name: 'Banca March Luxembourg',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bancamarchlux.png',
    id: 'flanks://telemarch.bancamarch.es/#bancamarchlux',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.ibroker.es/#ibroker': {
    flanksId: 'ibroker',
    bookingEngineId: BankKey.Ibroker,
    name: 'iBroker',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ibroker.png',
    id: 'flanks://www.ibroker.es/#ibroker',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://rmm.espace-prive.com/#rothschildmartinmaurel': {
    flanksId: 'rothschildmartinmaurel',
    bookingEngineId: BankKey.RothschildMartinMaurel,
    name: 'Rothschild Martin Maurel',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/rothschildmartinmaurel.jpg',
    id: 'flanks://rmm.espace-prive.com/#rothschildmartinmaurel',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://jpmpb001.jpmorgan.com/#jpmorgan': {
    flanksId: 'jpmorgan',
    bookingEngineId: BankKey.JPM,
    name: 'J.P. Morgan',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/jpmorgan.png',
    id: 'flanks://jpmpb001.jpmorgan.com/#jpmorgan',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.sms,
      SCATypesFlanksYeekatee.email,
    ],
    integration: 'flanks',
  },
  'flanks://www.deutschebank-dbdirect.com/#deutsche-e-multi': {
    flanksId: 'deutsche-e-multi',
    bookingEngineId: BankKey.DeutscheBank,
    name: 'Deutsche Bank Empresas',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/deutsche-e-multi.png',
    id: 'flanks://www.deutschebank-dbdirect.com/#deutsche-e-multi',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://www.bbva.es/#bbva': {
    flanksId: 'bbva',
    bookingEngineId: BankKey.BBVA,
    name: 'BBVA',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bbva.png',
    id: 'flanks://www.bbva.es/#bbva',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://ebanking.bbva.ch/#bbvasuiza': {
    flanksId: 'bbvasuiza',
    bookingEngineId: BankKey.BBVA,
    name: 'BBVA Switzerland',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bbva.png',
    id: 'flanks://ebanking.bbva.ch/#bbvasuiza',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://authorization.andbank.com/#andbanklux': {
    flanksId: 'andbanklux',
    bookingEngineId: BankKey.Andbank,
    name: 'Andbank Luxembourg',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/andbanklux.jpg',
    id: 'flanks://authorization.andbank.com/#andbanklux',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://www.tomamosimpulso.com/#targobank': {
    flanksId: 'targobank',
    bookingEngineId: BankKey.Targobank,
    name: 'Targobank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/targobank.png',
    id: 'flanks://www.tomamosimpulso.com/#targobank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://docs.cloud.coinbase.com/#coinbase': {
    flanksId: 'coinbase',
    bookingEngineId: BankKey.coinbase,
    name: 'Coinbase',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/coinbase.png',
    id: 'flanks://docs.cloud.coinbase.com/#coinbase',
    enabled: true,
    integration: 'flanks',
    info: InfoTypes.coinbaseIndividual,
    howtoLink: 'https://www.youtube.com/watch?v=10uOsar6ak8',
  },
  // 'flanks://app.n26.com/#n26': {
  //   flanksId: 'n26',
  //   name: 'N26',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/n26.png',
  //   id: 'flanks://app.n26.com/#n26',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.app],
  //   integration: 'flanks',
  // },
  'flanks://www.cajamar.es/#caixaruralvilavella': {
    flanksId: 'caixaruralvilavella',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural Vilavella',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_vilavella.png',
    id: 'flanks://www.cajamar.es/#caixaruralvilavella',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaltea': {
    flanksId: 'caixaltea',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Altea',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixaltea.png',
    id: 'flanks://www.cajamar.es/#caixaltea',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#caixaruralvilafames': {
    flanksId: 'caixaruralvilafames',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caixa Rural Vilafamés',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caixa_rural_vilafames.png',
    id: 'flanks://www.cajamar.es/#caixaruralvilafames',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.cajamar.es/#cajaruralvillar': {
    flanksId: 'cajaruralvillar',
    bookingEngineId: BankKey.Cajamar,
    name: 'Caja Rural de Villar',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caja_rural_villar.png',
    id: 'flanks://www.cajamar.es/#cajaruralvillar',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.big.pt/#bigportugal': {
    flanksId: 'bigportugal',
    bookingEngineId: BankKey.Big,
    name: 'BiG Portugal',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bigportugal.png',
    id: 'flanks://www.big.pt/#bigportugal',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  // 'flanks://www.bmedonline.es/#mediolanum': {
  //   flanksId: 'mediolanum',
  //   name: 'Banco Mediolanum',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/mediolanum.png',
  //   id: 'flanks://www.bmedonline.es/#mediolanum',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.sms],
  //   integration: 'flanks',
  // },
  'flanks://www.wizink.es/#wizink': {
    flanksId: 'wizink',
    bookingEngineId: BankKey.WiZink,
    name: 'WiZink',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/wizink.png',
    id: 'flanks://www.wizink.es/#wizink',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://dpb-emea.credit-suisse.com/#creditsuisse': {
    flanksId: 'creditsuisse',
    bookingEngineId: BankKey.CreditSuisse,
    name: 'Credit Suisse',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/creditsuisse.png',
    id: 'flanks://dpb-emea.credit-suisse.com/#creditsuisse',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://ebanking.juliusbaer.com/#juliusbaerch': {
    flanksId: 'juliusbaerch',
    bookingEngineId: BankKey.JuliusBaer,
    name: 'Julius Baer Schweiz',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/juliusbaerch.png',
    id: 'flanks://ebanking.juliusbaer.com/#juliusbaerch',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.secureKey],
    integration: 'flanks',
  },
  'flanks://my.wealthprivatbank.es/#wealthprivatbank': {
    flanksId: 'wealthprivatbank',
    bookingEngineId: BankKey.Degroof,
    name: 'Degroof Private Bank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/wealthprivatbank.jpg',
    id: 'flanks://my.wealthprivatbank.es/#wealthprivatbank',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.coordinateCard,
    ],
    integration: 'flanks',
  },
  // 'flanks://emp.millenniumbcp.pt/#bcp': {
  //   flanksId: 'bcp',
  //   name: 'Millennium bcp',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bcp.png',
  //   id: 'flanks://emp.millenniumbcp.pt/#bcp',
  //   enabled: true,
  //   integration: 'flanks',
  //
  // },
  'flanks://www.bbva.es/#netcash': {
    flanksId: 'netcash',
    bookingEngineId: BankKey.BBVA,
    name: 'BBVA Net cash',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/NetCash.png',
    id: 'flanks://www.bbva.es/#netcash',
    enabled: true,
    scas: [
      SCATypesFlanksYeekatee.notNeeded,
      SCATypesFlanksYeekatee.sms,
      SCATypesFlanksYeekatee.email,
    ],
    integration: 'flanks',
  },
  'flanks://www.inversis.com/#inversisagente': {
    flanksId: 'inversisagente',
    bookingEngineId: BankKey.BancoInversis,
    name: 'Inversis Agentes',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/inversisagentes.png',
    id: 'flanks://www.inversis.com/#inversisagente',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://binance-docs.github.io/#binance': {
    flanksId: 'binance',
    bookingEngineId: BankKey.BINANCE,
    name: 'Binance',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/binance.png',
    id: 'flanks://binance-docs.github.io/#binance',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://hub.allianz.lu/#allianzluxembourg': {
    flanksId: 'allianzluxembourg',
    bookingEngineId: BankKey.Allianz,
    name: 'Allianz Luxembourg',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/allianz_lux.png',
    id: 'flanks://hub.allianz.lu/#allianzluxembourg',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://ebanking-es1.ubs.com/#ubs': {
    flanksId: 'ubs',
    bookingEngineId: BankKey.UBS,
    name: 'UBS ES',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ubs.png',
    id: 'flanks://ebanking-es1.ubs.com/#ubs',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.cryptoCalculator],
    integration: 'flanks',
  },
  'flanks://epargnants.interepargne.natixis.fr/#natixisinterepargne': {
    flanksId: 'natixisinterepargne',
    bookingEngineId: BankKey.Natixis,
    name: 'Natixis Interépargne',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/natixisinterepargne.png',
    id: 'flanks://epargnants.interepargne.natixis.fr/#natixisinterepargne',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.wealthmanagement.natixis.com/#natixisfrance': {
    flanksId: 'natixisfrance',
    bookingEngineId: BankKey.Natixis,
    name: 'Natixis Wealth Management',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/Natixis_france.png',
    id: 'flanks://www.wealthmanagement.natixis.com/#natixisfrance',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://professionnels.societegenerale.fr/#sg': {
    flanksId: 'sg',
    bookingEngineId: BankKey.Sogenal,
    name: 'Société Générale Private Banking',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/SG_privatebanking_fr.png',
    id: 'flanks://professionnels.societegenerale.fr/#sg',
    enabled: true,
    integration: 'flanks',
  },
  // 'flanks://www.expat.hsbc.com/#hsbcexpat': {
  //   flanksId: 'hsbcexpat',
  //   name: 'HSBC Expat',
  //   logo: 'https://www.security.online-banking.expat.hsbc.com/ContentService/gsp/saas/Components/default/doc/hsbc-logo.gif?SAGG=gsp_je',
  //   id: 'flanks://www.expat.hsbc.com/#hsbcexpat',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.app],
  //   integration: 'flanks',
  // },
  'flanks://connexion-mabanque.bnpparibas/#bnpbanqueprive': {
    flanksId: 'bnpbanqueprive',
    bookingEngineId: BankKey.BNP,
    name: 'BNP Paribas Banque Privée',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bnpbanqueprive.png',
    id: 'flanks://connexion-mabanque.bnpparibas/#bnpbanqueprive',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://visiogo.bnpparibas.com/#bnpretraite': {
    flanksId: 'bnpretraite',
    bookingEngineId: BankKey.BNP,
    name: 'BNP Paribas Epargne & Retraite Entreprises',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bnpretraite.png',
    id: 'flanks://visiogo.bnpparibas.com/#bnpretraite',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://connexion-mabanque.bnpparibas/#bnparticuliers': {
    flanksId: 'bnparticuliers',
    bookingEngineId: BankKey.BNP,
    name: 'BNP Paribas Particuliers',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bnparticuliers.png',
    id: 'flanks://connexion-mabanque.bnpparibas/#bnparticuliers',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://ebanking-ch.jsafrasarasin.com/#jsafrasarasin': {
    flanksId: 'jsafrasarasin',
    bookingEngineId: BankKey.SafraSarasin,
    name: 'J. Safra Sarasin',
    logo: 'https://d31dn7nfpuwjnm.cloudfront.net/images/valoraciones/0035/9699/unnamed.jpg?1575558375',
    id: 'flanks://ebanking-ch.jsafrasarasin.com/#jsafrasarasin',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.codeDevice],
    integration: 'flanks',
  },
  'flanks://partenaire.axa-wealtheurope.lu/#axalux': {
    flanksId: 'axalux',
    bookingEngineId: BankKey.AXA,
    name: 'AXA Wealth Europe',
    logo: 'https://axa-wealtheurope.lu/android-chrome-512x512.png',
    id: 'flanks://partenaire.axa-wealtheurope.lu/#axalux',
    enabled: true,
    integration: 'flanks',
  },
  // 'flanks://www.caisse-epargne.fr/#caisseepargne': {
  //   flanksId: 'caisseepargne',
  //   name: "Caisse d'Epargne",
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/caisseepargne.png',
  //   id: 'flanks://www.caisse-epargne.fr/#caisseepargne',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.code],
  //   integration: 'flanks',
  // },
  'flanks://www.bbva.mx/#bbvamexico': {
    flanksId: 'bbvamexico',
    bookingEngineId: BankKey.BBVA,
    name: 'BBVA Mexico',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/BBVA_Bancomer_logo.svg/1280px-BBVA_Bancomer_logo.svg.png',
    id: 'flanks://www.bbva.mx/#bbvamexico',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://ebanking2.ca-indosuez.fr/#creditagricoleindosuezfr': {
    flanksId: 'creditagricoleindosuezfr',
    bookingEngineId: BankKey.CreditAgricole,
    name: 'Credit Agricole Indosuez France',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/creditagricolefrance.png',
    id: 'flanks://ebanking2.ca-indosuez.fr/#creditagricoleindosuezfr',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  // 'flanks://sso-ext.lombardinternational.com/#lombardinternational': {
  //   flanksId: 'lombardinternational',
  //   name: 'Lombard International Assurance',
  //   logo: 'https://sso-ext.lombardinternational.com/adfs/portal/logo/logo.jpg',
  //   id: 'flanks://sso-ext.lombardinternational.com/#lombardinternational',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.sms],
  //   integration: 'flanks',
  // },
  'flanks://app.myqiservices.com/#cnp': {
    flanksId: 'cnp',
    bookingEngineId: BankKey.CNP,
    name: 'CNP',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/cnp.png',
    id: 'flanks://app.myqiservices.com/#cnp',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://k4u.cnplux.net/#cnpluxembourg': {
    flanksId: 'cnpluxembourg',
    bookingEngineId: BankKey.CNP,
    name: 'CNP Luxembourg',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/cnpluxembourg.png',
    id: 'flanks://k4u.cnplux.net/#cnpluxembourg',
    enabled: true,
    integration: 'flanks',
  },
  // 'flanks://www.bred.fr/#bredbp': {
  //   flanksId: 'bredbp',
  //   name: 'BRED Banque Populaire',
  //   logo: 'https://www.oklein.fr/wp-content/uploads/2020/02/BRED_BANQUE_POPULAIRE_LOGO_2LG_RVB.png',
  //   id: 'flanks://www.bred.fr/#bredbp',
  //   enabled: true,
  //   integration: 'flanks',
  //
  // },
  'flanks://idp.juliusbaer.com/#juliusbaerintermediaries': {
    flanksId: 'juliusbaerintermediaries',
    bookingEngineId: BankKey.JuliusBaer,
    name: 'Julius Baer Intermediaries',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/julius_intermediaries.png',
    id: 'flanks://idp.juliusbaer.com/#juliusbaerintermediaries',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://www.finanbest.com/#finanbest': {
    flanksId: 'finanbest',
    bookingEngineId: BankKey.Finanbest,
    name: 'Finanbest',
    logo: 'https://www.finanbest.com/wp-content/themes/finanbest/_/images/landing_cartera/logo.png',
    id: 'flanks://www.finanbest.com/#finanbest',
    enabled: true,
    integration: 'flanks',
  },
  // 'flanks://www.cic.fr/#cicbp': {
  //   flanksId: 'cicbp',
  //   name: 'CIC Banque Privée',
  //   logo: 'https://www.creditmutuelalliancefederale.fr/partage/fr/CSD-CM/CMAF/assets/logo/CIC-Banque-Privee.jpg',
  //   id: 'flanks://www.cic.fr/#cicbp',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.notNeeded,SCATypesFlanksYeekatee.sms],
  //   integration: 'flanks',
  //
  // },
  'flanks://www.credit-agricole.fr/#caregion': {
    flanksId: 'caregion',
    bookingEngineId: BankKey.CreditAgricole,
    name: 'Credit Agricole Regional',
    logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a6/Cr%C3%A9dit_Agricole.svg/1200px-Cr%C3%A9dit_Agricole.svg.png',
    id: 'flanks://www.credit-agricole.fr/#caregion',
    enabled: true,
    integration: 'flanks',
  },
  // 'flanks://www.credit-du-nord.fr/#creditnord': {
  //   flanksId: 'creditnord',
  //   name: 'Crédit du Nord',
  //   logo: 'https://www.wiki-credit.fr/wp-content/uploads/2012/05/credit-du-nord.png',
  //   id: 'flanks://www.credit-du-nord.fr/#creditnord',
  //   enabled: true,
  //   integration: 'flanks',
  //
  // },
  'flanks://finecobank.com/#finecobank': {
    flanksId: 'finecobank',
    bookingEngineId: BankKey.FinecoBank,
    name: 'FinecoBank',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/FinecoBank_logo.svg/2560px-FinecoBank_logo.svg.png',
    id: 'flanks://finecobank.com/#finecobank',
    enabled: true,
    integration: 'flanks',
  },
  // 'flanks://www1.directatrading.com/#directatrading': {
  //   flanksId: 'directatrading',
  //   name: 'Directa Trading',
  //   logo: 'https://www.qualebroker.com/images/Directa_logo.webp',
  //   id: 'flanks://www1.directatrading.com/#directatrading',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.notNeeded,SCATypesFlanksYeekatee.sms,SCATypesFlanksYeekatee.email],
  //   integration: 'flanks',
  //
  // },
  'flanks://www.movestic.se/#movestic': {
    flanksId: 'movestic',
    bookingEngineId: BankKey.Movestic,
    name: 'Movestic',
    logo: 'https://www.movestic.se/globalassets/new-logo-movestic-transparant.png',
    id: 'flanks://www.movestic.se/#movestic',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.qrAccept],
    integration: 'flanks',
  },
  'flanks://ebanking.edmond-de-rothschild.eu/#edmondderothschildbelgica': {
    flanksId: 'edmondderothschildbelgica',
    bookingEngineId: BankKey.EDR,
    name: 'Edmond de Rothschild Belgium',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/edmonderothschildbelgica.png',
    id: 'flanks://ebanking.edmond-de-rothschild.eu/#edmondderothschildbelgica',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://dwoch.db.com/#deutschewealthmanagement': {
    flanksId: 'deutschewealthmanagement',
    bookingEngineId: BankKey.DeutscheBank,
    name: 'Deutsche Bank Wealth Management',
    logo: 'https://www.caproasia.com/wp-content/uploads/2021/01/Deutsche-Bank-Wealth-Management.png',
    id: 'flanks://dwoch.db.com/#deutschewealthmanagement',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  // 'flanks://www.americanexpress.com/#amex': {
  //   flanksId: 'amex',
  //   name: 'American Express',
  //   logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/1200px-American_Express_logo_%282018%29.svg.png',
  //   id: 'flanks://www.americanexpress.com/#amex',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.app],
  //   integration: 'flanks',
  // },
  // 'flanks://bpinet.bancobpi.pt/#bpiretail': {
  //   flanksId: 'bpiretail',
  //   name: 'BPI Retail',
  //   logo: 'https://www.bancobpi.pt/contentservice/getContent?documentName=pr_wcs01_ucm01004323',
  //   id: 'flanks://bpinet.bancobpi.pt/#bpiretail',
  //   enabled: true,
  //   integration: 'flanks',
  //
  // },
  'flanks://client.milleis.fr/#milleis': {
    flanksId: 'milleis',
    bookingEngineId: BankKey.Milleis,
    name: 'Milleis',
    logo: 'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/bc5xjld7idjhqejxftfe',
    id: 'flanks://client.milleis.fr/#milleis',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.avanza.se/#avanza': {
    flanksId: 'avanza',
    bookingEngineId: BankKey.Avanza,
    name: 'Avanza',
    logo: 'https://www.moneycontroller.es/upload/aziende/avanza-bank-holding-ab_20210224112241.png',
    id: 'flanks://www.avanza.se/#avanza',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.qrAccept],
    integration: 'flanks',
  },
  'flanks://ind.activobank.pt/#activobankpt': {
    flanksId: 'activobankpt',
    bookingEngineId: BankKey.ActivoBank,
    name: 'ActivoBank PT',
    logo: 'https://www.empregoestagios.com/wp-content/uploads/2022/05/ActivoBank.png',
    id: 'flanks://ind.activobank.pt/#activobankpt',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://secure.scottishwidows.co.uk/#scottishwidows': {
    flanksId: 'scottishwidows',
    bookingEngineId: BankKey.ScottishWidows,
    name: 'Scottish Widows',
    logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/75/Scottish_Widows_logo.svg/1280px-Scottish_Widows_logo.svg.png',
    id: 'flanks://secure.scottishwidows.co.uk/#scottishwidows',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.bnpparibasfortis.be/#bnpfortis': {
    flanksId: 'bnpfortis',
    bookingEngineId: BankKey.BNP,
    name: 'BNP Fortis Bruxelles',
    logo: 'https://bank-codes.es/template/logo/belgium/bnp-paribas-fortis.png',
    id: 'flanks://www.bnpparibasfortis.be/#bnpfortis',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.cryptoCalculator],
    integration: 'flanks',
  },
  'flanks://www.cmne.fr/#cmne': {
    flanksId: 'cmne',
    bookingEngineId: BankKey.CreditMutuel,
    name: 'Crédit Mutuel Nord Europe',
    logo: 'https://gmc38.fr/wp-content/uploads/2016/09/logo-cmne.png',
    id: 'flanks://www.cmne.fr/#cmne',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://secure6.iis-connect.com/#bdlbelgium': {
    flanksId: 'bdlbelgium',
    bookingEngineId: BankKey.BDL,
    name: 'BDL Belgium',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Logo_-_Banque_de_Luxembourg_-_Belgique-Belgie.svg/1280px-Logo_-_Banque_de_Luxembourg_-_Belgique-Belgie.svg.png',
    id: 'flanks://secure6.iis-connect.com/#bdlbelgium',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://banque.edmond-de-rothschild.fr/#edmondderothschildfrance': {
    flanksId: 'edmondderothschildfrance',
    bookingEngineId: BankKey.EDM,
    name: 'Edmond de Rothschild France',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/edmondderothschildfrance.png',
    id: 'flanks://banque.edmond-de-rothschild.fr/#edmondderothschildfrance',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://id.sebgroup.lu/#sebluxembourg': {
    flanksId: 'sebluxembourg',
    bookingEngineId: BankKey.SEB,
    name: 'SEB Luxembourg',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/SEB_logo.svg/2126px-SEB_logo.svg.png',
    id: 'flanks://id.sebgroup.lu/#sebluxembourg',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.digipass],
    integration: 'flanks',
  },
  'flanks://mabanquepro.bnpparibas/#bnpbanqueprofessionels': {
    flanksId: 'bnpbanqueprofessionels',
    bookingEngineId: BankKey.BNP,
    name: 'BNP Banque Professionnels',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bnpbanqueprofessionels.png',
    id: 'flanks://mabanquepro.bnpparibas/#bnpbanqueprofessionels',
    enabled: true,
    integration: 'flanks',
  },
  // 'flanks://ebanking.ing.be/#ingbusiness': {
  //   flanksId: 'ingbusiness',
  //   name: 'ING Business',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ingbusiness.png',
  //   id: 'flanks://ebanking.ing.be/#ingbusiness',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.cardReader],
  //   integration: 'flanks',
  // },
  'flanks://belfiusweb.belfius.be/#belfiusbelgium': {
    flanksId: 'belfiusbelgium',
    bookingEngineId: BankKey.Belfius,
    name: 'Belfius Belgium',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/belfiusbelgium.png',
    id: 'flanks://belfiusweb.belfius.be/#belfiusbelgium',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.cryptoCalculator],
    integration: 'flanks',
  },
  'flanks://online.beobank.be/#beobank': {
    flanksId: 'beobank',
    bookingEngineId: BankKey.Beobank,
    name: 'Beobank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/beobank.png',
    id: 'flanks://online.beobank.be/#beobank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.codeDevice],
    integration: 'flanks',
  },
  // 'flanks://secure.banquedeluxembourg.com/#banquedeluxembourg': {
  //   flanksId: 'banquedeluxembourg',
  //   name: 'Banque de Luxembourg',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/banquedeluxembourg.png',
  //   id: 'flanks://secure.banquedeluxembourg.com/#banquedeluxembourg',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.app],
  //   integration: 'flanks',
  // },
  'flanks://ebanking-fra1.ubs.com/#ubsfrance': {
    flanksId: 'ubsfrance',
    bookingEngineId: BankKey.UBS,
    name: 'UBS France',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/ubsfrance.png',
    id: 'flanks://ebanking-fra1.ubs.com/#ubsfrance',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.qrAccept],
    integration: 'flanks',
    info: InfoTypes.qrCodeAuthentication,
  },
  'flanks://auth.nutmeg.com/#nutmeg': {
    flanksId: 'nutmeg',
    bookingEngineId: BankKey.Nutmeg,
    name: 'Nutmeg',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/nutmeg.png',
    id: 'flanks://auth.nutmeg.com/#nutmeg',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://ebanking.privatebanking.societegenerale.lu/#societegeneraleluxembourg':
    {
      flanksId: 'societegeneraleluxembourg',
      bookingEngineId: BankKey.Sogenal,
      name: 'Société Générale Luxembourg',
      logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/societegeneraleluxembourg.png',
      id: 'flanks://ebanking.privatebanking.societegenerale.lu/#societegeneraleluxembourg',
      enabled: true,
      scas: [SCATypesFlanksYeekatee.app],
      integration: 'flanks',
    },
  // 'flanks://bankonline.sboff.com/#standardbank': {
  //   flanksId: 'standardbank',
  //   name: 'Standard Bank',
  //   logo: 'https://www.behavioraleconomics.com/wp-content/uploads/2022/03/Standard-Bank-Logo-400x104.png',
  //   id: 'flanks://bankonline.sboff.com/#standardbank',
  //   enabled: true,
  //   scas: [SCATypesFlanksYeekatee.email]
  //   integration: 'flanks',
  // },
  'flanks://login.skandia.se/#skandia': {
    flanksId: 'skandia',
    bookingEngineId: BankKey.Skandia,
    name: 'Skandia',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Skandia_2009_logo.svg/2560px-Skandia_2009_logo.svg.png',
    id: 'flanks://login.skandia.se/#skandia',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.updatableQr],
    integration: 'flanks',
  },
  'flanks://login.vanlanschot.com/#vanlanschot': {
    flanksId: 'vanlanschot',
    bookingEngineId: BankKey.Vanlanschot,
    name: 'Van Lanschot',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/vanlanschot.png',
    id: 'flanks://login.vanlanschot.com/#vanlanschot',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.digipass],
    integration: 'flanks',
  },
  'flanks://www.swisslifeone.fr/#swisslifefrance': {
    flanksId: 'swisslifefrance',
    bookingEngineId: BankKey.SwissLife,
    name: 'Swiss Life France',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/swisslifefrance.png',
    id: 'flanks://www.swisslifeone.fr/#swisslifefrance',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://online.swedbank.se/#swedbank': {
    flanksId: 'swedbank',
    bookingEngineId: BankKey.Swedbank,
    name: 'Swedbank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/swedbank.png',
    id: 'flanks://online.swedbank.se/#swedbank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.updatableQr],
    integration: 'flanks',
  },
  'flanks://savr.com/#savr': {
    flanksId: 'savr',
    bookingEngineId: BankKey.SAVR,
    name: 'SAVR',
    logo: 'https://media.licdn.com/dms/image/C4E0BAQGqBZUGSy7Daw/company-logo_200_200/0/1636705282417?e=2147483647&v=beta&t=V0jhob-Zxia6TAUPnndNplrr4OwqpMUavd-RXZwFnSI',
    id: 'flanks://savr.com/#savr',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://online.lgtwm.com/#lgtwm': {
    flanksId: 'lgtwm',
    bookingEngineId: BankKey.LGT,
    name: 'LGT Wealth Management',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/lgtwm.png',
    id: 'flanks://online.lgtwm.com/#lgtwm',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://personal.secure.scottishwidows.co.uk/#scottishwidowspersonal': {
    flanksId: 'scottishwidowspersonal',
    bookingEngineId: BankKey.ScottishWidows,
    name: 'Scottish Widows Personal',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/scottishwidowspersonal.png',
    id: 'flanks://personal.secure.scottishwidows.co.uk/#scottishwidowspersonal',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.openbank.pt/#openbankpt': {
    flanksId: 'openbankpt',
    bookingEngineId: BankKey.Openbank,
    name: 'Openbank Portugal',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/openbankpt.png',
    id: 'flanks://www.openbank.pt/#openbankpt',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://us.etrade.com/#etrade': {
    flanksId: 'etrade',
    bookingEngineId: BankKey.Etrade,
    name: 'E*TRADE',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/etrade.png',
    id: 'flanks://us.etrade.com/#etrade',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://app.revolut.com/#revolut': {
    flanksId: 'revolut',
    bookingEngineId: BankKey.Revolut,
    name: 'Revolut',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/revolut.png',
    id: 'flanks://app.revolut.com/#revolut',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://dpb-apac.credit-suisse.com/#creditsuissesingapore': {
    flanksId: 'creditsuissesingapore',
    bookingEngineId: BankKey.CreditSuisse,
    name: 'Credit Suisse Singapore',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/creditsuissesingapore.png',
    id: 'flanks://dpb-apac.credit-suisse.com/#creditsuissesingapore',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://login.morganstanleyclientserv.com/#morganstanley': {
    flanksId: 'morganstanley',
    bookingEngineId: BankKey.MorganStanley,
    name: 'Morgan Stanley',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/morganstanley.png',
    id: 'flanks://login.morganstanleyclientserv.com/#morganstanley',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://www.cointracker.io/#cointracker': {
    flanksId: 'cointracker',
    bookingEngineId: BankKey.Cointracker,
    name: 'CoinTracker',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/cointracker.png',
    id: 'flanks://www.cointracker.io/#cointracker',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://privatebank.jpmorgan.com/#jpmorganus': {
    flanksId: 'jpmorganus',
    bookingEngineId: BankKey.JPM,
    name: 'J.P. Morgan US',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/jpmorgan_us.png',
    id: 'flanks://privatebank.jpmorgan.com/#jpmorganus',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://www.bitstamp.net/#bitstamp': {
    flanksId: 'bitstamp',
    bookingEngineId: BankKey.Bitstamp,
    name: 'Bitstamp',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bitstamp.png',
    id: 'flanks://www.bitstamp.net/#bitstamp',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://docs.kucoin.com/#kucoin': {
    flanksId: 'kucoin',
    bookingEngineId: BankKey.Kucoin,
    name: 'KuCoin',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/kucoin.png',
    id: 'flanks://docs.kucoin.com/#kucoin',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.netxinvestor.com/#pershing': {
    flanksId: 'pershing',
    bookingEngineId: BankKey.Pershing,
    name: 'Pershing',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/pershing.png',
    id: 'flanks://www.netxinvestor.com/#pershing',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.email],
    integration: 'flanks',
  },
  'flanks://xstation5.xtb.com/#xtb': {
    flanksId: 'xtb',
    bookingEngineId: BankKey.XTB,
    name: 'XTB',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/xtb.png',
    id: 'flanks://xstation5.xtb.com/#xtb',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://developers.bitpanda.com/#bitpanda': {
    flanksId: 'bitpanda',
    bookingEngineId: BankKey.Bitpanda,
    name: 'Bitpanda',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bitpanda.png',
    id: 'flanks://developers.bitpanda.com/#bitpanda',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://docs.bitfinex.com/#bitfinex': {
    flanksId: 'bitfinex',
    bookingEngineId: BankKey.Bitfinex,
    name: 'Bitfinex',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bitfinex.png',
    id: 'flanks://docs.bitfinex.com/#bitfinex',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://bittrex.github.io/#bittrex': {
    flanksId: 'bittrex',
    bookingEngineId: BankKey.Bittrex,
    name: 'Bittrex',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/bittrex.png',
    id: 'flanks://bittrex.github.io/#bittrex',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://docs.kraken.com/#kraken': {
    flanksId: 'kraken',
    bookingEngineId: BankKey.Kraken,
    name: 'Kraken',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/kraken.png',
    id: 'flanks://docs.kraken.com/#kraken',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://portal.xpi.com.br/#xpinvestimentos': {
    flanksId: 'xpinvestimentos',
    bookingEngineId: BankKey.XP,
    name: 'XP Investimentos',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/xpinvestimentos.png',
    id: 'flanks://portal.xpi.com.br/#xpinvestimentos',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://cointracking.info/#cointracking': {
    flanksId: 'cointracking',
    bookingEngineId: BankKey.Cointracking,
    name: 'CoinTracking',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/cointracking.png',
    id: 'flanks://cointracking.info/#cointracking',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.netxinvestor.com/#btg': {
    flanksId: 'btg',
    bookingEngineId: BankKey.BTG,
    name: 'BTG',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/btg.png',
    id: 'flanks://www.netxinvestor.com/#btg',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.email],
    integration: 'flanks',
  },
  'flanks://authprd.larrainvial.com/#larrainvial': {
    flanksId: 'larrainvial',
    bookingEngineId: BankKey.Larrainvial,
    name: 'LarrainVial',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/larrainvial.png',
    id: 'flanks://authprd.larrainvial.com/#larrainvial',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://plus.gbm.com/#gbm': {
    flanksId: 'gbm',
    bookingEngineId: BankKey.GBM,
    name: 'GBM',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/gbm.png',
    id: 'flanks://plus.gbm.com/#gbm',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.trading212.com/#trading212': {
    flanksId: 'trading212',
    bookingEngineId: BankKey.Trading212,
    name: 'Trading 212',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/trading212.png',
    id: 'flanks://www.trading212.com/#trading212',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.codeDevice],
    integration: 'flanks',
  },
  'flanks://www.goldman.com/#goldmansachs': {
    flanksId: 'goldmansachs',
    bookingEngineId: BankKey.GoldmanSachs,
    name: 'Goldman Sachs',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/goldmansachs.png',
    id: 'flanks://www.goldman.com/#goldmansachs',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.code],
    integration: 'flanks',
  },
  'flanks://app.traderepublic.com/#traderepublic': {
    flanksId: 'traderepublic',
    bookingEngineId: BankKey.TradeRepublic,
    name: 'Trade Republic',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/traderepublic.jpg',
    id: 'flanks://app.traderepublic.com/#traderepublic',
    enabled: true,
    /*
     * Removed due to no effect, user sets it via their account
     * scas: [SCATypesFlanksYeekatee.app, SCATypesFlanksYeekatee.sms],
     */
    integration: 'flanks',
  },
  'flanks://www.fidelity.com/#fidelity': {
    flanksId: 'fidelity',
    bookingEngineId: BankKey.Fidelity,
    name: 'Fidelity Investments',
    logo: 'https://1000logos.net/wp-content/uploads/2017/11/Fidelity-Logo.png',
    id: 'flanks://www.fidelity.com/#fidelity',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.itauinternational.com/#itauprivatebankmiami': {
    flanksId: 'itauprivatebankmiami',
    bookingEngineId: BankKey.Itau,
    name: 'Itaú Private Bank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/itauprivatebankmiami.png',
    id: 'flanks://www.itauinternational.com/#itauprivatebankmiami',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.scotiainlatrade.com/#scotiatradermx': {
    flanksId: 'scotiatradermx',
    bookingEngineId: BankKey.Scotiabank,
    name: 'Scotiatrade Mexico',
    logo: 'https://logos-world.net/wp-content/uploads/2021/03/Scotiabank-Logo-1998-2019.png',
    id: 'flanks://www.scotiainlatrade.com/#scotiatradermx',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://portail-aep.fr/#nortiaaep': {
    flanksId: 'nortiaaep',
    bookingEngineId: BankKey.Nortia,
    name: 'Nortia AEP',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/nortiaaep.png',
    id: 'flanks://portail-aep.fr/#nortiaaep',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://jpmorgan.chase.com/#jpmorganchase': {
    flanksId: 'jpmorganchase',
    bookingEngineId: BankKey.JPM,
    name: 'JP Morgan Chase',
    logo: 'https://www.jpmorganchase.com/content/dam/jpmc/jpmorgan-chase-and-co/images/celebrating-supreme-court-decision.png',
    id: 'flanks://jpmorgan.chase.com/#jpmorganchase',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.notNeeded, SCATypesFlanksYeekatee.call],
    integration: 'flanks',
  },
  'flanks://www.privatebank.citibank.com/#citiprivatebank': {
    flanksId: 'citiprivatebank',
    bookingEngineId: BankKey.CitiBank,
    name: 'Citi Private Bank',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Citi_Private_Bank_1.png/640px-Citi_Private_Bank_1.png',
    id: 'flanks://www.privatebank.citibank.com/#citiprivatebank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  'flanks://de.scalable.capital/#scalablecapital': {
    flanksId: 'scalablecapital',
    bookingEngineId: BankKey.ScalableCapital,
    name: 'Scalable Capital',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/scalablecapital.png',
    id: 'flanks://de.scalable.capital/#scalablecapital',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://robinhood.com/#robinhood': {
    flanksId: 'robinhood',
    bookingEngineId: BankKey.Robinhood,
    name: 'Robinhood',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/robinhood.png',
    id: 'flanks://robinhood.com/#robinhood',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.sms],
    integration: 'flanks',
  },
  // 'flanks://cgp.oddo-bhf.com/#oddo': {
  //   flanksId: 'oddo',
  //   name: 'ODDO BHF',
  //   logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/oddo.png',
  //   id: 'flanks://cgp.oddo-bhf.com/#oddo',
  //   enabled: true,
  //   integration: 'flanks',
  //
  // },
  'flanks://nortia.my.site.com/#nortia': {
    flanksId: 'nortia',
    bookingEngineId: BankKey.Nortia,
    name: 'Nortia',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/nortia.png',
    id: 'flanks://nortia.my.site.com/#nortia',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://www.itau.com.br/#itaubr': {
    flanksId: 'itaubr',
    bookingEngineId: BankKey.Itau,
    name: 'Itaú Brazil',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/Itau.png',
    id: 'flanks://www.itau.com.br/#itaubr',
    enabled: true,
    integration: 'flanks',
  },
  'flanks://access.btgpactualdigital.com/#btgpactual': {
    flanksId: 'btgpactual',
    bookingEngineId: BankKey.BTG,
    name: 'BTG Pactual',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/btgpactual.png',
    id: 'flanks://access.btgpactualdigital.com/#btgpactual',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
  'flanks://ebanking.lukb.ch/#luzernerkantonalbank': {
    flanksId: 'luzernerkantonalbank',
    bookingEngineId: BankKey.LUKB,
    name: 'Luzerner Kantonalbank',
    logo: 'https://storage.googleapis.com/flanks-logo-repository/financial-entities/luzernerkantonalbank.png',
    id: 'flanks://ebanking.lukb.ch/#luzernerkantonalbank',
    enabled: true,
    scas: [SCATypesFlanksYeekatee.app],
    integration: 'flanks',
  },
};

export type SupportedBank = (typeof supportedBanks)[number];
