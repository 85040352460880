import { createFeature, createSelector } from '@ngrx/store';
import { evalRule, getLocalizedString } from '@yeekatee/launch-modal-util';
import { selectIsOnboarded } from '../auth/auth.selectors';
import { capacitorAppFeature } from '../capacitor-app/capacitor-app.selectors';
import { getBaseLocale } from '../locales/locales.selectors';
import { launchModalAdapter, name, reducer } from './launchmodals.reducer';

export const launchModalFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectLaunchmodalsState, selectAcknowledged }) => {
    const entitySelectors = launchModalAdapter.getSelectors(
      selectLaunchmodalsState,
    );

    const selectNonAcknowledged = createSelector(
      entitySelectors.selectAll,
      selectAcknowledged,
      (modals, acknowledged) =>
        modals.filter((m) => !acknowledged || !acknowledged.includes(m.id)),
    );

    const selectToShow = createSelector(
      selectNonAcknowledged,
      selectIsOnboarded,
      capacitorAppFeature.selectLatestStateUpdateISO,
      capacitorAppFeature.selectPlatforms,
      capacitorAppFeature.selectUpdateAvailable,
      capacitorAppFeature.selectAppVersion,
      (modals, authenticated, datetime, platforms, updateAvailable, version) =>
        modals.filter(
          (m) =>
            !m.rules ||
            evalRule(m.rules, {
              authenticated,
              datetime,
              platforms,
              updateAvailable,
              version,
            }),
        ),
    );

    const selectIfToShow = createSelector(
      selectToShow,
      (modals) => !!modals.length,
    );

    const selectCurrentToShow = createSelector(
      selectToShow,
      (modals) => modals[0],
    );

    const selectLocalisedCurrentToShow = createSelector(
      selectCurrentToShow,
      getBaseLocale,
      (modal, locale) => ({
        ...modal,
        title: getLocalizedString(modal?.title, locale),
        description: getLocalizedString(modal?.description, locale),
        actions: modal?.actions?.map((action) => ({
          ...action,
          label: getLocalizedString(action?.label, locale),
        })),
      }),
    );

    const selectVM = createSelector({
      isOpen: selectIfToShow,
      modal: selectLocalisedCurrentToShow,
    });

    return {
      ...entitySelectors,
      /**
       * Returns the list of modals that have not been acknowledged yet.
       */
      selectNonAcknowledged,

      /**
       * Returns the list of modals that should be shown,
       * based on the current state, the rules of each modal,
       * and whether the user has seen it before and acknowledged it.
       */
      selectToShow,

      /**
       * Returns true if there are any modals to show at all.
       */
      selectIfToShow,
      selectCurrentToShow,

      /**
       * Takes the current modal, and translates it based on the current locale.
       */
      selectLocalisedCurrentToShow,

      selectVM,
    };
  },
});
