import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { getQuoteId } from '@yeekatee/shared-util-typesafe-dynamodb';
import {
  InstrumentsApiActions,
  InstrumentsEffectsActions,
} from '../instruments.actions';
import { QuotesEntity } from '../instruments.models';

export const FEATURE_KEY = 'quotes';

export type State = EntityState<QuotesEntity>;

export const quotesAdapter = createEntityAdapter<QuotesEntity>();

export const initialState: State = quotesAdapter.getInitialState({});

const quotesReducer = createReducer(
  initialState,
  on(InstrumentsEffectsActions.fetchQuote, (state, { instrument }) => {
    const { id, symbol, mic } = instrument;
    const quote = { loading: true, id: getQuoteId(id, symbol, mic) };
    return quotesAdapter.upsertOne(quote, state);
  }),
  on(
    InstrumentsEffectsActions.portfolioItemsLoaded,
    (state, { instruments }) => {
      const quotes = instruments.map((i) => ({
        loading: true,
        id: getQuoteId(i.id, i.symbol, i.mic),
      }));
      return quotesAdapter.upsertMany(quotes, state);
    },
  ),
  on(
    InstrumentsApiActions.loadReportQuotesSuccess,
    (state: State, { quotes }) => {
      const entities = quotes.filter((q): q is QuotesEntity => !!q);
      if (!entities || !entities.length) return state;

      return quotesAdapter.upsertMany(
        entities.map((q) => ({ ...q, loading: false })),
        state,
      );
    },
  ),
  on(InstrumentsApiActions.loadQuoteSuccess, (state: State, { quote }) =>
    quotesAdapter.upsertOne({ ...quote, loading: false }, state),
  ),
  on(
    InstrumentsApiActions.loadInstrumentsSuccess,
    (state: State, { instruments }) => {
      const quotes = instruments
        ?.map((i) => i?.quote)
        .filter((q) => !!q) as QuotesEntity[];
      if (!quotes || !quotes.length) return state;

      return quotesAdapter.upsertMany(
        quotes.map((q) => ({ ...q, loading: false })),
        state,
      );
    },
  ),
  on(
    InstrumentsApiActions.loadDiscoverOverviewAssetsSuccess,
    (state, { instrumentsOverview }) => {
      const quotes: QuotesEntity[] = Object.values(instrumentsOverview)
        .flat()
        .map((i) => i?.quote)
        .filter((q): q is QuotesEntity => !!q);

      if (!quotes.length) return state;

      return quotesAdapter.upsertMany(
        quotes.map((q) => ({ ...q, loading: true })),
        state,
      );
    },
  ),
  on(
    InstrumentsApiActions.loadInstrumentSuccess,
    InstrumentsApiActions.loadSingleInstrumentSuccess,
    (state: State, { instrument }) =>
      instrument?.quote
        ? quotesAdapter.upsertOne(
            { ...instrument.quote, loading: false },
            state,
          )
        : state,
  ),
  on(InstrumentsEffectsActions.priceUpdate, (state: State, { quote }) =>
    quote
      ? quotesAdapter.upsertOne({ ...quote, loading: false }, state)
      : state,
  ),
  on(InstrumentsApiActions.loadQuoteFailure, (state: State, { instrument }) =>
    instrument
      ? quotesAdapter.upsertOne(
          {
            id: getQuoteId(instrument.id, instrument.symbol, instrument.mic),
            loading: false,
          },
          state,
        )
      : state,
  ),
  on(
    InstrumentsApiActions.loadReportQuotesFailure,
    (state: State, { instruments }) => {
      const quotes = instruments.map((i) => ({
        loading: false,
        id: getQuoteId(i.id, i.symbol, i.mic),
      }));
      return quotesAdapter.upsertMany(quotes, state);
    },
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return quotesReducer(state, action);
}
