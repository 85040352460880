import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import {
  GameQuizActions,
  GameQuizzesActions,
  GamesApiActions,
} from '../games.actions';
import {
  GameQuizEntity,
  reduceQuizEntities,
  reduceQuizEntity,
} from '../games.models';

export const FEATURE_KEY = 'quizzes';

export interface State extends EntityState<GameQuizEntity> {
  loading: boolean;
  selectedDate: string;
}

export const adapter = createEntityAdapter<GameQuizEntity>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  selectedDate: DateTime.now().toISODate(),
});

const competitionsReducer = createReducer(
  initialState,
  on(
    GameQuizzesActions.init,
    GameQuizActions.init,
    GameQuizActions.answerQuiz,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    GameQuizzesActions.changeDate,
    (state, { selectedDate }): State => ({
      ...state,
      selectedDate,
    }),
  ),
  on(
    GamesApiActions.loadGameQuizzesSuccess,
    (state, { quizzes }): State =>
      adapter.upsertMany(reduceQuizEntities(quizzes), {
        ...state,
        loading: false,
      }),
  ),
  on(
    GamesApiActions.loadOpenQuizzesSuccess,
    (state, { quizzes }): State =>
      adapter.upsertMany(reduceQuizEntities(quizzes), {
        ...state,
      }),
  ),
  on(
    GamesApiActions.loadGameQuizSuccess,
    (state, { quiz }): State =>
      adapter.upsertOne(reduceQuizEntity(quiz), {
        ...state,
        loading: false,
      }),
  ),
  on(
    GamesApiActions.answerGameQuizSuccess,
    (
      state,
      { id, correctAnswers, explanation, userAnswers, userAnswerDate, reward },
    ): State => {
      const quiz = state.entities[id];
      if (!quiz) return state;

      return adapter.upsertOne(
        reduceQuizEntity({
          ...quiz,
          correctAnswers,
          explanation,
          userAnswers,
          userAnswerDate,
          givenReward: reward,
        }),
        {
          ...state,
          loading: false,
        },
      );
    },
  ),
  on(
    GamesApiActions.loadGameQuizzesFailure,
    GamesApiActions.loadGameQuizFailure,
    GamesApiActions.answerGameQuizFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return competitionsReducer(state, action);
}
