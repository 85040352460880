import { createSelector } from '@ngrx/store';
import { GameCompetitionState } from '@yeekatee/client-api-angular';
import { DateTime } from 'luxon';
import { LocalesSelectors } from '../../locales';
import { ThemeSelectors } from '../../theme';
import { memoize } from '../../utils';
import { GameQuizEntity, extendQuizEntity } from '../games.models';
import { GamesState, selectGamesState } from '../games.reducer';
import * as CompetitionsSelectors from './games-competitions.selectors';
import * as fromReducers from './games-quizzes.reducer';

const { selectEntities, selectAll } = fromReducers.adapter.getSelectors();

export const selectGameQuizzesState = createSelector(
  selectGamesState,
  (state: GamesState) => state[fromReducers.FEATURE_KEY],
);

export const selectGameQuizzesEntities = createSelector(
  selectGameQuizzesState,
  (state: fromReducers.State) => selectEntities(state),
);

export const getAllGameQuizzes = createSelector(
  selectGameQuizzesState,
  (state: fromReducers.State) => selectAll(state),
);

export const isGameQuizzesLoading = createSelector(
  selectGameQuizzesState,
  (state: fromReducers.State) => state.loading,
);

export const selectGameQuizzesDate = createSelector(
  selectGameQuizzesState,
  (state: fromReducers.State) => state.selectedDate,
);

export const selectGameQuizzesById = memoize((gameId: string) =>
  createSelector(
    getAllGameQuizzes,
    LocalesSelectors.getBaseLocale,
    ThemeSelectors.selectThemeMode,
    (quizzes, locale, mode): GameQuizEntity[] =>
      quizzes
        .filter((q) => q.competitionId === gameId)
        .sort((a, b) => (a.id < b.id ? -1 : a.id < b.id ? 1 : 0))
        .map((q, i) => extendQuizEntity(q, locale, mode, i)),
  ),
);

export const selectGameQuizzesBySelectedDate = memoize((gameId: string) =>
  createSelector(
    selectGameQuizzesById(gameId),
    selectGameQuizzesDate,
    (quizzes, selectedDate): GameQuizEntity[] =>
      quizzes.filter((q) => q.startDate === selectedDate),
  ),
);

export const selectGameQuizById = memoize((id: string, gameId: string) =>
  createSelector(
    selectGameQuizzesById(gameId),
    (quizzes): GameQuizEntity | undefined => quizzes.find((q) => q.id === id),
  ),
);

export const selectOpenQuizzes = createSelector(
  getAllGameQuizzes,
  CompetitionsSelectors.selectGameCompetitionsEntities,
  (quizzes, competitions) =>
    quizzes.filter((q) => {
      const competition = competitions[q.competitionId ?? ''];
      return (
        !!competition &&
        competition.state === GameCompetitionState.STARTED &&
        !q.userAnswers &&
        q.startDate === DateTime.now().toISODate()
      );
    }),
);

export const selectOpenQuizzesCount = createSelector(
  selectOpenQuizzes,
  (quizzes) =>
    quizzes.reduce(
      (acc, quiz) => {
        if (!quiz.competitionId) return acc;
        acc[quiz.competitionId] = acc[quiz.competitionId] + 1 || 1;
        return acc;
      },
      {} as Record<string, number>,
    ),
);

export const selectOpenQuizzesByCompetitionId = memoize((id: string) =>
  createSelector(selectOpenQuizzes, (quizzes) =>
    quizzes.filter(({ competitionId }) => id === competitionId),
  ),
);
