import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { MarketNewsOrderingType } from '@yeekatee/client-api-angular';
import { InstrumentViewActions } from '../../instruments';
import {
  MarketNewsApiActions,
  MarketNewsViewActions,
} from '../market-news.actions';
import { MarketNewsInstrumentEntity } from '../market-news.models';

export const FEATURE_KEY = 'instruments';

export interface State extends EntityState<MarketNewsInstrumentEntity> {
  loading?: boolean;
  loadingChart?: boolean;
  defaultOrderingType: MarketNewsOrderingType;
}

export const mInstrumentsAdapter =
  createEntityAdapter<MarketNewsInstrumentEntity>();

export const initialState: State = mInstrumentsAdapter.getInitialState({
  defaultOrderingType: MarketNewsOrderingType.latest,
});

const mInstrumentsReducer = createReducer(
  initialState,
  on(
    MarketNewsViewActions.init,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    InstrumentViewActions.showInstrument,
    (state): State => ({
      ...state,
      loadingChart: true,
    }),
  ),
  on(
    MarketNewsApiActions.loadMarketNewsSuccess,
    (state: State, { id, stories, nextToken }): State =>
      mInstrumentsAdapter.upsertOne(
        {
          id,
          stories: stories.map((s) => s.id),
          orderingType:
            state.entities[id]?.orderingType ?? state.defaultOrderingType,
          nextToken,
        },
        { ...state, loading: false },
      ),
  ),
  on(
    MarketNewsApiActions.loadMoreMarketNewsSuccess,
    (state: State, { id, stories, nextToken }): State => {
      const entity = state.entities[id];
      const currentStories = entity?.stories ?? [];
      const newStories =
        stories.filter((s) => !currentStories.includes(s.id)) ?? [];

      return mInstrumentsAdapter.upsertOne(
        {
          id,
          stories: [...currentStories, ...newStories.map((s) => s.id)],
          nextToken,
        },
        { ...state, loading: false },
      );
    },
  ),
  on(
    MarketNewsApiActions.loadMarketNewsSentimentChartSuccess,
    (state: State, { id, sentimentChart }): State =>
      mInstrumentsAdapter.upsertOne(
        {
          id,
          sentimentChart,
        },
        { ...state, loadingChart: false },
      ),
  ),
  on(
    MarketNewsApiActions.loadMarketNewsFailure,
    (state: State): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    MarketNewsApiActions.loadMarketNewsSentimentChartFailure,
    (state: State): State => ({
      ...state,
      loadingChart: false,
    }),
  ),
  on(
    MarketNewsViewActions.changeOrderingType,
    (state: State, { id, orderingType }): State =>
      mInstrumentsAdapter.updateOne(
        {
          id,
          changes: {
            stories: [],
            nextToken: undefined,
            orderingType,
          },
        },
        { ...state, loading: true },
      ),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return mInstrumentsReducer(state, action);
}
