import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AccountLinkingApiActions } from '../../account-linking';
import { GamesApiActions } from '../../games/games.actions';
import {
  PortfolioActions,
  PortfoliosApiActions,
  PortfoliosListActions,
  PortfoliosNewSimulationActions,
  PortfoliosSettingsCurrencyActions,
  UserPropertiesEffectsActions,
} from '../portfolios.actions';
import { UserPropertiesEntity } from '../portfolios.models';

export const name = 'userProperties';

export interface State extends EntityState<UserPropertiesEntity> {
  loading: boolean;
  error: boolean;
}

export interface PartialState {
  [name]: State;
}

export const adapter = createEntityAdapter<UserPropertiesEntity>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: false,
});

const userPropertiesReducer = createReducer(
  initialState,
  on(
    PortfoliosApiActions.loadReportPreferencesSuccess,
    PortfoliosApiActions.loadReportPreferencesFailure,
    UserPropertiesEffectsActions.handleNotification,
    PortfoliosListActions.loadList,
    PortfoliosListActions.reloadList,
    AccountLinkingApiActions.exchangedFlanksCodeSuccess,
    AccountLinkingApiActions.syncYeekateeBankSuccess,
    AccountLinkingApiActions.submitScaSuccess,
    GamesApiActions.joinGameSuccess,
    PortfoliosNewSimulationActions.addSimulationPortfolio,
    PortfolioActions.refreshPortfolioReport,
    PortfoliosApiActions.copyPortfolioSuccess,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PortfoliosApiActions.loadUserPropertiesSuccess,
    (state, { properties }): State =>
      adapter.upsertOne(properties, {
        ...state,
        loading: false,
        error: false,
      }),
  ),
  on(
    PortfoliosSettingsCurrencyActions.updateReportCurrency,
    (state, { userId, currency }): State =>
      adapter.updateOne(
        { id: userId, changes: { reportCurrency: currency } },
        state,
      ),
  ),
  on(
    PortfoliosApiActions.loadUserPropertiesFailure,
    PortfoliosApiActions.copyPortfolioFailure,
    (state): State => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return userPropertiesReducer(state, action);
}
