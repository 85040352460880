export enum RouteParams {
  ACTIVITY_ID = 'activityId',
  BANK_ID = 'bankId',
  CHAT_ID = 'chatId',
  CHAT_TO = 'chatTo',
  GAME_ID = 'gameId',
  QUIZ_ID = 'quizId',
  INSTRUMENT_CATEGORY = 'category',
  INSTRUMENT_ID = 'instrumentId',
  INSTRUMENT_MIC = 'mic',
  INSTRUMENT_SYMBOL = 'symbol',
  PORTFOLIO_CALCULATION = 'calculationBaseType',
  PORTFOLIO_CURRENCY = 'currency',
  PORTFOLIO_ENTITY_ID = 'entityId',
  PORTFOLIO_ITEM_ID = 'itemId',
  PORTFOLIO_KEYS = 'portfolioKeys',
  PORTFOLIO_VIEW = 'portfolioViewType',
  PORTFOLIO_START_DATE = 'startDate',
  PORTFOLIO_END_DATE = 'endDate',
  PORTFOLIO_PERIOD = 'period',
  PORTFOLIO_SPLIT_ITEMS = 'splitItems',
  PORTFOLIO_TRANSACTION_KEY = 'transactionKey',
  USER_ID = 'userId',
}
