import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ActivityStreamsActivity,
  ActivityStreamsActivityType,
  ActivityStreamsNote,
  ActivityStreamsObjectType,
} from '@yeekatee/client-api-angular';
import * as ActivitiesActions from '../actions';
import {
  ActivitiesApiActions,
  ActivitiesEffectsActions,
} from '../actions/activities-groups.actions';
import {
  ActivitiesCollection,
  CollectionId,
  CollectionPrefix,
  CollectionsEntity,
  getCollectionId,
} from '../activity-streams.models';

export const FEATURE_KEY = 'collections';

export type State = EntityState<CollectionsEntity>;

export const adapter: EntityAdapter<CollectionsEntity> =
  createEntityAdapter<CollectionsEntity>();

export const initialState: State = adapter.getInitialState({});

const collectionsReducer = createReducer(
  initialState,
  on(
    ActivitiesApiActions.loadNotesTotalsSuccess,
    (state, { notes }): State =>
      adapter.upsertMany(
        notes.flatMap((note: ActivityStreamsNote) =>
          [
            note.likes
              ? {
                  id: getCollectionId(
                    CollectionPrefix.LIKES,
                    note.id ?? undefined,
                  ),
                  totalItems: note.likes.totalItems ?? undefined,
                }
              : undefined,
            note.replies
              ? {
                  id: getCollectionId(
                    CollectionPrefix.REPLIES,
                    note.id ?? undefined,
                  ),
                  totalItems: note.replies.totalItems ?? undefined,
                }
              : undefined,
          ]
            .filter(
              (c): c is { id: CollectionId; totalItems: number } =>
                !!c?.id && c?.totalItems !== undefined,
            )
            .map((c) => {
              const stateCollection = state.entities[c.id];
              return stateCollection
                ? { ...stateCollection, ...c }
                : { ...c, items: [] };
            }),
        ),
        state,
      ),
  ),
  on(
    ActivitiesActions.apiLoadPersonCollectionsTotalsSuccess,
    (state, { collections }): State =>
      adapter.upsertMany(
        collections.map((collection) =>
          mergeCollectionWithExistingState(state, {
            id: collection.id,
            totalItems: collection.totalItems,
            items: [],
            loading: false,
            refreshing: false,
          } satisfies CollectionsEntity),
        ),
        state,
      ),
  ),
  on(ActivitiesActions.apiDeleteNote, (state, action): State => {
    let collections: CollectionsEntity[] = [];
    const activity = action.activity;
    if (activity?.object?.inReplyTo) {
      collections = undoFromCollections(
        [getCollectionId(CollectionPrefix.REPLIES, activity.object?.inReplyTo)],
        state,
        action.noteActivityId,
      );
    }
    return adapter.upsertMany(collections, state);
  }),
  on(
    ActivitiesActions.loadPersonFollowingCollectionSuccess,
    ActivitiesActions.loadPersonFollowersCollectionSuccess,
    ActivitiesActions.loadPersonLikedCollectionSuccess,
    ActivitiesActions.loadNotesLikesCollectionSuccess,
    ActivitiesActions.loadNotesRepliesCollectionSuccess,
    ActivitiesActions.loadPersonOutboxCollectionSuccess,
    ActivitiesActions.loadAssetSharedInboxCollectionSuccess,
    ActivitiesActions.loadPersonInboxCollectionSuccess,
    ActivitiesActions.loadChatCollectionSuccess,
    (state, collection): State =>
      distributeCollectionsFromApiCollection(state, collection),
  ),
  on(
    ActivitiesActions.apiUnlikeNote,
    (state, { activity, noteId }): State =>
      adapter.upsertMany(
        undoLikeFromCollections(state, activity, noteId),
        state,
      ),
  ),
  on(
    ActivitiesActions.apiUnfollowUser,
    (state, { activity, userId }): State =>
      adapter.upsertMany(
        undoFollowFromCollections(
          state,
          activity?.object?.id,
          activity?.actor?.id,
          userId,
          false,
        ),
        state,
      ),
  ),
  on(
    ActivitiesActions.apiRevokeFollow,
    (state, { activity, userId }): State =>
      adapter.upsertMany(
        undoFollowFromCollections(
          state,
          activity?.object?.id,
          activity?.actor?.id,
          userId,
          true,
        ),
        state,
      ),
  ),
  on(
    ActivitiesActions.apiRejectFollow,
    (state, { activity, rejectedActivity }): State =>
      adapter.upsertMany(
        undoFollowFromCollections(
          state,
          activity.id,
          activity?.actor?.id,
          rejectedActivity.actor,
          rejectedActivity.result !== ActivityStreamsObjectType.Accept,
        ),
        state,
      ),
  ),
  on(
    ActivitiesActions.apiCreateNote,
    ActivitiesActions.apiLikeNote,
    ActivitiesEffectsActions.handleNotification,
    (state, { activity }): State => {
      const collections = distributeNewActivityToCollections(state, activity);
      return collections ? adapter.upsertMany(collections, state) : state;
    },
  ),
  on(
    ActivitiesActions.followUserResult,
    ActivitiesEffectsActions.handleFollowNotification,
    (state, { activity }): State => {
      const collections = distributeNewActivityToCollections(
        state,
        activity,
        activity.result?.type === ActivityStreamsObjectType.Accept,
      );
      return collections ? adapter.upsertMany(collections, state) : state;
    },
  ),
  on(
    ActivitiesActions.apiAcceptFollow,
    ActivitiesEffectsActions.handleAcceptFollowNotification,
    (state, { inReplyTo }): State => {
      if (!inReplyTo) return state;

      const followActivity: ActivityStreamsActivity = {
        type: ActivityStreamsActivityType.Follow,
        id: inReplyTo.id,
        object: { id: inReplyTo.object },
        actor: { id: inReplyTo.actor },
      };

      const collections = distributeNewActivityToCollections(
        state,
        followActivity,
        true,
      );

      return collections ? adapter.upsertMany(collections, state) : state;
    },
  ),
  on(
    ActivitiesEffectsActions.triggerFollowingTimelineLoad,
    ActivitiesEffectsActions.triggerForYouTimelineLoad,
    (state, { collectionId, loading, refreshing }): State => {
      const inbox = state.entities[collectionId];
      return inbox
        ? adapter.updateOne(
            {
              id: collectionId,
              changes: { loading, refreshing },
            },
            state,
          )
        : state;
    },
  ),
);

const distributeNewActivityToCollections = (
  state: State,
  activity: ActivityStreamsActivity,
  incrementTotal = true,
) => {
  if (!activity?.id) return;

  const mapToCollection = (collection: CollectionsEntity) => {
    const mergeCollection = mergeCollectionWithExistingState(state, collection);
    return {
      ...mergeCollection,
      totalItems: (mergeCollection.totalItems ?? 0) + Number(incrementTotal),
    };
  };

  if (activity.context) {
    return (dependingCollectionsForActivity(activity) ?? []).map(
      mapToCollection,
    );
  }

  const items = activity.context ? [] : [activity.id];

  // Since it is a new activity
  const inbox: CollectionsEntity = {
    id: getCollectionId(
      CollectionPrefix.INBOX,
      activity.actor?.id ?? undefined,
    ),
    items,
  };

  /**
   * Need to be sure that this code is only executed for my activities
   * or my followings' activities
   */
  const onlyFollowingInbox: CollectionsEntity = {
    id: getCollectionId(
      CollectionPrefix.ONLY_FOLLOWING_INBOX,
      activity.actor?.id ?? undefined,
    ),
    items,
  };

  const outbox: CollectionsEntity = {
    id: getCollectionId(
      CollectionPrefix.OUTBOX,
      activity.actor?.id ?? undefined,
    ),
    items,
  };

  return [inbox]
    .concat(
      [onlyFollowingInbox],
      [outbox],
      dependingCollectionsForActivity(activity) ?? [],
    )
    .map(mapToCollection);
};

const undoLikeFromCollections = (
  state: State,
  activity: ActivityStreamsActivity,
  noteId: string,
  decrementTotal = true,
): CollectionsEntity[] => {
  if (!activity.object?.id || !activity.actor?.id) return [];

  return undoFromCollections(
    [
      getCollectionId(CollectionPrefix.LIKES, noteId),
      getCollectionId(CollectionPrefix.LIKED, activity.actor.id),
    ],
    state,
    activity.object.id,
    decrementTotal,
  );
};

const undoFollowFromCollections = (
  state: State,
  activityId?: string | null,
  followerId?: string | null,
  followedId?: string | null,
  revoking = false,
): CollectionsEntity[] => {
  if (!activityId || !followerId || !followedId) return [];

  return undoFromCollections(
    [
      getCollectionId(CollectionPrefix.FOLLOWERS, followedId),
      getCollectionId(CollectionPrefix.FOLLOWING, followerId),
    ],
    state,
    activityId,
    !revoking,
  );
};

const undoFromCollections = (
  collections: string[],
  state: State,
  id: string,
  decrementTotal = true,
): CollectionsEntity[] =>
  collections.reduce<CollectionsEntity[]>((accumulator, collection) => {
    const newCollection = removeItemFromCollection(
      state.entities[collection],
      id,
      decrementTotal,
    );
    return newCollection ? [...accumulator, newCollection] : accumulator;
  }, [] as CollectionsEntity[]);

const removeItemFromCollection = (
  collection: CollectionsEntity | undefined,
  id: string,
  decrementTotal = true,
): CollectionsEntity | undefined => {
  if (!collection) return;
  return {
    ...collection,
    items: collection.items.filter((i) => i !== id),
    totalItems: (collection.totalItems ?? 1) - Number(decrementTotal),
  };
};

const addItemsToCollection = (
  collection: CollectionsEntity,
  items: string[],
): CollectionsEntity => {
  return {
    ...collection,
    items: [...new Set([...collection.items, ...items])].sort().reverse(),
  };
};

/**
 * Based on a received collection, we must touch all cascading collections.
 * For instance - when fetching the inbox -
 * we must not only update the inbox collection, but others too.
 * All likes and replies will have an actor and an object.
 * Actors and objects respectively have the liked/replied,
 * and likes/replies collections.
 * These collections must also be updated on the state to ensure consistency
 * with the backend data store.
 *
 * @param state
 * @param apiCollection
 */
const distributeCollectionsFromApiCollection = (
  state: State,
  apiCollection: ActivitiesCollection,
): State => {
  const parentCollection: CollectionsEntity = {
    id: apiCollection.id,
    items: apiCollection.items
      ? getIdsFromActivities(
          apiCollection.items.filter(
            (i) => !i.context || apiCollection.id.match(/^CHAT/),
          ),
        )
          .sort()
          .reverse()
      : [],
    totalItems: apiCollection.totalItems,
    nextToken: apiCollection.nextToken,
    loading: false,
    refreshing: false,
  };

  [parentCollection]
    .concat(
      apiCollection.items
        ? apiCollection?.items
            ?.flatMap((activity) => dependingCollectionsForActivity(activity))
            .filter(
              (collection): collection is CollectionsEntity => !!collection,
            )
        : [],
    )
    .forEach(
      (collection) =>
        (state = adapter.upsertOne(
          mergeCollectionWithExistingState(state, collection),
          state,
        )),
    );

  return state;
};

/**
 * This merges newly defined collections with existing collections in state.
 *
 * @param state
 * @param collection
 */
const mergeCollectionWithExistingState = (
  state: State,
  collection: CollectionsEntity,
): CollectionsEntity => {
  const existingCollection = state.entities?.[collection.id];

  if (!existingCollection) return collection;

  // Merge all the collection items, and keep them sorted in reverse order
  const mergedCollection = addItemsToCollection(
    existingCollection,
    collection.items,
  );

  return {
    id: collection.id,
    items: mergedCollection.items,
    totalItems: collection?.totalItems ?? mergedCollection.totalItems ?? 0,
    nextToken:
      !!collection?.nextToken || collection?.nextToken === null
        ? collection?.nextToken
        : mergedCollection.nextToken,
    loading: false,
    refreshing: false,
  };
};

/**
 * For a given Activity, usually coming from the Inbox,
 * we extract depending collections that we should create or append to.
 *
 * @param activity
 */
const dependingCollectionsForActivity = (
  activity: ActivityStreamsActivity,
): CollectionsEntity[] | undefined => {
  if (!activity.id || !activity.object?.id || !activity.actor?.id)
    return undefined;

  const items = [activity.id];

  /**
   * For Like activities, 1 collection for the actor and 1 for the liked object
   * * LIKES#<ObjectID> are the likes for a specific object
   * * LIKED#<ActorID> are the likes by a specific actor
   */
  if (activity.type === ActivityStreamsActivityType.Like) {
    return [
      {
        id: getCollectionId(CollectionPrefix.LIKES, activity.object.id),
        items,
      },
      { id: getCollectionId(CollectionPrefix.LIKED, activity.actor.id), items },
    ];
  }

  /**
   * When Create/Note activities are in reply to an existing object,
   * we append the activity in a REPLIES#<InReplyToID> collection.
   */
  if (
    activity.type === ActivityStreamsActivityType.Create &&
    activity.object.type === ActivityStreamsObjectType.Note &&
    !activity.context &&
    activity.object.inReplyTo
  ) {
    return [
      {
        id: getCollectionId(
          CollectionPrefix.REPLIES,
          activity.object.inReplyTo,
        ),
        items,
      },
    ];
  }

  /**
   * Chat activities have their own collection
   */
  if (activity.context) {
    return [
      { id: getCollectionId(CollectionPrefix.CHAT, activity.context), items },
    ];
  }

  /**
   * For Follow activities, 1 collection for the actor and 1 for the followed object
   * * FOLLOWERS#<ObjectID> are the followers for a specific object
   * * FOLLOWING#<ActorID> are the following by a specific actor
   */
  if (activity.type === ActivityStreamsActivityType.Follow) {
    return [
      {
        id: getCollectionId(CollectionPrefix.FOLLOWING, activity.actor.id),
        items,
      },
      {
        id: getCollectionId(CollectionPrefix.FOLLOWERS, activity.object.id),
        items,
      },
    ];
  }

  return undefined;
};

const getIdsFromActivities = (
  activities: ActivityStreamsActivity[],
): string[] =>
  activities.map((activity) => activity.id).filter((id): id is string => !!id);

export function reducer(state: State | undefined, action: Action) {
  return collectionsReducer(state, action);
}
