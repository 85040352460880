import { FetchPolicy } from '@apollo/client';
import { DateTime } from 'luxon';

/**
 * This function accepts a unique key and an expiration date. It returns "network-only" if the cache key is expired
 * or "cache-first" if the key is still valid for the given expiration time
 *
 * @param keys - the map containing the key-timestamp pair
 * @param key - the unique name you want to give this expiration key
 * @param expirationMin - the amount of seconds a key should be valid for. Default = 5min
 *
 */
export const getFetchPolicy = (
  keys: Map<string, DateTime>,
  key: string,
  expirationMin = 5,
): FetchPolicy => {
  const now = DateTime.now();
  const validUntil = keys.get(key)?.plus({ minutes: expirationMin }) ?? now;

  if (now >= validUntil) {
    keys.set(key, now);
    return 'network-only';
  }

  return 'cache-first';
};
