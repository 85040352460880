import { Dictionary } from '@ngrx/entity';
import { TrxTypeKey } from '@yeekatee/booking-util-definitions';
import {
  BookingPeriod,
  CalculationBaseType,
  InvestmentType,
  Listing,
  PortfolioAggregation,
  PortfolioCapital,
  PortfolioCashFlows,
  PortfolioCost,
  PortfolioInstrument,
  PortfolioItem,
  PortfolioNetGross,
  PortfolioPerformance,
  PortfolioProfitLossReturn,
  PortfolioReport,
  PortfolioReportState,
  PortfolioReportValues,
  PortfolioTimeSeriesRecord,
  TransactionsReport,
  TreeType,
  User,
  UserBookingProperties,
  UserPortfolio,
  UserPortfolioVisibility,
} from '@yeekatee/client-api-angular';
import {
  setChartFill,
  setDynamicBorderColor,
} from '@yeekatee/shared-charts-util-functions';
import {
  BubbleDataPoint,
  ChartData,
  ChartDataset,
  ComplexFillTarget,
  ScriptableContext,
} from 'chart.js';
import chroma from 'chroma-js';
import { AssetEntity } from '../instruments';
import { ThemeColors } from '../theme/theme.model';
import * as fromUserPortfolios from './features/user-portfolios.reducer';

/**
 * Portfolios entities
 */
export type PortfolioReportEntity = Omit<PortfolioReport, '__typename'>;

export type UserPropertiesEntity = Omit<
  UserBookingProperties,
  '__typename' | 'portfolios' | 'nSynching'
>;

export type UserPortfolioEntity = Omit<UserPortfolio, '__typename'>;

export type TransactionsReportEntity = Omit<TransactionsReport, '__typename'>;

export type Portfolio = UserPortfolioEntity & { isSelected: boolean };

export const getStoreReportId = (
  reportId: string,
  period?: BookingPeriod,
  currency?: string,
  startDate?: string | null,
  endDate?: string | null,
): string => {
  const periodKey = period
    ? `${period}`
    : !!startDate && !!endDate
      ? `${startDate}/${endDate}`
      : DEFAULT_REPORT_PERIOD;

  return reportId + `/${periodKey}` + (currency ? `/${currency}` : '');
};

/**
 * Portfolio view models
 */
export type CommonReportVM = {
  reportLoading: boolean;
  portfoliosLoading: boolean;
  error: boolean;
  portfolioName: string;
  canAddTransactions: boolean;
  canCopy: boolean;
  portfolios: Portfolio[];
  isAuthUserSelected: boolean;
  noPortfolios: boolean;
  visibility: UserPortfolioVisibility;
  colors?: ThemeColors;
};

export type UserPortfolioVM = CommonReportVM & {
  chartsData: PortfolioChartsData;
  period?: BookingPeriod;
  currency?: string;
  reportValues?: PortfolioReportValues;
  portfolioKey?: string;
  positions: PortfolioItem[];
  portfolioState?: PortfolioReportState;
  showNoPortfolioCard: boolean;
  synching: boolean;
  calculationBaseType: CalculationBaseType;
  portfolioViewType: PortfolioViewType;
  portfolioViewTypeI18n: PortfolioViewTypeI18n;
  gameId?: string;
};

export type PortfoliosReportVM = CommonReportVM & {
  userId?: string;
  userAvatar?: string;
  chartsData: PortfolioChartsData;
  currency?: string;
  reportValues?: PortfolioReportValues;
  portfolioKey?: string;
  positions: PortfolioItem[];
  portfolioState?: PortfolioReportState;
  showNoPortfolioCard: boolean;
};

export type PortfolioOptions = {
  calculationBaseType: CalculationBaseType; // Gross/Net
  investmentViewType: InvestmentViewType; // All/Active
  portfolioViewType: PortfolioViewType;
  currency: string | undefined;
  period: BookingPeriod | undefined;
  splitItems: boolean;
};

export type PortfoliosComparisonVM = {
  authUser?: User;
  selectedUser?: User;
};

export type PortfoliosBalances = {
  keys: string[];
  balances: {
    isCash: boolean;
    balance: number;
    currency?: string;
    instrumentId?: string;
    instrumentSymbol?: string;
    instrumentMic?: string;
  }[];
}[];

export type TradeFormVM = {
  portfolioLoading: boolean;
  portfoliosBalances: PortfoliosBalances;
};

export type PortfolioItemVM = {
  userId?: string;
  calculationBaseType?: CalculationBaseType;
  capital?: PortfolioCapital;
  currency?: string;
  historicCost?: PortfolioCost;
  instrument?: AssetEntity;
  instrumentLoading?: boolean;
  canAddTransactions?: boolean;
  item?: PortfolioItem;
  itemInstrument?: PortfolioInstrument;
  listing?: Listing;
  period?: BookingPeriod;
  periodicCost?: PortfolioCost;
  performanceReport?: PortfolioPerformance;
  profitLoss?: PortfolioProfitLossReturn;
  cashFlows?: PortfolioCashFlows;
  quoteLoading?: boolean;
  portfolio?: UserPortfolioEntity;
  title?: string;
  geekView?: boolean;
};

export type PortfolioPerfRisksVM = {
  aggregation?: PortfolioAggregation;
  performanceChart?: ChartData<'line'>;
  volatilityChart?: ChartData<'line'>;
  volatilityVsPerformanceChart?: ChartData<'bubble'>;
  topPositionsChart?: ChartData<'line'>;
  colors?: ThemeColors;
  performanceReport?: PortfolioPerformance;
  calculationBaseType: CalculationBaseType;
  loading: boolean;
};

export type PortfolioInsightsVM = {
  aggregation?: PortfolioAggregation;
  periodicCost?: PortfolioCost;
  profitLoss?: PortfolioProfitLossReturn;
  capital?: PortfolioCapital;
  performanceReport?: PortfolioPerformance;
  cashFlows?: PortfolioCashFlows;
  currency?: string;
  calculationBaseType: CalculationBaseType;
  geekView: boolean;
};

export type ReportPreferences = PortfolioOptions & {
  portfolioKeys: string[] | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  geekView: boolean;
};

export type PortfolioReportInputs = {
  portfolioKeys?: string[];
  currency?: string;
  startDate?: string;
  endDate?: string;
  period?: BookingPeriod;
  splitItems?: boolean;
};

export const DEFAULT_REPORT_PORTFOLIO_KEY = 'none';
export const DEFAULT_REPORT_PORTFOLIOS_KEYS = [];
export const DEFAULT_REPORT_PERIOD = BookingPeriod.max;

/**
 * Utils for charts
 */

export const twrGross = () => $localize`TWR gross`;
export const twrNet = () => $localize`TWR net`;
export const volatility = () =>
  $localize`30-day rolling volatility (annualized)`;

export interface PortfolioChartsData {
  aggregation?: PortfolioAggregation;
  evaluation?: ChartData<'line'>;
  assetExposure?: ChartData<'doughnut'>;
  performance?: ChartData<'line'>;
  volatility?: ChartData<'line'>;
  volatilityVsPerformance?: ChartData<'bubble'>;
  topPositions?: ChartData<'line'>;
  startDate?: string;
  endDate?: string;
  minDate?: string;
  maxDate?: string;
}

type TimeSeriesRecordFieldType = Pick<
  PortfolioTimeSeriesRecord,
  | 'marketValue'
  | 'timeWeightedNet'
  | 'timeWeightedGross'
  | 'timeWeightedCapitalized'
  | 'stdTimeWeightedNet'
  | 'stdTimeWeightedGross'
  | 'stdTimeWeightedCapitalized'
>;

export const getPortfolioReportChartData = (
  report?: PortfolioReportEntity,
  currency?: string,
  colors?: ThemeColors,
): ChartData<'line'> =>
  getReportChartData(
    report,
    'marketValue',
    currency,
    colors,
    true,
    $localize`Market value`,
  );

const getTimeWeightedField = (
  calculationBaseType?: CalculationBaseType,
): keyof TimeSeriesRecordFieldType => {
  switch (calculationBaseType) {
    case CalculationBaseType.Gross:
      return 'timeWeightedGross';
    case CalculationBaseType.Net:
      return 'timeWeightedNet';
    case CalculationBaseType.Capitalized:
      return 'timeWeightedCapitalized';
    default:
      return 'timeWeightedGross';
  }
};

const getStdTimeWeightedField = (
  calculationBaseType?: CalculationBaseType,
): keyof TimeSeriesRecordFieldType => {
  switch (calculationBaseType) {
    case CalculationBaseType.Gross:
      return 'stdTimeWeightedGross';
    case CalculationBaseType.Net:
      return 'stdTimeWeightedNet';
    case CalculationBaseType.Capitalized:
      return 'stdTimeWeightedCapitalized';
    default:
      return 'stdTimeWeightedGross';
  }
};

export const getReportPerformanceChartData = (
  report?: PortfolioReportEntity,
  currency?: string,
  colors?: ThemeColors,
  calculationBaseType = CalculationBaseType.Gross,
): ChartData<'line'> =>
  getReportChartData(
    report,
    getTimeWeightedField(calculationBaseType),
    currency,
    colors,
    true,
    'TWR ' + calculationBaseTypeI18n()[calculationBaseType],
  );

export const getReportVolatilityChartData = (
  report?: PortfolioReportEntity,
  currency?: string,
  colors?: ThemeColors,
  calculationBaseType = CalculationBaseType.Gross,
): ChartData<'line'> =>
  getReportChartData(
    report,
    getStdTimeWeightedField(calculationBaseType),
    currency,
    colors,
    false,
    volatility(),
    colors?.primary,
  );

function getReportChartData(
  report?: PortfolioReportEntity,
  field?: keyof TimeSeriesRecordFieldType,
  currency?: string,
  colors?: ThemeColors,
  fill = false,
  label?: string,
  color?: chroma.Color,
): ChartData<'line'> {
  const aggregation = report?.aggregation;
  if (!aggregation || !colors || !field) return { datasets: [] };

  const timeSeries = getReportValuesByCurrency(
    aggregation,
    currency,
  )?.timeSeries;
  if (!timeSeries) return { datasets: [] };

  const data = timeSeries.reduce<{ labels: string[]; data: number[] }>(
    (data, record) => {
      if (!record) return data;

      const fieldValue = record[field];
      const date = record.date;
      if (!date || fieldValue === undefined || fieldValue === null) return data;

      data.labels?.push(date);
      data.data.push(fieldValue);
      return data;
    },
    { labels: [], data: [] },
  );

  if (!data.data.length) return { datasets: [] };

  const borderColor = (context: ScriptableContext<'line'>) =>
    color
      ? color.css()
      : setDynamicBorderColor(
          context,
          colors.uptrend.css(),
          colors.downtrend.css(),
          data.data[0],
        );

  const traceConfig = {
    data: data.data,
    label,
    pointRadius: 0,
    pointBorderColor: borderColor,
    pointBackgroundColor: borderColor,
    pointStyle: 'line',
    borderColor,
    borderWidth: 2,
    tension: 0.3,
  };

  const firstDataPoint = data.data[0];
  const fillConfig = fill
    ? {
        fill: ((context: ScriptableContext<'line'>): ComplexFillTarget => ({
          target: {
            value: firstDataPoint,
          },
          above: setChartFill(
            context,
            colors.uptrend,
            firstDataPoint,
            'bottom',
          ),
          below: setChartFill(context, colors.downtrend, firstDataPoint, 'top'),
        })) as unknown as string,
      }
    : { fill: false };

  return {
    labels: data.labels,
    datasets: [{ ...traceConfig, ...fillConfig }],
  };
}

export const getReportVolatilityVsPerformanceChartData = (
  report?: PortfolioReportEntity,
  currency?: string,
  colorScale?: chroma.Scale,
  calculationBaseType?: CalculationBaseType,
  nTopItems?: number,
): ChartData<'bubble'> => {
  // Active positions bubbles
  const activeItems = report?.items?.filter((i) => (i.balance ?? 0) > 0);

  if (
    !activeItems ||
    !colorScale ||
    !calculationBaseType ||
    !currency ||
    !nTopItems
  )
    return { datasets: [] };

  // Get the top N positions
  const items = activeItems
    .slice()
    .sort((a, b) => (b.allocation ?? 0) - (a.allocation ?? 0))
    .slice(0, nTopItems);

  // Normalize allocations to get bubble radiuses
  const allocations = items
    .map((i) => i.allocation)
    .filter((a): a is number => a != null);

  const minRadius = 5;
  const maxRadius = 20;
  const minVal = Math.min(...allocations);
  const maxVal = Math.max(...allocations);
  const radiuses = allocations.map(
    (a) =>
      ((a - minVal) / (maxVal - minVal)) * (maxRadius - minRadius) + minRadius,
  );

  const borderColors = colorScale.colors(nTopItems + 1);

  const datasets = items.map((i, index) => {
    const label = i.instrument?.name ?? '';

    const performance = getReportValuesByCurrencyAndCalculationBase(
      i,
      currency,
      calculationBaseType,
    )?.netGross?.performance;

    return {
      label,
      data: [
        {
          x: performance?.stdTimeWeighted ?? 0,
          y: performance?.timeWeighted ?? 0,
          r: radiuses[index],
        } satisfies BubbleDataPoint,
      ],
      backgroundColor: borderColors[index],
    };
  });

  // Portfolio bubble
  const label = $localize`Total portfolio`;

  const performance = getReportValuesByCurrencyAndCalculationBase(
    report?.aggregation ?? undefined,
    currency,
    calculationBaseType,
  )?.netGross?.performance;

  datasets.push({
    label,
    data: [
      {
        x: performance?.stdTimeWeighted ?? 0,
        y: performance?.timeWeighted ?? 0,
        r: maxRadius + 5, // Make total portfolio a bit bigger to highlight it
      },
    ],
    backgroundColor: borderColors.at(-1) ?? '',
  });

  return {
    datasets,
  };
};

export const getReportTopPositionsChartData = (
  report?: PortfolioReportEntity,
  currency?: string,
  colorScale?: chroma.Scale,
  calculationBaseType?: CalculationBaseType,
  nTopItems?: number,
): ChartData<'line'> => {
  const items = report?.items;
  if (!items || !colorScale || !calculationBaseType || !currency || !nTopItems)
    return { datasets: [] };

  const allDates = items.reduce<string[]>((acc, item) => {
    const timeSeries = getReportValuesByCurrency(item, currency)?.timeSeries;
    if (!timeSeries) return acc;

    acc.push(
      ...(timeSeries
        ?.map((t) => t.date)
        .filter((date): date is string => !!date) ?? []),
    );

    return acc;
  }, []);

  const dates = Array.from(new Set<string>(allDates.sort()));

  const borderColors = colorScale.colors(nTopItems);

  const traceConfig = {
    pointRadius: 0,
    pointStyle: 'line',
    borderWidth: 2,
    tension: 0.3,
  };

  const timeWeightedField = getTimeWeightedField(calculationBaseType);
  let nItem = 0;
  const datasets = items.reduce<ChartDataset<'line'>[]>((acc, item) => {
    const timeSeries = getReportValuesByCurrency(item, currency)?.timeSeries;
    if (!timeSeries) return acc;

    let nTimeSeries = 0;
    const data: (number | null)[] = [];
    dates.forEach((date) => {
      const ts = timeSeries.at(nTimeSeries);
      if (ts?.date === date) {
        data.push(ts[timeWeightedField] ?? null);
        nTimeSeries++;
      } else {
        data.push(null);
      }
    });

    const borderColor = borderColors[nItem];
    nItem++;

    acc.push({
      ...traceConfig,
      label: item.instrument?.name ?? undefined,
      data,
      borderColor,
      pointBorderColor: borderColor,
      pointBackgroundColor: borderColor,
    });

    return acc;
  }, []);

  if (!datasets.length) return { datasets: [] };

  return {
    labels: dates,
    datasets,
  };
};

export const getReportAssetExposureChartData = (
  report?: PortfolioReportEntity,
  colorScale?: chroma.Scale,
): ChartData<'doughnut'> => {
  const trees = report?.trees;

  if (!trees || !colorScale) return { datasets: [] };

  const aggregations = trees
    .find((tree) => tree && tree.treeType === TreeType.InvType)
    ?.aggregations?.filter((a) => a?.allocation);

  const data = aggregations?.map((a) => a?.allocation ?? 0) ?? [];

  return {
    datasets: [
      {
        data,
        backgroundColor: colorScale.colors(data?.length ?? 1),
        hoverOffset: 10,
      },
    ],
    labels: aggregations?.map((a) =>
      portfolioI18nInvestmentType(a?.investmentType ?? InvestmentType.Other),
    ),
  };
};

/**
 * Utils for reducers
 */
export function mergeTransactionsReports(
  entities: Dictionary<TransactionsReportEntity>,
  newReport?: TransactionsReportEntity,
): TransactionsReportEntity {
  if (!newReport?.id) return {};

  const prevReport = entities[newReport.id];
  const prevTxs = prevReport?.transactions ?? [];
  const newTxs = newReport?.transactions ?? [];

  return {
    id: newReport.id,
    nextToken: newReport.nextToken,
    transactions: [...prevTxs, ...newTxs],
  };
}

export const getLegacyReportsIds = (
  entities: Dictionary<PortfolioReportEntity>,
  userId: string,
  portfolioKey: string,
) =>
  Object.values(entities)
    .filter(
      (r): r is PortfolioReportEntity =>
        !!r &&
        r.userId === userId &&
        (r.portfolioKeys ?? []).includes(portfolioKey),
    )
    .map((r) => r.id);

export const removeNonExistingPortfolios = (
  portfolios: UserPortfolioEntity[],
  portfoliosKeys?: string[],
): string[] => {
  if (!portfoliosKeys) return DEFAULT_REPORT_PORTFOLIOS_KEYS;
  return portfoliosKeys.filter((k) => !!portfolios.find((p) => k === p.key));
};

/**
 * Utils for portfolios views
 */
export type TransactionTradeType = TrxTypeKey.Buy | TrxTypeKey.Sell;

export type TransactionType =
  | TrxTypeKey.InPayment
  | TrxTypeKey.OutPayment
  | TrxTypeKey.GeneralExpenses
  | TrxTypeKey.AdvisoryFee
  | TrxTypeKey.CustodyFee
  | TrxTypeKey.Forex
  | TransactionTradeType;

const investmentTypeOrder: InvestmentType[] = [
  InvestmentType.Share,
  InvestmentType.ETF,
  InvestmentType.Fund,
  InvestmentType.VariableIncome,
  InvestmentType.CryptoCurrency,
  InvestmentType.FixedIncome,
  InvestmentType.PreciousMetal,
  InvestmentType.NonBankableAsset,

  InvestmentType.Account,
  InvestmentType.CallDeposit,
  InvestmentType.ContractForDifference,
  InvestmentType.Derivative,
  InvestmentType.Deposit,
  InvestmentType.Forward,
  InvestmentType.Future,
  InvestmentType.FXForward,
  InvestmentType.Liability,
  InvestmentType.TermDeposit,

  InvestmentType.YeekateeCoin,
  InvestmentType.GameReward,

  InvestmentType.CashAccount,
  InvestmentType.SavingsAccount,
  InvestmentType.OtherMoneyAccount,

  InvestmentType.Other,
];

const orderedInvestmentTypeMap = investmentTypeOrder.reduce<
  Partial<Record<InvestmentType, number>>
>((map, type, currentIndex) => {
  map[type] = currentIndex;
  return map;
}, {});

export const portfolioI18nInvestmentType = (type: InvestmentType) => {
  const investmentTypeI18n: Record<InvestmentType, string> = {
    [InvestmentType.Share]: $localize`:@@nounToShare:Share`,
    [InvestmentType.ETF]: $localize`ETF`,
    [InvestmentType.Fund]: $localize`Fund`,
    [InvestmentType.FixedIncome]: $localize`Fixed income`,
    [InvestmentType.CryptoCurrency]: $localize`Cryptocurrency`,
    [InvestmentType.VariableIncome]: $localize`Variable income`,
    [InvestmentType.Future]: $localize`Future`,
    [InvestmentType.Other]: $localize`Other`,
    [InvestmentType.ContractForDifference]: $localize`Contract for difference`,
    [InvestmentType.FXForward]: $localize`FX forward`,
    [InvestmentType.Forward]: $localize`Forward`,
    [InvestmentType.Derivative]: $localize`Derivative`,
    [InvestmentType.PreciousMetal]: $localize`Precious metal`,
    [InvestmentType.Deposit]: $localize`Deposit`,
    [InvestmentType.TermDeposit]: $localize`Term deposit`,
    [InvestmentType.CallDeposit]: $localize`Call deposit`,
    [InvestmentType.CashAccount]: $localize`Cash account`,
    [InvestmentType.SavingsAccount]: $localize`Savings account`,
    [InvestmentType.OtherMoneyAccount]: $localize`Other money account`,
    [InvestmentType.Liability]: $localize`Liability`,
    [InvestmentType.Account]: $localize`Account`,
    [InvestmentType.NonBankableAsset]: $localize`Non-Bankable assets`,
    [InvestmentType.YeekateeCoin]: $localize`yeekatee coin`,
    [InvestmentType.GameReward]: $localize`Game reward`,
  };

  return investmentTypeI18n[type];
};

export const sortPortfolioReportItems = (
  report?: PortfolioReportEntity,
): PortfolioItem[] =>
  report?.items
    ?.reduce(
      (orderedItems, item) => sortReportItems(orderedItems, item),
      new Array<PortfolioItem[]>(investmentTypeOrder.length),
    )
    .map((items) =>
      items.sort((a, b) => (b.allocation ?? 0) - (a.allocation ?? 0)),
    )
    .flat() ?? [];

function sortReportItems(
  orderedItems: PortfolioItem[][],
  item?: PortfolioItem,
): PortfolioItem[][] {
  const type = item?.investmentType;
  if (!type) return orderedItems;

  const index = orderedInvestmentTypeMap[type];
  if (index == undefined) return orderedItems;

  const items = orderedItems[index] ?? [];
  items.push(item);
  orderedItems[index] = items;

  return orderedItems;
}

export enum InvestmentViewType {
  Active = 'Active',
  All = 'All',
}

export enum PortfolioViewType {
  TotalReturn = 'TotalReturn',
  UnrealisedPL = 'UnrealisedPL',
  TWR = 'TWR',
  INTRADAY = 'Intraday',
}

export const PortfolioViewTypeOrdered = [
  PortfolioViewType.TotalReturn,
  PortfolioViewType.TWR,
  PortfolioViewType.UnrealisedPL,
  PortfolioViewType.INTRADAY,
];

export const portfolioViewTypeSummary = (viewType: PortfolioViewType) => {
  const summary: Record<PortfolioViewType, string> = {
    [PortfolioViewType.TotalReturn]: $localize`Glimpse into reality`,
    [PortfolioViewType.TWR]: $localize`What you can compare with others`,
    [PortfolioViewType.UnrealisedPL]: $localize`What your broker tells you`,
    [PortfolioViewType.INTRADAY]: $localize`What you gained & lost today`,
  };
  return summary[viewType];
};

export enum SplitOptionType {
  Merged = 'Merged',
  Separated = 'Separated',
}

const getNetGrossByCalculationBase = (
  reportValues?: PortfolioReportValues,
  calculationBaseType?: CalculationBaseType,
) => {
  if (!reportValues) return undefined;

  switch (calculationBaseType) {
    case CalculationBaseType.Gross:
      return reportValues.gross ?? undefined;
    case CalculationBaseType.Net:
      return reportValues.net ?? undefined;
    case CalculationBaseType.Capitalized:
      return reportValues.capitalized ?? undefined;
    default:
      return undefined;
  }
};

export const getReportValuesByCurrencyAndCalculationBase = (
  item?: PortfolioAggregation | PortfolioItem,
  currency?: string,
  calculationBaseType?: CalculationBaseType,
):
  | { netGross?: PortfolioNetGross; cashFlows?: PortfolioCashFlows }
  | undefined => {
  const reportValues = getReportValuesByCurrency(item, currency);
  const netGross = getNetGrossByCalculationBase(
    reportValues,
    calculationBaseType,
  );

  return { netGross, cashFlows: reportValues?.cashFlows ?? undefined };
};

export const getReportValuesReturns = (
  reportValues?: PortfolioReportValues,
  calculationBaseType?: CalculationBaseType,
  portfolioViewType?: PortfolioViewType,
): (number | undefined)[] => {
  const netGross = getNetGrossByCalculationBase(
    reportValues,
    calculationBaseType,
  );

  const volatility = netGross?.performance?.stdTimeWeighted;
  let rateOfReturn = netGross?.performance?.moneyWeighted;
  let returnValue = netGross?.ledger?.profitLoss?.return?.total;
  if (portfolioViewType === PortfolioViewType.UnrealisedPL) {
    rateOfReturn = netGross?.periodicCost?.unrealisedFactor;
    returnValue = netGross?.periodicCost?.unrealised;
  }
  if (portfolioViewType === PortfolioViewType.INTRADAY) {
    rateOfReturn = netGross?.intradayCost?.unrealisedFactor;
    returnValue = netGross?.intradayCost?.unrealised;
  }
  if (portfolioViewType === PortfolioViewType.TWR) {
    rateOfReturn = netGross?.performance?.timeWeighted;
    returnValue = undefined; // There exists none by definition
  }
  return [
    returnValue ?? undefined,
    rateOfReturn ?? undefined,
    volatility ?? undefined,
  ];
};

export const getReportValuesByCurrency = <
  T extends PortfolioAggregation | PortfolioItem,
>(
  item?: T,
  currency?: string,
): PortfolioReportValues | undefined => {
  const values = item?.reportValues ?? undefined;
  if (!values?.length) return undefined;

  if (!currency) return values.at(0);

  return item?.reportValues?.find((rv) => rv.currency === currency);
};

export const mergeReportsTimeSeries = (
  source: PortfolioReportEntity,
  target: PortfolioReportEntity,
): PortfolioReportEntity => {
  const items = target.items ?? [];
  const newItems = source.items?.map((i, nItem) => ({
    ...i,
    reportValues: i.reportValues?.map((rv, nRV) => ({
      ...rv,
      timeSeries: items?.[nItem].reportValues?.[nRV].timeSeries,
    })),
  }));

  return { ...source, items: newItems };
};

export const getDefaultPortfolioKeyAfterDelete = (
  state: fromUserPortfolios.State,
  portfolio: UserPortfolioEntity,
): string | undefined => {
  const currentDefaultKey = state.defaultPortfolioKey;
  const portfolioKey = portfolio.key ?? undefined;
  if (portfolioKey !== currentDefaultKey) return currentDefaultKey;

  const firstId = state.ids.find(
    (id) => (state.entities[id]?.key ?? undefined) !== portfolioKey,
  );
  if (!firstId) return undefined;

  const firstPortfolio = state.entities[firstId];
  return firstPortfolio?.key ?? undefined;
};

export const getReportPortfoliosKeys = (
  selectedPortfoliosKeys: string[] | undefined,
  portfoliosKeysPreferences: string[] | undefined,
  defaultPortfolioKey: string | undefined,
): string[] =>
  selectedPortfoliosKeys?.length
    ? selectedPortfoliosKeys
    : portfoliosKeysPreferences?.length
      ? portfoliosKeysPreferences
      : defaultPortfolioKey
        ? [defaultPortfolioKey]
        : [];

export const getPortfolioBalances = (
  portfoliosBalances?: PortfoliosBalances,
  key?: string,
) => {
  if (!key) return [];

  return (
    (portfoliosBalances ?? []).find(
      (p) => p.keys.length === 1 && p.keys.at(0) === key,
    )?.balances ?? []
  );
};

export type InvestmentViewTypeI18n = Record<InvestmentViewType, string>;
export type CalculationBaseTypeI18n = Record<CalculationBaseType, string>;
export type SplitOptionTypeI18n = Record<SplitOptionType, string>;
export type PortfolioViewTypeI18n = Record<PortfolioViewType, string>;

export const investmentViewType18n = (): InvestmentViewTypeI18n => ({
  [InvestmentViewType.All]: $localize`All`,
  [InvestmentViewType.Active]: $localize`Active`,
});

export const calculationBaseTypeI18n = (): CalculationBaseTypeI18n => ({
  [CalculationBaseType.Gross]: $localize`Gross`,
  [CalculationBaseType.Net]: $localize`Net`,
  [CalculationBaseType.Capitalized]: $localize`Capitalized`,
});

export const splitOptionsI18n = (): SplitOptionTypeI18n => ({
  [SplitOptionType.Merged]: $localize`Merge positions`,
  [SplitOptionType.Separated]: $localize`Split positions`,
});

export const portfolioViewTypeI18n = (): PortfolioViewTypeI18n => ({
  [PortfolioViewType.TotalReturn]: $localize`Total return (recommended)`,
  [PortfolioViewType.UnrealisedPL]: $localize`Unrealised P&L`,
  [PortfolioViewType.TWR]: $localize`Time-weighted return (TWR)`,
  [PortfolioViewType.INTRADAY]: $localize`Daily P&L`,
});

export const getPortfolioName = (
  portfolioName?: string,
  noPortfolios?: boolean,
): string =>
  noPortfolios
    ? $localize`No portfolio`
    : portfolioName ?? $localize`Portfolios`;
