import { AppInfo } from '@capacitor/app';
import {
  AppUpdateInfo,
  FlexibleUpdateInstallStatus,
  FlexibleUpdateState,
} from '@capawesome/capacitor-app-update';
import { createReducer, on } from '@ngrx/store';
import {
  CapacitorAppActions,
  CapacitorAppEffectsActions,
  CapacitorAppUpdateActions,
} from './capacitor-app.actions';

export const name = 'capacitorApp';

export interface CapacitorAppState {
  loading: boolean;
  error: unknown | undefined;
  appInfo: AppInfo | undefined;
  updateInfo: AppUpdateInfo | undefined;
  flexibleUpdateState: FlexibleUpdateState;
  isActive: boolean | undefined;
  latestStateUpdate: number | undefined;
  platforms: string[];
}

export interface CapacitorAppPartialState {
  [name]: CapacitorAppState;
}

export const initialState: CapacitorAppState = {
  loading: false,
  error: undefined,
  appInfo: undefined,
  updateInfo: undefined,
  isActive: undefined,
  latestStateUpdate: undefined,
  platforms: [],
  flexibleUpdateState: {
    installStatus: FlexibleUpdateInstallStatus.UNKNOWN,
    bytesDownloaded: undefined,
    totalBytesToDownload: undefined,
  },
};

export const reducer = createReducer(
  initialState,
  on(CapacitorAppEffectsActions.onInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapacitorAppActions.getAppInfoSuccess, (state, { appInfo }) => ({
    ...state,
    appInfo,
    loading: false,
  })),
  on(CapacitorAppActions.getAppInfoFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(
    CapacitorAppUpdateActions.checkForUpdateSuccess,
    (state, { updateInfo }) => ({
      ...state,
      updateInfo,
      loading: false,
    }),
  ),
  on(CapacitorAppUpdateActions.checkForUpdateFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(
    CapacitorAppUpdateActions.flexibleUpdateStatusUpdate,
    (state, { flexibleUpdateState }) => ({
      ...state,
      flexibleUpdateState,
    }),
  ),
  on(CapacitorAppActions.appStateChange, (state, { isActive }) => ({
    ...state,
    isActive,
    loading: false,
  })),
  on(
    CapacitorAppEffectsActions.updateTimestamp,
    (state, { latestStateUpdate }) => ({
      ...state,
      latestStateUpdate,
      loading: false,
    }),
  ),
  on(CapacitorAppEffectsActions.updatePlatforms, (state, { platforms }) => ({
    ...state,
    platforms,
    loading: false,
  })),
);
