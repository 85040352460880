import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  FavouritesApiActions,
  FavouritesListActions,
} from './favourites.actions';
import { FavouritesEntity } from './favourites.models';

export const FAVOURITES_FEATURE_KEY = 'favourites';

export interface FavouritesState extends EntityState<FavouritesEntity> {
  selectedId?: string | number; // which Favourites record has been selected
  loaded: boolean; // has the Favourites list been loaded
  error?: string | null; // last known error (if any)
}

export interface FavouritesPartialState {
  readonly [FAVOURITES_FEATURE_KEY]: FavouritesState;
}

export const favouritesAdapter: EntityAdapter<FavouritesEntity> =
  createEntityAdapter<FavouritesEntity>();

export const initialFavouritesState: FavouritesState =
  favouritesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialFavouritesState,
  on(FavouritesListActions.init, (state) => ({
    ...state,
    error: null,
  })),
  on(FavouritesApiActions.loadFavouritesSuccess, (state, { favourites }) =>
    favouritesAdapter.setAll(favourites, { ...state, loaded: true }),
  ),
  on(FavouritesApiActions.loadFavouriteSuccess, (state, { favourite }) =>
    favouritesAdapter.setOne(favourite, state),
  ),
  on(
    FavouritesApiActions.loadFavouritesFailure,
    FavouritesApiActions.loadFavouriteFailure,
    (state, { error }) => ({
      ...state,
      error,
    }),
  ),
  on(FavouritesApiActions.loadFavouritesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    FavouritesApiActions.addUserSuccess,
    FavouritesApiActions.addInstrumentSuccess,
    (state, { favourites }) => favouritesAdapter.addMany(favourites, state),
  ),
  on(
    FavouritesApiActions.removeUserSuccess,
    FavouritesApiActions.removeInstrumentSuccess,
    (state, { favourites }) =>
      favouritesAdapter.removeMany(
        favourites.map((f) => f.id),
        state,
      ),
  ),
);

export function favouritesReducer(
  state: FavouritesState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
