import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CalculationBaseType } from '@yeekatee/client-api-angular';
import {
  PortfolioActions,
  PortfolioInsightsActions,
  PortfolioItemActions,
  PortfolioOptionsActions,
  PortfolioPerfRisksActions,
  PortfoliosAddTransactionActions,
  PortfoliosApiActions,
  PortfoliosListActions,
  PortfoliosReportsActions,
  PortfoliosSettingsActions,
  PortfoliosWebWorkersActions,
  ReportsEffectsActions,
  TransactionDetailsActions,
} from '../portfolios.actions';
import {
  DEFAULT_REPORT_PERIOD,
  DEFAULT_REPORT_PORTFOLIOS_KEYS,
  getLegacyReportsIds,
  InvestmentViewType,
  mergeReportsTimeSeries,
  PortfolioReportEntity,
  PortfolioViewType,
  removeNonExistingPortfolios,
  ReportPreferences,
} from '../portfolios.models';

export const name = 'portfolioReports';

export type State = EntityState<PortfolioReportEntity> &
  ReportPreferences & {
    loading: boolean;
    error: boolean;

    nTopItems: number;
  };

export interface PartialState {
  [name]: State;
}

export const adapter = createEntityAdapter<PortfolioReportEntity>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: false,

  portfolioKeys: undefined,
  startDate: undefined,
  endDate: undefined,
  period: DEFAULT_REPORT_PERIOD,
  currency: undefined,
  splitItems: false,
  calculationBaseType: CalculationBaseType.Gross,
  investmentViewType: InvestmentViewType.Active,
  portfolioViewType: PortfolioViewType.TotalReturn,
  geekView: false,
  nTopItems: 10,
});

export const reducer = createReducer(
  initialState,
  on(
    PortfolioActions.loadPortfolioReport,
    PortfolioActions.refreshPortfolioReport,
    PortfoliosReportsActions.setPortfoliosReport,
    PortfoliosReportsActions.setChartPeriod,
    PortfoliosSettingsActions.recalculatePortfolioFigures,
    PortfolioPerfRisksActions.loadPortfolioItemsReport,
    PortfoliosAddTransactionActions.addTradeTransaction,
    PortfoliosAddTransactionActions.addManualTransaction,
    TransactionDetailsActions.deleteTransaction,
    PortfoliosAddTransactionActions.setPortfolio,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PortfoliosApiActions.loadPortfoliosReportSuccess,
    PortfoliosApiActions.loadPortfolioReportSuccess,
    (state, { report }): State =>
      adapter.upsertOne(report, {
        ...state,
        loading: false,
        error: false,
      }),
  ),
  on(
    PortfoliosApiActions.loadPortfolioItemsSuccess,
    (state, { report }): State => {
      const currentReport = state.entities[report.id];
      if (!currentReport) return state;

      return adapter.upsertOne(mergeReportsTimeSeries(currentReport, report), {
        ...state,
        loading: false,
        error: false,
      });
    },
  ),
  on(
    PortfoliosWebWorkersActions.valuatePortfolioSuccess,
    (state, { report }): State => {
      const currentReport = state.entities[report.id];
      if (!currentReport) return state;

      return adapter.upsertOne(
        mergeReportsTimeSeries(report, currentReport),
        state,
      );
    },
  ),
  on(
    PortfoliosApiActions.loadPortfoliosReportFailure,
    PortfoliosApiActions.loadPortfolioReportFailure,
    PortfoliosApiActions.loadPortfolioItemsFailure,
    (state): State => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
  on(
    PortfoliosApiActions.recalculateUserPortfolioFiguresFailure,
    PortfoliosApiActions.addVirtualTransactionFailure,
    PortfoliosApiActions.addSimulationTransactionFailure,
    PortfoliosApiActions.deleteSimulationTransactionFailure,
    ReportsEffectsActions.noPortfolios,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    PortfoliosApiActions.addVirtualTransactionSuccess,
    PortfoliosApiActions.addSimulationTransactionSuccess,
    PortfoliosApiActions.deleteSimulationTransactionSuccess,
    PortfoliosApiActions.deleteAllTransactionsSuccess,
    (state, { portfolioKey, userId }): State =>
      adapter.removeMany(
        getLegacyReportsIds(state.entities, userId, portfolioKey),
        state,
      ),
  ),
  on(
    PortfoliosListActions.deletePortfolio,
    (state, { portfolio }): State =>
      portfolio.key && portfolio.userId
        ? adapter.removeMany(
            getLegacyReportsIds(
              state.entities,
              portfolio.userId,
              portfolio.key,
            ),
            state,
          )
        : state,
  ),

  // Report preferences
  on(
    PortfoliosApiActions.loadReportPreferencesSuccess,
    (state, { type, ...preferences }): State => ({
      ...state,
      ...preferences,
      portfolioKeys:
        preferences.portfolioKeys ?? DEFAULT_REPORT_PORTFOLIOS_KEYS,
      period:
        preferences.startDate || preferences.endDate
          ? undefined
          : preferences.period ?? DEFAULT_REPORT_PERIOD,
    }),
  ),
  on(
    PortfoliosApiActions.loadReportPreferencesFailure,
    (state): State => ({
      ...state,
      portfolioKeys: DEFAULT_REPORT_PORTFOLIOS_KEYS,
    }),
  ),
  on(
    PortfoliosApiActions.loadUserPropertiesSuccess,
    (state, { portfolios }): State => ({
      ...state,
      portfolioKeys: removeNonExistingPortfolios(
        portfolios,
        state.portfolioKeys,
      ),
    }),
  ),
  on(
    PortfolioActions.setDates,
    (state, { startDate, endDate }): State => ({
      ...state,
      startDate,
      endDate,
      period: undefined,
    }),
  ),
  on(
    PortfolioActions.resetPeriodToDefault,
    (state): State => ({
      ...state,
      period: DEFAULT_REPORT_PERIOD,
      startDate: undefined,
      endDate: undefined,
    }),
  ),
  on(
    PortfolioActions.setChartPeriod,
    PortfolioOptionsActions.setChartPeriod,
    (state, { period }): State => ({
      ...state,
      period,
      startDate: undefined,
      endDate: undefined,
    }),
  ),
  on(
    PortfolioOptionsActions.setReportCurrency,
    (state, { currency }): State => ({
      ...state,
      currency,
    }),
  ),
  on(
    PortfolioActions.setPortfolios,
    (state, { portfolioKeys }): State => ({
      ...state,
      portfolioKeys,
    }),
  ),
  on(
    PortfolioOptionsActions.setIncludeSplitItems,
    (state, { splitItems }): State => ({
      ...state,
      splitItems,
    }),
  ),
  on(
    PortfolioOptionsActions.setCalculationBase,
    (state, { calculationBaseType }): State => ({
      ...state,
      calculationBaseType,
    }),
  ),
  on(
    PortfolioOptionsActions.setInvestmentViewType,
    (state, { investmentViewType }): State => ({
      ...state,
      investmentViewType,
    }),
  ),
  on(
    PortfolioInsightsActions.toggleGeekView,
    PortfolioItemActions.toggleGeekView,
    (state): State => ({
      ...state,
      geekView: !state.geekView,
    }),
  ),
  on(
    PortfolioOptionsActions.setPortfolioView,
    (state, { portfolioViewType }): State => ({
      ...state,
      portfolioViewType,
    }),
  ),
);
