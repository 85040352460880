import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  PortfoliosApiActions,
  TransactionsEffectsActions,
  TransactionsListActions,
} from '../portfolios.actions';
import {
  mergeTransactionsReports,
  TransactionsReportEntity,
} from '../portfolios.models';

export const name = 'transactions';

export interface State extends EntityState<TransactionsReportEntity> {
  loading: boolean;
  error: boolean;
}

export interface PartialState {
  [name]: State;
}

export const adapter = createEntityAdapter<TransactionsReportEntity>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: false,
});

export const reducer = createReducer(
  initialState,
  on(
    TransactionsListActions.init,
    TransactionsEffectsActions.loadTransactionsReport,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PortfoliosApiActions.loadUserTransactionsSuccess,
    (state, { report }): State =>
      adapter.upsertOne(report, {
        ...state,
        loading: false,
      }),
  ),
  on(
    PortfoliosApiActions.loadMoreUserTransactionsSuccess,
    (state, { report }): State =>
      adapter.upsertOne(mergeTransactionsReports(state.entities, report), {
        ...state,
        loading: false,
      }),
  ),
  on(
    PortfoliosApiActions.loadUserTransactionsFailure,
    PortfoliosApiActions.loadMoreUserTransactionsFailure,
    (state): State => ({
      ...state,
      loading: false,
      error: true,
    }),
  ),
);
