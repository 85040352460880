import { AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { createFeature, createSelector } from '@ngrx/store';
import { name, reducer } from './capacitor-app.reducer';

export const capacitorAppFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({
    selectAppInfo,
    selectLatestStateUpdate,
    selectUpdateInfo,
  }) => ({
    selectAppVersion: createSelector(
      selectAppInfo,
      (info) => info?.version ?? '0.0', // TODO: this should really be set...
    ),
    selectLatestStateUpdateISO: createSelector(
      selectLatestStateUpdate,
      (latestStateUpdate) =>
        latestStateUpdate ? new Date(latestStateUpdate).toISOString() : '',
    ),
    selectUpdateAvailable: createSelector(
      selectUpdateInfo,
      (updateInfo) =>
        updateInfo?.updateAvailability ===
        AppUpdateAvailability.UPDATE_AVAILABLE,
    ),
  }),
});
