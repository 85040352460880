<ion-button
  fill="clear"
  size="small"
  shape="round"
  class="align-middle round"
  (click)="modal.present()"
>
  <ion-icon slot="icon-only" size="small" name="bulb-outline" />
</ion-button>

<ion-modal
  #modal
  [initialBreakpoint]="initialBreakpoint"
  [breakpoints]="breakpoints"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title i18n>Did you know?</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ng-content />
    </ion-content>
  </ng-template>
</ion-modal>
