import { Chart, Plugin } from 'chart.js';
import { Subject } from 'rxjs';
import { getIonColor } from './chart-aux-functions';

export const VerticalHoverLine: Plugin<'line' | 'bar'> = {
  id: 'verticalHoverLine',
  beforeTooltipDraw: (chart: Chart) => {
    if (chart.tooltip) {
      const x = chart.tooltip.caretX;
      const yAxis = chart.scales['y'];
      const ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 2;
      ctx.setLineDash([1, 4]);
      ctx.strokeStyle = getIonColor('light-contrast');
      ctx.stroke();
      ctx.restore();
    }
  },
};

export const resetToolTip = <T>(
  subject?: Subject<T | undefined>,
): Plugin<'line' | 'bar'> => ({
  id: 'resetToolTip',
  afterEvent: (chart: Chart, args: { event: { type: string } }, opts: any) => {
    if (['mouseup', 'mouseout'].includes(args.event.type)) {
      chart.setActiveElements([]);
      chart.tooltip?.setActiveElements([], { x: 0, y: 0 });
      chart.update();
      if (subject) subject.next(undefined);
    }
  },
});
