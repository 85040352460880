export interface RootNode {
  type: 'root';
  content: ParagraphNode[];
}

export interface ParagraphNode {
  type: 'paragraph';
  content: (LinkNode | TagNode | TextNode)[];
}

export interface LinkNode {
  type: 'link';
  content: TextNode;
  link?: string;
}

export interface TagNode {
  type: 'tag';
  content: TextNode;
  link?: string;
}

export interface TextNode {
  type: 'text';
  content: string;
}

export type NoteContentTree = RootNode;

// Keeps backwards compatibility for notes with string content
export function mapNoteContentTree(content: string): NoteContentTree {
  let parsedContent: RootNode | undefined;
  try {
    parsedContent = JSON.parse(content);
  } catch (e) {
    // Do nothing
  }

  return parsedContent
    ? parsedContent
    : {
        type: 'root',
        content: [],
      };
}

export function formatNoteContent(content: string): string {
  const tree = mapNoteContentTree(content);

  return tree.content
    .map((p) =>
      p.content
        .map((e) => (e.type === 'text' ? e.content : e.content.content))
        .join(''),
    )
    .join('\n');
}
