import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  SelectCustomEvent,
} from '@ionic/angular/standalone';

@Component({
  selector: 'yeekatee-currency-selector',
  standalone: true,
  imports: [IonItem, IonLabel, IonList, IonRadio, IonRadioGroup],
  templateUrl: './currency-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencySelectorComponent {
  @Input() currencies?: string[];
  @Input() defaultCurrency?: string;

  @Output() selected = new EventEmitter<string>();

  onSelect(event: Event) {
    this.selected.emit((event as SelectCustomEvent).detail.value);
  }
}
