import { Action, createReducer, on } from '@ngrx/store';
import * as LocalesActions from './locales.actions';
import { AvailableLocale } from './locales.models';

export const LOCALES_FEATURE_KEY = 'locales';

export interface LocalesState {
  /**
   * The locale that was loaded when initializing the app,
   * either from the device or from the user's preferences.
   */
  loadedLocale?: AvailableLocale;

  /**
   * Whether the user is using the locale coming from the device.
   */
  useDeviceLocale?: boolean;

  /**
   * Whether the locale successfully loaded.
   */
  loaded: boolean;

  /**
   * An optional error coming from the initializer.
   */
  error?: unknown;
}

export interface LocalesPartialState {
  readonly [LOCALES_FEATURE_KEY]: LocalesState;
}

export const initialLocalesState: LocalesState = {
  loaded: false,
};

const reducer = createReducer(
  initialLocalesState,
  on(
    LocalesActions.loadLocaleSuccess,
    (state, { loadedLocale, useDeviceLocale }) => ({
      ...state,
      loadedLocale,
      useDeviceLocale,
      loaded: true,
    }),
  ),
  on(LocalesActions.loadLocaleFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);

export function localesReducer(
  state: LocalesState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
