<ion-modal [trigger]="modalId">
  <ng-template>
    <ion-datetime
      [value]="date"
      presentation="date"
      size="fixed"
      [min]="min"
      [max]="max"
      [locale]="locale"
      [firstDayOfWeek]="1"
      [showDefaultButtons]="true"
      i18n-doneText
      doneText="Done"
      i18n-cancelText
      cancelText="Cancel"
      (ionChange)="onDateConfirmed($event)"
      yeekateeFirstDayWeek
    />
  </ng-template>
</ion-modal>

<ion-button fill="clear" [slot]="slot" [disabled]="disabled" [id]="modalId">
  {{ date | date }}
  @if (!disabled) {
    <ion-icon name="calendar-outline" slot="end" />
  }
</ion-button>
