import { z } from 'zod';

/**
 * A localized string.
 * Must have a value for each supported language.
 */
export const localizedString = z.object({
  de: z.string(),
  en: z.string(),
  es: z.string(),
  fr: z.string(),
  it: z.string(),
});

export type LocalizedString = z.infer<typeof localizedString>;
export type LocalizedStringKey = keyof LocalizedString;

/**
 * Returns the localized string for the given locale, or the English string if
 * the locale is not known or not found.
 *
 * @param localizedString
 * @param locale
 */
export const getLocalizedString = (
  localizedString?: LocalizedString,
  locale?: string,
): string | undefined =>
  (locale ? localizedString?.[locale as LocalizedStringKey] : undefined) ??
  localizedString?.en;
