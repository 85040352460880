import { createSelector } from '@ngrx/store';
import {
  ActivitiesState,
  selectActivitiesState,
} from '../activity-streams.reducer';
import * as fromActivities from './activities.reducer';

const { selectAll, selectEntities } = fromActivities.adapter.getSelectors();

export const getActivitiesState = createSelector(
  selectActivitiesState,
  (state: ActivitiesState) => state[fromActivities.FEATURE_KEY],
);

export const getAllActivities = createSelector(
  getActivitiesState,
  (state: fromActivities.State) => selectAll(state),
);

export const getActivitiesEntities = createSelector(
  getActivitiesState,
  (state: fromActivities.State) => selectEntities(state),
);

export const getActivitiesLoading = createSelector(
  getActivitiesState,
  (state: fromActivities.State) => state.loading,
);

export const getChatsLoading = createSelector(
  getActivitiesState,
  (state: fromActivities.State) => state.chatsLoading,
);
