import { DomainName } from '@yeekatee/shared-cloud-constructs';

export const YEEKATEE_DOMAIN = 'yeekatee.com';

export const CDN_RECORD_NAME = 'cdn';
export const GQL_RECORD_NAME = 'gql';
export const RTHUB_RECORD_NAME = 'rthub';
export const AUTH_RECORD_NAME = 'auth';
export const WEB_RECORD_NAME = 'www';
export const WEB_APP_RECORD_NAME = 'app';
export const API_RECORD_NAME = 'api';
export const SMART_APP_RECORD_NAME = 'smart-app';
export const API_DOCS_RECORD_NAME = 'docs';
export const REPORT_APP_RECORD_NAME = 'report-app';

export const MVP_ACCOUNT_PREFIX = 'mvp';
export const SAAS_ACCOUNT_PREFIX = 'saas';

export type DomainNames = {
  cdn: DomainName;
  gql: DomainName;
  rthub: DomainName;
  auth: DomainName;
  web: DomainName[];
  webapp: DomainName;
  api: DomainName;
  smartapp: DomainName;
  apidocs: DomainName;
  reportapp: DomainName;
};

export const accountZoneName = (
  zoneName: string,
  account?: string,
  accountPrefix: string = MVP_ACCOUNT_PREFIX,
): string => (account ? `${accountPrefix}-${account}.` : '') + zoneName;

export const domainNames = (
  zoneName: string,
  account?: string,
  accountPrefix?: string,
): DomainNames => {
  const domain = accountZoneName(zoneName, account, accountPrefix);
  return {
    cdn: {
      domainName: `${CDN_RECORD_NAME}.${domain}`,
      recordName: CDN_RECORD_NAME,
    },
    gql: {
      domainName: `${GQL_RECORD_NAME}.${domain}`,
      recordName: GQL_RECORD_NAME,
    },
    rthub: {
      domainName: `${RTHUB_RECORD_NAME}.${domain}`,
      recordName: RTHUB_RECORD_NAME,
    },
    auth: {
      domainName: `${AUTH_RECORD_NAME}.${domain}`,
      recordName: AUTH_RECORD_NAME,
    },
    /**
     * Unfortunately, for web and webapp we need to use the domain name as record name
     * due to a legacy code issue that used both initially. If we tried to deploy them
     * right now, AWS would assume that we're trying to create the same A record twice
     * and fail. If we wanted to do it right, we would need first to remove the existing
     * three A records and redeploy them with the right configuration.
     *
     * Keep in mind that this deployment works because the hosted zone is deployed in
     * the stateful stack, and therefore the domain is resolved to the corresponding
     * account ID. If we were to deploy the hosted zone and the static site in the same
     * stack, we would get an error when trying to create the A record due to an
     * unsolved token used for the account ID.
     */
    web: [
      {
        domainName: domain,
        recordName: domain,
      },
      {
        domainName: `${WEB_RECORD_NAME}.${domain}`,
        recordName: `${WEB_RECORD_NAME}.${domain}`,
      },
    ],
    webapp: {
      domainName: `${WEB_APP_RECORD_NAME}.${domain}`,
      recordName: `${WEB_APP_RECORD_NAME}.${domain}`,
    },
    api: {
      domainName: `${API_RECORD_NAME}.${domain}`,
      recordName: API_RECORD_NAME,
    },
    smartapp: {
      domainName: `${SMART_APP_RECORD_NAME}.${domain}`,
      recordName: SMART_APP_RECORD_NAME,
    },
    apidocs: {
      domainName: `${API_DOCS_RECORD_NAME}.${domain}`,
      recordName: API_DOCS_RECORD_NAME,
    },
    reportapp: {
      domainName: `${REPORT_APP_RECORD_NAME}.${domain}`,
      recordName: REPORT_APP_RECORD_NAME,
    },
  };
};
