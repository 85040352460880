<ion-chip
  [disabled]="disabled"
  [class.selected]="isSelected"
  (click)="!disabled && clicked.emit()"
>
  <ng-content />
  @if (mode === SelectionMode.Multiple && !disabled) {
    @if (isSelected) {
      <ion-icon color="primary-contrast" name="close-outline" />
    } @else {
      <ion-icon name="add-outline" />
    }
  }
</ion-chip>
