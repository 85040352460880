import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkify-string';

@Pipe({
  name: 'linkify',
  standalone: true,
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string): string {
    return value
      ? linkifyStr(value, {
          target: {
            url: '_blank',
          },
        })
      : value;
  }
}
