import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hostname',
  standalone: true,
})
export class HostnamePipe implements PipeTransform {
  transform(value: string): string {
    try {
      const url = new URL(value);
      return url.hostname.replace(/^www\./, '');
    } catch (e) {
      console.error(e);
      return value;
    }
  }
}
