import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IonButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/angular/standalone';
import {
  ChartNavButtonValue,
  ChartNavigation,
} from './chart-navigation.interface';

@Component({
  selector: 'yeekatee-chart-navigation',
  standalone: true,
  imports: [IonLabel, IonSegment, IonSegmentButton, IonButton, NgClass],
  templateUrl: './chart-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartNavigationComponent {
  @Input() navigationInput?: ChartNavigation;
  @Input() activeButton?: string = this.navigationInput?.[0]?.value;

  @Output() buttonChange = new EventEmitter<ChartNavButtonValue>();
}
