import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import katex from 'katex';

@Directive({
  selector: '[yeekateeKatex]',
  standalone: true,
})
export class KatexDirective implements OnChanges {
  @Input('yeekateeKatex') latexExpression?: string;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['latexExpression']?.currentValue) {
      this.renderLatex();
    }
  }

  private renderLatex() {
    if (this.latexExpression) {
      katex.render(this.latexExpression, this.el.nativeElement, {
        throwOnError: false, // To suppress rendering errors
      });
    }
  }
}
