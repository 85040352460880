import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ChatListActions = createActionGroup({
  source: 'Chat List',
  events: {
    Init: emptyProps(),
    Refresh: emptyProps(),
    'Chat Fab Clicked': emptyProps(),
  },
});

export const ChatEffectsActions = createActionGroup({
  source: 'Chat Effects',
  events: {
    'Load Chat Room': props<{ id: string }>(),
    'Empty Chat List': emptyProps(),
  },
});

export const ChatRoomActions = createActionGroup({
  source: 'Chat Room',
  events: {
    'Load More': emptyProps(),
  },
});
