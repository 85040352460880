<ion-title (click)="button && titleClicked.emit()">
  <div
    class="flex items-center overflow-hidden"
    [ngClass]="{ 'justify-center': isIos }"
  >
    <ion-label class="truncate">{{ title }}</ion-label>
    @if (button) {
      <div class="flex items-center">
        <ion-icon name="chevron-down-outline" />
      </div>
    }
  </div>
</ion-title>
