import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
} from '@angular/core';
import {
  DatetimeCustomEvent,
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonLabel,
  IonModal,
} from '@ionic/angular/standalone';
import { DateConst } from '@yeekatee/booking-util-definitions';
import { addIcons } from 'ionicons';
import { calendarOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { ulid } from 'ulid';
import { FirstDayWeekDirective } from '../directives/first-day-week.directive';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FirstDayWeekDirective,
    IonDatetime,
    IonModal,
    IonDatetimeButton,
    IonButton,
    IonLabel,
    DatePipe,
    IonIcon,
  ],
  selector: 'yeekatee-datetime-button',
  standalone: true,
  styleUrls: ['./datetime-button.component.css'],
  templateUrl: './datetime-button.component.html',
})
export class DatetimeButtonComponent {
  @Input() min?: string | null = DateConst.BookMin;
  @Input() max?: string | null = DateTime.now().toISODate();
  @Input() date?: string;
  @Input() slot?: string;
  @Input() disabled?: boolean = false;

  @Output() dateChange = new EventEmitter<string>();

  protected today = DateTime.now().toISODate();

  // Needed to have more than one on the same page
  protected readonly modalId = `modal-${ulid()}`;

  constructor(@Inject(LOCALE_ID) readonly locale: string) {
    addIcons({ calendarOutline });
  }

  protected onDateConfirmed(event: Event) {
    const selectedDate = (event as DatetimeCustomEvent).detail.value;
    if (!selectedDate || typeof selectedDate !== 'string') return;

    this.dateChange.emit(
      DateTime.fromISO(selectedDate).toISODate() ?? this.today,
    );
  }
}
