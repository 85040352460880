@if (isDetailView) {
  <yeekatee-note-card-skeleton [isDetailView]="true" />
  <ion-item color="light" lines="none">
    <ion-label>
      <ion-skeleton-text style="width: 20%" [animated]="true" />
    </ion-label>
  </ion-item>
}
@for (i of [].constructor(nCards); track i) {
  <yeekatee-note-card-skeleton [isDetailView]="isDetailView" />
}
