import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User, UserUpdateInput } from '@yeekatee/client-api-angular';
import { AvailableLocale } from '../locales';
import { errorProps } from '../utils';
import { UsersEntity } from './users.models';

const userProps = () => props<{ user: UsersEntity }>();
const usernameProps = () =>
  props<{
    user: Pick<UserUpdateInput, 'preferred_username'>;
  }>();
const visibilityProps = () =>
  props<{
    user: Pick<UserUpdateInput, 'visibility'>;
  }>();
const localeProps = () => props<{ locale: AvailableLocale }>();

export const UsersApiActions = createActionGroup({
  source: 'Users API',
  events: {
    'Load User Success': userProps(),
    'Load User Failure': errorProps(),

    'Find Username Success': props<{ available: boolean }>(),
    'Find Username Failure': errorProps(),

    'Update User Success': userProps(),
    'Update User Failure': errorProps(),

    'Update Locale Success': localeProps(),
    'Update User With Locale Success': userProps(),
    'Update Locale Failure': errorProps(),

    'Delete User Success': emptyProps(),
    'Delete User Failure': errorProps(),

    'Search Users Success': props<{ users: User[] }>(),
    'Search Users Failure': errorProps(),
  },
});

export const UsersOnboardingUsernameActions = createActionGroup({
  source: 'Onboarding Username',
  events: {
    'Update User': usernameProps(),
    'Sign Out': emptyProps(),
  },
});

export const UsersProfileActions = createActionGroup({
  source: 'User Profile',
  events: {
    'On Init': emptyProps(),
    'On Enter': emptyProps(),
    'On Refresh': emptyProps(),

    'Update User': userProps(),
    'Flag User': userProps(),

    'Add Favourite': props<{ userId: string }>(),
    'Remove Favourite': props<{ userId: string }>(),

    'Follow User': props<{ userId: string }>(),
    'Unfollow User': props<{ followId: string; userId: string }>(),
    'Accept Follow': props<{ followId: string }>(),
    'Reject Follow': props<{ followId: string }>(),
    'Revoke Follow': props<{ followId: string; userId: string }>(),
  },
});

export const UsersSettingsProfileActions = createActionGroup({
  source: 'Settings Profile',
  events: {
    'On Init': emptyProps(),
    'Update User': userProps(),
  },
});

export const UsersSettingsUsernameActions = createActionGroup({
  source: 'Settings Username',
  events: {
    'Update User': usernameProps(),
  },
});

export const UsersSettingsPrivacyActions = createActionGroup({
  source: 'Settings Privacy',
  events: {
    'On Init': emptyProps(),
    'Update User': visibilityProps(),
  },
});

export const UsersSettingsDeleteAccountActions = createActionGroup({
  source: 'Settings Delete Account',
  events: {
    'Delete User': emptyProps(),
  },
});

export const UsersEffectsActions = createActionGroup({
  source: 'Users Effects',
  events: {
    'Notify Error': errorProps(),
    'User Requires Locale': props<{ user: User }>(),
  },
});
