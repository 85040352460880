import { createSelector } from '@ngrx/store';
import { TimeSeriesPeriod } from '@yeekatee/client-api-angular';
import { getQuoteId } from '@yeekatee/shared-util-typesafe-dynamodb';
import { produce } from 'immer';
import { DateTime } from 'luxon';
import { ActivitiesSelectors } from '../activity-streams';
import { FavouritesSelectors } from '../favourites';
import {
  AssetEntity,
  computeFinancialCharts,
  CryptoEntity,
  DiscoverCategorySelectors,
  discoverInstrumentCategoryTitleI18n,
  DiscoverInstrumentCategoryType,
  EtfEntity,
  InstrumentsSelectors,
  isCryptoEntity,
  isEtfEntity,
  isStockEntity,
  isTradeable,
  numQuoteUps,
  QuotesSelectors,
  SortingCategoryKey,
  SortingType,
  StockEntity,
  updateQuotePercentageChange,
} from '../instruments';
import { SortingTypeI18n } from '../instruments/features/instruments.selectors';
import { MarketNewsSelectors } from '../market-news';
import { NavigationSelectors } from '../navigation';
import { userPortfoliosFeature } from '../portfolios';
import { ThemeSelectors } from '../theme';
import { UsersSelectors } from '../users';

export const selectInstrumentChart = createSelector(
  InstrumentsSelectors.selectTimeSeriesPeriod,
  InstrumentsSelectors.selectTimeSeriesChartData,
  InstrumentsSelectors.selectTimeSeriesLoading,
  ThemeSelectors.selectColors,
  (period, timeSeriesChartData, loadingChartData, colors) => {
    const timeSeries = timeSeriesChartData?.datasets?.[0]?.data;
    const format = [TimeSeriesPeriod.day, TimeSeriesPeriod.week].includes(
      period ?? TimeSeriesPeriod.year,
    )
      ? DateTime.DATETIME_MED
      : DateTime.DATE_MED;
    const startTime = timeSeries?.at(-1)?.datetime;
    const endTime = timeSeries?.at(0)?.datetime;
    const startDate = startTime
      ? DateTime.fromISO(startTime).toLocaleString(format)
      : undefined;
    const endDate = endTime
      ? DateTime.fromISO(endTime).toLocaleString(format)
      : undefined;

    return {
      period,
      timeSeriesChartData,
      loadingChartData,
      startDate,
      endDate,
      colors,
    };
  },
);

export const selectInstrumentVM = createSelector(
  selectInstrumentChart,
  InstrumentsSelectors.selectInstrument,
  InstrumentsSelectors.selectInstrumentLoading,
  InstrumentsSelectors.selectQuoteLoading,
  FavouritesSelectors.selectedInstrumentIsFavourite,
  InstrumentsSelectors.selectListing,
  userPortfoliosFeature.hasTradeableUserPortfolios,
  InstrumentsSelectors.selectInstrumentNextEarningsCall,
  (
    instrumentChart,
    instrument,
    loading,
    quoteLoading,
    isFavourite,
    listing,
    hasPortfolios,
    nextEarningsCall,
  ) => ({
    ...instrumentChart,
    instrument:
      instrumentChart.timeSeriesChartData &&
      instrumentChart.period &&
      instrument?.quote
        ? updateQuotePercentageChange(
            instrumentChart.timeSeriesChartData,
            instrumentChart.period,
            instrument,
          )
        : instrument,
    loading,
    quoteLoading,
    isFavourite,
    isTradeable: isTradeable(instrument),
    listing,
    symbol: listing?.symbol ?? undefined,
    mic: listing?.mic ?? undefined,
    hasPortfolios,
    previousClose: instrument?.quote?.previousClose ?? undefined,
    nextEarningsCall,
  }),
);

export const selectAssetFeedVM = createSelector(
  ActivitiesSelectors.selectActivitiesForSelectedAsset,
  ActivitiesSelectors.selectActivitiesForSelectedAssetLoaded,
  ActivitiesSelectors.getActivitiesLoading,
  UsersSelectors.selectAuthenticatedUser,
  (activities, loaded, loading, me) => ({
    activities,
    loaded,
    loading,
    myUserId: me?.id ?? undefined,
  }),
);

export const selectInstrumentStatsVM = createSelector(
  selectInstrumentChart,
  InstrumentsSelectors.selectInstrumentLoading,
  InstrumentsSelectors.selectInstrument,
  MarketNewsSelectors.selectLoadingSentimentChart,
  MarketNewsSelectors.selectSentimentChartData,
  MarketNewsSelectors.selectSentimentChartStatistics,
  (
    instrumentChart,
    loadingInstrument,
    instrument,
    loadingSentimentChart,
    sentimentChartData,
    sentimentChartStatistics,
  ) => ({
    ...instrumentChart,
    loadingInstrument,
    instrument: instrument?.id
      ? {
          ...instrument,
          financialCharts: computeFinancialCharts(
            instrument,
            instrumentChart.colors,
          ),
        }
      : undefined,
    loadingSentimentChart,
    sentimentChartData,
    sentimentChartStatistics,
    previousClose: instrument?.quote?.previousClose ?? undefined,
  }),
);

export interface InstrumentCategoryVM {
  instrumentsInCategory?: AssetEntity[];
  categoryTitleI18n?: string;
  showRank?: boolean;
  listView?: boolean;
  quoteUp?: number;
  sortingType: SortingType;
  sortingCategoryKey: SortingCategoryKey;
  sortingTypeI18n: SortingTypeI18n;
}

export const selectInstrumentsInCategory = createSelector(
  NavigationSelectors.selectInstrumentCategoryFromRoute,
  InstrumentsSelectors.selectInstrumentsEntities,
  QuotesSelectors.selectQuotesEntities,
  DiscoverCategorySelectors.selectDiscoverCategoryEntities,
  InstrumentsSelectors.selectListView,
  InstrumentsSelectors.selectSortingTypeI18n,
  InstrumentsSelectors.selectSortingCategoryType,
  (
    route,
    instruments,
    quotes,
    category,
    listView,
    sortingTypeI18n,
    sortingCategory,
  ) => {
    if (!route) return undefined;

    const { sortingType: currentSortingType, categoryKey: currentCategoryKey } =
      sortingCategory;

    return {
      instrumentsInCategory: category[route]?.instrumentIds.map((id, index) => {
        const instrument = instruments[id];
        let extendedCoreData;

        // Check if the instrument is a Stock or Etf and add extendedCoreData if present
        if (
          isStockEntity(instrument) ||
          isEtfEntity(instrument) ||
          isCryptoEntity(instrument)
        ) {
          extendedCoreData = instrument.extendedCoreData;
        }

        return {
          ...instrument,
          id,
          quote:
            quotes[
              getQuoteId(id ?? '', instrument?.symbol ?? '', instrument?.mic)
            ],
          rank: index + 1,
          extendedCoreData, // Add extendedCoreData conditionally
        };
      }),
      categoryTitleI18n: discoverInstrumentCategoryTitleI18n(
        route as DiscoverInstrumentCategoryType,
      ),
      showRank: route === DiscoverInstrumentCategoryType.CRYPTOS,
      listView: listView,
      quoteUp: numQuoteUps(category[route]?.instrumentIds, instruments, quotes),
      sortingType: currentSortingType ?? SortingType.AtoZ,
      sortingCategoryKey: currentCategoryKey ?? undefined,
      sortingTypeI18n: sortingTypeI18n,
    } satisfies InstrumentCategoryVM;
  },
);

const selectInstrumentsInCategorySortingByGainers = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort(
        (a, b) => (b.quote?.percentChange ?? 0) - (a.quote?.percentChange ?? 0),
      );
    }),
);

const selectInstrumentsInCategorySortingByLosers = createSelector(
  selectInstrumentsInCategorySortingByGainers,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.reverse();
    }),
);

const selectInstrumentsInCategorySortingByNameAZ = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const nameA = a?.name?.toLowerCase() ?? '';
        const nameB = b?.name?.toLowerCase() ?? '';
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }),
);

const selectInstrumentsInCategorySortingByNameZA = createSelector(
  selectInstrumentsInCategorySortingByNameAZ,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.reverse();
    }),
);

const selectInstrumentsInCategorySortingByDividendYield = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA =
          aa?.extendedCoreData?.trailingAnnualDividendRate ??
          Number.NEGATIVE_INFINITY;
        const rB =
          bb?.extendedCoreData?.trailingAnnualDividendRate ??
          Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByROE = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA = aa?.extendedCoreData?.roe ?? Number.NEGATIVE_INFINITY;
        const rB = bb?.extendedCoreData?.roe ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByROA = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA = aa?.extendedCoreData?.roa ?? Number.NEGATIVE_INFINITY;
        const rB = bb?.extendedCoreData?.roa ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByOperatingMargin = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA =
          aa?.extendedCoreData?.operatingMargin ?? Number.NEGATIVE_INFINITY;
        const rB =
          bb?.extendedCoreData?.operatingMargin ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByProfitMargin = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA =
          aa?.extendedCoreData?.profitMargin ?? Number.NEGATIVE_INFINITY;
        const rB =
          bb?.extendedCoreData?.profitMargin ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByPriceSalesRatio = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA =
          aa?.extendedCoreData?.priceToSales ?? Number.NEGATIVE_INFINITY;
        const rB =
          bb?.extendedCoreData?.priceToSales ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByRank = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as CryptoEntity;
        const bb = b as CryptoEntity;
        const rA = aa?.extendedCoreData?.rank ?? Number.POSITIVE_INFINITY;
        const rB = bb?.extendedCoreData?.rank ?? Number.POSITIVE_INFINITY;
        return rA - rB;
      });
    }),
);

const selectInstrumentsInCategorySortingByMarketCap = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity | CryptoEntity;
        const bb = b as StockEntity | CryptoEntity;
        const rA = aa?.extendedCoreData?.marketCap ?? Number.NEGATIVE_INFINITY;
        const rB = bb?.extendedCoreData?.marketCap ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByTrailingPE = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA = aa?.extendedCoreData?.trailingPe ?? Number.NEGATIVE_INFINITY;
        const rB = bb?.extendedCoreData?.trailingPe ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByBeta = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as StockEntity;
        const bb = b as StockEntity;
        const rA = aa?.extendedCoreData?.beta ?? Number.NEGATIVE_INFINITY;
        const rB = bb?.extendedCoreData?.beta ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByFundSize = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as EtfEntity;
        const bb = b as EtfEntity;
        const rA = aa?.extendedCoreData?.fundSize ?? Number.NEGATIVE_INFINITY;
        const rB = bb?.extendedCoreData?.fundSize ?? Number.NEGATIVE_INFINITY;
        return rB - rA;
      });
    }),
);

const selectInstrumentsInCategorySortingByETFTer = createSelector(
  selectInstrumentsInCategory,
  (categories) =>
    produce(categories, (draft) => {
      draft?.instrumentsInCategory?.sort((a, b) => {
        const aa = a as EtfEntity;
        const bb = b as EtfEntity;
        const rA = aa?.extendedCoreData?.ter ?? Number.POSITIVE_INFINITY;
        const rB = bb?.extendedCoreData?.ter ?? Number.POSITIVE_INFINITY;
        return rA - rB;
      });
    }),
);

export const selectInstrumentsInCategorySorting = createSelector(
  selectInstrumentsInCategory,
  selectInstrumentsInCategorySortingByGainers,
  selectInstrumentsInCategorySortingByLosers,
  selectInstrumentsInCategorySortingByNameAZ,
  selectInstrumentsInCategorySortingByNameZA,
  selectInstrumentsInCategorySortingByDividendYield,
  selectInstrumentsInCategorySortingByROE,
  selectInstrumentsInCategorySortingByROA,
  selectInstrumentsInCategorySortingByOperatingMargin,
  selectInstrumentsInCategorySortingByProfitMargin,
  selectInstrumentsInCategorySortingByPriceSalesRatio,
  selectInstrumentsInCategorySortingByBeta,
  selectInstrumentsInCategorySortingByTrailingPE,
  selectInstrumentsInCategorySortingByMarketCap,
  selectInstrumentsInCategorySortingByETFTer,
  selectInstrumentsInCategorySortingByFundSize,
  selectInstrumentsInCategorySortingByRank,
  (
    categories,
    sortingByGainers,
    sortingByLosers,
    sortingByNameAZ,
    sortingByNameZA,
    sortingByDividendRate,
    sortingByROE,
    sortingByROA,
    sortingByOpMargin,
    sortingByProfitMargin,
    sortingByPriceSalesRatio,
    sortingByBeta,
    sortingByTrailingPE,
    sortingByMarketCap,
    sortingByETFTer,
    sortingByFundSize,
    sortingByRank,
  ) => {
    switch (categories?.sortingType) {
      case SortingType.GainersFirst:
        return sortingByGainers;
      case SortingType.LosersFirst:
        return sortingByLosers;
      case SortingType.AtoZ:
        return sortingByNameAZ;
      case SortingType.ZtoA:
        return sortingByNameZA;
      case SortingType.DividendYield:
        return sortingByDividendRate;
      case SortingType.ROA:
        return sortingByROA;
      case SortingType.ROE:
        return sortingByROE;
      case SortingType.OPERATING_MARGIN:
        return sortingByOpMargin;
      case SortingType.PROFIT_MARGIN:
        return sortingByProfitMargin;
      case SortingType.PRICE_TO_SALES:
        return sortingByPriceSalesRatio;
      case SortingType.BETA:
        return sortingByBeta;
      case SortingType.TRAILING_PE:
        return sortingByTrailingPE;
      case SortingType.MARKET_CAP:
        return sortingByMarketCap;
      case SortingType.LOWEST_ETF_TER:
        return sortingByETFTer;
      case SortingType.FUND_SIZE:
        return sortingByFundSize;
      case SortingType.RANK:
        return sortingByRank;
      default:
        return categories;
    }
  },
);
