import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  MarketNewsIdentifier,
  MarketNewsOrderingType,
  MarketNewsSentimentChart,
} from '@yeekatee/client-api-angular';
import { errorProps } from '../utils';
import { MarketNewsStoryEntity } from './market-news.models';

export const MarketNewsTimelineActions = createActionGroup({
  source: 'Market News Timeline',
  events: {
    'Scroll Bottom': emptyProps(),
  },
});

export const MarketNewsViewActions = createActionGroup({
  source: 'Market News View',
  events: {
    Init: emptyProps(),
    Enter: emptyProps(),
    'Scroll Bottom': emptyProps(),
    Refresh: emptyProps(),
    'Change Ordering Type': props<{
      id: string;
      orderingType: MarketNewsOrderingType;
    }>(),
  },
});

export const MarketNewsApiActions = createActionGroup({
  source: 'Market News API',
  events: {
    'Load Market News Success': props<{
      id: string;
      stories: MarketNewsStoryEntity[];
      nextToken?: string;
    }>(),
    'Load Market News Failure': errorProps(),

    'Load More Market News Success': props<{
      id: string;
      stories: MarketNewsStoryEntity[];
      nextToken?: string;
    }>(),
    'Load More Market News Failure': errorProps(),

    'Load Market News Timeline Success': props<{
      identifiers: MarketNewsIdentifier[];
      stories: MarketNewsStoryEntity[];
    }>(),
    'Load Market News Timeline Failure': errorProps(),

    'Load More Market News Timeline Success': props<{
      identifiers: MarketNewsIdentifier[];
      stories: MarketNewsStoryEntity[];
    }>(),
    'Load More Market News Timeline Failure': errorProps(),

    'Load Market News Sentiment Chart Success': props<{
      id: string;
      sentimentChart: MarketNewsSentimentChart;
    }>(),
    'Load Market News Sentiment Chart Failure': errorProps(),
  },
});
