import { Action, createReducer, on } from '@ngrx/store';
import {
  DividendDateFilter,
  SecuritiesType,
} from '@yeekatee/client-api-angular';
import { DateTime } from 'luxon';
import {
  DiscoverViewActions,
  DividendCalendarOptionsViewActions,
  DividendCalendarViewActions,
  InstrumentsApiActions,
} from '../instruments.actions';
import {
  DividendCalendarEntity,
  DividendFilterOption,
  LocationFilter,
} from '../instruments.models';

export const FEATURE_KEY = 'dividends';

export interface State {
  dividendCalendar: DividendCalendarEntity[];
  loading: boolean;
  selectedDividendDateType: DividendDateFilter;
  selectedSecurityType?: SecuritiesType;
  selectedLocationFilter?: LocationFilter;
  selectedYear: number;
  selectedMonth: number;
  selectedDay?: number;
}

export const initialState: State = {
  dividendCalendar: [],
  loading: false,
  selectedDividendDateType: DividendDateFilter.ExDate,
  selectedSecurityType: undefined,
  selectedLocationFilter: undefined,
  selectedYear: new Date().getFullYear(),
  selectedMonth: new Date().getMonth() + 1,
  selectedDay: undefined,
};

const dividendsReducer = createReducer(
  initialState,
  on(DividendCalendarViewActions.initDividendCalendarPage, (state: State) => ({
    ...state,
    loading: true,
  })),
  on(
    InstrumentsApiActions.loadDividendCalendarSuccess,
    (state: State, { dividendCalendar }) => ({
      ...state,
      dividendCalendar,
      loading: false,
    }),
  ),
  on(
    DividendCalendarOptionsViewActions.setSortingDateType,
    (state: State, { dateTypeOption }) => ({
      ...state,
      selectedDividendDateType: dateTypeOption,
    }),
  ),
  on(
    DividendCalendarOptionsViewActions.setDividendFilterOption,
    (state: State, { filter }) => {
      if (filter === DividendFilterOption.ALL)
        return {
          ...state,
          selectedSecurityType: undefined,
          selectedLocationFilter: undefined,
        };
      if (filter === DividendFilterOption.ETF)
        return {
          ...state,
          selectedSecurityType: SecuritiesType.ETF,
          selectedLocationFilter: undefined,
        };
      if (filter === DividendFilterOption.STOCK)
        return {
          ...state,
          selectedSecurityType: SecuritiesType.Stock,
          selectedLocationFilter: undefined,
        };
      if (filter === DividendFilterOption.PORTFOLIO)
        return {
          ...state,
          selectedSecurityType: undefined,
          selectedLocationFilter: LocationFilter.PORTFOLIO,
        };
      if (filter === DividendFilterOption.FAVOURITES)
        return {
          ...state,
          selectedSecurityType: undefined,
          selectedLocationFilter: LocationFilter.FAVOURITES,
        };

      return state;
    },
  ),
  on(
    DiscoverViewActions.setDividendYearAndMonth,
    DividendCalendarViewActions.setYearAndMonth,
    (state: State, { year, month }) => ({
      ...state,
      selectedYear: year,
      selectedMonth: month,
      selectedDay: undefined,
      loading: true,
    }),
  ),
  on(
    DiscoverViewActions.selectDividendDate,
    DividendCalendarViewActions.selectDate,
    (state: State, { date }) => {
      const { year, month, day } = DateTime.fromISO(date);
      return {
        ...state,
        selectedYear: year,
        selectedMonth: month,
        selectedDay: day,
      };
    },
  ),
  on(DividendCalendarViewActions.resetFullMonth, (state: State) => ({
    ...state,
    selectedDay: undefined,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return dividendsReducer(state, action);
}
