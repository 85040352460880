import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '@yeekatee/client-api-angular';
import * as AuthSelectors from '../auth/auth.selectors';
import { NavigationSelectors } from '../navigation';
import { memoize } from '../utils';
import { State, USERS_FEATURE_KEY, usersAdapter } from './users.reducer';

// Lookup the 'Users' feature state managed by NgRx
export const getUsersState = createFeatureSelector<State>(USERS_FEATURE_KEY);

const { selectAll, selectEntities } = usersAdapter.getSelectors();

export const getAllUsers = createSelector(getUsersState, (state: State) =>
  selectAll(state),
);

export const getUsersEntities = createSelector(getUsersState, (state: State) =>
  selectEntities(state),
);

export const selectAuthenticatedUser = createSelector(
  getUsersEntities,
  AuthSelectors.selectUserId,
  (entities, id) => (id ? entities[id] : undefined),
);

export const selectUserId = createSelector(
  NavigationSelectors.selectUserIdFromRoute,
  AuthSelectors.selectUserId,
  (userId, authUserId) => userId ?? authUserId,
);

export const selectUser = createSelector(
  getUsersEntities,
  selectUserId,
  (users, userId) => (userId ? users[userId] : undefined),
);

export const selectUserById = memoize((id: string) =>
  createSelector(getUsersEntities, (entities) => entities[id]),
);

export const selectUserProfileButton = createSelector(
  selectAuthenticatedUser,
  (user): User | undefined =>
    user?.id
      ? {
          id: user.id,
          picture: user?.picture,
        }
      : undefined,
);

export const selectLoading = createSelector(
  getUsersState,
  (state: State) => state.loading,
);

export const isAuthUserSelected = createSelector(
  selectUserId,
  AuthSelectors.selectUserId,
  (userId, authUserId) => !userId || userId === authUserId,
);

export const isSelectedUserStored = createSelector(
  getUsersEntities,
  NavigationSelectors.selectUserIdFromRoute,
  (users, userId) => !!(userId ? users[userId] : undefined),
);
