import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSkeletonText,
  IonThumbnail,
} from '@ionic/angular/standalone';

@Component({
  selector: 'yeekatee-note-card-skeleton',
  templateUrl: './note-card-skeleton.component.html',
  standalone: true,
  imports: [
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonSkeletonText,
    IonThumbnail,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteCardSkeletonComponent {
  @Input() isDetailView = false;
}
