import { createSelector } from '@ngrx/store';
import {
  EarningsAnnouncementType,
  TimeSeriesPeriod,
} from '@yeekatee/client-api-angular';
import {
  getQuoteId,
  getTimeSeriesId,
} from '@yeekatee/shared-util-typesafe-dynamodb';
import { NavigationSelectors } from '../../navigation';
import { ThemeSelectors } from '../../theme';
import { memoize } from '../../utils';
import {
  EarningsCallEntity,
  SortingType,
  findListing,
  getAssetEntity,
  getSortingTypePerCategory,
  isStockEntity,
  mapTimeSeriesToChartData,
} from '../instruments.models';
import {
  InstrumentsState,
  selectInstrumentsState,
} from '../instruments.reducer';
import { FEATURE_KEY, State, instrumentsAdapter } from './instruments.reducer';
import { selectQuotesEntities } from './quotes.selectors';
import { selectTimeSeriesEntities } from './time-series.selectors';

// Lookup the 'Instruments' feature state managed by NgRx
export const instrumentsSliceSelector = createSelector(
  selectInstrumentsState,
  (state: InstrumentsState) => state[FEATURE_KEY],
);

const { selectEntities } = instrumentsAdapter.getSelectors();

export const selectInstrumentsEntities = createSelector(
  instrumentsSliceSelector,
  selectEntities,
);

export const selectInstrumentId = createSelector(
  NavigationSelectors.selectInstrumentIdFromRoute,
  (id) => id,
);

export const selectInstrumentEntity = createSelector(
  selectInstrumentsEntities,
  selectInstrumentId,
  (entities, id) => entities[id ?? ''],
);

export const selectListing = createSelector(
  selectInstrumentEntity,
  NavigationSelectors.selectInstrumentSymbolFromRoute,
  NavigationSelectors.selectInstrumentMicFromRoute,
  (instrument, symbol, mic) => findListing(instrument, symbol, mic),
);

export const selectYearTimeSeries = createSelector(
  selectTimeSeriesEntities,
  selectInstrumentId,
  selectListing,
  (timeSeries, id, listing) =>
    id && listing && listing?.symbol
      ? timeSeries[
          getTimeSeriesId(
            TimeSeriesPeriod.year,
            id,
            listing.symbol,
            listing.mic,
          )
        ]
      : undefined,
);

export const selectFiveYearTimeSeries = createSelector(
  selectTimeSeriesEntities,
  selectInstrumentId,
  selectListing,
  (timeSeries, id, listing) =>
    id && listing && listing.symbol
      ? timeSeries[
          getTimeSeriesId(
            TimeSeriesPeriod.fiveYears,
            id,
            listing.symbol,
            listing.mic,
          )
        ]
      : undefined,
);

export const selectQuoteId = createSelector(
  selectInstrumentId,
  selectListing,
  (id, listing) =>
    id && listing && listing.symbol
      ? getQuoteId(id, listing.symbol, listing.mic)
      : undefined,
);

export const selectInstrumentQuote = createSelector(
  selectQuotesEntities,
  selectQuoteId,
  (quotes, id) => quotes[id ?? ''],
);

export const selectInstrument = createSelector(
  selectInstrumentEntity,
  selectYearTimeSeries,
  selectFiveYearTimeSeries,
  selectInstrumentQuote,
  ThemeSelectors.selectColors,
  (instrument, year, fiveYears, quote, colors) =>
    getAssetEntity(instrument, year, fiveYears, quote, colors),
);

export const selectInstrumentWithId = memoize((id: string) =>
  createSelector(selectInstrumentsEntities, (entities) => entities[id]),
);

export const selectInstrumentLoading = createSelector(
  instrumentsSliceSelector,
  (state: State) => state.loadingInstrument,
);

export const selectQuoteLoading = createSelector(
  instrumentsSliceSelector,
  (state: State) => state.loadingQuote,
);

export const selectTimeSeriesPeriod = createSelector(
  instrumentsSliceSelector,
  (state: State) => state.selectedTimeSeries,
);

export const selectTimeSeriesId = createSelector(
  selectInstrumentId,
  selectListing,
  selectTimeSeriesPeriod,
  (id, listing, period) =>
    id && listing && listing.symbol && period
      ? getTimeSeriesId(period, id, listing.symbol, listing.mic)
      : undefined,
);

export const selectTimeSeriesLoading = createSelector(
  instrumentsSliceSelector,
  selectInstrumentLoading,
  (state: State, fundamentalsLoading) =>
    fundamentalsLoading || state.loadingTimeSeries,
);

export const selectTimeSeriesEntity = createSelector(
  selectInstrument,
  selectTimeSeriesId,
  selectTimeSeriesEntities,
  (instrument, timeSeriesId, timeSeriesEntities) => {
    if (!timeSeriesId || !instrument || !instrument.symbol) return undefined;
    return timeSeriesEntities[timeSeriesId];
  },
);

const quoteLabel = () => $localize`Quote`;
export const selectTimeSeriesChartData = createSelector(
  selectInstrumentQuote,
  selectTimeSeriesEntity,
  ThemeSelectors.selectColors,
  (quote, timeSeries, colors) =>
    mapTimeSeriesToChartData(
      timeSeries?.items,
      timeSeries?.period,
      quote?.previousClose ?? undefined,
      colors,
      quoteLabel(),
    ),
);

export const selectDiscoverOverviewAssets = createSelector(
  instrumentsSliceSelector,
  (state) => state.discoverOverviewAssets,
);

export const selectLoadingDiscoverOverview = createSelector(
  instrumentsSliceSelector,
  (state) => state.loadingDiscoverOverview,
);

export const selectListView = createSelector(
  instrumentsSliceSelector,
  (state: State) => state.showListView,
);

export const selectSortingType = createSelector(
  instrumentsSliceSelector,
  (state: State) => state.sortingType,
);

export const selectSortingCategory = createSelector(
  instrumentsSliceSelector,
  (state: State) => state.sortingCategory,
);

export const selectSortingCategoryType = createSelector(
  NavigationSelectors.selectInstrumentCategoryFromRoute,
  selectSortingCategory,
  (route, sortingCategory) => {
    return getSortingTypePerCategory(route, sortingCategory);
  },
);

export type SortingTypeI18n = Partial<Record<SortingType, string>>;

export const selectSortingTypeI18n = createSelector(
  (): SortingTypeI18n => ({
    [SortingType.GainersFirst]: $localize`Gainers first`,
    [SortingType.LosersFirst]: $localize`Losers first`,
    [SortingType.AtoZ]: $localize`By name A-Z`,
    [SortingType.ZtoA]: $localize`By name Z-A`,
    [SortingType.DividendYield]: $localize`Dividend yield ⬇`,
    [SortingType.ROA]: $localize`ROA ⬇`,
    [SortingType.ROE]: $localize`ROE ⬇`,
    [SortingType.OPERATING_MARGIN]: $localize`Operating margin ⬇`,
    [SortingType.PROFIT_MARGIN]: $localize`Profit margin ⬇`,
    [SortingType.PRICE_TO_SALES]: $localize`Price/Sales Ratio ⬇`,
    [SortingType.BETA]: $localize`Beta ⬇`,
    [SortingType.TRAILING_PE]: $localize`Trailing PE ⬇`,
    [SortingType.MARKET_CAP]: $localize`Market cap ⬇`,
    [SortingType.LOWEST_ETF_TER]: $localize`TER ⬆`,
    [SortingType.FUND_SIZE]: $localize`Fund size ⬇`,
    [SortingType.RANK]: $localize`Rank ⬇`,
  }),
);

export const selectSortingTypeVM = createSelector(
  selectSortingCategoryType,
  selectSortingTypeI18n,
  (sortingCategory, sortingTypeI18n) => {
    const { sortingType: sortingType, categoryKey: categoryKey } =
      sortingCategory;

    return { sortingType, categoryKey, sortingTypeI18n };
  },
);

type EarningsAnnouncementTypeI18n = Partial<
  Record<EarningsAnnouncementType, string>
>;

const selectEarningsAnnouncementTypeI18n = createSelector(
  (): EarningsAnnouncementTypeI18n => ({
    [EarningsAnnouncementType.AfterHours]: $localize`After-hours`,
    [EarningsAnnouncementType.PreMarket]: $localize`Pre-market`,
  }),
);

export const selectInstrumentNextEarningsCall = createSelector(
  selectInstrument,
  selectEarningsAnnouncementTypeI18n,
  (
    instrument,
    earningsAnnouncementTypeI18n,
  ): EarningsCallEntity | undefined => {
    const nextEarningsCall = isStockEntity(instrument)
      ? instrument?.fundamentals?.nextEarningsCall
      : undefined;

    return nextEarningsCall
      ? {
          ...nextEarningsCall,
          announcementText: nextEarningsCall.announcement
            ? earningsAnnouncementTypeI18n[nextEarningsCall.announcement]
            : undefined,
        }
      : undefined;
  },
);
