<ion-item
  button
  lines="full"
  [routerLink]="[NavigationRouteNames.GAME_OVERVIEW, gameId]"
>
  <ion-icon slot="start" name="flame-outline" color="danger" />
  <ion-label
    ><h2 i18n>Join our stock market game!</h2
    ><p i18n
      >Achieve the best performance, answer a daily quiz, and win CHF
      {{ 1000 | formatNumber }}</p
    ></ion-label
  >
</ion-item>
