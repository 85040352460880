import { createSelector } from '@ngrx/store';
import {
  computeHash,
  RepTrxItemDetailType,
  TrxTypeKey,
} from '@yeekatee/booking-util-definitions';
import {
  BookingPeriod,
  PortfolioItem,
  Transaction,
  TransactionItem,
  TransactionItemDetails,
  TransactionSourceType,
} from '@yeekatee/client-api-angular';
import { NavigationSelectors } from '../navigation';
import {
  getPortfolioName,
  getStoreReportId,
  portfolioReportsFeature,
  TradeFormVM,
  userPortfoliosFeature,
} from '../portfolios';
import { transactionsFeature } from '../portfolios/features/transactions-reports.selectors';
import { memoize } from '../utils';

type TrxTypeKeyI18n = Partial<Record<TrxTypeKey, string>>;

const selectTransactionsTypeI18n = createSelector(
  (): TrxTypeKeyI18n => ({
    [TrxTypeKey.Init]: $localize`Balance`,
    [TrxTypeKey.Valuation]: $localize`Valuation`,
    [TrxTypeKey.BalCheck]: $localize`Adjust balance`,
    //
    [TrxTypeKey.Open]: $localize`Open`,
    [TrxTypeKey.InterestMacc]: $localize`Money accout interests`,
    [TrxTypeKey.Close]: $localize`Close`,
    //
    [TrxTypeKey.Buy]: $localize`:@@nounToBuy:Buy`,
    [TrxTypeKey.BuyClose]: $localize`Buy close`,
    [TrxTypeKey.BuyOpen]: $localize`Buy open`,
    [TrxTypeKey.Sell]: $localize`:@@nounToSell:Sell`,
    [TrxTypeKey.SellClose]: $localize`Sell close`,
    [TrxTypeKey.SellOpen]: $localize`Sell open`,
    [TrxTypeKey.CapitalCall]: $localize`Capital call`,
    //
    [TrxTypeKey.OpenDeposit]: $localize`Open deposit`,
    [TrxTypeKey.IncreaseDeposit]: $localize`Increase deposit`,
    [TrxTypeKey.InterestDeposit]: $localize`Deposit interests`,
    [TrxTypeKey.DecreaseDeposit]: $localize`Decrease deposit`,
    [TrxTypeKey.CloseDeposit]: $localize`Close deposit`,
    //
    [TrxTypeKey.InPayment]: $localize`Inpayment`,
    [TrxTypeKey.OutPayment]: $localize`Outpayment`,
    [TrxTypeKey.TransferMacc]: $localize`Transfer`,
    [TrxTypeKey.Forex]: $localize`Forex`,
    [TrxTypeKey.ForexHalf]: $localize`Forex`,
    //
    [TrxTypeKey.TransferFi]: $localize`Transfer`,
    [TrxTypeKey.DeliveryFi]: $localize`Delivery`,
    //
    [TrxTypeKey.GeneralExpenses]: $localize`General expenses`,
    [TrxTypeKey.CustodyFee]: $localize`Custody fees`,
    [TrxTypeKey.AdvisoryFee]: $localize`Advisory fees`,
    [TrxTypeKey.ChargesFi]: $localize`Charges`,
    [TrxTypeKey.ChargesMacc]: $localize`Charges`,
    //
    [TrxTypeKey.CashReturn]: $localize`Cash return`,
    [TrxTypeKey.Dividend]: $localize`Dividend`,
    [TrxTypeKey.Distribution]: $localize`Distribution`,
    [TrxTypeKey.VarMargin]: $localize`Variation margin`,
    [TrxTypeKey.StockDividend]: $localize`Stock dividend`,
    [TrxTypeKey.Bonus]: $localize`Bonus`,
    [TrxTypeKey.Retention]: $localize`Retention`,
    //
    [TrxTypeKey.Split]: $localize`Split`,
    [TrxTypeKey.SplitSwap]: $localize`Split`,
    [TrxTypeKey.Spinoff]: $localize`Spinoff`,
    [TrxTypeKey.RightDistribution]: $localize`Rights distribution`,
    [TrxTypeKey.RightExecution]: $localize`Execution of rights`,
    [TrxTypeKey.Merger]: $localize`Merger`,
    [TrxTypeKey.SwapFi]: $localize`Swap`,
    [TrxTypeKey.Writeoff]: $localize`Write-off`,
    //
    [TrxTypeKey.CorpAction]: $localize`Corporate action`,
    [TrxTypeKey.Execution]: $localize`Execution`,
    [TrxTypeKey.Expiry]: $localize`Expiry`,
    [TrxTypeKey.Redemption]: $localize`Redemption`,
    //
    [TrxTypeKey.GameReward]: $localize`Game reward`,
  }),
);

type RepTrxItemDetailTypeI18n = Record<RepTrxItemDetailType, string>;

const selectTransactionItemDetailsTypesI18n = createSelector(
  (): RepTrxItemDetailTypeI18n => ({
    [RepTrxItemDetailType.exchange]: $localize`exchange`,
    [RepTrxItemDetailType.expenses]: $localize`expenses`,
    [RepTrxItemDetailType.fraction]: $localize`fractions`,
    [RepTrxItemDetailType.varmarg]: $localize`variation margin`,
    [RepTrxItemDetailType.transfer]: $localize`transfer`,
    [RepTrxItemDetailType.gross]: $localize`gross`,
    [RepTrxItemDetailType.price]: $localize`price`,
    [RepTrxItemDetailType.net]: $localize`net`,
    [RepTrxItemDetailType.retention]: $localize`retention`,
    [RepTrxItemDetailType.ratio]: $localize`ratio`,
    [RepTrxItemDetailType.return]: $localize`return`,
    [RepTrxItemDetailType.value]: $localize`value`,
  }),
);

type TransactionSourceTypeI18n = Record<TransactionSourceType, string>;

const selectTransactionSourceTypesI18n = createSelector(
  (): TransactionSourceTypeI18n => ({
    [TransactionSourceType.Generated]: $localize`Generated`,
    [TransactionSourceType.Manual]: $localize`Manual`,
    [TransactionSourceType.Synched]: $localize`Synced`,
  }),
);

export type TransactionsReportVM = TransactionsListVM;

export const selectTransactionsReportVM = memoize(
  (
    entityId: string,
    userId?: string,
    currency?: string,
    period?: BookingPeriod,
    startDate?: string,
    endDate?: string,
    keys?: string[],
  ) => {
    const reportId = userId
      ? getStoreReportId(
          computeHash(keys, userId),
          period,
          currency,
          startDate,
          endDate,
        )
      : undefined;

    return createSelector(
      transactionsFeature.selectTransactionsById(entityId),
      portfolioReportsFeature.selectReportItemsById(reportId),
      transactionsFeature.selectTransactionsReportLoading,
      transactionsFeature.selectAllTransactionsLoadedById(entityId),
      portfolioReportsFeature.selectPortfolioItemSelectedById(
        reportId,
        entityId,
      ),
      portfolioReportsFeature.selectReportDatesById(reportId),
      portfolioReportsFeature.selectSplitItems,
      selectTransactionsTypeI18n,
      selectTransactionItemDetailsTypesI18n,
      selectTransactionSourceTypesI18n,
      (
        transactions,
        items,
        loading,
        loaded,
        portfolioItem,
        reportPeriod,
        splitItemsOption,
        transactionsTypesI18n,
        transactionItemDetailsTypesI18n,
        transactionSourceTypesI18n,
      ): TransactionsReportVM => ({
        transactions: transactions.map((t) => ({
          ...mapToEntityVM(
            t,
            items,
            transactionsTypesI18n,
            transactionItemDetailsTypesI18n,
            transactionSourceTypesI18n,
          ),
        })),
        loading,
        loaded,
        entityId,
        selectedSplitItemsType: splitItemsOption
          ? $localize`w/ splits`
          : $localize`w/o splits`,
        title: portfolioItem?.instrument?.name ?? $localize`Transactions`,
        ...reportPeriod,
      }),
    );
  },
);

export type TransactionReportVM = Pick<
  TransactionDetailsVM,
  'transaction' | 'refItemKey'
>;

export const selectTransactionReportVM = memoize(
  (
    entityId: string,
    transactionKey: string,
    userId?: string,
    currency?: string,
    period?: BookingPeriod,
    startDate?: string,
    endDate?: string,
    keys?: string[],
  ) => {
    const reportId = userId
      ? getStoreReportId(
          computeHash(keys, userId),
          period,
          currency,
          startDate,
          endDate,
        )
      : undefined;

    return createSelector(
      transactionsFeature.selectTransactionByKey(entityId, transactionKey),
      portfolioReportsFeature.selectReportItemsById(reportId),
      portfolioReportsFeature.selectPortfolioItemSelectedById(
        reportId,
        entityId,
      ),
      selectTransactionsTypeI18n,
      selectTransactionItemDetailsTypesI18n,
      selectTransactionSourceTypesI18n,
      (
        transaction,
        items,
        portfolioItem,
        transactionsTypesI18n,
        transactionItemDetailsTypesI18n,
        transactionSourceTypesI18n,
      ): TransactionReportVM => ({
        transaction: transaction
          ? mapToEntityVM(
              transaction,
              items,
              transactionsTypesI18n,
              transactionItemDetailsTypesI18n,
              transactionSourceTypesI18n,
            )
          : undefined,
        refItemKey: portfolioItem?.key ?? undefined,
      }),
    );
  },
);

export interface TransactionsListVM {
  transactions: TransactionVM[];
  loading: boolean;
  loaded: boolean;
  title: string;
  entityId?: string;
  startDate?: string;
  endDate?: string;
  selectedSplitItemsType: string;
}

export const selectTransactionsListVM = createSelector(
  transactionsFeature.selectTransactions,
  portfolioReportsFeature.selectReportItems,
  transactionsFeature.selectTransactionsReportLoading,
  transactionsFeature.selectAllTransactionsLoaded,
  portfolioReportsFeature.selectPortfolioName,
  portfolioReportsFeature.selectPortfolioItemSelected,
  portfolioReportsFeature.selectReportDates,
  portfolioReportsFeature.selectSplitItems,
  NavigationSelectors.selectPortfolioEntityIdFromRoute,
  selectTransactionsTypeI18n,
  selectTransactionItemDetailsTypesI18n,
  selectTransactionSourceTypesI18n,
  (
    transactions,
    items,
    loading,
    loaded,
    portfolioName,
    portfolioItem,
    reportPeriod,
    splitItemsOption,
    entityId,
    transactionsTypesI18n,
    transactionItemDetailsTypesI18n,
    transactionSourceTypesI18n,
  ): TransactionsListVM => ({
    transactions: transactions.map((t) => ({
      ...mapToEntityVM(
        t,
        items,
        transactionsTypesI18n,
        transactionItemDetailsTypesI18n,
        transactionSourceTypesI18n,
      ),
    })),
    loading,
    loaded,
    entityId,
    selectedSplitItemsType: splitItemsOption
      ? $localize`w/ splits`
      : $localize`w/o splits`,
    title: portfolioItem?.instrument?.name ?? getPortfolioName(portfolioName),
    ...reportPeriod,
  }),
);

export interface TransactionDetailsVM {
  transaction?: TransactionVM;
  transactionsVersion?: string;
  canDelete: boolean;
  refItemKey?: string;
  portfolioKey?: string;
}

export const selectTransactionDetailsVM = createSelector(
  transactionsFeature.selectTransaction,
  transactionsFeature.selectTransactionsVersion,
  portfolioReportsFeature.selectReportItems,
  portfolioReportsFeature.selectPortfolioItemSelected,
  portfolioReportsFeature.selectPortfolioKey,
  selectTransactionsTypeI18n,
  selectTransactionItemDetailsTypesI18n,
  selectTransactionSourceTypesI18n,
  (
    transaction,
    transactionsVersion,
    items,
    portfolioItem,
    portfolioKey,
    transactionsTypesI18n,
    transactionItemDetailsTypesI18n,
    transactionSourceTypesI18n,
  ): TransactionDetailsVM => ({
    transaction: transaction
      ? mapToEntityVM(
          transaction,
          items,
          transactionsTypesI18n,
          transactionItemDetailsTypesI18n,
          transactionSourceTypesI18n,
        )
      : undefined,
    transactionsVersion,
    canDelete: transaction?.canDelete ?? false,
    refItemKey: portfolioItem?.key ?? undefined,
    portfolioKey,
  }),
);

export interface TransactionVM
  extends Omit<Transaction, '__typename' | 'source' | 'items'> {
  source?: string;
  items: TransactionItemVM[];
}

export interface TransactionItemVM
  extends Omit<TransactionItem, '__typename' | 'details'> {
  name: string;
  details: TransactionItemDetailsVM[];
}

export type TransactionItemDetailsVM = Omit<
  TransactionItemDetails,
  '__typename'
>;

export const mapToEntityVM = (
  transaction: Transaction,
  portfolioItems: PortfolioItem[],
  transactionsTypesI18n: TrxTypeKeyI18n,
  transactionItemDetailsTypesI18n: RepTrxItemDetailTypeI18n,
  selectTransactionTypesI18n: TransactionSourceTypeI18n,
): TransactionVM => {
  const items = (transaction.items ?? []).map((txItem) => {
    const item = portfolioItems.find(
      (portfolioItem) => portfolioItem?.key === txItem.itemKey,
    );

    return {
      amount: txItem.amount,
      balance: txItem.balance,
      detailCurrency: txItem.detailCurrency,
      itemKey: item?.key,
      name: item?.instrument?.name,
      currency: item?.currency,
      details: transactionItemDetailsTypesI18n
        ? (txItem.details ?? []).map(
            (details) =>
              ({
                value: details.value,
                type: details.type
                  ? transactionItemDetailsTypesI18n[
                      details.type as RepTrxItemDetailType
                    ]
                  : undefined,
              }) as TransactionItemDetailsVM,
          )
        : undefined,
    } as TransactionItemVM;
  });

  return {
    key: transaction.key ?? undefined,
    customId: transaction.customId ?? undefined,
    date: transaction.date ?? undefined,
    exDate: transaction.exDate ?? undefined,
    transactionDate: transaction.transactionDate ?? undefined,
    valueDate: transaction.valueDate ?? undefined,
    isReversal: transaction.isReversal ?? false,
    source: transaction.source
      ? selectTransactionTypesI18n[transaction.source as TransactionSourceType]
      : undefined,
    text: transaction.text ?? undefined,
    account: transaction.account ?? undefined,
    type:
      transactionsTypesI18n[transaction.type as TrxTypeKey] ?? $localize`Other`,
    items,
  };
};

export const selectAddTransactionsVM = createSelector(
  portfolioReportsFeature.selectLoading,
  userPortfoliosFeature.selectTradeablePortfolioInRoute,
  portfolioReportsFeature.selectPortfolioName,
  portfolioReportsFeature.selectReportChartData,
  selectTransactionsTypeI18n,
  (
    portfolioLoading,
    portfolio,
    portfolioName,
    chartsData,
    transactionsTypesI18n,
  ) => ({
    portfolioLoading,
    portfolio,
    portfolioName: getPortfolioName(portfolioName),
    aggregationKey: chartsData.aggregation?.key,
    transactionsTypesI18n,
  }),
);

export const selectAddTransactionVM = createSelector(
  userPortfoliosFeature.selectTradeablePortfolioInRoute,
  userPortfoliosFeature.selectUserPortfoliosCanAddTransactions,
  portfolioReportsFeature.selectLoading,
  selectTransactionsTypeI18n,
  (selectedPortfolio, portfolios, portfolioLoading, transactionsTypesI18n) => ({
    selectedPortfolio,
    portfolios,
    portfolioLoading,
    transactionsTypesI18n,
  }),
);

export const selectManualFormVM = createSelector(
  selectTransactionsTypeI18n,
  portfolioReportsFeature.selectReportBalances,
  (transactionsTypesI18n, portfoliosBalances) => ({
    transactionsTypesI18n,
    portfoliosBalances,
  }),
);

export const selectTradeFormVM = createSelector(
  portfolioReportsFeature.selectLoading,
  portfolioReportsFeature.selectReportBalances,
  (portfolioLoading, portfoliosBalances): TradeFormVM => ({
    portfolioLoading,
    portfoliosBalances,
  }),
);
