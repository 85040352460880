import { getRouterSelectors } from '@ngrx/router-store';
import { RouteParams } from './navigation.model';

const { selectRouteParam, selectQueryParam, selectUrl } = getRouterSelectors();

export { selectUrl };

export const selectActivityIdFromRoute = selectRouteParam(
  RouteParams.ACTIVITY_ID,
);

export const selectBankIdFromRoute = selectRouteParam(RouteParams.BANK_ID);

export const selectChatIdFromRoute = selectRouteParam(RouteParams.CHAT_ID);
export const selectChatToFromRoute = selectRouteParam(RouteParams.CHAT_TO);

export const selectGameIdFromRoute = selectRouteParam(RouteParams.GAME_ID);

export const selectInstrumentCategoryFromRoute = selectRouteParam(
  RouteParams.INSTRUMENT_CATEGORY,
);
export const selectInstrumentIdFromRoute = selectRouteParam(
  RouteParams.INSTRUMENT_ID,
);
export const selectInstrumentMicFromRoute = selectQueryParam(
  RouteParams.INSTRUMENT_MIC,
);
export const selectInstrumentSymbolFromRoute = selectQueryParam(
  RouteParams.INSTRUMENT_SYMBOL,
);

export const selectPortfolioCalculationFromRoute = selectQueryParam(
  RouteParams.PORTFOLIO_CALCULATION,
);
export const selectPortfolioCurrencyFromRoute = selectQueryParam(
  RouteParams.PORTFOLIO_CURRENCY,
);
export const selectPortfolioEntityIdFromRoute = selectRouteParam(
  RouteParams.PORTFOLIO_ENTITY_ID,
);
export const selectPortfolioItemIdFromRoute = selectRouteParam(
  RouteParams.PORTFOLIO_ITEM_ID,
);
export const selectPortfolioKeysFromRoute = selectQueryParam(
  RouteParams.PORTFOLIO_KEYS,
);
export const selectPortfolioViewFromRoute = selectQueryParam(
  RouteParams.PORTFOLIO_VIEW,
);
export const selectPortfolioStartDateFromRoute = selectQueryParam(
  RouteParams.PORTFOLIO_START_DATE,
);
export const selectPortfolioEndDateFromRoute = selectQueryParam(
  RouteParams.PORTFOLIO_END_DATE,
);
export const selectPortfolioPeriodFromRoute = selectQueryParam(
  RouteParams.PORTFOLIO_PERIOD,
);
export const selectPortfolioTransactionFromRoute = selectRouteParam(
  RouteParams.PORTFOLIO_TRANSACTION_KEY,
);

export const selectUserIdFromRoute = selectRouteParam(RouteParams.USER_ID);
