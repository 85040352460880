import { createSelector } from '@ngrx/store';
import { AuthSelectors } from '../auth';
import { NotificationsSelectors } from '../notifications';
import { ThemeSelectors } from '../theme';

export const selectAppViewModel = createSelector({
  isAuthenticated: AuthSelectors.isAuthenticated,
  notification: NotificationsSelectors.selectNotification,
  isWebThemeActive: ThemeSelectors.selectWebThemeActive,
});
