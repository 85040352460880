import { createSelector } from '@ngrx/store';
import { GameAssetSchema } from '@yeekatee/instruments-util-discover';
import Fuse from 'fuse.js';
import { ThemeSelectors } from '../../theme';
import { memoize } from '../../utils';
import {
  GameCompetitionEntity,
  applyThemeMode,
  getGameCompetitionAssets,
} from '../games.models';
import { GamesState, selectGamesState } from '../games.reducer';
import * as fromReducers from './games-competitions.reducer';

const { selectEntities, selectAll } = fromReducers.adapter.getSelectors();

export const selectGameCompetitionsState = createSelector(
  selectGamesState,
  (state: GamesState) => state[fromReducers.FEATURE_KEY],
);

export const selectGameCompetitionsEntities = createSelector(
  selectGameCompetitionsState,
  (state: fromReducers.State) => selectEntities(state),
);

export const getAllGameCompetitions = createSelector(
  selectGameCompetitionsState,
  (state: fromReducers.State) => selectAll(state),
);

export const isGameCompetitionLoading = createSelector(
  selectGameCompetitionsState,
  (state: fromReducers.State) => state.loading,
);

export const selectGameAssets = createSelector(
  selectGameCompetitionsState,
  (state: fromReducers.State) => state.assets,
);

export const selectRankingType = createSelector(
  selectGameCompetitionsState,
  (state: fromReducers.State) => state.rankingType,
);

export const selectSearchQuery = createSelector(
  selectGameCompetitionsState,
  (state: fromReducers.State) => state.searchQuery,
);

export const selectSearchFilter = createSelector(
  selectGameCompetitionsState,
  (state: fromReducers.State) => state.searchFilter,
);

export const selectGameCompetitionById = memoize((gameId?: string) =>
  createSelector(
    selectGameCompetitionsEntities,
    ThemeSelectors.selectThemeMode,
    (entities, themeMode): GameCompetitionEntity | undefined =>
      applyThemeMode(themeMode, entities[gameId ?? '']),
  ),
);

export const selectGameCompetitionAssetsById = memoize((gameId?: string) =>
  createSelector(
    selectGameCompetitionById(gameId),
    selectGameAssets,
    (competition, assets): GameAssetSchema[] =>
      getGameCompetitionAssets(competition, assets) ?? [],
  ),
);

export const selectSearchAssetsById = memoize((gameId?: string) =>
  createSelector(
    selectGameCompetitionAssetsById(gameId),
    (assets) =>
      new Fuse(assets, {
        keys: ['name', 'type'],
        threshold: 0.3, // the default 0.6 returned too many results...
      }),
  ),
);

export const selectSearchFilteredAssetsById = memoize((gameId?: string) =>
  createSelector(
    selectSearchAssetsById(gameId),
    selectGameCompetitionAssetsById(gameId),
    selectSearchQuery,
    selectSearchFilter,
    (fuse, assets, query, filter) =>
      query || filter
        ? fuse
            .search({
              $and: [
                ...(query ? [{ name: query }] : []),
                ...(filter ? [{ type: filter }] : []),
              ],
            })
            .map((result) => result.item)
        : assets,
  ),
);
