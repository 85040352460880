import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DynamicTranslatedText } from '../dynamic-translation.pipe';

@Pipe({ name: 'dynamicTranslation' })
export class MockDynamicTranslationPipe implements PipeTransform {
  transform(
    sourceText?: string | null,
    sourceLanguage?: string,
    targetLanguage?: string,
  ): Observable<DynamicTranslatedText> | undefined {
    if (!sourceText) return undefined;
    return of({ status: 'identical', text: sourceText });
  }
}
