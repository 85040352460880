import { createFeature, createSelector } from '@ngrx/store';
import { NavigationSelectors } from '../../navigation';
import { memoize } from '../../utils';
import { portfolioReportsFeature } from './portfolio-reports.selectors';
import { State, adapter, name, reducer } from './transactions-reports.reducer';

export const transactionsFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectEntities, selectTransactionsState }) => {
    const entitySelectors = adapter.getSelectors(selectTransactionsState);

    const selectTransactionsReportLoading = createSelector(
      selectTransactionsState,
      portfolioReportsFeature.selectLoading,
      (state: State, loading) => state.loading || loading,
    );

    const selectTransactionsReport = createSelector(
      selectEntities,
      NavigationSelectors.selectPortfolioEntityIdFromRoute,
      (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
    );

    const selectTransactionsReportById = memoize((entityId: string) =>
      createSelector(selectEntities, (entities) => entities[entityId]),
    );

    const selectAllTransactionsLoaded = createSelector(
      selectTransactionsReport,
      (report) => !report?.nextToken,
    );

    const selectTransactionsReportNextTokenById = memoize((entityId: string) =>
      createSelector(
        selectTransactionsReportById(entityId),
        (report) => report?.nextToken,
      ),
    );

    const selectAllTransactionsLoadedById = memoize((entityId: string) =>
      createSelector(
        selectTransactionsReportNextTokenById(entityId),
        (nextToken) => !nextToken,
      ),
    );

    const selectTransactions = createSelector(
      selectTransactionsReport,
      (report) => report?.transactions ?? [],
    );

    const selectTransactionsById = memoize((entityId: string) =>
      createSelector(
        selectTransactionsReportById(entityId),
        (report) => report?.transactions ?? [],
      ),
    );

    const selectTransactionsVersion = createSelector(
      selectTransactionsReport,
      (report) => report?.version ?? undefined,
    );

    const selectTransaction = createSelector(
      NavigationSelectors.selectPortfolioTransactionFromRoute,
      selectTransactions,
      (key, transactions) => transactions?.find((t) => t?.key === key),
    );

    const selectTransactionByKey = memoize(
      (entityId: string, transactionKey: string) =>
        createSelector(selectTransactionsById(entityId), (transactions) =>
          transactions?.find((t) => t?.key === transactionKey),
        ),
    );

    return {
      ...entitySelectors,
      selectTransactionsReportLoading,
      selectTransactionsReport,
      selectTransactionsReportById,
      selectTransactionsReportNextTokenById,
      selectAllTransactionsLoaded,
      selectAllTransactionsLoadedById,
      selectTransactions,
      selectTransactionsById,
      selectTransactionsVersion,
      selectTransaction,
      selectTransactionByKey,
    };
  },
});
