import { DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IonButton,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
} from '@ionic/angular/standalone';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { MaskitoDateMode, maskitoDateOptionsGenerator } from '@maskito/kit';
import { DateConst } from '@yeekatee/booking-util-definitions';
import { addIcons } from 'ionicons';
import { calendarOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { ulid } from 'ulid';
import { FirstDayWeekDirective } from '../directives/first-day-week.directive';

@Component({
  selector: 'yeekatee-datetime-input',
  standalone: true,
  imports: [
    DatePipe,
    FirstDayWeekDirective,
    IonButton,
    IonDatetime,
    IonIcon,
    IonInput,
    IonItem,
    IonModal,
    MaskitoDirective,
  ],
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatetimeInputComponent,
    },
  ],
})
export class DatetimeInputComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() minDate?: string = DateConst.BookMin;
  @Input() disableWeekends?: boolean = false;

  @ViewChild(IonDatetime) datetime?: IonDatetime;

  protected selectedDate?: string;
  protected touched = false;
  protected disabled = false;

  // Needed to have more than one on the same page
  protected readonly modalId = `modal-${ulid()}`;

  private readonly localeDateFormat = getLocaleDateFormat(
    this.appLocale,
    FormatWidth.Short,
  );
  private readonly dateFormatSeparator = this.getFormatDateSeparator();
  private readonly maskitoDateMode = this.getMaskitoDateMode();
  protected readonly dateFormat = this.getFormatDate();
  protected readonly placeholder = this.getLocalizedPlaceholder();

  protected readonly dateMask: MaskitoOptions = maskitoDateOptionsGenerator({
    mode: this.maskitoDateMode,
    separator: this.dateFormatSeparator,
  });
  protected readonly maskPredicate: MaskitoElementPredicate = async (el) =>
    (el as HTMLIonInputElement).getInputElement();
  protected readonly today = DateTime.now().toISO();

  constructor(
    @Inject(LOCALE_ID) readonly appLocale: string,
    private readonly cd: ChangeDetectorRef,
  ) {
    addIcons({ calendarOutline });
  }

  private getFormatDateSeparator(): string | undefined {
    return this.localeDateFormat.match(/[^A-Za-z]/)?.[0] || undefined;
  }

  private getMaskitoDateMode(): MaskitoDateMode {
    const df = this.localeDateFormat.toLowerCase();
    let dateMode: MaskitoDateMode = 'yyyy/mm/dd';
    if (df.startsWith('d')) dateMode = 'dd/mm/yyyy';
    else if (df.startsWith('m')) dateMode = 'mm/dd/yyyy';
    return dateMode;
  }

  private getFormatDate(): string | undefined {
    if (!this.dateFormatSeparator) return undefined;

    /**
     * Since Maskito doesn't follow standards,
     * we need to adapt the format to it.
     */
    return this.maskitoDateMode
      .replace(/m/g, 'M')
      .replace(/\//g, this.dateFormatSeparator);
  }

  private getLocalizedPlaceholder(): string | undefined {
    if (!this.dateFormatSeparator) return undefined;

    const df = this.localeDateFormat.toLowerCase();
    let placeholder = $localize`YYYY/MM/DD`;
    if (df.startsWith('d')) placeholder = $localize`DD/MM/YYYY`;
    else if (df.startsWith('m')) placeholder = $localize`MM/DD/YYYY`;
    return placeholder.replace(/\//g, this.dateFormatSeparator);
  }

  isDateEnabled = (dateString: string) =>
    !this.disableWeekends || this.isWeekday(dateString);

  private isWeekday = (dateString: string) => {
    const date = new Date(dateString);
    const utcDay = date.getUTCDay();
    return utcDay !== 0 && utcDay !== 6;
  };

  protected onInputChange(event: Event) {
    if (!this.dateFormat) return undefined;

    const input = (event as CustomEvent).detail.value;
    const date = DateTime.fromFormat(input, this.dateFormat);
    this.selectedDate = date.isValid ? date.toISO()! : '';
    this.selectedDate && this.datetime?.reset(this.selectedDate);
    this.onChange(this.selectedDate);
  }

  protected onDateChange(event: Event) {
    this.selectedDate = (event as CustomEvent).detail.value;
    this.selectedDate && this.onChange(this.selectedDate);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (date: string) => {};

  onTouched = () => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(date: string): void {
    this.selectedDate = date;
    this.cd.markForCheck();
  }
}
