import { TimeSeriesItem } from '@yeekatee/client-api-typescript';
import {
  AnimationSpec,
  ChartData,
  ChartDataset,
  Color,
  ScriptableContext,
  TooltipItem,
  TooltipLabelStyle,
} from 'chart.js';
import chroma from 'chroma-js';

/*
 *  Fill with gradient beneath line
 */

export const getIonColor = (colorName: string, alpha = 1) => {
  const style = getComputedStyle(document.body);
  const ionColor = `--ion-color-${colorName}-rgb`;
  const rgb = style.getPropertyValue(ionColor);
  return `rgba(${rgb}, ${alpha})`;
};

export const setChartFill = (
  context: ScriptableContext<'line' | 'bar'>,
  color: chroma.Color,
  boundary: number | null | undefined = 0,
  direction: 'top' | 'bottom',
): Color => {
  const {
    ctx,
    chartArea,
    scales: { y },
  } = context.chart;

  if (!chartArea) return ctx.createLinearGradient(0, 0, 0, 0);

  const { top, bottom } = chartArea;

  const boundaryPx = y.getPixelForValue(boundary ?? 0);

  const gradient =
    direction === 'bottom'
      ? ctx.createLinearGradient(0, 0, 0, bottom)
      : ctx.createLinearGradient(0, bottom, 0, 0);

  const colorStart = color.alpha(0.4).css();
  const colorStop = color.alpha(0).css();

  gradient.addColorStop(0, colorStart);

  if (boundaryPx < bottom && boundaryPx > top) {
    const relativeBoundary =
      direction === 'bottom' ? boundaryPx : bottom - boundaryPx;
    gradient.addColorStop(relativeBoundary / bottom, colorStop);
    gradient.addColorStop(1, colorStart);
  } else {
    gradient.addColorStop(1, colorStop);
  }

  return gradient;
};

export const setDynamicBorderColor = (
  context: ScriptableContext<'line' | 'bar'>,
  positiveColor: string,
  negativeColor: string,
  boundary: number | null | undefined = 0,
) => {
  const {
    ctx,
    chartArea,
    scales: { y },
  } = context.chart;
  if (!chartArea) return;

  const { top, bottom } = chartArea;

  // top can be 0, but not bottom
  if (top === undefined || !bottom) return;

  const boundaryPx = y.getPixelForValue(boundary ?? 0);

  if (boundaryPx >= bottom) return positiveColor;
  if (boundaryPx < top) return negativeColor;

  const gradient = ctx.createLinearGradient(0, 0, 0, bottom);

  gradient.addColorStop(0, positiveColor);
  gradient.addColorStop(boundaryPx / bottom, positiveColor);
  gradient.addColorStop(boundaryPx / bottom, negativeColor);
  gradient.addColorStop(1, negativeColor);

  return gradient;
};

export const setDynamicLabelColor = (
  context: TooltipItem<'line' | 'bar'>,
  positiveColor?: string,
  negativeColor?: string,
  reference?: number | null,
): TooltipLabelStyle | undefined => {
  if (!positiveColor || !negativeColor) return;

  const color =
    (reference ?? Number(context.dataset.data[0])) <= context.parsed.y
      ? positiveColor
      : negativeColor;

  return {
    borderColor: color,
    backgroundColor: chroma(color).alpha(0.2).css(),
    borderWidth: 2,
    borderRadius: 2,
  };
};

export const toolTipLabelStylePrimary: TooltipLabelStyle = {
  borderColor: getIonColor('primary'),
  backgroundColor: getIonColor('primary', 0.7),
};

export const toolTipLabelStyleSecondary: TooltipLabelStyle = {
  borderColor: getIonColor('secondary'),
  backgroundColor: getIonColor('secondary', 0.7),
};

// Performance chart
const PERFORMANCE_COLORS_IONIC = ['danger', 'medium', 'uptrend'];
const DEFAULT_PERFORMANCE_COLOR = PERFORMANCE_COLORS_IONIC[1];

const PERFORMANCE_ICONS = ['caret-down-outline', '', 'caret-up-outline'];
const DEFAULT_PERFORMANCE_ICON = PERFORMANCE_ICONS[1];

export function getPerfColorAndIcon(perf?: number): [string, string] {
  if (perf === undefined)
    return [DEFAULT_PERFORMANCE_COLOR, DEFAULT_PERFORMANCE_ICON];
  const pos = Math.sign(perf) + 1;

  return [PERFORMANCE_COLORS_IONIC[pos], PERFORMANCE_ICONS[pos]];
}

// Skeleton
export const defaultLineChartData = (
  chartData?: ChartData<'line'>,
  color?: chroma.Color,
): ChartData<'line'> => {
  const labels = chartData?.labels ?? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const data = chartData?.datasets?.[0]?.data ?? [
    40, 50, 45, 60, 55, 80, 70, 90, 85, 110,
  ];

  return getDefaultLineChartData(data, labels, color);
};

export const defaultAssetTimeSeriesChartData = (
  chartData?: ChartData<'line', TimeSeriesItem[]>,
  color?: chroma.Color,
): ChartData<'line', TimeSeriesItem[]> => {
  const data =
    chartData?.datasets?.[0]?.data ??
    [40, 50, 45, 60, 55, 80, 70, 90, 85, 110].map((p, index) => ({
      datetime: index,
      price: p,
    }));

  return getDefaultLineChartData(data, undefined, color);
};

const getDefaultLineChartData = <T>(
  data: T,
  labels?: unknown[],
  color?: chroma.Color,
): ChartData<'line', T> => {
  const animationSpec: AnimationSpec<'line'> = {
    duration: 500,
    easing: 'linear',
    loop: true,
  };

  return color
    ? {
        labels,
        datasets: [
          {
            animation: { duration: 0 },
            animations: {
              borderWidth: {
                ...animationSpec,
                type: 'number',
                from: 2,
                to: 1,
              },
              borderColor: {
                ...animationSpec,
                type: 'color',
                from: color?.alpha(0.9).css(),
                to: color?.alpha(0.2).css(),
              },
              backgroundColor: {
                ...animationSpec,
                type: 'color',
                from: color?.alpha(0.1).css(),
                to: color?.alpha(0.3).css(),
              },
            },
            data,
            fill: true,
            pointRadius: 0,
            tension: 0.3,
          },
        ],
      }
    : { datasets: [] };
};

export const defaultBarChartData = (
  chartData?: ChartData<'bar'>,
  color?: chroma.Color,
  length?: number,
): ChartData<'bar'> => {
  const defaultLabels = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  const labels =
    chartData?.labels ?? defaultLabels.slice(0, length ?? defaultLabels.length);

  const defaultData = [
    1, 2, 3, 4, 5, 4, 3, 2, 0, 1, -10, -8, -12, 0, 5, 15, 18, 25, 23, 0, -15,
    -10, 0, 5, 20, 18, 17, 14, 19, 21,
  ];
  const data =
    chartData?.datasets?.[0]?.data ??
    defaultData.slice(0, length ?? defaultLabels.length);

  return getDefaultBarChartData(data, labels, color);
};

const getDefaultBarChartData = <T>(
  data: T,
  labels?: unknown[],
  color?: chroma.Color,
): ChartData<'bar', T> => {
  const animationSpec: AnimationSpec<'bar'> = {
    duration: 500,
    easing: 'linear',
    loop: true,
  };

  return color
    ? {
        labels,
        datasets: [
          {
            animation: { duration: 0 },
            animations: {
              borderWidth: {
                ...animationSpec,
                type: 'number',
                from: 2,
                to: 1,
              },
              borderColor: {
                ...animationSpec,
                type: 'color',
                from: color?.alpha(0.9).css(),
                to: color?.alpha(0.2).css(),
              },
              backgroundColor: {
                ...animationSpec,
                type: 'color',
                from: color?.alpha(0.1).css(),
                to: color?.alpha(0.3).css(),
              },
            },
            data,
          },
        ],
      }
    : { datasets: [] };
};

export const defaultDoughnutChartData = (
  chartData?: ChartData<'doughnut'>,
  color?: chroma.Color,
): ChartDataset<'doughnut'>[] => {
  const labels = [1, 2, 3, 4, 5];
  const data = chartData?.datasets?.[0]?.data ?? [25, 23, 18, 5, 12];

  return getDefaultDoughnutChartData(data, labels, color);
};

const getDefaultDoughnutChartData = <T>(
  data: T,
  labels?: number[],
  color?: chroma.Color,
): ChartDataset<'doughnut', T>[] => {
  const animationSpec: AnimationSpec<'doughnut'> = {
    duration: 500,
    easing: 'linear',
    loop: true,
  };

  return [
    {
      animation: { ...animationSpec, duration: 0 },
      animations: {
        borderWidth: {
          ...animationSpec,
          type: 'number',
          from: 2,
          to: 1,
        },
        borderColor: {
          ...animationSpec,
          type: 'color',
          from: color?.alpha(0.9).css() ?? 'grey',
          to: color?.alpha(0.2).css() ?? 'grey',
        },
        backgroundColor: {
          ...animationSpec,
          type: 'color',
          from: color?.alpha(0.1).css() ?? 'grey',
          to: color?.alpha(0.3).css() ?? 'grey',
        },
      },
      data,
    },
  ];
};

export type SelectedItem<T> = { item: T; index: number };

export const createSelectedItem = <T>(timeSeries: Array<T>, data?: object) => {
  if (!data || !('index' in data)) return;

  const index = data.index as number;

  if (isNaN(index)) return;

  const item = timeSeries.at(index);

  if (!item) return;

  return { item, index };
};
