import { createSelector } from '@ngrx/store';
import {
  InstrumentsState,
  selectInstrumentsState,
} from '../instruments.reducer';
import { FEATURE_KEY, quotesAdapter } from './quotes.reducer';

// Lookup the 'Instruments' feature state managed by NgRx
export const quotesSliceSelector = createSelector(
  selectInstrumentsState,
  (state: InstrumentsState) => state[FEATURE_KEY],
);

const { selectEntities } = quotesAdapter.getSelectors();

export const selectQuotesEntities = createSelector(
  quotesSliceSelector,
  selectEntities,
);
