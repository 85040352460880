import { createActionGroup, props } from '@ngrx/store';
import { AnalyticsEvent } from './analytics.models';

export const AnalyticsApiActions = createActionGroup({
  source: 'Analytics/API',
  events: {
    'Record Event Success': props<{
      event: AnalyticsEvent;
      response: any;
    }>(),
    'Record Event Failure': props<{
      event: AnalyticsEvent;
      error: any;
    }>(),
  },
});

export const AnalyticsEffectsActions = createActionGroup({
  source: 'Analytics Effects',
  events: {
    'Record Event': props<{
      event: AnalyticsEvent;
    }>(),
  },
});
