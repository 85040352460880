import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IonCard,
  IonCardContent,
  IonItemDivider,
  IonSkeletonText,
} from '@ionic/angular/standalone';

@Component({
  selector: 'yeekatee-stats-skeleton',
  standalone: true,
  imports: [IonCard, IonCardContent, IonItemDivider, IonSkeletonText],
  templateUrl: './stats-skeleton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsSkeletonComponent {
  @Input() numLines = 1;
}
