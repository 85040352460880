import { Injectable } from '@angular/core';
import { TranslateTextGQL } from '@yeekatee/client-api-angular';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicTranslationService {
  constructor(private readonly translateTextGQL: TranslateTextGQL) {}

  /**
   * Send a text for translation to the API.
   *
   * @param sourceText The original text.
   * @param sourceLanguageCode
   * @param targetLanguageCode
   */
  translateText(
    sourceText: string,
    sourceLanguageCode: string,
    targetLanguageCode: string,
  ) {
    return this.translateTextGQL
      .fetch({
        input: { sourceText, sourceLanguageCode, targetLanguageCode },
      })
      .pipe(map(({ data }) => data.translateText));
  }
}
