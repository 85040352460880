import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { bulbOutline } from 'ionicons/icons';

@Component({
  standalone: true,
  selector: 'yeekatee-helpline',
  templateUrl: './helpline.component.html',
  styleUrls: ['./helpline.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
  ],
})
export class HelplineComponent {
  readonly breakpoints = [0, 0.5, 1];

  @Input() fullscreen?: boolean;

  get initialBreakpoint(): number {
    return this.fullscreen ? 1 : 0.5;
  }

  constructor() {
    addIcons({ bulbOutline });
  }
}
