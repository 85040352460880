import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'yeekatee-chart-no-data',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-no-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartNoDataComponent {
  @Input() height?: number;
  @Input() text?: string = $localize`Not enough data to display this chart`;
}
